import { Translation } from '../Localization.model';

class RegistrationLocale {
  public static REGISTRATION: Translation = { EN: 'Registration', CZ: 'Registrace' };
  public static PASSWORD: Translation = { EN: 'Password', CZ: 'Heslo' };
  public static PASSWORD_CONFIRMATION: Translation = { EN: 'Password confirmation', CZ: 'Heslo znovu' };
  public static PASSWORD_CONFIRMATION_PLACEHOLDER: Translation = { EN: 'Confirmation password', CZ: 'Potvrď heslo' };
  public static SURVEY: Translation = { EN: 'How did you find us?', CZ: 'Jak jste na nás přišli?' };
  public static SUBMIT_REG: Translation = { EN: 'Confirm', CZ: 'Registrovat' };
  public static USER_NAME: Translation = { EN: 'User name', CZ: 'Uživatelské jméno' };
  public static USER_NAME_PLACEHOLDER: Translation = { EN: 'Doe123', CZ: 'Doe123' };
  public static PERSONAL_INFORMATION: Translation = { EN: 'Personal information', CZ: 'Osobní údaje' };
  public static COMPANY_INFORMATION: Translation = { EN: 'Company information', CZ: 'Firemní údaje' };
  public static PAYMENT_INFORMATION: Translation = { EN: 'Payment information', CZ: 'Platební údaje' };
  public static PAYMENT_METHOD_ADDED: Translation = { EN: 'Payment information added', CZ: 'Platební metoda přidaná' };
  public static COMPANY_NUMBER: Translation = { EN: 'ID', CZ: 'IČO' };
  public static COMPANY_VAT: Translation = { EN: 'VAT NR.', CZ: 'DIČ' };
  public static CLIENT_NAME: Translation = { EN: 'Company name', CZ: 'Jméno firmy' };
  public static CLIENT_EMAIL: Translation = { EN: 'Billing email', CZ: 'Email pro fakturaci' };
  public static CLIENT_PHONE: Translation = { EN: 'Company phone', CZ: 'Telefon společnosti' };
  public static COMPANY_STREET: Translation = { EN: 'Street', CZ: 'Ulice' };
  public static COMPANY_CITY: Translation = { EN: 'City', CZ: 'Město' };
  public static COMPANY_ZIPCODE: Translation = { EN: 'ZIP', CZ: 'PSČ' };
  public static COMPANY_COUNTRY: Translation = { EN: 'Country', CZ: 'Stát' };
  public static SURVEY_WORK: Translation = { EN: 'Work', CZ: 'Z práce' };
  public static SURVEY_FRIEND: Translation = { EN: 'Friend', CZ: 'Od přátel' };
  public static SURVEY_WEB: Translation = { EN: 'Web', CZ: 'Z webu' };
  public static DIC_PLACEHOLDER: Translation = { EN: 'GB 123 456 789', CZ: 'CZ 123 456 789' };
  public static CLIENT_NAME_PLACEHOLDER: Translation = { EN: 'My company', CZ: 'Má firma' };
  public static COMPANY_STREET_PLACEHOLDER: Translation = { EN: 'Evergreen', CZ: 'Široká' };
  public static COMPANY_COUNTRY_PLACEHOLDER: Translation = { EN: 'England', CZ: 'Česká republika' };
  public static COMPANY_CITY_PLACEHOLDER: Translation = { EN: 'London', CZ: 'Praha' };
  public static COMPANY_ZIPCODE_PLACEHOLDER: Translation = { EN: '391 65', CZ: '391 65' };
  public static FIRST_NAME: Translation = { EN: 'First name', CZ: 'Jméno' };
  public static FIRST_NAME_PLACEHOLDER: Translation = { EN: 'John', CZ: 'Jan' };
  public static LAST_NAME: Translation = { EN: 'Last name', CZ: 'Příjmení' };
  public static LAST_NAME_PLACEHOLDER: Translation = { EN: 'Doe', CZ: 'Novák' };
  public static POSITION: Translation = { EN: 'Position', CZ: 'Pozice' };
  public static POSITION_PLACEHOLDER: Translation = { EN: 'Manager', CZ: 'Manažer' };
  public static EMAIL: Translation = { EN: 'Email', CZ: 'Email' };
  public static EMAIL_PLACEHOLDER: Translation = { EN: 'john.doe@mycompany.com', CZ: 'jan.novak@mojefirma.CZ' };
  public static EMAIL_ERROR_INVALID: Translation = { EN: 'Invalid email', CZ: 'Email není validní' };
  public static PHONE: Translation = { EN: 'Phone', CZ: 'Telefon' };
  public static PHONE_PLACEHOLDER: Translation = { EN: '+44 1234 1234', CZ: '+420123456789' };
  public static PHONE_ERROR_MIN_LENGTH: Translation = {
    EN: 'Phone has to have more than 8 numbers', CZ: 'Telefon musí mít více jak 8 číslic',
  };
  public static PHONE_ERROR_MAX_LENGTH: Translation = {
    EN: 'Phone has to have less than 15 numbers', CZ: 'Telefon musí mít méně než 15 číslic',
  };
  public static CZECH_REPUBLIC: Translation = { EN: 'Czech republic', CZ: 'Česká republika' };
  public static PASSWORD_CONFIRMATION_ERROR: Translation = { EN: 'Passwords don\'t match', CZ: 'Hesla se neshodují' };
  public static BACK_TO_LOGIN: Translation = { EN: 'Login', CZ: 'Přihlášení' };
  public static LOGIN_INFORMATION: Translation = { EN: '', CZ: '' };
  public static SELECT_VALUE: Translation = { EN: 'Select value', CZ: 'Vyberte hodnotu' };
  public static ADD_PAYMENT_CARD: Translation = { EN: 'Add payment card', CZ: 'Přidat platební kartu' };
  public static SUBMIT: Translation = { EN: 'Submit', CZ: 'Přidat' };

  public static LEGAL_MESSAGE_1_PART_1: Translation = {
    EN: 'I have read and agree to the Scaut, s.r.o. ',
    CZ: 'Souhlasím s ',
  };
  public static LEGAL_MESSAGE_1_PART_2: Translation = {
    EN: 'terms of service',
    CZ: 'obchodními podmínkami',
  };

  public static LEGAL_MESSAGE_2_PART_1: Translation = {
    EN: 'I have read the Scaut, s.r.o. ',
    CZ: 'Potvrzuji, že jsem se seznámil se ',
  };
  public static LEGAL_MESSAGE_2_PART_2: Translation = {
    EN: 'Privacy Policy ',
    CZ: 'Zásadami ochrany osobních údajů',
  };
  public static LEGAL_MESSAGE_2_PART_3: Translation = {
    EN: '',
    CZ: ' společnosti SCAUT, s.r.o.',
  };
}

export default RegistrationLocale;
