import React, { useEffect, useState } from 'react';
import {
  Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, useDisclosure,
} from '@chakra-ui/react';
import { H3, Translate } from '@scaut-sro/meepo';
import { AddIcon } from '@chakra-ui/icons';
import { CatalogueProps } from './Catalogue.model';
import ProductPicker from '../ProductPicker/ProductPicker';
import { useGetUser } from '../../core/store/reducers/UserSettingsReducer';
import { CountryProductsDto } from '../../build/generated-sources/dto/CountryProductsDto';
import CatalogueLocale from './Catalogue.locale';
import AddCountryModal from './AddCountryModal';

const Catalogue: React.FunctionComponent<CatalogueProps> = (props) => {
  const {
    data, packages, definitions, onChange, countries, allowedCountries, changeCountriesHandler,
  } = props;
  const { language } = useGetUser();
  const [globalProducts, setGlobalProducts] = useState<CountryProductsDto>();
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    data?.map((countryProduct) => {
      if (countryProduct.country?.translation?.name === 'Global') {
        setGlobalProducts(countryProduct);
      }
    });
  }, [data, setGlobalProducts]);

  return (
    <>
      { globalProducts ? (
        <ProductPicker
          screeningPackages={globalProducts.screeningPackages}
          screeningDefinitions={globalProducts.screeningDefinitions}
          onChange={onChange}
          packages={packages}
          definitions={definitions}
          packagesLabel={<Translate label={CatalogueLocale.RECCOMENDED_PACKAGES} />}
          screeningsLabel={<Translate label={CatalogueLocale.GLOBAL_SCREENINGS} />}
        />
      ) : undefined}
      <Accordion defaultIndex={[0]} allowMultiple>
        {data?.map((countryCatalogue) => {
          if (countryCatalogue.country?.translation?.name !== 'Global') {
            return (
              <AccordionItem key={`catalogue_${countryCatalogue.country?.translation?.localizationStrings?.[language]}`}>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    <H3 mt={3} mb={3}>{countryCatalogue.country?.translation?.localizationStrings?.[language]}</H3>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel p={6}>
                  <ProductPicker
                    screeningPackages={countryCatalogue.screeningPackages}
                    screeningDefinitions={countryCatalogue.screeningDefinitions}
                    onChange={onChange}
                    packages={packages}
                    definitions={definitions}
                  />
                </AccordionPanel>
              </AccordionItem>
            );
          }
          return undefined;
        })}
        <AccordionItem>
          <AccordionButton onClick={onOpen}>
            <Box flex="1" textAlign="left">
              <H3 mt={3} mb={3}>
                <AddIcon mr={3} mt={-1} fontSize="md" />
                <Translate label={CatalogueLocale.ADD_COUNTRY} />
              </H3>
            </Box>
          </AccordionButton>
          {isOpen && (
          <AddCountryModal
            isOpen={isOpen}
            onClose={onClose}
            data={data}
            countries={countries}
            allowedCountries={allowedCountries}
            changeCountriesHandler={changeCountriesHandler}
          />
          )}
        </AccordionItem>
      </Accordion>
    </>
  );
};

export default Catalogue;
