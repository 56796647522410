import React, { useState } from 'react';
import {
  Button, ButtonGroup, Flex, Text,
} from '@chakra-ui/react';
import { SectionContainer, Translate } from '@scaut-sro/meepo';
import TagManager from 'react-gtm-module';
import NotificationBoxes from '../../components/NotificationBox/NotificationBoxes';
import OverviewLastChecksOptions from './OverviewLastChecksOptions';
import OverviewLastChecksLocale from './OverviewLastChecks.locale';
import { OrderTypeOptions } from './OverviewLastChecks.model';
import { PrimaryOrderOverviewFilter } from '../../build/generated-sources/dto/PrimaryOrderOverviewFilter';
import { LastRecordType } from '../../build/generated-sources/enum/LastRecordType';
import { Result } from '../../build/generated-sources/enum/Result';
import { useGetUser } from '../../core/store/reducers/UserSettingsReducer';

const filters: PrimaryOrderOverviewFilter[] = [
  {
    filterId: 'Unresolved',
    filter: {
      results: [Result.UNRESOLVED],
    },
    lastRecordType: LastRecordType.FINISHED,
  },
  {
    filterId: 'Resolved',
    filter: {
      results: [Result.CLEAR, Result.FOUND_RESULTS],
    },
    lastRecordType: LastRecordType.FINISHED,
  },
  {
    filterId: 'Clear',
    filter: {
      results: [Result.CLEAR],
    },
    lastRecordType: LastRecordType.FINISHED,
  },
  {
    filterId: 'FoundResults',
    filter: {
      results: [Result.FOUND_RESULTS],
    },
    lastRecordType: LastRecordType.FINISHED,
  },
];

const Overview: React.FunctionComponent = () => {
  const { language, userProfile } = useGetUser();
  const [orderType, setOrderType] = useState<OrderTypeOptions>('ALL');
  const [filter, setFilter] = useState<PrimaryOrderOverviewFilter>({
    filterId: 'Everything',
    filter: {},
    lastRecordType: LastRecordType.CREATED,
  });
  TagManager.dataLayer({
    dataLayer: {
      event: 'page_view',
      realm: 'client',
      page: {
        url: document.location.origin + document.location.pathname + document.location.search,
        title: 'Overview',
      },
      user: {
        email: userProfile?.email,
        given_name: userProfile?.firstName,
        family_name: userProfile?.lastName,
        job_position: userProfile?.jobPosition,
        language,
      },
    },
  });

  return (
    <SectionContainer>
      <Flex>
        <Text fontSize="3xl" mb={6} fontWeight={400} color="#002458">
          <Translate label={OverviewLastChecksLocale.OVERVIEW} />
        </Text>
        <ButtonGroup size="sm" ml={8} isAttached variant="outline">
          <Button
            bg={orderType === 'ALL' ? '#E6F0FE' : 'transparent'}
            color={orderType === 'ALL' ? '#0066F8' : '#5E6C7A'}
            mr="-px"
            height="32px"
            minWidth="70px"
            onClick={() => { setOrderType('ALL'); }}
          >
            <Translate label={OverviewLastChecksLocale.ALL} />
          </Button>
          <Button
            bg={orderType === 'SINGLE' ? '#E6F0FE' : 'transparent'}
            color={orderType === 'SINGLE' ? '#0066F8' : '#5E6C7A'}
            mr="-px"
            height="32px"
            onClick={() => { setOrderType('SINGLE'); }}
          >
            <Translate label={OverviewLastChecksLocale.SINGLE} />
          </Button>
          <Button
            bg={orderType === 'MULTIPLE' ? '#E6F0FE' : 'transparent'}
            color={orderType === 'MULTIPLE' ? '#0066F8' : '#5E6C7A'}
            mr="-px"
            height="32px"
            onClick={() => { setOrderType('MULTIPLE'); }}
          >
            <Translate label={OverviewLastChecksLocale.MULTIPLE} />
          </Button>
        </ButtonGroup>
      </Flex>
      <NotificationBoxes setFilter={(index) => { setFilter(filters[index]); }} filters={filters} />
      <OverviewLastChecksOptions type={orderType} filter={filter.filter} />
    </SectionContainer>
  );
};
export default Overview;
