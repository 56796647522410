import { Translation } from '../../core/localization/Localization.model';

class ChecksLocale {
  public static CHECKS: Translation = { EN: 'Checks', CZ: 'Kontroly' };
  public static LABEL: Translation = { EN: 'Name', CZ: 'Název' };
  public static CUSTOM_ID: Translation = { EN: 'Custom ID', CZ: 'Identifikátor' };
  public static TEAM: Translation = { EN: 'Team', CZ: 'Tým' };
  public static ORDER_RESULT: Translation = { EN: 'Result', CZ: 'Výsledek' };
  public static ORDER_STATE: Translation = { EN: 'State', CZ: 'Stav' };
  public static DATE_OF_BIRTH: Translation = { EN: 'Date of birth', CZ: 'Datum narození' };
  public static SINGLE_CHECK: Translation = { EN: 'Single person', CZ: 'Jednotlivec' };
  public static MULTIPLE_PEOPLE: Translation = { EN: 'Multiple people', CZ: 'Hromadné zadání' };
  public static CZECH_REPUBLIC: Translation = { EN: 'Czech Republic', CZ: 'Česká Republika' };
  public static SLOVAK_REPUBLIC: Translation = { EN: 'Slovak Republic', CZ: 'Slovenská Republika' };
  public static ORDER_TYPE: Translation = { EN: 'Type', CZ: 'Typ' };
  public static ORDERED_BY: Translation = { EN: 'Ordered by', CZ: 'Objednal' };
  public static ORDER_CREATED: Translation = { EN: 'Ordered', CZ: 'Zadáno' };
  public static TABLE_DETAIL_GO_TO_DETAIL: Translation = { EN: 'Go to detail', CZ: 'Přejít na detail' };
  public static TABLE_DETAIL_DOWNLOAD_REPORT: Translation = { EN: 'Download report', CZ: 'Stáhnout report' };
  public static TABLE_DETAIL_DOWNLOAD_MULTIPLE_REPORT: Translation = {
    EN: 'Download multiple report',
    CZ: 'Stáhnout hromadný report',
  };
  public static TABLE_DETAIL_SHARE: Translation = { EN: 'Share', CZ: 'Sdílet' };
  public static TABLE_DETAIL_INFO: Translation = { EN: 'Information', CZ: 'Informace' };
  public static COUNTRY: Translation = { EN: 'Country', CZ: 'Země' };
  public static TABLE_DETAIL_SCREENINGS: Translation = { EN: 'Screenings', CZ: 'Prováděné kontroly' };
  public static TABLE_DETAIL_CANDIDATE_SCREENING_START: Translation = { EN: 'Candidate screening start', CZ: 'Začátek kontroly' };
  public static TABLE_DETAIL_CANDIDATE_SCREENING_END: Translation = {
    EN: 'Candidate screening completion',
    CZ: 'Dokončení kontroly',
  };
  public static ORDER_PAID_SUCCESS: Translation = {
    EN: 'Order successfully paid', CZ: 'Objednávka byla úspěšně zaplacena',
  };
  public static ORDER_PAY_ERROR: Translation = {
    EN: 'There was a problem paying the order', CZ: 'Při platbě došlo k problému',
  };
  public static TABLE_DETAIL_MULTIPLE_CHECK_START: Translation = {
    EN: 'Multiple check start',
    CZ: 'Začátek hromadné kontroly',
  };
  public static TABLE_DETAIL_MULTIPLE_CHECK_END: Translation = {
    EN: 'Multiple check completion',
    CZ: 'Dokončení hromadné kontroly',
  };
  public static TABLE_DETAIL_CANDIDATE_SCREENING_ANONYMIZED: Translation = {
    EN: 'This screening will be anonymized',
    CZ: 'Kontrola bude anonymizována',
  };
  public static TABLE_DETAIL_UNFINISHED_ORDER: Translation = {
    EN: 'Unfinished order',
    CZ: 'Rozpracovaná objednávka',
  };
  public static TABLE_DETAIL_MULTIPLE_CHECK: Translation = {
    EN: 'Multiple check order',
    CZ: 'Objedávka hromadné kontroly',
  };
  public static TABLE_DETAIL_CANDIDATES_COUNT: Translation = {
    EN: 'Candidate count',
    CZ: 'Počet kontrolovaných osob',
  };
  public static ERROR_GETTING_DATA: Translation = {
    EN:
      'There was a problem getting data',
    CZ: 'Při získávání dat došlo k problému',
  };
  public static ERROR_GETTING_ORDERS: Translation = {
    EN:
      'There was a problem getting orders',
    CZ: 'Při získávání objednávek došlo k problému\n',
  };
  public static ERROR_GETTING_SUBSCRIPTIONS: Translation = {
    EN: 'There was a problem getting client payment methods.',
    CZ: 'Problém při načitání platebních metod.',
  };
}

export default ChecksLocale;
