import * as React from 'react';
import {
  useEffect, useMemo, useState,
} from 'react';
import {
  Box, Button, Flex, FormControl, FormLabel, Select, SimpleGrid, Text,
} from '@chakra-ui/react';
import {
  H2, Toast,
} from '@scaut-sro/meepo';
import { translate } from '../../../core/localization/LocaleUtils';
import { useGetUser } from '../../../core/store/reducers/UserSettingsReducer';
import Translate from '../../../components/Translate/Translate';
import FormLocale from '../../../core/localization/translations/Form.locale';
import ChecksLocale from '../../Checks/Checks.locale';
import {
  useGetAllowedClientPaymentMethods,
  useGetExecutionDetailGetPrice,
} from '../../../build/generated-sources/service/QueryService';
import { PaymentMethod } from '../../../build/generated-sources/enum/PaymentMethod';
import CandidateScreeningDetailLocale from '../CandidateScreeningDetail.locale';
import { ExecutionsModalProps } from '../CandidateScreeningDetail.model';
import { CountryProductsDto } from '../../../build/generated-sources/dto/CountryProductsDto';
import { ScreeningDefinitionDto } from '../../../build/generated-sources/dto/ScreeningDefinitionDto';
import ListOfServices from '../../../components/ListOfServices/ListOfServices';
import TotalPrice from '../../../components/TotalPrice/TotalPrice';
import { useSubmitExecutionDetails } from '../../../build/generated-sources/service/MutationService';
import { Language } from '../../../build/generated-sources/enum/Language';
import { AllowedPaymentMethod } from '../../../build/generated-sources/dto/AllowedPaymentMethod';
import NewCheckLocale from '../../NewCheck/NewCheck.locale';
import { Translation } from '../../../core/localization/Localization.model';

const ExecutionsConfirmation: React.FunctionComponent<ExecutionsModalProps> = (props) => {
  const {
    searchResults, formId, loadingSetter, loading, onClose, refreshData,
  } = props;
  const { language } = useGetUser();
  const [clientAllowedPaymentMethods, setClientAllowedPaymentMethods] = useState<{ [key:string]: AllowedPaymentMethod }>({});
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethod>(PaymentMethod.CARD_PAYMENT);
  const [executionDetailPrice, setExecutionDetailPrice] = useState<number>();

  useGetExecutionDetailGetPrice(
    {
      price: true,
    }, {
      onError: () => {
        Toast({
          title: translate(CandidateScreeningDetailLocale.ERROR_GETTING_DATA, language),
          status: 'error',
        });
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: (response) => {
        setExecutionDetailPrice(response.executionDetailGetPrice.price);
      },
    },
  );

  const screeningData = useMemo(() => {
    if (executionDetailPrice) {
      const products: CountryProductsDto[] = [];
      const definitions: ScreeningDefinitionDto[] = [];
      const price = executionDetailPrice * searchResults?.length;
      searchResults?.map((searchResult) => {
        definitions.push({
          name: {
            localizationStrings: {
              EN: `${CandidateScreeningDetailLocale.EXECUTION_DETAIL[Language.EN]} ${searchResult.code}`,
              CZ: `${CandidateScreeningDetailLocale.EXECUTION_DETAIL[Language.CZ]} ${searchResult.code}`,
            },
          },
          price: executionDetailPrice,
        });
      });
      products.push({
        screeningDefinitions: definitions,
      });
      return { price, products };
    }
    return {};
  }, [searchResults, executionDetailPrice]);

  useGetAllowedClientPaymentMethods(
    {
      paymentMethod: true,
      hasPaymentProfile: true,
    }, {
      onError: () => {
        Toast({
          title: translate(ChecksLocale.ERROR_GETTING_SUBSCRIPTIONS, language),
          status: 'error',
        });
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: (response) => {
        const allowedPaymentMethods: { [key:string]: AllowedPaymentMethod } = {};

        response.allowedClientPaymentMethods.forEach((allowedPaymentMethod) => {
          allowedPaymentMethods[`${allowedPaymentMethod.paymentMethod}`] = allowedPaymentMethod;
        });

        setClientAllowedPaymentMethods(allowedPaymentMethods || {});
        if (!response.allowedClientPaymentMethods) {
          Toast({
            title: translate(CandidateScreeningDetailLocale.ERROR_GETTING_DATA, language),
            status: 'error',
          });
        } else if (response.allowedClientPaymentMethods.length > 0) {
          const allowedClientPaymentMethod = response.allowedClientPaymentMethods[0];
          setSelectedPaymentMethod(allowedClientPaymentMethod.paymentMethod as PaymentMethod);
        }
      },
    },
  );

  const clientSubscriptionOptionsList = useMemo(() => Object.keys(clientAllowedPaymentMethods).map((key) => {
    const paymentMethod = clientAllowedPaymentMethods[key].paymentMethod as PaymentMethod;
    const translation = NewCheckLocale[paymentMethod] || paymentMethod;

    return ({
      value: clientAllowedPaymentMethods[key].paymentMethod,
      label: translate(translation, language) || '',
    });
  }), [clientAllowedPaymentMethods, language]);

  const [mutateSubmitExecutionDetails,
    mutationSubmitExecutionDetailsOptions] = useSubmitExecutionDetails({
    success: true,
  }, {
    onCompleted: () => {
      Toast({
        title: translate(FormLocale.SUCCESS, language),
        status: 'success',
      });
      if (onClose) {
        onClose();
      }
      if (refreshData) {
        refreshData();
      }
    },
    onError: () => {
      Toast({
        title: translate(CandidateScreeningDetailLocale.ERROR_GETTING_DATA, language),
        status: 'error',
      });
    },
  });

  useEffect(() => {
    if (loadingSetter) loadingSetter(mutationSubmitExecutionDetailsOptions.loading);
  }, [loadingSetter, mutationSubmitExecutionDetailsOptions]);

  const handlePayOrderSubmit = () => {
    const executions: string[] = [];
    searchResults?.map((searchResult) => {
      executions.push(searchResult.code);
    });
    mutateSubmitExecutionDetails({
      variables: {
        formId: formId || -1,
        paymentMethod: selectedPaymentMethod,
        formData: {
          executionDetails: executions,
        },
      },
    });
  };

  const handlePaymentSelect = (value: PaymentMethod) => {
    setSelectedPaymentMethod(value);
  };

  const errorMessage: Translation | undefined = useMemo(() => {
    const allowedMethod = clientAllowedPaymentMethods[`${selectedPaymentMethod}`];
    if (allowedMethod && selectedPaymentMethod === PaymentMethod.CARD_PAYMENT && !allowedMethod.hasPaymentProfile) {
      return NewCheckLocale.MISSING_PAYMENT_CARD;
    }

    return undefined;
  }, [selectedPaymentMethod, clientAllowedPaymentMethods]);

  return (
    <>
      <H2 mb={6} mt={8}><Translate label={CandidateScreeningDetailLocale.DETAILS} /></H2>
      <Box mb={8}>
        <ListOfServices data={screeningData.products} />
      </Box>
      {screeningData.price ? (
        <TotalPrice price={screeningData.price} isMultipleCheck={false} numberOfPersons={5} />
      ) : undefined}

      {
        clientSubscriptionOptionsList.length > 0
          ? (
            <>
              <H2 mt={8} mb={6}>
                <Translate label={CandidateScreeningDetailLocale.PAYMENT_INFORMATION} />
              </H2>
              <SimpleGrid columns={[1, 2, 2, 3, 3]} spacing={[6]}>
                <FormControl mt={3}>
                  <FormLabel><Translate label={CandidateScreeningDetailLocale.PAYMENT_METHOD} /></FormLabel>
                  <Select
                    onChange={(event: any) => {
                      handlePaymentSelect(event.target.value);
                    }}
                    value={selectedPaymentMethod}
                    defaultValue={selectedPaymentMethod}
                  >
                    <option value="">- Select value -</option>
                    { clientSubscriptionOptionsList.map((subscription) => (
                      <option key={subscription.value} value={subscription.value}>
                        {`${subscription.label}`}
                      </option>
                    )) }
                  </Select>
                </FormControl>
              </SimpleGrid>

            </>
          )
          : undefined
      }
      {
        errorMessage ? (
          <Text fontSize="xs" color="red"><Translate label={errorMessage} /></Text>
        ) : undefined
      }

      <Flex mt={8} justifyContent="flex-end">
        <Button
          isLoading={loading}
          onClick={handlePayOrderSubmit}
          isDisabled={!selectedPaymentMethod || !!errorMessage || !executionDetailPrice}
        >
          <Translate label={FormLocale.PAY_ORDER} />
        </Button>
      </Flex>
    </>
  );
};

export default ExecutionsConfirmation;
