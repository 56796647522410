import { Translation } from '../../../core/localization/Localization.model';

class AnonymizationLocale {
  public static ANONYMIZATION: Translation = { EN: 'Data anonymization', CZ: 'Anonymizace údajů' };
  public static ANONYMIZATION_SELECT: Translation = { EN: 'Automatic anonymization', CZ: 'Automatická anonymizace' };
  public static ANONYMIZATION_SELECT_2: Translation = {
    EN: 'from check completion',
    CZ: 'od ukončení prověření',
  };
  public static TEN_DAYS: Translation = { EN: '10 days', CZ: '10 dnů' };
  public static THIRTY_DAYS: Translation = { EN: '30 days', CZ: '30 dnů' };
  public static SIXTY_DAYS: Translation = { EN: '60 days', CZ: '60 dnů' };
  public static NINETY_DAYS: Translation = { EN: '90 days', CZ: '90 dnů' };
  public static YEAR: Translation = { EN: '1 year', CZ: '1 rok' };
  public static CONFIRM_SETTINGS: Translation = { EN: 'Confirm settings', CZ: 'Potvrdit nastavení' };
  public static INFORMATION_TEXT: Translation = {
    EN: 'Set the retention period according to your internal policies or procedures'
        + ' By default, the period is set to 90 days from completion of a check. Anonymization is permanent!',
    CZ: 'Lhůta, po jaké má dojít k automatické anonymizaci Vašich dat.'
        + ' Standardně je nastavena na 90 dní of dokončení kontroly. Anonymizace je nevratný krok!',

  };
  public static WHAT_IS_ANONYMIZATION: Translation = {
    EN: 'What is data anonymization?',
    CZ: 'Co je anonymizace údajů?',
  };
  public static ANONYMIZATION_TEXT_1: Translation = {
    EN: 'During anonymization, names, surnames and addresses are deleted from every row. Fields that are still'
        + ' displayed are date of birth and any categories filled out by you. Graphs stay unchanged so that you'
        + ' can still work with your anonymized reports. If you want to continue working with complete list of names,'
        + ' the only way to do that is to export the list to your computer before anonymization has been fulfilled.',
    CZ: 'Při anonymizaci dochází ke smazání jména, příjmení a adresy na každém řádku. Pole, která zůstávají'
        + ' zobrazená jsou datum narození a případně Vámi zadané kategorie. Grafy zůstávají v nezměněné podobě,'
        + ' abyste se svými anonymizovanými reporty mohli dále pracovat. Pokud chcete pracovat s kompletním'
        + ' jmenným seznam i po době anonymizace, jediný způsob je tento seznam exportovat do svého počitače'
        + ' před uplynutím lhůty automatické anonymizace.',
  };
  public static ANONYMIZATION_TEXT_2: Translation = {
    EN: 'Anonymization settings are the same for all user accounts under the same company.',
    CZ: 'Nastavení anonymizační lhůty je společné pro všechny uživatelské účty spadající pod stejnou společnost.',
  };
  public static ANONYMIZATION_CHANGE_SUCCESS: Translation = {
    EN: 'Settings changed succesfully', CZ: 'Nastavení úspěšně změněno',
  };
}

export default AnonymizationLocale;
