import { gql } from '@apollo/client';

// eslint-disable-next-line
export const getPasswordPolicies = gql`
      query passwordPolicies {
          passwordPolicies @rest(type: "passwordPolicies", path: "/passwordPolicies") {
              policy
              value
          }
      }
  `;
