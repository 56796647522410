import { Translation } from '../../../core/localization/Localization.model';

class CreditsLocale {
  public static YOUR_CREDIT: Translation = { EN: 'Your credit', CZ: 'Váš kredit' };
  public static ADD_CREDIT: Translation = { EN: 'Add credit', CZ: 'Dobít kredit' };
  public static CHANGE_PAYMENT_CARD: Translation = { EN: 'Change payment card', CZ: 'Změnit platební kartu' };
  public static ADD_PAYMENT_CARD: Translation = { EN: 'Add payment card', CZ: 'Přidat platební kartu' };
  public static PAYMENT_CARD: Translation = { EN: 'Payment card', CZ: 'Platební karta' };
  public static LAST_CHARGE: Translation = { EN: 'Last charged', CZ: 'Naposledy dobito' };
  public static NEED_HELP: Translation = { EN: 'Top up credit by invoice?', CZ: 'Chcete nabít kredit na fakturu?' };
  public static WRITE_TO_US: Translation = { EN: 'Contact us at ', CZ: 'Napište nám na adresu ' };
  public static UNKNOWN_USER: Translation = { EN: 'Unknown user', CZ: 'Neznámý uživatel' };
  public static ADD_CREDIT_INFO: Translation = {
    EN: 'Enter amount and confirm by clicking on the "Add credit" button.',
    CZ: 'Zadejte částku a potvrďte tlačítkem "Dobít kredit.',
  };
  public static CHANGE_PAYMENT_CARD_INFO: Translation = {
    EN: 'Enter your new payment card data and confirm by clicking on the "Change payment card" button.',
    CZ: 'Zadejte informace o své nové platební kartě a potvrďte tlačítkem "Změnit platební kartu.',
  };
  public static ADD_PAYMENT_CARD_INFO: Translation = {
    EN: 'Enter your new payment card data and confirm by clicking on the "Add payment card" button.',
    CZ: 'Zadejte informace o své nové platební kartě a potvrďte tlačítkem "Přidat platební kartu.',
  };
  public static CREDIT_HISTORY: Translation = { EN: 'Charge history', CZ: 'Historie dobití' };
  public static DATE: Translation = { EN: 'Date', CZ: 'Datum' };
  public static AMOUNT: Translation = { EN: 'Amount', CZ: 'Částka' };
  public static USER: Translation = { EN: 'User', CZ: 'Uživatel' };
  public static CARD_NUMBER: Translation = { EN: 'Card number', CZ: 'Číslo karty' };
  public static CARD_MONTH: Translation = { EN: 'Month', CZ: 'Měsíc' };
  public static CARD_YEAR: Translation = { EN: 'Year', CZ: 'Rok' };
  public static CARD_CVV: Translation = { EN: 'CVV', CZ: 'CVV' };
  public static ERROR_CHANGING_PAYMENT_CARD: Translation = {
    EN: 'There was a problem submitting payment card.',
    CZ: 'Při odesílání platební karty došlo k problému.',
  };
  public static CREDIT_CHARGE_SUCCESSFUL: Translation = {
    EN: 'Paid succesfully', CZ: 'Úspěšně zaplaceno',
  };
  public static PAYMENT_CARD_CHANGE_SUCCESSFUL: Translation = {
    EN: 'Added succesfully', CZ: 'Úspěšně přidáno',
  };
  public static MISSING_PAYMENT_CARD: Translation = {
    EN: 'Missing payment card for adding credits. Please add a payment card below.',
    CZ: 'Pro přidání kreditů je nutné přidat platební kartu. Níže prosíme přidejte platební kartu.',
  };
  public static INVALID_CARD_NUMBER: Translation = {
    EN: 'Payment card number is invalid', CZ: 'Číslo platební karty je neplatné',
  };
  public static INVALID_MONTH: Translation = {
    EN: 'Payment card month is invalid', CZ: 'Měsíc platební karty je neplatný',
  };
  public static INVALID_YEAR: Translation = {
    EN: 'Payment card year is invalid', CZ: 'Rok platební karty je neplatný',
  };
  public static INVALID_CVV: Translation = {
    EN: 'Payment card CVV is invalid', CZ: 'CVV platební karty je neplatné',
  };
  public static ERROR_GETTING_PREPAYMENTS: Translation = {
    EN: 'There was a problem getting charge history.',
    CZ: 'Při získávání historie kreditů došlo k problému.',
  };
  public static ERROR_GETTING_PAYMENT_CARD_INFO: Translation = {
    EN: 'There was a problem getting payment card info.',
    CZ: 'Při získávání informací o platební kartě došlo k problému.',
  };
  public static ERROR_DELETING_PAYMENT_CARD: Translation = {
    EN: 'There was a problem deleting payment card.',
    CZ: 'Při mazání platební karty došlo k problému.',
  };
  public static PAYMENT_CARD_DELETED: Translation = {
    EN: 'Payment card successfully removed.',
    CZ: 'Platební karta byla úspěšně odstraněna.',
  };
  public static PAYMENT_CARD_DELETE_CONFIRMATION: Translation = {
    EN: 'Are you sure you want to delete payment card',
    CZ: 'Opravdu chcete smazat platební kartu',
  };
}

export default CreditsLocale;
