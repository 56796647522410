import React, { useEffect, useMemo, useState } from 'react';
import {
  H2, MeepoTable, SectionContainer, Toast,
} from '@scaut-sro/meepo';
import {
  Box, Flex, HStack, Spacer, Text,
} from '@chakra-ui/react';
import {
  UserDetailSwitchType,
  UsersProps,
  UsersTableData,
  UsersTableFilterAllFilterType,
  UsersTableFilterType,
} from './Users.model';
import UsersLocale from './Users.locale';
import UserDetailModal from './UserDetail/UserDetailModal';
import { ClientUserDto } from '../../../build/generated-sources/dto/ClientUserDto';
import Translate from '../../../components/Translate/Translate';
import { useGetUser } from '../../../core/store/reducers/UserSettingsReducer';
import { Direction } from '../../../build/generated-sources/enum/Direction';
import { useLazyGetClientUsersPage } from '../../../build/generated-sources/service/QueryService';
import DetailHeader from '../../../components/DetailHeader/DetailHeader';
import { translate } from '../../../core/localization/LocaleUtils';
import TableLocale from '../../../core/localization/translations/Table.locale';
import UserDetail from './UserDetail/UserDetail';
import UsersTableFilter from './UsersTableFilter';
import UserDetailSwitch from './UserDetail/UserDetailSwitch';
import { Language } from '../../../build/generated-sources/enum/Language';

export function getClientUsersTableData(data: ClientUserDto[] | undefined,
  setUsersUpdated: (updated: boolean) => void, language: Language, loggedUserEmail?: string): UsersTableData[] {
  if (data) {
    return data.map((clientUserDto) => {
      let teamsString = '';
      clientUserDto.teams?.map((team, index) => {
        if (team.name) {
          if (clientUserDto.teams!.length === index + 1) {
            teamsString += team.name;
          } else {
            teamsString += `${team.name}, `;
          }
        }
      });

      const isCurrentUser = !!loggedUserEmail && loggedUserEmail === clientUserDto.email;
      let name = clientUserDto?.firstName && clientUserDto?.lastName
        ? `${clientUserDto?.firstName} ${clientUserDto?.lastName}` : clientUserDto?.email;
      if (isCurrentUser) {
        name += ` ${translate(UsersLocale.YOU, language)}`;
      }

      return {
        id: clientUserDto?.id || '-undefined-',
        name,
        teams: teamsString,
        active: <UserDetailSwitch
          switchType={UserDetailSwitchType.ACTIVE}
          clientUser={clientUserDto}
          setUsersUpdated={setUsersUpdated}
          disabled={isCurrentUser}
        />,
        admin: <UserDetailSwitch
          switchType={UserDetailSwitchType.ROLE}
          clientUser={clientUserDto}
          setUsersUpdated={setUsersUpdated}
          disabled={isCurrentUser}
        />,
        detail: <UserDetail clientUser={clientUserDto} setUsersUpdated={setUsersUpdated} loggedUserEmail={loggedUserEmail} />,
      };
    });
  }
  return [];
}

const Users:React.FunctionComponent<UsersProps> = (props) => {
  const { filters } = props;
  const [tableFilter, setTableFilter] = useState<UsersTableFilterType>({});
  const [allFilters, setAllFilter] = useState<UsersTableFilterType>({});
  const [usersList, setUsersList] = useState<UsersTableData[]>([]);
  const [usersUpdated, setUsersUpdated] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const { language, userProfile } = useGetUser();
  const [sorting, setSorting] = useState<{ sortBy?: string, sortDirection?: Direction }>({});
  const size = 100;

  const [usersHandle, { loading }] = useLazyGetClientUsersPage({
    content: {
      id: true,
      email: true,
      firstName: true,
      lastName: true,
      phone: true,
      teams: {
        id: true,
        name: true,
      },
      role: true,
      active: true,
      lastActive: true,
    },
  }, {
    onError: () => {
      Toast({
        title: translate(UsersLocale.ERROR_GETTING_USERS, language),
        status: 'error',
      });
    },
    variables: {
      search: allFilters.fulltext && allFilters.fulltext.length ? allFilters.fulltext : undefined,
      paging: {
        page,
        size,
        sortBy: sorting.sortBy,
        sortDirection: sorting.sortDirection,
      },
    },
    fetchPolicy: 'no-cache',
    onCompleted: (response) => {
      const onCompleteData = getClientUsersTableData(response.clientUsersPage.content,
        setUsersUpdated, language, userProfile?.email);
      if (page === 0) {
        setUsersList(onCompleteData);
      } else {
        setUsersList((prev) => [...prev, ...onCompleteData]);
      }
    },
  });

  useMemo(() => {
    const newAllFilters: UsersTableFilterAllFilterType = {
      ...filters,
      ...tableFilter,
    };
    setAllFilter(newAllFilters);
    setPage(0);
  }, [filters, tableFilter]);

  useEffect(() => {
    if (!usersList || usersList.length === 0) {
      usersHandle();
    }
  }, [usersHandle, usersList]);

  useEffect(() => {
    if (usersUpdated) {
      usersHandle();
      setUsersUpdated(false);
    }
  }, [usersHandle, setUsersUpdated, usersUpdated]);

  return (
    <>
      <SectionContainer>
        <Box overflowX="auto">
          <Box minW="600px">
            <Flex>
              <DetailHeader>
                <H2><Translate label={UsersLocale.USERS} /></H2>
              </DetailHeader>
              <Spacer />
              <HStack justifyContent="right" alignItems="top">
                <UserDetailModal clientUser={{}} setUsersUpdated={setUsersUpdated} />
              </HStack>
            </Flex>
            <Box overflowX="auto">
              <Box mb={8}>
                <UsersTableFilter
                  filter={tableFilter}
                  onFilterChange={(newTableFilter) => {
                    setTableFilter(newTableFilter);
                  }}
                />
              </Box>
              <Box minW="600px">
                <MeepoTable
                  columns={[
                    {
                      id: 'name',
                      label: translate(UsersLocale.NAME, language),
                      minW: '200px',
                    },
                    {
                      id: 'teams',
                      label: translate(UsersLocale.TEAMS, language),
                      minW: '100px',
                    },
                    {
                      id: 'active',
                      label: translate(UsersLocale.ACTIVE, language),
                      minW: '100px',
                    },
                    {
                      id: 'admin',
                      label: translate(UsersLocale.ADMIN, language),
                      minW: '100px',
                    },
                  ]}
                  data={usersList}
                  isLoading={loading}
                  noData={<Text><Translate label={TableLocale.NO_DATA} /></Text>}
                  setPage={() => {
                    setPage((prev) => prev + 1);
                  }}
                  onSort={(newSorting) => {
                    const direction: Direction | undefined = newSorting.type === 'asc'
                      ? Direction.ASC
                      : newSorting.type === 'desc'
                        ? Direction.DESC
                        : undefined;
                    setSorting({
                      sortBy: newSorting.column,
                      sortDirection: direction,
                    });
                  }}
                  activeSorting={{
                    column: sorting.sortBy,
                    type: sorting.sortDirection === Direction.ASC
                      ? 'asc'
                      : sorting.sortDirection === Direction.DESC
                        ? 'desc'
                        : 'none',
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </SectionContainer>
    </>
  );
};
export default Users;
