import React from 'react';
import {
  Box, Button,
  SimpleGrid, useDisclosure,
} from '@chakra-ui/react';
import {
  ConfirmationModal,
  InfoContainer, ReadOnlyInput, Toast,
} from '@scaut-sro/meepo';
import { useGetUser } from '../../../../core/store/reducers/UserSettingsReducer';
import { translate } from '../../../../core/localization/LocaleUtils';
import { UserDetailProps } from '../Users.model';
import { showDateTime } from '../../../../core/date/showDate';
import UsersLocale from '../Users.locale';
import Translate from '../../../../components/Translate/Translate';
import FormLocale from '../../../../core/localization/translations/Form.locale';
import { useClientUserDelete } from '../../../../build/generated-sources/service/MutationService';
import UserDetailModal from './UserDetailModal';

const UserDetail: React.FunctionComponent<UserDetailProps> = (props) => {
  const { clientUser, setUsersUpdated, loggedUserEmail } = props;
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
  const { language } = useGetUser();

  const [mutateUser] = useClientUserDelete({
    success: true,
  }, {
    onCompleted: () => {
      Toast({
        title: translate(UsersLocale.CLIENT_USER_DELETED, language),
        status: 'success',
      });
      setUsersUpdated(true);
      onDeleteClose();
    },
    onError: () => {
      Toast({
        title: translate(UsersLocale.ERROR_DELETING_CLIENT_USER, language),
        status: 'error',
      });
    },
  });

  const deleteUser = () => {
    mutateUser({
      variables: {
        clientUserId: clientUser.id || -1,
      },
    });
  };

  return (
    <>
      {clientUser ? (
        <InfoContainer>
          <SimpleGrid columns={[1, 2, 2, 5]} spacing={[6]}>
            <ReadOnlyInput
              label={translate(UsersLocale.PHONE, language)}
              value={clientUser.phone}
            />
            <ReadOnlyInput
              label={translate(UsersLocale.EMAIL, language)}
              value={clientUser.email}
            />
            <ReadOnlyInput
              label={translate(UsersLocale.LAST_ACTIVE, language)}
              value={showDateTime(language, clientUser.lastActive)}
            />
            <Box />
            <Box>
              <SimpleGrid columns={[1, 1, 1, 1]} spacing={[6]}>
                <UserDetailModal clientUser={clientUser} setUsersUpdated={setUsersUpdated} />
                <Button
                  onClick={() => onDeleteOpen()}
                  variant="ghost"
                  color="grey"
                  disabled={clientUser.email === loggedUserEmail}
                >
                  <Translate label={FormLocale.DELETE} />
                </Button>
                <ConfirmationModal
                  message={translate(UsersLocale.CLIENT_USER_DELETE_CONFIRMATION, language)}
                  yes={translate(FormLocale.YES, language)}
                  no={translate(FormLocale.NO, language)}
                  name={`${clientUser?.firstName} ${clientUser?.lastName}?`}
                  modalFunction={deleteUser}
                  isOpen={isDeleteOpen}
                  onClose={onDeleteClose}
                  onOpen={onDeleteOpen}
                />
              </SimpleGrid>
            </Box>
          </SimpleGrid>
        </InfoContainer>
      ) : undefined}
    </>
  );
};

export default UserDetail;
