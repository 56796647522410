import React, {
  FocusEvent, useEffect, useState,
} from 'react';
import {
  Input, Table, Tbody, Td, Th, Thead, Tr,
} from '@chakra-ui/react';
import { useGetUser } from '../../../core/store/reducers/UserSettingsReducer';
import { translate } from '../../../core/localization/LocaleUtils';
import { ConflictsEditableProps } from './Conflicts.model';
import { ClientConflictGraphQLInput } from '../../../build/generated-sources/dto/ClientConflictGraphQLInput';
import ConflictsLocale from './Conflicts.locale';

const ConflictsEditable: React.FunctionComponent<ConflictsEditableProps> = (props) => {
  const { language } = useGetUser();
  const { conflicts, onConflictsChange, newData } = props;

  const [rows, setRows] = useState<ClientConflictGraphQLInput[]>(conflicts);

  useEffect(() => {
    const newRows = [...conflicts] || [];
    if ((newRows.length === 0) || (
      newRows[newRows.length - 1] && (
        !!newRows[newRows.length - 1].name
        || !!newRows[newRows.length - 1].ico
        || !!newRows[newRows.length - 1].note
      )
    )) {
      newRows.push({});
    }
    setRows(newRows);
  }, [conflicts, setRows]);

  useEffect(() => {
    if (newData === true) {
      setRows([]);
    }
  }, [newData]);

  const handleCandidatesEditableBlur = (event: FocusEvent<HTMLInputElement>) => {
    const newConflicts = [...rows];
    const index: number = parseInt(event.target.id.split('-')[0], 10) - 1;
    const attribute: string = event.target.name;
    const { value } = event.target;

    newConflicts[index] = { ...conflicts[index] };
    if (attribute === 'name') {
      newConflicts[index].name = value;
    } else if (attribute === 'ico') {
      newConflicts[index].ico = value;
    } else if (attribute === 'note') {
      newConflicts[index].note = value;
    }

    onConflictsChange(newConflicts);
  };

  return (
    <>
      <Table size="sm">
        <Thead>
          <Tr>
            <Th />
            <Th>{translate(ConflictsLocale.ID, language)}</Th>
            <Th>{translate(ConflictsLocale.SUBJECT_NAME, language)}</Th>
            <Th>{translate(ConflictsLocale.NOTE, language)}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {rows.map((conflictsItem, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Tr key={index}>
              <Td>{index + 1}</Td>
              <Td>
                <Input
                  id={`${index + 1}-ico`}
                  name="ico"
                  placeholder={translate(ConflictsLocale.ID, language)}
                  defaultValue={conflictsItem.ico}
                  onBlur={handleCandidatesEditableBlur}
                />
              </Td>
              <Td>
                <Input
                  id={`${index + 1}-name`}
                  name="name"
                  placeholder={translate(ConflictsLocale.SUBJECT_NAME, language)}
                  defaultValue={conflictsItem.name}
                  onBlur={handleCandidatesEditableBlur}
                />
              </Td>
              <Td>
                <Input
                  id={`${index + 1}-note`}
                  name="note"
                  placeholder={translate(ConflictsLocale.NOTE, language)}
                  defaultValue={conflictsItem.note}
                  onBlur={handleCandidatesEditableBlur}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </>
  );
};

export default ConflictsEditable;
