import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  Button, Flex, Spacer,
} from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useHistory } from 'react-router-dom';
import { Toast } from '@scaut-sro/meepo';
import Translate from '../../../components/Translate/Translate';
import FormLocale from '../../../core/localization/translations/Form.locale';
import TotalPrice from '../../../components/TotalPrice/TotalPrice';
import { OrderType } from '../../../build/generated-sources/enum/OrderType';
import { ProductFormAttr, ProductFormProps } from './ProductForm.model';
import { useNewCheckReduce } from '../../../core/store/reducers/NewCheckReducer';
import CatalogueLoader from '../../../components/Catalogue/CatalogueLoader';
import Catalogue from '../../../components/Catalogue/Catalogue';
import { getPrice } from '../../../components/TotalPrice/TotalPrice.utils';
import {
  useCreateOrderMultiple,
  useCreateOrderSingle,
} from '../../../build/generated-sources/service/MutationService';
import { CandidateIdentity } from '../../../build/generated-sources/dto/CandidateIdentity';
import { OrderDefinitionInputDto } from '../../../build/generated-sources/dto/OrderDefinitionInputDto';
import NewCheckLocale from '../NewCheck.locale';
import { translate } from '../../../core/localization/LocaleUtils';
import { useGetUser } from '../../../core/store/reducers/UserSettingsReducer';

const createOrderMapper = {
  id: true,
  price: true,
  name: true,
  type: true,
  team: {
    name: true,
  },
  result: true,
  orderState: true,
  clientOrderCustomId: true,
};

const ProductForm: React.FunctionComponent<ProductFormProps> = (props) => {
  const {
    orderType,
    inviteCandidate, packages, definitions, responseMultiple, responseSingle, orderId, candidates,
    orderCustomId, orderName, countries, allowedCountries, changeCountriesHandler,
  } = props;

  const {
    handleSubmit, setValue,
  } = useForm<ProductFormAttr>();
  const {
    setSecondStep, setCurrentStep, setPrice, setDefaultValues,
  } = useNewCheckReduce();
  const history = useHistory();
  const { language } = useGetUser();
  const [formPackages, setFormPackages] = useState<number[]>(packages);
  const [formDefinitions, setFormDefinitions] = useState<OrderDefinitionInputDto[]>(definitions);
  const [localPrice, setLocalPrice] = useState<number>(0);
  const [formOrderType, setFormOrderType] = useState<OrderType>(orderType);
  const [formCandidates, setFormCandidates] = useState<CandidateIdentity[]>(candidates);

  const [mutateCreateOrderSingle, optionsMutationSingle] = useCreateOrderSingle(createOrderMapper, {
    onCompleted: (response) => {
      setDefaultValues();
      history.push(`/order/${response.createOrderSingle.id}`);
      setCurrentStep(3);
    },
    onError: () => {
      Toast({
        title: translate(NewCheckLocale.ERROR_CREATING_ORDERS, language),
        status: 'error',
      });
    },
  });

  const [mutateCreateOrderMultiple, optionsMutationMultiple] = useCreateOrderMultiple(createOrderMapper, {
    onCompleted: (response) => {
      setDefaultValues();
      history.push(`/order/${response.createOrderMultiple.id}`);
      setCurrentStep(3);
    },
    onError: () => {
      Toast({
        title: translate(NewCheckLocale.ERROR_CREATING_ORDERS, language),
        status: 'error',
      });
    },
  });

  useEffect(() => {
    const data = formOrderType === OrderType.SINGLE ? responseSingle.products : responseMultiple.products;
    const newLocalPrice = getPrice(formPackages, formDefinitions, data || []);
    setLocalPrice(newLocalPrice);
    setPrice(localPrice);
  }, [formPackages, formDefinitions, responseSingle, responseMultiple, setPrice,
    formOrderType, setLocalPrice, localPrice]);

  const handleProductSubmit: SubmitHandler<ProductFormAttr> = (values) => {
    const formValues: ProductFormAttr = {
      packages: values.packages || packages,
      definitions: values.definitions || definitions,
    };
    setFormOrderType(orderType);
    const candidateList: CandidateIdentity[] = [];
    if (!inviteCandidate) {
      formCandidates.forEach((candidate) => {
        candidateList.push({
          firstName: candidate.firstName,
          lastName: candidate.lastName,
          dateOfBirth: candidate.dateOfBirth,
          personalTitle: candidate.personalTitle,
          jobPosition: candidate.jobPosition,
        });
      });
    } else {
      formCandidates.forEach((candidate) => {
        candidateList.push(candidate);
      });
    }

    setFormCandidates(candidateList);
    if (orderType === OrderType.SINGLE) {
      mutateCreateOrderSingle({
        variables: {
          primaryOrderId: orderId,
          primaryOrder: {
            inviteCandidate,
            clientOrderCustomId: orderCustomId || undefined,
            primaryOrderName: orderName || undefined,
            screeningPackages: formValues.packages,
            screeningDefinitions: formValues.definitions,
          },
          candidateIdentity: candidateList[0],
        },
      });
    } else {
      mutateCreateOrderMultiple({
        variables: {
          primaryOrderId: orderId,
          primaryOrder: {
            inviteCandidate,
            clientOrderCustomId: orderCustomId || undefined,
            primaryOrderName: orderName || undefined,
            screeningPackages: formValues.packages,
            screeningDefinitions: formValues.definitions,
          },
          candidateIdentities: candidateList,
        },
      });
    }
    setSecondStep(formValues);
    setPrice(localPrice);
  };
  const handleProductChange = (newPackages: number[], newDefinitions: OrderDefinitionInputDto[]) => {
    setFormPackages(newPackages);
    setFormDefinitions(newDefinitions);
    setValue('packages', newPackages);
    setValue('definitions', newDefinitions);
  };
  const handleBack = () => {
    setCurrentStep(1);
  };

  const isSubmitDisabled = () => formPackages.length === 0 && formDefinitions.length === 0;

  return (
    <form name="productForm" onSubmit={handleSubmit(handleProductSubmit)}>
      {
        formOrderType === OrderType.SINGLE ? (
          <>
            { responseSingle.loading
              ? (
                <CatalogueLoader />
              ) : (
                <Catalogue
                  data={responseSingle.products}
                  definitions={formDefinitions}
                  packages={formPackages}
                  onChange={handleProductChange}
                  countries={countries}
                  allowedCountries={allowedCountries}
                  changeCountriesHandler={changeCountriesHandler}
                />
              )}
          </>
        ) : (
          <>
            { responseMultiple.loading
              ? (
                <CatalogueLoader />
              ) : (
                <Catalogue
                  data={responseMultiple.products}
                  definitions={formDefinitions}
                  packages={formPackages}
                  onChange={handleProductChange}
                  countries={countries}
                  allowedCountries={allowedCountries}
                  changeCountriesHandler={changeCountriesHandler}
                />
              )}
          </>
        )
       }
      <TotalPrice
        price={candidates.length > 1
          ? localPrice * candidates.length : localPrice}
        perPerson={false}
        isMultipleCheck={false}
      />
      <Flex mt={8}>
        <Button variant="ghost" onClick={handleBack} leftIcon={<ArrowBackIcon />}>
          <Translate label={FormLocale.BACK} />
        </Button>
        <Spacer />
        <Button
          type="submit"
          isDisabled={isSubmitDisabled()}
          isLoading={optionsMutationSingle.loading || optionsMutationMultiple.loading}
        >
          <Translate label={FormLocale.CONTINUE} />
        </Button>
      </Flex>
    </form>
  );
};

export default ProductForm;
