import React, { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import { UsersTableFilterProps, UsersTableFilterType } from './Users.model';

const UsersTableFilter: React.FunctionComponent<UsersTableFilterProps> = (props) => {
  const { filter, onFilterChange } = props;
  const {
    handleSubmit, register, reset,
  } = useForm();

  const handleFilterSubmit: SubmitHandler<UsersTableFilterType> = (values) => {
    onFilterChange(values);
  };

  const handleKeyUp = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSubmit(handleFilterSubmit);
    }
  };

  useEffect(() => {
    reset({
      fulltext: filter.fulltext,
    });
  }, [filter, reset]);

  return (
    <Box>
      <form
        name="UsersTableFilter"
        onSubmit={handleSubmit((values) => {
          handleFilterSubmit(values);
        })}
      >
        <Flex>
          <InputGroup zIndex={0}>
            <InputLeftElement>
              <SearchIcon />
            </InputLeftElement>
            <Input
              id="fulltext"
              variant="filled"
              onKeyUp={handleKeyUp}
              {...register('fulltext')}
            />
          </InputGroup>
        </Flex>
      </form>
    </Box>
  );
};

export default UsersTableFilter;
