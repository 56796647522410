import { Translation } from '../../core/localization/Localization.model';

class NotificationBoxLocale {
  public static COMPLETED: Translation = { EN: 'Completed', CZ: 'Dokončeno' };
  public static WAITING_FOR_YOU: Translation = { EN: 'Waiting for you', CZ: 'Čeká na vás' };
  public static WAITING_FOR_CANDIDATE: Translation = { EN: 'Waiting for candidate', CZ: 'Čeká na kandidáta' };
  public static PROCESSING: Translation = { EN: 'Processing', CZ: 'Probíhá' };
  public static TOTAL: Translation = { EN: 'persons total', CZ: 'osob celkem' };
  public static PROCESSED: Translation = { EN: 'persons checked', CZ: 'prověřených osob' };
  public static WITH_RESULTS: Translation = { EN: 'with result', CZ: 'S nálezem' };
  public static CLEAR: Translation = { EN: 'Clear', CZ: 'V pořádku' };
  public static LAST: Translation = { EN: 'Last change', CZ: 'Poslední změna' };
  public static ALL: Translation = { EN: 'All orders', CZ: 'Všechny' };
  public static IN_PROGRESS: Translation = { EN: 'In progress', CZ: 'Ve zpracování' };
  public static CONSIDER: Translation = { EN: 'Consider', CZ: 'Ke zvážení' };
}

export default NotificationBoxLocale;
