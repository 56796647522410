import { Translation } from '../Localization.model';

class MainMenuLocale {
  public static NEW_CHECK: Translation = { EN: 'New check', CZ: 'Nová kontrola' };
  public static OVERVIEW: Translation = { EN: 'Overview', CZ: 'Přehled' };
  public static CHECKS: Translation = { EN: 'Checks', CZ: 'Kontroly' };
  public static ORDER_DETAIL: Translation = { EN: 'Order detail', CZ: 'Detail objednávky' };
  public static ORDERS: Translation = { EN: 'Orders', CZ: 'Přehled objednávek' };
  public static USERS: Translation = { EN: 'Users', CZ: 'Uživatelé' };
  public static PAYMENT_CARD: Translation = { EN: 'Payment Card', CZ: 'Platební karta' };
  public static SUPPORT: Translation = { EN: 'Support', CZ: 'Podpora' };
  public static FAQ: Translation = { EN: 'FAQ', CZ: 'FAQ' };
  public static SINGLE_ORDERS: Translation = { EN: 'Single checks', CZ: 'Jednotlivé kontroly' };
  public static MULTIPLE_ORDERS: Translation = { EN: 'Multiple checks', CZ: 'Hromadné kontroly' };
}

export default MainMenuLocale;
