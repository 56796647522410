import { Translation } from '../../core/localization/Localization.model';

class NewCheckLocale {
  public static INVITE_CANDIDATE: Translation = { EN: 'Invite candidate', CZ: 'Pozvat kandidáta' };
  public static INVITE_CANDIDATES: Translation = { EN: 'Invite candidates', CZ: 'Pozvat kandidáty' };
  public static NEW_CHECK: Translation = { EN: 'New Check', CZ: 'Nová kontrola' };
  public static PERSONAL_TITLE: Translation = { EN: 'Personal title', CZ: 'Osobní titul' };
  public static JOB_POSITION: Translation = { EN: 'Job Position', CZ: 'Pracovní pozice' };
  public static PACKAGES_AND_CHECKS: Translation = { EN: '2. Select products', CZ: '2. Výběr kontrol' };
  public static CANDIDATES_INFORMATION: Translation = { EN: '1. Candidate', CZ: '1. Kandidát' };
  public static PAYMENT_INFORMATION: Translation = { EN: 'Payment information', CZ: 'Platební údaje' };
  public static FIRST_NAME_REQUIRED: Translation = { EN: 'First Name*', CZ: 'Jméno*' };
  public static FIRST_NAME: Translation = { EN: 'First Name', CZ: 'Jméno' };
  public static FIRST_NAME_PLACEHOLDER: Translation = { EN: 'First Name', CZ: 'Jméno' };
  public static LAST_NAME_REQUIRED: Translation = { EN: 'Last Name*', CZ: 'Příjmení*' };
  public static LAST_NAME: Translation = { EN: 'Last Name', CZ: 'Příjmení' };
  public static LAST_NAME_PLACEHOLDER: Translation = { EN: 'Last Name', CZ: 'Příjmení' };
  public static OTHER_NAME: Translation = { EN: 'Other Names', CZ: 'Další jména' };
  public static DATE_OF_BIRTH_REQUIRED: Translation = { EN: 'Date Of Birth*', CZ: 'Datum narození*' };
  public static DATE_OF_BIRTH: Translation = { EN: 'Date Of Birth', CZ: 'Datum narození' };
  public static NATIONALITY: Translation = { EN: 'Nationality', CZ: 'Národnost' };
  public static LANGUAGE: Translation = { EN: 'Language', CZ: 'Jazyk' };
  public static INVITATION_LANGUAGE: Translation = { EN: 'Invitation Language', CZ: 'Jazyk pozvánky' };
  public static INVITATION_LANGUAGE_REQUIRED: Translation = { EN: 'Invitation Language*', CZ: 'Jazyk pozvánky*' };
  public static CANDIDATE_ID: Translation = { EN: 'Candidate ID', CZ: 'ID kandidáta' };
  public static EMAIL_REQUIRED: Translation = { EN: 'Email*', CZ: 'E-mail*' };
  public static EMAIL: Translation = { EN: 'Email', CZ: 'E-mail' };
  public static EMAIL_PLACEHOLDER: Translation = { EN: 'Email', CZ: 'E-mail' };
  public static RECAPITULATION: Translation = { EN: '3. Payment', CZ: '3. Platba' };
  public static ORDER_NAME: Translation = { EN: 'Order name', CZ: 'Název objednávky' };
  public static PAYMENT_METHOD: Translation = { EN: 'Payment method', CZ: 'Platební metoda' };
  public static CARD_PAYMENT: Translation = { EN: 'Card payment', CZ: 'Platební karta' };
  public static INVOICE_AFTER_ORDER: Translation = { EN: 'Invoice after order', CZ: 'Faktura po objednávce' };
  public static INVOICE_MONTHLY: Translation = { EN: 'Invoice monthly', CZ: 'Měsíční faktura' };
  public static PREPAID: Translation = { EN: 'Prepaid', CZ: 'Předplacené' };
  public static SALUTATION: Translation = { EN: 'Salutation', CZ: 'Oslovení' };
  public static SALUTATION_MR: Translation = { EN: 'MR', CZ: 'Pan' };
  public static SALUTATION_MRS: Translation = { EN: 'MRS', CZ: 'Paní' };
  public static ERROR_CREATING_ORDERS: Translation = {
    EN:
      'There was a problem creating the order',
    CZ: 'Při vytváření objednávky došlo k problému',
  };
  public static ORDER_NAME_TOOLTIP: Translation = {
    EN: 'If you leave this field empty order name will be autogenerated',
    CZ: 'Pokud nevyplníte, bude název objednávky automaticky vygenerován',
  };
  public static ORDER_CUSTOM_ID: Translation = { EN: 'Your purchase order number', CZ: 'Vaše číslo objednávky' };
  public static ASSETS_INFO: Translation = {
    EN: 'After submitting the order it will be necessary to add some information about the candidate to '
      + 'successfully complete the check',
    CZ: 'Pro úspěšné provedení kontroly bude po dokončení objednávky potřeba doplnit některé údaje o kandidátovi',
  };
  public static MISSING_PAYMENT_CARD: Translation = {
    EN: 'Missing payment card for the selected payment method.'
        + ' Please add a payment card in your company settings or contact an admin.',
    CZ: 'Pro danou platební metodu je nutné přidat platební kartu.'
        + ' Přidejte si do nastavení vaší společnosti platební kartu nebo kontaktujte admina.',
  };
  public static BACK_TO_CHECKS: Translation = { EN: 'Back to checks', CZ: 'Zpět na kontroly' };
  public static TYPE: Translation = { EN: 'Type', CZ: 'Typ' };
  public static TEAM: Translation = { EN: 'Team', CZ: 'Tým' };
  public static SERVICES: Translation = { EN: 'Services', CZ: 'Služby' };
  public static SELECTED_CHECKS: Translation = { EN: 'Selected checks', CZ: 'Vybrané kontroly' };
  public static CHECKED_FIELDS: Translation = { EN: 'Checked fields', CZ: 'Kontrolována pole' };
  public static ALERT: Translation = { EN: 'Alert', CZ: 'Upozornění' };
  public static ALERT_DETAIL: Translation = {
    EN: 'You will need to add some candidate information to successfully complete your check.',
    CZ: 'Pro úspěšné provedení kontroly bude po dokončení objednávky potřeba doplnit některé údaje o kandidátovi.',
  };
}

export default NewCheckLocale;
