import { gql } from '@apollo/client';

// eslint-disable-next-line
export const postClient = gql`
        mutation registerClient {
        registerClient(input: $clientRegistrationDto) @rest(type: "Post", path: "/clientRegistration", method: "POST") {
            responseBody
            success
            exceptionDto
        }
      }
  `;
