import React, { useEffect, useState } from 'react';
import { SectionContainer, Toast } from '@scaut-sro/meepo';
import {
  Tab, TabList, TabPanel, TabPanels, Tabs,
} from '@chakra-ui/react';
import TagManager from 'react-gtm-module';
import { useGetUser } from '../../core/store/reducers/UserSettingsReducer';
import Users from './ClientUsers/Users';
import ClientDetail from './Client/ClientDetail';
import { translate } from '../../core/localization/LocaleUtils';
import ClientSettingsLocale from './ClientSettings.locale';
import { useLazyGetClientDetail } from '../../build/generated-sources/service/QueryService';
import { ClientDetail as Client } from '../../build/generated-sources/dto/ClientDetail';
import Translate from '../../components/Translate/Translate';
import Conflicts from './Conflicts/Conflicts';
import Anonymization from './Anonymization/Anonymization';
import Credits from './Credits/Credits';
import Checks from '../Checks/Checks';
import Invoices from './Invoices/Invoices';
import Whitelist from './Whitelist/Whitelist';

const ClientSettings: React.FunctionComponent = () => {
  const { language, userProfile } = useGetUser();
  const [clientDetail, setClientDetail] = useState<Client | undefined>(undefined);
  const [clientUpdated, setClientUpdated] = useState<boolean>(false);
  const [defaultTabIndex] = useState<number>(0);

  const [clientHandle] = useLazyGetClientDetail(
    {
      client: {
        id: true,
        name: true,
        email: true,
        phone: true,
        ico: true,
        dic: true,
        street: true,
        zipcode: true,
        city: true,
        country: {
          id: true,
          translation: {
            id: true,
            localizationStrings: true,
          },
        },
        enabled2fa: true,
        anonymizeAfter: true,
        communicationLanguage: true,
      },
      prepaidBalance: true,
      lastCharge: true,
    },
    {
      fetchPolicy: 'no-cache',
      onCompleted: (response) => {
        if (response.clientDetail === null) {
          Toast({
            title: translate(ClientSettingsLocale.ERROR_GETTING_DATA, language),
            status: 'error',
          });
        }
        setClientDetail(response.clientDetail);
      },
      onError: () => {
        Toast({
          title: translate(ClientSettingsLocale.ERROR_GETTING_DATA, language),
          status: 'error',
        });
      },
    },
  );

  useEffect(() => {
    if (!clientDetail) {
      clientHandle();
    }
  }, [clientHandle, clientDetail]);

  useEffect(() => {
    if (clientUpdated) {
      clientHandle();
      setClientUpdated(false);
    }
  }, [clientHandle, clientUpdated]);

  TagManager.dataLayer({
    dataLayer: {
      event: 'page_view',
      realm: 'client',
      page: {
        url: document.location.origin + document.location.pathname + document.location.search,
        title: 'Client settings',
      },
      user: {
        email: userProfile?.email,
        given_name: userProfile?.firstName,
        family_name: userProfile?.lastName,
        job_position: userProfile?.jobPosition,
        language,
      },
    },
  });

  return (
    <>
      <SectionContainer>
        <Tabs size="lg" defaultIndex={defaultTabIndex}>
          <TabList color="#5E6C7A" borderBottomColor="rgba(44, 45, 65, 0.08)" borderBottomWidth="1px">
            <Tab
              _selected={{ borderBottomColor: '#0066F8', borderBottomWidth: '3px', color: '#2C2D41' }}
              paddingTop={0}
              paddingLeft={0}
              height="55px"
              key="users"
              fontWeight="medium"
            >
              <Translate label={ClientSettingsLocale.USERS} />
            </Tab>
            <Tab
              _selected={{ borderBottomColor: '#0066F8', borderBottomWidth: '3px', color: '#2C2D41' }}
              paddingTop={0}
              height="55px"
              fontWeight="medium"
              key="company"
            >
              <Translate label={ClientSettingsLocale.MY_COMPANY} />
            </Tab>
            <Tab
              _selected={{ borderBottomColor: '#0066F8', borderBottomWidth: '3px', color: '#2C2D41' }}
              paddingTop={0}
              height="55px"
              fontWeight="medium"
              key="conflict"
            >
              <Translate label={ClientSettingsLocale.CONFLICT_OF_INTEREST} />
            </Tab>
            <Tab
              _selected={{ borderBottomColor: '#0066F8', borderBottomWidth: '3px', color: '#2C2D41' }}
              paddingTop={0}
              height="55px"
              fontWeight="medium"
              key="anonymization"
            >
              <Translate label={ClientSettingsLocale.ANONYMIZATION} />
            </Tab>
            <Tab
              _selected={{ borderBottomColor: '#0066F8', borderBottomWidth: '3px', color: '#2C2D41' }}
              paddingTop={0}
              height="55px"
              fontWeight="medium"
              key="credits"
            >
              <Translate label={ClientSettingsLocale.CREDITS} />
            </Tab>
            <Tab
              _selected={{ borderBottomColor: '#0066F8', borderBottomWidth: '3px', color: '#2C2D41' }}
              paddingTop={0}
              height="55px"
              fontWeight="medium"
              key="orders"
            >
              <Translate label={ClientSettingsLocale.ORDERS} />
            </Tab>
            <Tab
              _selected={{ borderBottomColor: '#0066F8', borderBottomWidth: '3px', color: '#2C2D41' }}
              paddingTop={0}
              height="55px"
              fontWeight="medium"
              key="invoices"
            >
              <Translate label={ClientSettingsLocale.INVOICES} />
            </Tab>
            {/* <Tab key="countries"><Translate label={ClientSettingsLocale.COUNTRIES} /></Tab> */}
            <Tab
              _selected={{ borderBottomColor: '#0066F8', borderBottomWidth: '3px', color: '#2C2D41' }}
              paddingTop={0}
              height="55px"
              fontWeight="medium"
              key="ip-addresses"
            >
              <Translate label={ClientSettingsLocale.WHITELIST} />
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel p={4} key="users">
              <Users />
            </TabPanel>
            <TabPanel p={4} key="company">
              <ClientDetail clientDetail={clientDetail} setClientUpdated={setClientUpdated} />
            </TabPanel>
            <TabPanel p={4} key="conflict">
              <Conflicts />
            </TabPanel>
            <TabPanel p={4} key="anonymization">
              <Anonymization clientDetail={clientDetail} setClientUpdated={setClientUpdated} />
            </TabPanel>
            <TabPanel p={4} key="credits">
              <Credits clientDetail={clientDetail} setClientUpdated={setClientUpdated} />
            </TabPanel>
            <TabPanel p={4} key="orders">
              <Checks simpleView />
            </TabPanel>
            <TabPanel p={4} key="invoices">
              <Invoices clientDetail={clientDetail} setClientUpdated={setClientUpdated} />
            </TabPanel>
            {/*            <TabPanel p={4} key="countries">
            </TabPanel> */}
            <TabPanel p={4} key="ip-addresses">
              <Whitelist />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </SectionContainer>
    </>
  );
};

export default ClientSettings;
