import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { FilterReducerState } from './FilterReducer.model';

const initialState: FilterReducerState = {
  search: undefined,
};

const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setSearch(state, action: PayloadAction<string | undefined>) {
      state.search = action.payload;
    },
  },
});

// Getter Hooks
export function useGetFilter(): FilterReducerState {
  return useSelector((state: any) => state.filter);
}

export const { setSearch } = filterSlice.actions;

// Reduce Hooks
export const useFilterReduce = () => {
  const dispatch = useDispatch();
  return {
    setSearch(search: string | undefined) {
      dispatch(filterSlice.actions.setSearch(search));
    },
  };
};

export default filterSlice.reducer;
