import React, { useEffect, useRef, useState } from 'react';
import {
  Button, Flex, FormLabel, GridItem, SimpleGrid, Spacer,
} from '@chakra-ui/react';
import { Toast } from '@scaut-sro/meepo';
import { ChargifyFormProps } from './ChargifyForm.model';
import Translate from '../../../../components/Translate/Translate';
import CreditsLocale from '../Credits.locale';
import { translate } from '../../../../core/localization/LocaleUtils';
import { useGetUser } from '../../../../core/store/reducers/UserSettingsReducer';

const ChargifyForm: React.FunctionComponent<ChargifyFormProps> = (props) => {
  const { onSubmit, isUpdate } = props;
  const { language } = useGetUser();
  const chargifyForm = useRef();
  // @ts-ignore
  const chargify = useRef(new Chargify());
  const [tokenString, setTokenString] = useState<string>('');
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const handleSubmit = () => {
    setButtonLoading(true);
    chargify.current.token(
      chargifyForm.current,
      (token: string) => {
        onSubmit(token, setButtonLoading);
        setTokenString(token);
      },
      () => {
        Toast({
          title: translate(CreditsLocale.ERROR_CHANGING_PAYMENT_CARD, language),
          status: 'error',
        });
        setButtonLoading(false);
      },
    );
  };

  useEffect(
    () => {
      fetch('/chargifyJsConfig.json').then((data) => {
        data.json().then((json) => {
          let stringified = JSON.stringify(json);
          stringified = stringified.replace('__card_number_error__', translate(CreditsLocale.INVALID_CARD_NUMBER, language));
          stringified = stringified.replace('__card_month_error__', translate(CreditsLocale.INVALID_MONTH, language));
          stringified = stringified.replace('__card_year_error__', translate(CreditsLocale.INVALID_YEAR, language));
          stringified = stringified.replace('__card_cvv_error__', translate(CreditsLocale.INVALID_CVV, language));
          const jsonObject = JSON.parse(stringified);
          chargify.current.load(jsonObject);
        });
      });
    }, [language, tokenString],
  );

  return (
  // @ts-ignore
    <form ref={chargifyForm}>
      <div id="chargify-form">
        <SimpleGrid columns={[6]} mt={8}>
          <GridItem colSpan={[6]}>
            <FormLabel color="gray.400"><Translate label={CreditsLocale.CARD_NUMBER} /></FormLabel>
            <div id="card-number" />
          </GridItem>
          <GridItem colSpan={[1]}>
            <FormLabel color="gray.400"><Translate label={CreditsLocale.CARD_MONTH} /></FormLabel>
            <div id="month" />
          </GridItem>
          <GridItem colSpan={[1]}>
            <FormLabel color="gray.400"><Translate label={CreditsLocale.CARD_YEAR} /></FormLabel>
            <div id="year" />
          </GridItem>
          <GridItem colSpan={[1]}>
            <FormLabel color="gray.400"><Translate label={CreditsLocale.CARD_CVV} /></FormLabel>
            <div id="cvv" />
          </GridItem>
          <GridItem colSpan={[3]} mt={8}>
            <Flex>
              <Spacer />
              <Button
                size="lg"
                bg="#40d372"
                _hover={{ bg: '#3abe67' }}
                _active={{
                  bg: '#3abe67',
                }}
                onClick={handleSubmit}
                isLoading={buttonLoading}
              >
                { isUpdate ? (
                  <Translate label={CreditsLocale.CHANGE_PAYMENT_CARD} />
                ) : (
                  <Translate label={CreditsLocale.ADD_PAYMENT_CARD} />
                ) }
              </Button>
            </Flex>
          </GridItem>
        </SimpleGrid>
      </div>
    </form>
  );
};

export default ChargifyForm;
