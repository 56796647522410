import { Merge, Row } from '@scaut-sro/meepo';
import { ClientUserDto } from '../../../build/generated-sources/dto/ClientUserDto';
import { LocalDate } from '../../../build/scalars/LocalDate';

export type UsersProps = {
  filters?: UsersTableFilterAllFilterType;
};

export type UserDetailFormProps = {
  clientUser?: ClientUserDto;
  loadingSetter?: (isLoading: boolean) => void;
  formRef?: React.MutableRefObject<HTMLFormElement | null>;
  onClose: () => void;
  setUsersUpdated: (updated: boolean) => void;
};

export type UserDetailFormAttr = {
  id?: number;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  teams?: { value: number, label: string }[];
};

export type UsersTableData = {
  name?: string;
  teams?: any;
  active?: any;
  admin?: any;
} & Row;

export type UserDetailProps = {
  clientUser: ClientUserDto;
  loggedUserEmail?: string;
  setUsersUpdated: (updated: boolean) => void;
};

export type UserDetailSwitchProps = {
  clientUser: ClientUserDto;
  switchType: UserDetailSwitchType;
  setUsersUpdated: (updated: boolean) => void;
  disabled: boolean;
};

export enum UserDetailSwitchType {
  ROLE = 'ROLE',
  ACTIVE = 'ACTIVE',
}

export type UsersTableFilterOtherFilterType = {};

export type UsersTableFilterAllFilterType = Merge<UsersTableFilterOtherFilterType, UsersTableFilterType>;

export type UsersTableFilterType = {
  fulltext?: string;
} & { [key: string]: string | string[] | LocalDate };

export type UsersTableFilterProps = {
  filter: UsersTableFilterType;
  onFilterChange: (newFilter: UsersTableFilterType) => void;
};
