import React from 'react';
import {
  Switch,
} from '@chakra-ui/react';
import {
  Toast,
} from '@scaut-sro/meepo';
import { useGetUser } from '../../../../core/store/reducers/UserSettingsReducer';
import { translate } from '../../../../core/localization/LocaleUtils';
import { UserDetailSwitchProps, UserDetailSwitchType } from '../Users.model';
import UsersLocale from '../Users.locale';
import {
  useChangeActiveStatus,
  useChangeRole,
} from '../../../../build/generated-sources/service/MutationService';

const UserDetailSwitch: React.FunctionComponent<UserDetailSwitchProps> = (props) => {
  const {
    clientUser, switchType, setUsersUpdated, disabled,
  } = props;
  const { language } = useGetUser();

  const [mutateRole] = useChangeRole({
    success: true,
  }, {
    onCompleted: () => {
      Toast({
        title: translate(UsersLocale.SUCCESS_USER_SAVED, language),
        status: 'success',
      });
      setUsersUpdated(true);
    },
    onError: () => {
      Toast({
        title: translate(UsersLocale.ERROR_SAVING_CLIENT_USER, language),
        status: 'error',
      });
    },
  });

  const [mutateActiveStatus] = useChangeActiveStatus({
    success: true,
  }, {
    onCompleted: () => {
      Toast({
        title: translate(UsersLocale.SUCCESS_USER_SAVED, language),
        status: 'success',
      });
      setUsersUpdated(true);
    },
    onError: () => {
      Toast({
        title: translate(UsersLocale.ERROR_SAVING_CLIENT_USER, language),
        status: 'error',
      });
    },
  });

  const changeUser = (checked: boolean) => {
    if (UserDetailSwitchType.ACTIVE === switchType) {
      mutateActiveStatus({
        variables: {
          clientUserId: clientUser.id || -1,
          active: checked,
        },
      });
    } else {
      mutateRole({
        variables: {
          clientUserId: clientUser.id || -1,
          role: checked ? 'admin' : 'regular',
        },
      });
    }
  };

  return (
    <>
      {clientUser ? (
        <Switch
          defaultChecked={UserDetailSwitchType.ACTIVE === switchType
            ? clientUser.active : clientUser.role === 'admin'}
          size="lg"
          colorScheme="green"
          isDisabled={disabled}
          onChange={(event) => {
            changeUser(event.target.checked);
          }}
        />
      ) : undefined}
    </>
  );
};

export default UserDetailSwitch;
