import React, { useEffect, useMemo, useState } from 'react';
import {
  Box, Button, Divider, Flex, Spacer,
} from '@chakra-ui/react';
import {
  ExecutionsDynamicField, FormInputLabel, P, SectionContainer, Toast,
} from '@scaut-sro/meepo';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { SearchResult } from '@scaut-sro/meepo/lib/components/ExecutionsDynamicField/ExecutionsDynamicField.model';
import {
  ScautScreeningComparisonTableApi,
} from '@scaut-sro/meepo/lib/components/ScautScreeningComparisonTable/ScautScreeningComparisonTable.model';
import { useApolloClient } from '@apollo/client';
import { ScreeningTableDetailProps } from './CandidateScreeningDetail.model';
import { useGetUser } from '../../core/store/reducers/UserSettingsReducer';
import FormLocale from '../../core/localization/translations/Form.locale';
import Translate from '../../components/Translate/Translate';
import CandidateScreeningDetailLocale from './CandidateScreeningDetail.locale';
import { QuestionnaireDefinition } from '../../components/Questionnaire/Questionnaire.model';
import { useSubmitExecutionDetails } from '../../build/generated-sources/service/MutationService';
import { translate } from '../../core/localization/LocaleUtils';
import ExecutionsModal from './Executions/ExecutionsModal';
import Questionnaire from '../../components/Questionnaire/Questionnaire';
import getCompletedDataFields from '../../core/service/queries/DynamicFormApiService';
import MapFormFieldDtoTODynamicFormField from '../../core/util/ObjectMappers';
import NotesLog from '../../components/ScreeningNotes/NotesLog';
import { ComponentNote } from '../../build/generated-sources/dto/ComponentNote';
import Expander from '../../components/Expander/Expander';

const ScreeningTableDetail: React.FunctionComponent<ScreeningTableDetailProps> = (props) => {
  const {
    screening, screeningForms, onFormSend, displayCountable, screeningResultData, candidateScreeningHandle, candidateScreeningId,
    notesDetails,
  } = props;

  const { language } = useGetUser();
  const useFormMethods = useForm();
  const [executionSearchResults, setExecutionSearchResults] = useState<SearchResult[]>([]);
  const [loading, setLoading] = useState(false);

  const client = useApolloClient();
  const form = useMemo(() => (screeningForms?.[screening?.id as number]), [screeningForms, screening]);
  const resultData = useMemo(() => (screeningResultData?.[screening?.id as number]?.form),
    [screening, screeningResultData]);

  const handleScreeningFormSubmit: SubmitHandler<any> = (values) => {
    setLoading(true);
    // eslint-disable-next-line no-console
    if (onFormSend) {
      onFormSend(form.id || -1, values);
      if (screening.id && candidateScreeningHandle) {
        candidateScreeningHandle({
          variables: {
            candidateScreeningId: +screening.id,
          },
        });
      }
    }
  };

  const [actualValues, setActualValues] = useState<{ [p: string]: any }>({});
  const handleFormChange = () => {
    setActualValues(useFormMethods.getValues());
  };

  useEffect(() => {
    setActualValues(useFormMethods.getValues());
  }, [useFormMethods, setActualValues]);

  const executionDefinition = useMemo(() => {
    if (form?.form) {
      const keys = Object.keys(form.form);

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < keys.length; i++) {
        if (form.form[keys[i]].properties?.component === 'EXECUTION_DYNAMIC_FIELD') {
          const foundDefinition = form.form[keys[i]];

          return MapFormFieldDtoTODynamicFormField(foundDefinition, language);
        }
      }
    }
    return undefined;
  }, [form, language]);

  const formDefinition: QuestionnaireDefinition = useMemo(() => {
    const definition: QuestionnaireDefinition = {};

    if (form?.form) {
      const keys = Object.keys(form.form);
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < keys.length; i++) {
        if (form.form[keys[i]].properties?.component !== 'EXECUTION_DYNAMIC_FIELD') {
          definition[keys[i]] = form.form[keys[i]];
        }
      }
    }
    return definition;
  }, [form]);

  const [mutateSubmitExecutionDetails] = useSubmitExecutionDetails({
    success: true,
  }, {
    onCompleted: () => {
      if (screening.id && candidateScreeningHandle) {
        candidateScreeningHandle({
          variables: {
            candidateScreeningId: +screening.id,
          },
        });
      }
      Toast({
        title: translate(FormLocale.SUCCESS, language),
        status: 'success',
      });
    },
    onError: () => {
      Toast({
        title: translate(CandidateScreeningDetailLocale.ERROR_GETTING_DATA, language),
        status: 'error',
      });
    },
  });

  const submitSelectedExecutions = (searchResults: SearchResult[]) => {
    setExecutionSearchResults(searchResults);
    if (searchResults.length === 0) {
      mutateSubmitExecutionDetails({
        variables: {
          formId: form.id || -1,
          paymentMethod: undefined,
          formData: {
            executionDetails: [],
          },
        },
      });
    }
  };

  const screeningApi: ScautScreeningComparisonTableApi = {
    getFields: ((fieldIds, callback) => {
      if (candidateScreeningId) {
        getCompletedDataFields(client, {
          id: true,
          value: true,
          label: true,
          defaultValue: true,
          type: true,
          properties: true,
          validation: true,
          preloadedTranslations: true,
        }, {
          candidateScreeningDetailId: candidateScreeningId,
          fieldIds,
        }).then((response) => {
          callback(response.data.getFields.map((field) => MapFormFieldDtoTODynamicFormField(field, language)));
        });
      }
    }),
  };

  const notes: ComponentNote[] = useMemo(() => {
    const notesDetail = notesDetails?.find((nd) => nd?.screening?.id === screening.id);
    if (notesDetail && notesDetail.notes) {
      const sortedNotes = [...notesDetail.notes];
      sortedNotes.sort((noteA, noteB) => (((noteA.id || 0) > (noteB.id || 0)) ? -1 : 1));
      return sortedNotes;
    }
    return [];
  }, [notesDetails, screening.id]);

  return (
    <Box py={[2, 4, 8]} px={[2, 6, 12]}>
      <P>
        {screening.screeningDefinition?.description?.localizationStrings?.[language]}
        {' '}
        { displayCountable ? screening.screeningTypeIndex as number + 1 : undefined }
      </P>
      <Divider mt={4} mb={4} />
      {resultData && (
        <Questionnaire
          definition={resultData}
          readonly
          scautScreeningComparisonTableApi={screeningApi}
          dynamicFormProps={{
            hideSectionNames: true,
          }}
        />
      )}
      { form?.form
        ? (
          <Expander>
            <SectionContainer>
              <FormProvider {...useFormMethods}>
                { executionDefinition ? (
                  <>
                    <ExecutionsDynamicField
                      field={executionDefinition}
                      executionCallback={{ onSubmit: submitSelectedExecutions }}
                    />
                    <ExecutionsModal
                      searchResults={executionSearchResults}
                      formId={form.id}
                      refreshData={candidateScreeningHandle}
                    />
                  </>
                ) : undefined }
                { Object.keys(formDefinition).length > 0 ? (
                  <form onSubmit={useFormMethods.handleSubmit(handleScreeningFormSubmit)} onChange={handleFormChange}>
                    <Questionnaire
                      definition={formDefinition}
                      actualValues={actualValues}
                      scautScreeningComparisonTableApi={screeningApi}
                      dynamicFormProps={{
                        styleProps: {
                          margin: '10px',
                          padding: '20px',
                          border: '1px solid #c7c2c2',
                          borderRadius: '10px',
                        },
                      }}
                    />
                    <Flex>
                      <Spacer />
                      <Button mt={6} type="submit" isLoading={loading}>
                        <Translate label={FormLocale.SEND} />
                      </Button>
                    </Flex>
                  </form>
                ) : undefined }
              </FormProvider>
            </SectionContainer>
          </Expander>
        )
        : (<></>)}
      {
        notes && notes.length ? (
          <Box mt={8}>
            <Expander>
              <FormInputLabel>
                <Translate label={CandidateScreeningDetailLocale.NOTES_LABEL} />
              </FormInputLabel>
              <NotesLog notes={notes} />
            </Expander>
          </Box>
        ) : (<></>)
      }
    </Box>
  );
};

export default ScreeningTableDetail;
