import React from 'react';
import { HStack } from '@chakra-ui/react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { LocalUpload } from '@scaut-sro/meepo';
import { FileUploadProps } from './ConflictsFileUpload.model';
import { ClientConflictGraphQLInput } from '../../../../build/generated-sources/dto/ClientConflictGraphQLInput';
import { Language } from '../../../../build/generated-sources/enum/Language';
import { useGetUser } from '../../../../core/store/reducers/UserSettingsReducer';

const ConflictsFileUpload: React.FunctionComponent<FileUploadProps> = (
  props,
) => {
  const { setConflictsList } = props;
  const { language } = useGetUser();

  return (
    <HStack mb={10} justifyContent="right">
      <LocalUpload
        dataCallback={(value: ClientConflictGraphQLInput[]) => {
          const data = value.filter((row, index) => index !== 0);
          const mappedData = data.map((row) => ({
            ico: row.ico,
            name: row.name,
            note: row.note,
          }));
          if (mappedData.length) setConflictsList(mappedData);
        }}
        templatePath={language === Language.CZ ? 'import-files/CSV_template_cz.xlsx' : 'import-files/CSV_template_en.xlsx'}
        header={[
          'ico',
          'name',
          'note',
        ]}
      />
    </HStack>
  );
};

export default ConflictsFileUpload;
