import React, {
  useState,
} from 'react';
import {
  Box, Button, Flex, Spacer, Text,
} from '@chakra-ui/react';
import {
  H2, SectionContainer, Toast,
} from '@scaut-sro/meepo';
import {
  WhitelistItem,
} from './Whitelist.model';
import { useGetUser } from '../../../core/store/reducers/UserSettingsReducer';
import { translate } from '../../../core/localization/LocaleUtils';
import WhitelistLocale from './Whitelist.locale';
import {
  useGetClientWhitelist,
} from '../../../build/generated-sources/service/QueryService';
import Translate from '../../../components/Translate/Translate';
import LoadingScreen from '../../LoadingScreen/LoadingScreen';
import UsersLocale from '../ClientUsers/Users.locale';
import DetailHeader from '../../../components/DetailHeader/DetailHeader';
import FormLocale from '../../../core/localization/translations/Form.locale';
import WhitelistEditable from './WhitelistEditable';
import { useUpdateClientWhitelist } from '../../../build/generated-sources/service/MutationService';
import { handleApolloErrors } from '../../../core/error/ScautErrorHandler';
import { ClientWhitelist } from '../../../build/generated-sources/dto/ClientWhitelist';
import { ClientWhitelistGraphQLInput } from '../../../build/generated-sources/dto/ClientWhitelistGraphQLInput';

export function getWhitelistTableData(data: ClientWhitelist[]): WhitelistItem[] {
  if (data) {
    return data.map((item) => ({
      address: item.address,
      note: item.note || '',
    }));
  }
  return [];
}

const Whitelist: React.FunctionComponent = () => {
  const { language } = useGetUser();
  const [whitelist, setWhitelist] = useState<WhitelistItem[]>([]);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState<boolean>(false);

  const { loading } = useGetClientWhitelist({
    address: true,
    note: true,
  }, {
    onError: () => {
      Toast({
        title: translate(UsersLocale.ERROR_GETTING_USERS, language),
        status: 'error',
      });
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (response) => {
      const onCompleteData = getWhitelistTableData(response.clientWhitelist);
      setWhitelist(onCompleteData);
    },
  });

  const [mutateClientWhitelist] = useUpdateClientWhitelist(
    {
      success: true,
    },
    {
      onCompleted: () => {
        Toast({
          title: translate(WhitelistLocale.CHANGE_SUCCESS, language),
          status: 'success',
        });
      },
      onError: (error) => {
        handleApolloErrors(error, language);
      },
    },
  );

  const updateClientWhitelist = () => {
    const newWhitelist: ClientWhitelistGraphQLInput[] = [];
    whitelist.forEach((item) => {
      if (item.address) {
        newWhitelist.push({
          address: item.address,
          note: item.note,
        });
      }
    });
    mutateClientWhitelist({
      variables: {
        whitelist: newWhitelist,
      },
    });
  };

  const eraseWhitelist = () => {
    setWhitelist([]);
  };

  return (
    <>
      { !whitelist ? (
        <LoadingScreen />
      ) : (
        <SectionContainer>
          <Box overflowX="auto">
            <DetailHeader>
              <H2><Translate label={WhitelistLocale.WHITELIST} /></H2>
            </DetailHeader>
            <Text>
              <Translate label={WhitelistLocale.WHITELIST_INFO} />
            </Text>
            <Box mt={5} minW="600px" />
            <Text>
              <Translate label={WhitelistLocale.WHITELIST_EXAMPLE} />
            </Text>
            <Box mt={10} minW="600px" maxWidth="600px">
              <Text mb={6} color="grey"><Translate label={WhitelistLocale.RECOMMENDATION_TEXT} /></Text>
              <WhitelistEditable
                whitelist={whitelist}
                onWhitelistChange={setWhitelist}
                setSaveButtonDisabled={setSaveButtonDisabled}
              />
              <Flex>
                <Button isLoading={loading} mt={6} onClick={updateClientWhitelist} isDisabled={saveButtonDisabled}>
                  <Translate label={FormLocale.SAVE} />
                </Button>
                <Spacer />
                <Button isLoading={loading} color="gray.400" variant="link" onClick={eraseWhitelist}>
                  <Translate label={WhitelistLocale.ERASE_TABLE} />
                </Button>
              </Flex>
            </Box>
          </Box>
        </SectionContainer>
      )}
    </>
  );
};
export default Whitelist;
