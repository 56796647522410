import { Translation } from '../../core/localization/Localization.model';

class MultipleCheckDetailLocale {
  public static MULTICHECK: Translation = { EN: 'Multiple check', CZ: 'Hromadná kontrola' };
  public static ORDER_INFO: Translation = { EN: 'Order information', CZ: 'Informace o objednávce' };
  public static CANDIDATE_SCREENINGS: Translation = { EN: 'Candidate screenings', CZ: 'Kontroly kandidátů' };
  public static CANDIDATE_SCREENING_START: Translation = { EN: 'Created', CZ: 'Zadáno' };
  public static CANDIDATE_SCREENING_END: Translation = { EN: 'Finished', CZ: 'Dokončeno' };
  public static PLANNED_ANONYMIZATION: Translation = { EN: 'Planned anonymization', CZ: 'Plánovaná anonymizace' };
  public static TIME_TO_COMPLETE: Translation = { EN: 'Time to complete', CZ: 'Čas na vyplnění' };
  public static TABLE_FIRST_NAME: Translation = { EN: 'First name', CZ: 'Jméno' };
  public static TABLE_LAST_NAME: Translation = { EN: 'Last name', CZ: 'Příjmení' };
  public static TABLE_DATE_OF_BIRTH: Translation = { EN: 'Date of birth', CZ: 'Datum narození' };
  public static TABLE_RESULT: Translation = { EN: 'Result', CZ: 'Výsledek' };
  public static TABLE_STATE: Translation = { EN: 'State', CZ: 'Stav' };
  public static MULTIPLE_CHECK_INFO: Translation = { EN: 'Multiple check info', CZ: 'Informace o hromadné kontrole' };
  public static MULTIPLE_CHECK_START: Translation = { EN: 'Multiple check start', CZ: 'Informace o hromadné kontroly' };
  public static MULTIPLE_CHECK_END: Translation = { EN: 'Multiple check end', CZ: 'Informace o hromadné kontroly' };
  public static ORDERED_SERVICES: Translation = {
    EN: 'Ordered packages and checks',
    CZ: 'Objednané balíčky a dílčí kontroly',
  };
  public static ERROR_GETTING_DATA: Translation = {
    EN:
      'There was a problem getting data',
    CZ: 'Při získávání dat došlo k problému',
  };

  public static RESULT: Translation = { EN: 'Multiple check result', CZ: 'Výsledek hromadné kontroly' };
}

export default MultipleCheckDetailLocale;
