import React, {
  FocusEvent, useEffect, useState,
} from 'react';
import {
  Input, Table, Tbody, Td, Th, Thead, Tr,
} from '@chakra-ui/react';
import { Toast } from '@scaut-sro/meepo';
import { WhitelistEditableProps, WhitelistItem } from './Whitelist.model';
import WhitelistLocale from './Whitelist.locale';
import { useGetUser } from '../../../core/store/reducers/UserSettingsReducer';
import { translate } from '../../../core/localization/LocaleUtils';

const WhitelistEditable: React.FunctionComponent<WhitelistEditableProps> = (props) => {
  const { language } = useGetUser();
  const { whitelist, onWhitelistChange, setSaveButtonDisabled } = props;

  const [rows, setRows] = useState<WhitelistItem[]>(whitelist);

  useEffect(() => {
    const newRows = [...whitelist] || [];
    if ((newRows.length === 0) || (
      newRows[newRows.length - 1] && (
        !!newRows[newRows.length - 1].address
      )
    )) {
      newRows.push({});
    }
    setRows(newRows);
  }, [whitelist, setRows]);

  const handleWhitelistEditableBlur = (event: FocusEvent<HTMLInputElement>) => {
    const newWhitelist = [...rows];
    const index: number = parseInt(event.target.id.split('-')[0], 10) - 1;
    const attribute: string = event.target.name;
    const { value } = event.target;
    newWhitelist[index] = { ...whitelist[index] };
    if (attribute === 'address') {
      newWhitelist[index].address = value;
    } else if (attribute === 'note') {
      newWhitelist[index].note = value;
    }
    onWhitelistChange(newWhitelist);

    const containsForbiddenCharacters: boolean[] = [];
    newWhitelist.forEach((item) => {
      if (item.address?.includes(';') || item.note?.includes(';')) {
        containsForbiddenCharacters.push(true);
      }
    });
    if (containsForbiddenCharacters.includes(true)) {
      Toast({
        title: translate(WhitelistLocale.WHITELIST_CONTAINS_FORBIDDEN_CHARACTERS, language),
        status: 'error',
      });
      setSaveButtonDisabled(true);
    } else {
      setSaveButtonDisabled(false);
    }
  };

  return (
    <>
      <Table size="sm">
        <Thead>
          <Tr>
            <Th />
            <Th>{translate(WhitelistLocale.IP, language)}</Th>
            <Th>{translate(WhitelistLocale.NOTE, language)}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {rows.map((whitelistItem, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Tr key={index}>
              <Td>{index + 1}</Td>
              <Td>
                <Input
                  id={`${index + 1}-address`}
                  name="address"
                  placeholder={translate(WhitelistLocale.IP_EXAMPLE, language)}
                  defaultValue={whitelistItem.address}
                  onBlur={handleWhitelistEditableBlur}
                />
              </Td>
              <Td>
                <Input
                  id={`${index + 1}-note`}
                  name="note"
                  placeholder={translate(WhitelistLocale.NOTE, language)}
                  defaultValue={whitelistItem.note || ''}
                  onBlur={handleWhitelistEditableBlur}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </>
  );
};

export default WhitelistEditable;
