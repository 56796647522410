import { Translation } from '../../core/localization/Localization.model';

class TotalPriceLocale {
  public static TOTAL_PRICE: Translation = { EN: 'Total price', CZ: 'Celkem' };
  public static NUMBER: Translation = { EN: 'Number of', CZ: 'Počet' };
  public static PERSONS: Translation = { EN: 'persons', CZ: 'osob' };
  public static INCLUDING_TAXES: Translation = { EN: 'Including taxes', CZ: 'Včetně DPH' };
  public static PRICE_PER_PERSON: Translation = { EN: 'Price per person', CZ: 'Cena za osobu' };
}

export default TotalPriceLocale;
