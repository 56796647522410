import { BadgeProps } from '@chakra-ui/react';
import { Translation } from '../../core/localization/Localization.model';

export type StateBadgeProps = {
  state?: string;
  size?: 'md' | 'lg';
  badgeProps?: BadgeProps
};

export class InvoiceStatesLocale {
  public static UNDEFINED: Translation = { EN: 'Processing', CZ: 'Zpracovává se' };
  public static OPEN: Translation = { EN: 'To be paid', CZ: 'K zaplacení' };
  public static PAID: Translation = { EN: 'Paid', CZ: 'Zaplaceno' };
}
