import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { OrderType } from '../../build/generated-sources/enum/OrderType';
import Translate from '../../components/Translate/Translate';
import OverviewLastChecksLocale from './OverviewLastChecks.locale';
import OverviewLastChecks from './OverviewLastChecks';
import { OverviewLastChecksOptionsProps } from './OverviewLastChecks.model';

const OverviewLastChecksOptions: React.FunctionComponent<OverviewLastChecksOptionsProps> = ({ type = 'ALL', filter }) => (
  <>
    <Flex mt={8} mb={2}>
      <Text fontSize="xl" color="#002458" fontWeight="700"><Translate label={OverviewLastChecksLocale.LAST_CHECKS} /></Text>
    </Flex>
    { type === 'ALL' ? (<OverviewLastChecks type={undefined} filters={filter} />)
      : type === 'SINGLE' ? (<OverviewLastChecks type={OrderType.SINGLE} filters={filter} />)
        : type === 'MULTIPLE' ? (<OverviewLastChecks type={OrderType.MULTIPLE} filters={filter} />)
          : undefined }
  </>
);
export default OverviewLastChecksOptions;
