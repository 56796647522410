import React from 'react';
import { Center, Text } from '@chakra-ui/react';
import TagManager from 'react-gtm-module';
import { useGetUser } from '../../core/store/reducers/UserSettingsReducer';

const NoMatch: React.FunctionComponent = () => {
  const { language, userProfile } = useGetUser();

  TagManager.dataLayer({
    dataLayer: {
      event: 'page_view',
      realm: 'client',
      page: {
        url: document.location.origin + document.location.pathname + document.location.search,
        title: 'Error 404',
      },
      user: {
        email: userProfile?.email,
        given_name: userProfile?.firstName,
        family_name: userProfile?.lastName,
        job_position: userProfile?.jobPosition,
        language,
      },
    },
  });

  return (
    <>
      <Center flexDirection="column">
        <Text color="primaryBase" fontSize="7xl">404</Text>
        <Text>
          The page you requested is unavailable at the moment.
          Please press the back button or restart Scaut.
        </Text>
      </Center>
    </>
  );
};

export default NoMatch;
