import React, { useState } from 'react';
import {
  H2,
  SectionContainer, StepperTabs,
} from '@scaut-sro/meepo';
import { GridItem, SimpleGrid } from '@chakra-ui/react';
import TagManager from 'react-gtm-module';
import ProductForm from './ProductForm/ProductForm';
import NewCheckLocale from './NewCheck.locale';
import { useGetUser } from '../../core/store/reducers/UserSettingsReducer';
import { useGetNewCheck } from '../../core/store/reducers/NewCheckReducer';
import { CountryProductsDtoMapper } from '../../build/generated-sources/dto/CountryProductsDto';
import {
  useGetClientDetail,
  useGetScreeningCatalogMultiple,
  useGetScreeningCatalogSingle,
} from '../../build/generated-sources/service/QueryService';
import { handleApolloErrors } from '../../core/error/ScautErrorHandler';
import Translate from '../../components/Translate/Translate';
import { translate } from '../../core/localization/LocaleUtils';
import CandidateForm from './CandidateForm/CandidateForm';
import { Country } from '../../build/generated-sources/dto/Country';

const catalogueMapper: CountryProductsDtoMapper = {
  country: {
    translation: {
      localizationStrings: true,
      name: true,
    },
  },
  screeningPackages: {
    id: true,
    price: true,
    name: {
      localizationStrings: true,
    },
    description: {
      localizationStrings: true,
    },
    screeningDefinitions: {
      id: true,
      price: true,
      countable: true,
      name: {
        localizationStrings: true,
      },
      description: {
        localizationStrings: true,
      },
    },
  },
  screeningDefinitions: {
    id: true,
    price: true,
    countable: true,
    countLimit: true,
    name: {
      localizationStrings: true,
    },
    description: {
      localizationStrings: true,
    },
  },
};

const NewCheck: React.FunctionComponent = () => {
  const { language, userProfile } = useGetUser();
  const {
    currentStep, definitions, packages, inviteCandidate, orderType, orderCustomId, candidates, orderName, assets,
    orderId,
  } = useGetNewCheck();
  const [countries, setCountries] = useState<Country[]>([]);

  const clientDetail = useGetClientDetail(
    {
      client: {
        id: true,
        allowedCountries: {
          id: true,
          translation: {
            localizationStrings: true,
          },
        },
        defaultCountries: {
          id: true,
          translation: {
            localizationStrings: true,
          },
        },
      },
    },
    {
      onCompleted: (data) => {
        setCountries(data?.clientDetail?.client?.defaultCountries || []);
      },
    },
  );

  const catalogueSingle = useGetScreeningCatalogSingle(catalogueMapper, {
    variables: {
      countries: countries.map((c) => c.id || -1),
    },
    onError: (error) => {
      handleApolloErrors(error, language);
    },
  });

  const catalogueMultiple = useGetScreeningCatalogMultiple(catalogueMapper, {
    variables: {
      countries: countries.map((c) => c.id || -1),
    },
    onError: (error) => {
      handleApolloErrors(error, language);
    },
  });

  TagManager.dataLayer({
    dataLayer: {
      event: 'page_view',
      realm: 'client',
      page: {
        url: document.location.origin + document.location.pathname + document.location.search,
        title: 'Checks - new',
      },
      user: {
        email: userProfile?.email,
        given_name: userProfile?.firstName,
        family_name: userProfile?.lastName,
        job_position: userProfile?.jobPosition,
        language,
      },
    },
  });

  return (
    <SectionContainer>
      <SimpleGrid
        columns={[1, 1, 1, 2, 2, 2]}
        mb={10}
        gap={10}
        borderBottom="2px"
        borderColor="#dedfe9"
        pl={3}
        minHeight="46px"
      >
        <GridItem>
          <H2
            lineHeight="35px"
            letterSpacing="-0.42px"
            fontSize="20px"
            color="#2c2d41"
            fontWeight="regular"
          >
            <Translate label={NewCheckLocale.NEW_CHECK} />
          </H2>
        </GridItem>
        <GridItem mr="0" mb="-1px">
          <StepperTabs
            steps={[
              translate(NewCheckLocale.CANDIDATES_INFORMATION, language),
              translate(NewCheckLocale.PACKAGES_AND_CHECKS, language),
              translate(NewCheckLocale.RECAPITULATION, language),
            ]}
            currentStep={currentStep - 1}
            key={currentStep}
            colorScheme="#0066F8"
            activeStepColor="#2c2d41"
            afterActiveStepColor="#5e6c7a"
            beforeActiveStepColor="#5e6c7a"
            boxProps={{ width: '495px', height: '47px' }}
            gridItemBoxProps={{ pr: '20px' }}
            textProps={{ fontSize: 'lg' }}
          />
        </GridItem>
      </SimpleGrid>
      {
        currentStep === 2 ? (
          <ProductForm
            orderId={orderId}
            candidates={candidates}
            orderName={orderName}
            orderCustomId={orderCustomId}
            packages={packages}
            definitions={definitions}
            orderType={orderType}
            inviteCandidate={inviteCandidate}
            responseSingle={{
              products: catalogueSingle.data?.screeningCatalogSingle || [],
              loading: catalogueSingle.loading,
            }}
            responseMultiple={{
              products: catalogueMultiple.data?.screeningCatalogMultiple || [],
              loading: catalogueMultiple.loading,
            }}
            allowedCountries={clientDetail.data?.clientDetail.client?.allowedCountries || []}
            countries={countries}
            changeCountriesHandler={setCountries}
          />

        ) : (
          <CandidateForm
            orderType={orderType}
            candidates={candidates}
            orderName={orderName}
            orderCustomId={orderCustomId}
            assets={assets}
            inviteCandidate={inviteCandidate}
            orderId={orderId}
          />
        )
      }
    </SectionContainer>
  );
};

export default NewCheck;
