import { Translation } from '../../core/localization/Localization.model';

class FaqLocale {
  public static MODAL_FAQ_HEADER: Translation = { EN: 'Frequently asked questions', CZ: 'Nejčastěji pokládané otázky' };
  public static ERROR_GETTING_FAQ: Translation = {
    EN: 'There was a problem getting FAQ information',
    CZ: 'Problém při načítání FAQ údajů',
  };
}

export default FaqLocale;
