import {
  Box, Flex, GridItem, SimpleGrid, Text,
} from '@chakra-ui/react';
import { Translate } from '@scaut-sro/meepo';
import React from 'react';
import { BsRecordCircleFill } from 'react-icons/bs';
import { showDateTime } from '../../core/date/showDate';
import { useGetUser } from '../../core/store/reducers/UserSettingsReducer';
import { ScreeningNotesProps } from './NotesLog.model';
import NotesLogLocale from './NotesLog.locale';

const NotesLog = ({ notes }: ScreeningNotesProps) => {
  const { language } = useGetUser();
  return (
    <Box>
      {notes && notes.length ? (
        <SimpleGrid columns={12}>
          {notes.map((note, index) => (
            <React.Fragment key={note.id}>
              <GridItem colSpan={5}>
                <Flex h="100%" flexDirection="column" justifyContent="center" color="#5E6C7A">
                  <Text textAlign="right">{showDateTime(language, note.created)}</Text>
                </Flex>
              </GridItem>
              <GridItem>
                <Flex alignItems="center" justifyContent="center" w="100%" h="100%" flexDirection="column">
                  <Flex h="100%">
                    <Box w="50%" borderRight="1px solid" borderColor={index === 0 ? 'white' : '#979797'} />
                    <Box w="50%" />
                  </Flex>
                  <Box color="#5E6C7A" fontSize="7px"><BsRecordCircleFill /></Box>
                  <Flex h="100%">
                    <Box w="50%" borderRight="1px solid" borderColor={index === (notes?.length - 1) ? 'white' : '#979797'} />
                    <Box w="50%" />
                  </Flex>
                </Flex>
              </GridItem>
              <GridItem colSpan={5}>
                <Flex h="100%" flexDirection="column" justifyContent="center" py={5}>
                  {
                    note.title && <Text color="#414254" fontSize="lg" fontWeight="500" mb={2}>{note.title}</Text>
                  }
                  <Text color="#2C2D41" lineHeight={1.4}>{note.text}</Text>
                </Flex>
              </GridItem>
            </React.Fragment>
          ))}
        </SimpleGrid>
      ) : (
        <Box w="100%" p={5} textAlign="center" color="#5E6C7A" fontWeight={300}>
          <Translate label={NotesLogLocale.NO_NOTES} />
        </Box>
      )}
    </Box>
  );
};

export default NotesLog;
