import { Translation } from '../../../core/localization/Localization.model';

class UsersLocale {
  public static USERS: Translation = { EN: 'Users list', CZ: 'Seznam uživatelů' };
  public static ADD_USER: Translation = { EN: 'Add user', CZ: 'Přidat uživatele' };
  public static NEW_USER: Translation = { EN: 'New user', CZ: 'Nový uživatel' };
  public static SAVE_CHANGES: Translation = { EN: 'Save changes', CZ: 'Uložit změny' };
  public static NAME: Translation = { EN: 'Name', CZ: 'Jméno' };
  public static TEAMS: Translation = { EN: 'Teams', CZ: 'Týmy' };
  public static ACTIVE: Translation = { EN: 'Active', CZ: 'Aktivní' };
  public static ADMIN: Translation = { EN: 'Admin', CZ: 'Admin' };
  public static PHONE: Translation = { EN: 'Phone', CZ: 'Tel.' };
  public static EMAIL: Translation = { EN: 'Email', CZ: 'Email' };
  public static LAST_ACTIVE: Translation = { EN: 'Last activity', CZ: 'Poslední akce' };
  public static FIRST_NAME: Translation = { EN: 'First name', CZ: 'Jméno' };
  public static LAST_NAME: Translation = { EN: 'Last name', CZ: 'Příjmení' };
  public static YOU: Translation = { EN: '(you)', CZ: '(vy)' };
  public static CLIENT_USER_DELETE_CONFIRMATION: Translation = {
    EN: 'Are you sure you want to delete user',
    CZ: 'Opravdu chcete smazat uživatele',
  };
  public static CLIENT_USER_DELETED: Translation = {
    EN: 'User deleted',
    CZ: 'Uživatel smazán',
  };
  public static ERROR_DELETING_CLIENT_USER: Translation = {
    EN: 'There was a problem deleting user.',
    CZ: 'Při mazání uživatele došlo k problému',
  };
  public static INVITE_SENT: Translation = { EN: 'Invite sent', CZ: 'Pozvánka odeslána' };
  public static SUCCESS_USER_SAVED: Translation = {
    EN: 'Successfully saved',
    CZ: 'Úspěšně uloženo',
  };
  public static PROBLEM_INVITING_USER: Translation = {
    EN: 'There was a problem inviting user',
    CZ: 'Došlo k problému s pozváním uživatele',
  };
  public static ERROR_GETTING_USERS: Translation = {
    EN: 'There was a problem getting users.',
    CZ: 'Při získávání uživatelů došlo k problému.',
  };
  public static ERROR_GETTING_TEAMS: Translation = {
    EN: 'There was a problem getting teams.',
    CZ: 'Problém při načitání týmů.',
  };
  public static ERROR_SAVING_CLIENT_USER: Translation = {
    EN: 'There was a problem saving user.',
    CZ: 'Při ukládání uživatele došlo k problému',
  };
  public static EMAIL_ERROR_INVALID: Translation = { EN: 'Invalid email', CZ: 'Email není validní' };
}
export default UsersLocale;
