import React, { PropsWithChildren } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { Translate, Translation } from '@scaut-sro/meepo';
import FullGreen from './fullGreen.png';
import HalfGreen from './halfGreen.png';
import HalfOrange from './halfOrange.png';
import {
  InvoiceStatesLocale,
  StateBadgeProps,
} from './InvoiceStateBadge.model';

function getStateBadgeData(state?: string): {
  locale: Translation,
  icon: any,
} {
  switch (state) {
    case 'OPEN': {
      return {
        locale: InvoiceStatesLocale.OPEN,
        icon: <img src={HalfOrange} height="16px" width="16px" alt="Open" />,
      };
    }
    case 'PAID': {
      return {
        locale: InvoiceStatesLocale.PAID,
        icon: <img src={FullGreen} height="16px" width="16px" alt="Paid" />,
      };
    }
    default: {
      return {
        locale: InvoiceStatesLocale.UNDEFINED,
        icon: <img src={HalfGreen} height="16px" width="16px" alt="Processing" />,
      };
    }
  }
}

export const InvoiceStateBadge = (
  props: PropsWithChildren<StateBadgeProps>,
) => {
  const { state } = props;
  const stateBadgeData = getStateBadgeData(state);

  return (
    <Flex alignItems="center">
      <>{stateBadgeData.icon}</>
      <Text ml={2} fontSize="sm"><Translate label={stateBadgeData.locale} /></Text>
    </Flex>
  );
};

export default InvoiceStateBadge;
