import React, { PropsWithChildren } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { Translate, Translation } from '@scaut-sro/meepo';
import FullGreen from './fullGreen.png';
import HalfGreen from './halfGreen.png';
import HalfGrey from './halfGrey.png';
import HalfOrange from './halfOrange.png';
import OrangeEx from './orangeEx.png';
import QuarterOrange from './quarterOrange.png';
import {
  StateBadgeProps, StatesLocale,
} from './StateBadge.model';

function getStateBadgeData(state?: string): {
  locale: Translation,
  icon: any,
} {
  switch (state) {
    case 'DRAFT': {
      return {
        locale: StatesLocale.DRAFT,
        icon: <img src={HalfGrey} height="16px" width="16px" alt="Draft" />,
      };
    }
    case 'NOT_STARTED': {
      return {
        locale: StatesLocale.PROCESSING,
        icon: <img src={HalfGreen} height="16px" width="16px" alt="Processing" />,
      };
    }
    case 'WAITING_FOR_CLIENT': {
      return {
        locale: StatesLocale.WAITING_FOR_CLIENT,
        icon: <img src={HalfOrange} height="16px" width="16px" alt="Waiting for client" />,
      };
    }
    case 'WAITING_FOR_CANDIDATE': {
      return {
        locale: StatesLocale.WAITING_FOR_CANDIDATE,
        icon: <img src={HalfGrey} height="16px" width="16px" alt="Waiting for candidate" />,
      };
    }
    case 'WAITING_FOR_POA': {
      return {
        locale: StatesLocale.WAITING_FOR_POWER_OF_ATTORNEY,
        icon: <img src={HalfGrey} height="16px" width="16px" alt="Waiting for candidate" />,
      };
    }
    case 'PROCESSING': {
      return {
        locale: StatesLocale.PROCESSING,
        icon: <img src={HalfGreen} height="16px" width="16px" alt="Processing" />,
      };
    }
    case 'STARTED': {
      return {
        locale: StatesLocale.PROCESSING,
        icon: <img src={HalfGreen} height="16px" width="16px" alt="Processing" />,
      };
    }
    case 'FINISHED': {
      return {
        locale: StatesLocale.FINISHED,
        icon: <img src={FullGreen} height="16px" width="16px" alt="Finished" />,
      };
    }
    case 'FOR_APPROVAL': {
      return {
        locale: StatesLocale.FOR_APPROVAL,
        icon: <img src={QuarterOrange} height="16px" width="16px" alt="For approval" />,
      };
    }
    case 'CANNOT_COMPLETE': {
      return {
        locale: StatesLocale.CANNOT_COMPLETE,
        icon: <img src={OrangeEx} height="16px" width="16px" alt="Cannot complete" />,
      };
    }
    default: {
      return {
        locale: StatesLocale.PROCESSING,
        icon: <img src={HalfGreen} height="16px" width="16px" alt="Processing" />,
      };
    }
  }
}

export const StateBadge = (
  props: PropsWithChildren<StateBadgeProps>,
) => {
  const { state } = props;
  const stateBadgeData = getStateBadgeData(state);

  return (
    <Flex alignItems="center">
      <>{stateBadgeData.icon}</>
      <Text ml={2} fontSize="sm"><Translate label={stateBadgeData.locale} /></Text>
    </Flex>
  );
};

export default StateBadge;
