import { BadgeProps } from '@chakra-ui/react';
import { Translation } from '../../core/localization/Localization.model';

export type ResultBadgeProps = {
  result?: string;
  size?: 'md' | 'lg';
  badgeProps?: BadgeProps
};

export type ResultBadgeData = {
  locale?: Translation;
  color?: string;
  width?: number;
  fontSize?: string;
  variant?: string;
};

export class ResultsLocale {
  public static CLEAR: Translation = { EN: 'Clear', CZ: 'V pořádku' };
  public static FOUND_RESULTS: Translation = { EN: 'Consider', CZ: 'Ke zvážení' };
  public static UNDEFINED: Translation = { EN: 'Processing', CZ: 'Zpracovává se' };
}
