import React from 'react';
import {
  Button,
  Center, Flex, Icon,
} from '@chakra-ui/react';
import { FiDownload } from 'react-icons/fi';
import { useKeycloak } from '@react-keycloak/web';
import InvoicesLocale from './Invoices.locale';
import Translate from '../../../components/Translate/Translate';
import { downloadFile } from '../../../core/service/queries/FileUploadService';

const DownloadInvoiceRow: React.FunctionComponent<{ fileId: number; }> = ({ fileId }) => {
  const keycloak = useKeycloak();

  const downloadInvoice = () => {
    if (keycloak.keycloak.token) {
      downloadFile(keycloak.keycloak.token, `/dms/getFile/${fileId}`);
    }
  };
  return (
    <>
      <Flex>
        <Center>
          <Button
            leftIcon={<Icon as={FiDownload} />}
            variant="link"
            onClick={() => {
              downloadInvoice();
            }}
          >
            <Translate label={InvoicesLocale.DOWNLOAD_INVOICE} />
          </Button>

        </Center>
      </Flex>

    </>

  );
};

export default DownloadInvoiceRow;
