import React from 'react';
import {
  H2, SectionContainer,
} from '@scaut-sro/meepo';
import TagManager from 'react-gtm-module';
import UserSettingsLocale from './UserSettings.locale';
import Translate from '../../components/Translate/Translate';
import UserInfoForm from './UserInfoForm';
import ChangePasswordForm from './ChangePasswordForm';
import AvatarSection from './AvatarSection';
import { useGetUser } from '../../core/store/reducers/UserSettingsReducer';

const UserSettings: React.FunctionComponent = () => {
  const { language, userProfile } = useGetUser();

  TagManager.dataLayer({
    dataLayer: {
      event: 'page_view',
      realm: 'client',
      page: {
        url: document.location.origin + document.location.pathname + document.location.search,
        title: 'User settings',
      },
      user: {
        email: userProfile?.email,
        given_name: userProfile?.firstName,
        family_name: userProfile?.lastName,
        job_position: userProfile?.jobPosition,
        language,
      },
    },
  });

  return (
    <div>
      <AvatarSection />
      <SectionContainer>
        <H2 mb={6}><Translate label={UserSettingsLocale.PERSONAL_INFORMATION} /></H2>
        <UserInfoForm />
      </SectionContainer>
      <SectionContainer>
        <H2 mb={6}>
          <Translate label={UserSettingsLocale.PASSWORD_CHANGE} />
        </H2>
        <ChangePasswordForm />
      </SectionContainer>
    </div>
  );
};

export default UserSettings;
