import { CountryProductsDto } from '../../build/generated-sources/dto/CountryProductsDto';
import { OrderDefinitionInputDto } from '../../build/generated-sources/dto/OrderDefinitionInputDto';

export function getPrice(
  selectedPackages: number[],
  selectedDefinitions: OrderDefinitionInputDto[],
  dataDefinition: CountryProductsDto[],
): number {
  let price = 0;
  dataDefinition.map((countryBlock) => {
    countryBlock.screeningPackages?.map((packageDef) => {
      if (selectedPackages.indexOf(packageDef?.id || -1) !== -1) {
        price += packageDef.price || 0;
      }
    });
    countryBlock.screeningDefinitions?.map((definitionDef) => {
      if (selectedDefinitions.map((def) => def.id).indexOf(definitionDef?.id || -1) !== -1) {
        const selectedDefAmount = selectedDefinitions.find((def) => def.id === definitionDef.id)?.amount;
        if (selectedDefAmount && selectedDefAmount > 1) {
          price += (definitionDef.price || 0) * selectedDefAmount;
        } else {
          price += definitionDef.price || 0;
        }
      }
    });
  });
  return price;
}

export default getPrice;
