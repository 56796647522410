import { Translation } from '@scaut-sro/meepo';

class AuthorizationSectionLocale {
  public static SEND: Translation = { EN: 'Send the Authorization', CZ: 'Odeslat pověření' };
  public static INFO_TEXT1: Translation = {
    EN: 'An authorization signed by the inspected person is required for this inspection. '
      + 'Before printing, please check whether the given information is correct. '
      + 'Upload the signed document back into the system.',
    CZ: 'Pro tuto kontrolu je vyžadováno pověření podepsané kontrolovanou osobou. '
      + 'Před vytištěním prosíme zkontrolujte zda uvedené informace jsou zdány správně. '
      + 'Podepsaný dokument nahrajte zpět do systému.',
  };
  public static FORM_ERROR_ATTACHMENT_MANDATORY: Translation = {
    EN: 'A signed authorization needs to be uploaded',
    CZ: 'Je potřeba nahrát podepsané pověření',
  };
}

export default AuthorizationSectionLocale;
