import { Translation } from '@scaut-sro/meepo';

class CatalogueLocale {
  public static ADD_COUNTRY: Translation = { EN: 'Add country', CZ: 'Přidat stát' };
  public static RECCOMENDED_PACKAGES: Translation = { EN: 'Recommended packages', CZ: 'Doporučené balíčky' };
  public static GLOBAL_SCREENINGS: Translation = { EN: 'Global screenings', CZ: 'Globální kontroly' };
  public static CHOOSE_COUNTRY: Translation = { EN: 'Choose a country', CZ: 'Vyberte stát' };
  public static CANCEL_MODAL: Translation = { EN: 'Cancel', CZ: 'Zrušit' };
  public static CONFIRM_MODAL: Translation = { EN: 'Confirm', CZ: 'Potvrdit' };
  public static SEARCH_COUNTRY: Translation = { EN: 'Search', CZ: 'Vyhledat' };
}

export default CatalogueLocale;
