import { combineReducers } from '@reduxjs/toolkit';
import user from './UserSettingsReducer';
import newCheck from './NewCheckReducer';
import filter from './FilterReducer';

const RootReducer = combineReducers({
  user,
  newCheck,
  filter,
});

export default RootReducer;
