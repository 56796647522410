import { Translation } from '../../core/localization/Localization.model';

class ClientSettingsLocale {
  public static USERS: Translation = { EN: 'Users', CZ: 'Uživatelé' };
  public static MY_COMPANY: Translation = { EN: 'My company', CZ: 'Moje firma' };
  public static CONFLICT_OF_INTEREST: Translation = { EN: 'Conflict of interest', CZ: 'Konflikt zájmů' };
  public static ANONYMIZATION: Translation = { EN: 'Anonymization', CZ: 'Anonymizace' };
  public static CREDITS: Translation = { EN: 'Credits and prepayments', CZ: 'Kredit a dobíjení' };
  public static ORDERS: Translation = { EN: 'Orders', CZ: 'Objednávky' };
  public static INVOICES: Translation = { EN: 'Invoices', CZ: 'Fakturace' };
  public static COUNTRIES: Translation = { EN: 'Displayed countries', CZ: 'Zobrazované země' };
  public static WHITELIST: Translation = { EN: 'Access limitation', CZ: 'Omezení přístupu' };
  public static ERROR_GETTING_DATA: Translation = {
    EN: 'There was a problem getting data',
    CZ: 'Při získávání dat došlo k problému',
  };
}

export default ClientSettingsLocale;
