import { Translation } from '../../../core/localization/Localization.model';

class ClientDetailLocale {
  public static COMPANY_INFORMATION: Translation = { EN: 'Company information', CZ: 'Údaje o firmě' };
  public static SAVE_CHANGES: Translation = { EN: 'Save changes', CZ: 'Uložit změny' };
  public static NAME: Translation = { EN: 'Name', CZ: 'Název' };
  public static CLIENT_EMAIL: Translation = { EN: 'Company email', CZ: 'Email společnosti' };
  public static CLIENT_PHONE: Translation = { EN: 'Company phone', CZ: 'Telefon společnosti' };
  public static ID: Translation = { EN: 'ID', CZ: 'IČO' };
  public static VAT: Translation = { EN: 'VAT NR.', CZ: 'DIČ' };
  public static COUNTRY: Translation = { EN: 'Country', CZ: 'Země' };
  public static STREET: Translation = { EN: 'Street', CZ: 'Ulice' };
  public static CITY: Translation = { EN: 'City', CZ: 'Město' };
  public static ZIP: Translation = { EN: 'ZIP', CZ: 'PSČ' };
  public static COMMUNICATION_LANGUAGE: Translation = { EN: 'Communication language', CZ: 'Jazyk komunikace' };
  public static ENABLE_2FA: Translation = { EN: 'Two-factor authentication', CZ: 'Dvoufaktorové ověření' };
  public static EMAIL_PLACEHOLDER: Translation = { EN: 'john.doe@mycompany.com', CZ: 'jan.novak@mojefirma.CZ' };
  public static PHONE_PLACEHOLDER: Translation = { EN: '+44 1234 1234', CZ: '+420123456789' };
  public static PHONE_ERROR_MIN_LENGTH: Translation = {
    EN: 'Phone has to have more than 8 numbers', CZ: 'Telefon musí mít více jak 8 číslic',
  };
  public static PHONE_ERROR_MAX_LENGTH: Translation = {
    EN: 'Phone has to have less than 15 numbers', CZ: 'Telefon musí mít méně než 15 číslic',
  };
  public static EN: Translation = { EN: 'English', CZ: 'Angličtina' };
  public static ENGLISH: Translation = { EN: 'English', CZ: 'Angličtina' };
  public static CZ: Translation = { EN: 'Czech', CZ: 'Čeština' };
  public static CZECH: Translation = { EN: 'Czech', CZ: 'Čeština' };
  public static ERROR_GETTING_CLIENT_DATA: Translation = {
    EN: 'There was a problem getting client data.',
    CZ: 'Při získávání klientských dat došlo k problému.',
  };
  public static ERROR_GETTING_DATA: Translation = {
    EN: 'There was a problem getting data',
    CZ: 'Při získávání dat došlo k problému',
  };
}

export default ClientDetailLocale;
