import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { ExecutionsModalProps } from '../CandidateScreeningDetail.model';
import ExecutionsConfirmation from './ExecutionsConfirmation';

const ExecutionsModal: React.FunctionComponent<ExecutionsModalProps> = (props) => {
  const { searchResults, formId, refreshData } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (searchResults && searchResults.length > 0) {
      onOpen();
    }
  }, [searchResults, onOpen]);

  return (
    <>
      <Modal closeOnOverlayClick={false} size="4xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <ExecutionsConfirmation
              searchResults={searchResults}
              formId={formId}
              loadingSetter={(isLoading) => { setLoading(isLoading); }}
              loading={loading}
              onClose={onClose}
              refreshData={refreshData}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ExecutionsModal;
