import React, { useEffect, useMemo, useState } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import {
  CentralizedContainer,
  H1, H3, PasswordInput, Toast,
} from '@scaut-sro/meepo';
import {
  Box,
  Button, Checkbox,
  Divider, Flex,
  FormControl,
  FormErrorMessage,
  FormLabel, GridItem,
  Input, Link, ListItem, Popover, PopoverBody, PopoverContent, PopoverTrigger,
  Select,
  SimpleGrid, Spacer, UnorderedList,
} from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useHistory } from 'react-router-dom';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { RestLink } from 'apollo-link-rest';
import { format } from 'react-string-format';
import { Validate } from 'react-hook-form/dist/types/validator';
import { FieldPath, FieldPathValue } from 'react-hook-form/dist/types/path';
import { RiQuestionLine } from 'react-icons/all';
import { translate } from '../../core/localization/LocaleUtils';
import RegistrationLocale from '../../core/localization/translations/RegistrationLocale';
import { Language } from '../../build/generated-sources/enum/Language';
import FormLocale from '../../core/localization/translations/Form.locale';
import * as surveyQuery from '../../core/service/queries/SurveyQuery';
import * as countryQuery from '../../core/service/queries/CountryQuery';
import * as passwordPolicyQuery from '../../core/service/queries/PasswordPolicyQuery';
import * as registrationQuery from '../../core/service/queries/RegistrationQuery';
import ScautErrorHandlerLocale from '../../core/error/ScautErrorHandler.locale';
import ScautError from '../../core/error/ScautErrorHandler.model';
import { getKeycloakPasswordValidations } from '../../core/util/Validation';
import UserSettingsLocale from '../UserSettings/UserSettings.locale';
import { KeycloakPasswordPolicy } from '../../build/generated-sources/enum/KeycloakPasswordPolicy';
import { KeycloakPasswordPolicyDto } from '../../build/generated-sources/dto/KeycloakPasswordPolicyDto';
import Translate from '../../components/Translate/Translate';
import ScautLogo from '../../components/Logo/Logo';

const Registration: React.FunctionComponent = () => {
  const [language, setMylanguage] = useState<Language>(Language.EN);
  const [legalMessage1Checked, setLegalMessage1Checked] = useState<boolean>(false);
  const [legalMessage2Checked, setLegalMessage2Checked] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    if (navigator.language === 'cs' || navigator.language === 'cs-CZ') {
      setMylanguage(Language.CZ);
    } else {
      setMylanguage(Language.EN);
    }
  }, []);
  const ico = '25596641';
  const history = useHistory();
  const {
    register, handleSubmit, getValues, formState: { errors },
  } = useForm();

  const client = useMemo(() => {
    const restLink = new RestLink({
      uri: `${process.env.REACT_APP_API_URL}/public`,
    });

    return new ApolloClient({
      link: restLink,
      cache: new InMemoryCache(),
    });
  }, []);

  const [enumerations, setEnumerations] = useState([]);
  const [survey, setSurvey] = useState([]);
  const [passwordPolicies, setPasswordPolicies] = useState<KeycloakPasswordPolicyDto[]>([]);
  const [passwordPoliciesPopover, setPasswordPoliciesPopover] = useState<string[]>([]);

  const [validations, setValidations] = useState<{
    [key: string]: Validate<FieldPathValue<FieldValues, FieldPath<FieldValues>>>
  }>({});

  useEffect(() => {
    client.query({ query: countryQuery.getCountry }).then((response) => setEnumerations(response.data.enumerations));
    client.query({ query: surveyQuery.getSurvey }).then((response) => setSurvey(response.data.enumerations));
    client.query({ query: passwordPolicyQuery.getPasswordPolicies })
      .then((response) => setPasswordPolicies(response.data.passwordPolicies));
  }, [client]);

  const countryOptions = () => {
    if (enumerations !== undefined && enumerations !== null) {
      const flattened = enumerations.map((element: any) => ({
        ...element,
        value: element.translation.localizationStrings[language].value,
      })).sort((a, b) => a.value.localeCompare(b.value));

      return flattened
        .map((element: any) => (
          <option
            key={element.id}
            value={element.id}
            label={element.translation.localizationStrings[language].value}
          >
            {element.translation.localizationStrings[language].value}
          </option>
        ));
    }
    return <option label=" " />;
  };

  const surveyOptions = () => {
    if (survey !== undefined && survey !== null) {
      return survey.map((element: any) => (
        <option
          key={element.id}
          value={element.id}
          label={element.translation.localizationStrings[language].value}
        >
          {element.translation.localizationStrings[language].value}
        </option>
      ));
    }
    return <option label=" " />;
  };

  const handleRegistrationSubmit: SubmitHandler<FieldValues> = (values) => {
    setLoading(true);
    client.mutate({
      mutation: registrationQuery.postClient,
      variables: {
        clientRegistrationDto:
        {
          clientName: values.clientName,
          clientEmail: values.clientEmail,
          clientPhone: values.clientPhone,
          ico: values.ico,
          dic: values.dic,
          street: values.street,
          city: values.city,
          zipcode: values.zipcode,
          countryId: values.countryId,

          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          phone: values.phone,
          password: values.password,
          jobPosition: values.jobPosition,
          survey: values.survey,
          language,
        },
      },
    }).then((response) => {
      if (response?.data?.registerClient && !response?.data?.registerClient?.success) {
        const exception = response?.data?.registerClient?.exceptionDto;
        let errorMessage = translate(FormLocale.ERROR, language);
        if (exception) {
          const scautError = translate(ScautErrorHandlerLocale[exception.type as ScautError], language);
          errorMessage = format(scautError, exception.params[0]);
          Toast({
            title: translate(FormLocale.ERROR, language),
            description: errorMessage,
            status: 'error',
            position: 'bottom-right',
            variant: 'solid',
            isClosable: true,
          });
          setLoading(false);
        }
      } else if (!response.errors) {
        Toast({
          title: translate(FormLocale.SUCCESS, language),
          status: 'success',
        });
        history.push('/');
      }
    }).catch(() => {
      Toast({
        title: translate(FormLocale.ERROR, language),
        status: 'error',
      });
      setLoading(false);
    });
  };

  useEffect(() => {
    if (passwordPolicies) {
      const { email } = getValues();
      setValidations(getKeycloakPasswordValidations(passwordPolicies, language, email));
      const popoverData: string[] = [];
      passwordPolicies.forEach((policy) => {
        if (policy.policy) {
          let message = translate(UserSettingsLocale[policy.policy as KeycloakPasswordPolicy], language);
          if (policy.value) {
            message = format(message, policy.value);
          }
          popoverData.push(message);
        }
      });
      setPasswordPoliciesPopover(popoverData);
    }
  }, [passwordPolicies, language, getValues]);

  return (
    <Box>
      <CentralizedContainer
        header={(<Box mt={-1}><ScautLogo /></Box>)}
      >
        <form name="regInfo" onSubmit={handleSubmit(handleRegistrationSubmit)}>
          <Divider mb={['20px', '20px', '20px', '30px', '30px']} />
          <H1>{translate(RegistrationLocale.REGISTRATION, language)}</H1>
          <H3>{translate(RegistrationLocale.LOGIN_INFORMATION, language)}</H3>
          <SimpleGrid columns={[1, 1, 2]} spacing={6} mb={6}>
            <FormControl isInvalid={errors.email} isRequired>
              <FormLabel htmlFor="email">{translate(RegistrationLocale.EMAIL, language)}</FormLabel>
              <Input
                id="email"
                placeholder={translate(RegistrationLocale.EMAIL_PLACEHOLDER, language)}
                {...register('email', {
                  required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
                  pattern: {
                    // eslint-disable-next-line max-len
                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: translate(RegistrationLocale.EMAIL_ERROR_INVALID, language),
                  },
                })}
              />
              <FormErrorMessage>
                {errors.email && errors.email.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.password} isRequired>
              <Flex>
                <FormLabel htmlFor="password">{translate(RegistrationLocale.PASSWORD, language)}</FormLabel>
                {passwordPoliciesPopover && passwordPoliciesPopover.length > 0 ? (
                  <Popover placement="top-end">
                    <PopoverTrigger>
                      <Box mt={1}>
                        <RiQuestionLine />
                      </Box>
                    </PopoverTrigger>
                    <PopoverContent width="310px">
                      <PopoverBody>
                        <Translate label={UserSettingsLocale.PASSWORD_RULES} />
                        <UnorderedList>
                          {passwordPoliciesPopover.map((popoverInfo) => <ListItem>{popoverInfo}</ListItem>)}
                        </UnorderedList>
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                ) : undefined}
              </Flex>
              <PasswordInput
                id="password"
                placeholder={
                  translate(RegistrationLocale.PASSWORD, language)
                }
                {...register('password', {
                  required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
                  validate: validations,
                })}
              />
              <FormErrorMessage>
                {errors.password && errors.password.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.passwordConfirmation} isRequired>
              <FormLabel htmlFor="passwordConfirmation">
                {translate(RegistrationLocale.PASSWORD_CONFIRMATION, language)}
              </FormLabel>
              <PasswordInput
                id="passwordConfirmation"
                placeholder={
                  translate(RegistrationLocale.PASSWORD_CONFIRMATION_PLACEHOLDER, language)
                }
                {...register('passwordConfirmation', {
                  required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
                  validate: {
                    matchesPreviousPassword: (value) => {
                      const { password } = getValues();
                      return password === value || translate(
                        RegistrationLocale.PASSWORD_CONFIRMATION_ERROR, language,
                      );
                    },
                  },
                })}
              />
              <FormErrorMessage>
                {errors.passwordConfirmation && errors.passwordConfirmation.message}
              </FormErrorMessage>
            </FormControl>
          </SimpleGrid>
          <H3>{translate(RegistrationLocale.PERSONAL_INFORMATION, language)}</H3>
          <SimpleGrid columns={[1, 1, 2]} spacing={6} mb={6}>
            <FormControl isInvalid={errors.firstName} isRequired>
              <FormLabel htmlFor="firstName">{translate(RegistrationLocale.FIRST_NAME, language)}</FormLabel>
              <Input
                id="firstName"
                placeholder={translate(RegistrationLocale.FIRST_NAME_PLACEHOLDER, language)}
                {...register('firstName', {
                  required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
                })}
              />
              <FormErrorMessage>
                {errors.firstName && errors.firstName.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.lastName} isRequired>
              <FormLabel htmlFor="lastName">{translate(RegistrationLocale.LAST_NAME, language)}</FormLabel>
              <Input
                id="lastName"
                placeholder={translate(RegistrationLocale.LAST_NAME_PLACEHOLDER, language)}
                {...register('lastName', {
                  required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
                })}
              />
              <FormErrorMessage>
                {errors.lastName && errors.lastName.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.position}>
              <FormLabel htmlFor="jobPosition">{translate(RegistrationLocale.POSITION, language)}</FormLabel>
              <Input
                id="jobPosition"
                placeholder={translate(RegistrationLocale.POSITION_PLACEHOLDER, language)}
                {...register('jobPosition')}
              />
            </FormControl>
            <FormControl isInvalid={errors.phone}>
              <FormLabel htmlFor="phone">{translate(RegistrationLocale.PHONE, language)}</FormLabel>
              <Input
                id="phone"
                type="tel"
                placeholder={translate(RegistrationLocale.PHONE_PLACEHOLDER, language)}
                {...register('phone', {
                  maxLength: {
                    value: 15,
                    message: translate(RegistrationLocale.PHONE_ERROR_MAX_LENGTH, language),
                  },
                  minLength: {
                    value: 8,
                    message: translate(RegistrationLocale.PHONE_ERROR_MIN_LENGTH, language),
                  },
                  pattern: {
                    value: /^\+?[\d\s-]*$/,
                    message: translate(FormLocale.INVALID_CHARACTERS, language),
                  },
                })}
              />
              <FormErrorMessage>
                {errors.phone && errors.phone.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="survey">{translate(RegistrationLocale.SURVEY, language)}</FormLabel>
              <Select
                id="survey"
                {...register('survey')}
                placeholder={`--${translate(RegistrationLocale.SELECT_VALUE, language)}--`}
              >
                {surveyOptions()}
              </Select>
            </FormControl>
          </SimpleGrid>
          <H3>{translate(RegistrationLocale.COMPANY_INFORMATION, language)}</H3>
          <SimpleGrid columns={[1, 1, 2]} spacing={6} mb={10}>
            <FormControl isRequired>
              <FormLabel htmlFor="ico">{translate(RegistrationLocale.COMPANY_NUMBER, language)}</FormLabel>
              <Input
                id="ico"
                placeholder={ico}
                {...register('ico', { required: translate(FormLocale.ERROR_MANDATORY_FIELD, language) })}
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="dic">{translate(RegistrationLocale.COMPANY_VAT, language)}</FormLabel>
              <Input
                id="dic"
                placeholder={translate(RegistrationLocale.DIC_PLACEHOLDER, language)}
                {...register('dic')}
              />
            </FormControl>

            <FormControl isRequired isInvalid={errors.clientName}>
              <FormLabel htmlFor="clientName">{translate(RegistrationLocale.CLIENT_NAME, language)}</FormLabel>
              <Input
                id="clientName"
                placeholder={translate(RegistrationLocale.CLIENT_NAME_PLACEHOLDER, language)}
                {...register('clientName', { required: translate(FormLocale.ERROR_MANDATORY_FIELD, language) })}
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel htmlFor="street">{translate(RegistrationLocale.COMPANY_STREET, language)}</FormLabel>
              <Input
                id="street"
                placeholder={translate(RegistrationLocale.COMPANY_STREET_PLACEHOLDER, language)}
                {...register('street', { required: translate(FormLocale.ERROR_MANDATORY_FIELD, language) })}
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel htmlFor="city">{translate(RegistrationLocale.COMPANY_CITY, language)}</FormLabel>
              <Input
                id="city"
                placeholder={translate(RegistrationLocale.COMPANY_CITY_PLACEHOLDER, language)}
                {...register('city', { required: translate(FormLocale.ERROR_MANDATORY_FIELD, language) })}
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel htmlFor="zipcode">{translate(RegistrationLocale.COMPANY_ZIPCODE, language)}</FormLabel>
              <Input
                id="zipcode"
                placeholder={translate(RegistrationLocale.COMPANY_ZIPCODE_PLACEHOLDER, language)}
                {...register('zipcode', { required: translate(FormLocale.ERROR_MANDATORY_FIELD, language) })}
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel htmlFor="countryId">{translate(RegistrationLocale.COMPANY_COUNTRY, language)}</FormLabel>
              <Select
                id="countryId"
                placeholder={`--${translate(RegistrationLocale.SELECT_VALUE, language)}--`}
                {...register('countryId', { required: translate(FormLocale.ERROR_MANDATORY_FIELD, language) })}
              >
                {countryOptions()}
              </Select>
            </FormControl>

            <FormControl isRequired isInvalid={errors.clientEmail}>
              <FormLabel htmlFor="clientEmail">{translate(RegistrationLocale.CLIENT_EMAIL, language)}</FormLabel>
              <Input
                id="clientEmail"
                placeholder={translate(RegistrationLocale.EMAIL_PLACEHOLDER, language)}
                {...register('clientEmail', {
                  required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
                  pattern: {
                    // eslint-disable-next-line max-len
                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: translate(RegistrationLocale.EMAIL_ERROR_INVALID, language),
                  },
                })}
              />
              <FormErrorMessage>
                {errors.clientEmail && errors.clientEmail.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.clientPhone}>
              <FormLabel htmlFor="clientPhone">{translate(RegistrationLocale.CLIENT_PHONE, language)}</FormLabel>
              <Input
                id="clientPhone"
                type="tel"
                placeholder={translate(RegistrationLocale.PHONE_PLACEHOLDER, language)}
                {...register('clientPhone', {
                  maxLength: {
                    value: 15,
                    message: translate(RegistrationLocale.PHONE_ERROR_MAX_LENGTH, language),
                  },
                  minLength: {
                    value: 8,
                    message: translate(RegistrationLocale.PHONE_ERROR_MIN_LENGTH, language),
                  },
                  pattern: {
                    value: /^\+?[\d\s-]*$/,
                    message: translate(FormLocale.INVALID_CHARACTERS, language),
                  },
                })}
              />
              <FormErrorMessage>
                {errors.clientPhone && errors.clientPhone.message}
              </FormErrorMessage>
            </FormControl>
            <Box />
            <GridItem colSpan={[1, 1, 2]}>
              <FormControl isRequired isInvalid={errors.legalMessage1}>
                <Flex>
                  <Checkbox
                    id="legalMessage1"
                    mt="5px"
                    {...register('legalMessage1')}
                    onChange={(event) => setLegalMessage1Checked(event.target.checked)}
                  />
                  <FormLabel mt={3} ml={3} htmlFor="legalMessage1">
                    {translate(RegistrationLocale.LEGAL_MESSAGE_1_PART_1, language)}
                    {' '}
                    {' '}
                    <Link color="#5456d3" href="/terms_and_conditions-2023.pdf" isExternal>
                      {translate(RegistrationLocale.LEGAL_MESSAGE_1_PART_2, language)}
                    </Link>
                  </FormLabel>
                </Flex>
                <FormErrorMessage>
                  {errors.legalMessage1 && errors.legalMessage1.message}
                </FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={[1, 1, 2]}>
              <FormControl isRequired isInvalid={errors.legalMessage2}>
                <Flex>
                  <Checkbox
                    id="legalMessage2"
                    mt="5px"
                    {...register('legalMessage2')}
                    onChange={(event) => setLegalMessage2Checked(event.target.checked)}
                  />
                  <FormLabel mt={3} ml={3} htmlFor="legalMessage2">
                    {translate(RegistrationLocale.LEGAL_MESSAGE_2_PART_1, language)}
                    {' '}
                    <Link color="#5456d3" href="/privacy_policy-2023.pdf" isExternal>
                      {translate(RegistrationLocale.LEGAL_MESSAGE_2_PART_2, language)}
                    </Link>
                    {' '}
                    {translate(RegistrationLocale.LEGAL_MESSAGE_2_PART_3, language)}
                  </FormLabel>
                </Flex>
                <FormErrorMessage>
                  {errors.legalMessage2 && errors.legalMessage2.message}
                </FormErrorMessage>
              </FormControl>
            </GridItem>
          </SimpleGrid>
          <Flex>
            <Button
              leftIcon={<ArrowBackIcon />}
              variant="ghost"
              onClick={() => history.push('./')}
            >
              {translate(RegistrationLocale.BACK_TO_LOGIN, language)}
            </Button>
            <Spacer />
            <Button
              type="submit"
              isLoading={loading}
              isDisabled={!legalMessage1Checked || !legalMessage2Checked}
            >
              {translate(RegistrationLocale.SUBMIT_REG, language)}
            </Button>
          </Flex>
        </form>
      </CentralizedContainer>
    </Box>
  );
};

export default Registration;
