import React, { useMemo, useState } from 'react';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Flex,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Box,
  useDisclosure, AccordionIcon, MenuItem,
} from '@chakra-ui/react';
import { Toast } from '@scaut-sro/meepo';
import Translate from '../../components/Translate/Translate';
import FaqLocale from './Faq.locale';
import { useGetFaqs } from '../../build/generated-sources/service/QueryService';
import { translate } from '../../core/localization/LocaleUtils';
import { useGetUser } from '../../core/store/reducers/UserSettingsReducer';
import { FAQ } from '../../build/generated-sources/dto/FAQ';
import UserMenuLocale from '../../core/localization/translations/UserMenu.locale';

const FaqModal: React.FunctionComponent = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [faqList, setFaqList] = useState<FAQ[]>([]);
  const { language } = useGetUser();

  useGetFaqs(
    {
      id: true,
      question: { localizationStrings: true },
      answer: { localizationStrings: true },
    }, {
      onError: () => {
        Toast({
          title: translate(FaqLocale.ERROR_GETTING_FAQ, language),
          status: 'error',
        });
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: (response) => {
        setFaqList(response.faqs);
      },
    },
  );

  const faqOptions = useMemo(() => faqList.map((f) => ({
    value: f.id,
    question: f?.question?.localizationStrings?.[language],
    answer: f?.answer?.localizationStrings?.[language],
  })), [faqList, language]);

  return (
    <>
      <MenuItem
        onClick={onOpen}
      >
        <Translate label={UserMenuLocale.FAQ} />
      </MenuItem>
      <Modal size="sm" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text><Translate label={FaqLocale.MODAL_FAQ_HEADER} /></Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex direction="column" p="4">
              <Accordion allowToggle allowMultiple>
                {
                  faqOptions.map((faq) => (
                    <AccordionItem key={faq.value}>
                      <AccordionButton>
                        <Box flex="1" textAlign="left">
                          <Text fontWeight="semibold">
                            {faq.question}
                          </Text>
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel pb="4">
                        {faq.answer}
                      </AccordionPanel>
                    </AccordionItem>
                  ))
                }
              </Accordion>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default FaqModal;
