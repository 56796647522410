import React, { useEffect, useMemo, useState } from 'react';
import {
  FormControl, FormErrorMessage, FormLabel, Input, SimpleGrid,
} from '@chakra-ui/react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Select } from 'chakra-react-select';

import { Toast } from '@scaut-sro/meepo';
import UsersLocale from '../Users.locale';
import { useGetUser } from '../../../../core/store/reducers/UserSettingsReducer';
import { TeamDto } from '../../../../build/generated-sources/dto/TeamDto';
import { useGetTeams } from '../../../../build/generated-sources/service/QueryService';
import { translate } from '../../../../core/localization/LocaleUtils';
import { useClientUser, useClientUserCreate } from '../../../../build/generated-sources/service/MutationService';
import FormLocale from '../../../../core/localization/translations/Form.locale';
import Translate from '../../../../components/Translate/Translate';
import { UserDetailFormAttr, UserDetailFormProps } from '../Users.model';
import { handleApolloErrors } from '../../../../core/error/ScautErrorHandler';

const UserRegistrationMapper = {
  id: true,
};

const UserDetailForm: React.FunctionComponent<UserDetailFormProps> = (props) => {
  const {
    clientUser, formRef, loadingSetter, onClose, setUsersUpdated,
  } = props;
  const { language } = useGetUser();
  const [teamList, setTeamList] = useState<TeamDto[]>([]);
  const defaultValues: Partial<UserDetailFormAttr> = useMemo(() => ({
    firstName: clientUser?.firstName,
    lastName: clientUser?.lastName,
    phone: clientUser?.phone,
    email: clientUser?.email,
    teams: clientUser?.teams?.map((team) => ({
      value: team.id || -1,
      label: team.name || '',
    })),
  }), [clientUser]);

  const {
    handleSubmit, register, setValue, reset, formState: { errors }, control,
  } = useForm<Partial<UserDetailFormAttr>>({
    defaultValues,
  });

  const teamOptions = useMemo(() => teamList.map((team) => ({
    value: team.id,
    label: team.name,
  })), [teamList]);

  useGetTeams(
    {
      id: true,
      name: true,
    }, {
      onError: () => {
        Toast({
          title: translate(UsersLocale.ERROR_GETTING_TEAMS, language),
          status: 'error',
        });
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: (response) => {
        setTeamList(response.teams);
      },
    },
  );

  const teamOptionSelected = useMemo(() => (teamOptions.filter(
    (option) => {
      const isSelected = clientUser?.teams?.find(
        (team) => team.id === option.value,
      );
      return !!isSelected;
    },
  )), [teamOptions, clientUser]);

  useEffect(() => {
    reset(defaultValues);
    setValue('teams', teamOptionSelected as { value: number, label: string }[]);
  }, [
    reset, setValue, defaultValues, teamOptionSelected,
  ]);

  const [mutateClientUserCreate,
    mutateClientUserCreateOptions] = useClientUserCreate(UserRegistrationMapper, {
    onCompleted: () => {
      Toast({
        title: translate(UsersLocale.INVITE_SENT, language),
        status: 'success',
      });
      setUsersUpdated(true);
      onClose();
    },
    onError: (error) => {
      handleApolloErrors(error, language);
    },
  });

  const [mutateUser,
    mutateUserOptions] = useClientUser({
    id: true,
  }, {
    onCompleted: () => {
      Toast({
        title: translate(UsersLocale.SUCCESS_USER_SAVED, language),
        status: 'success',
      });
      setUsersUpdated(true);
      onClose();
    },
    onError: (error) => {
      handleApolloErrors(error, language);
    },
  });

  useEffect(() => {
    if (loadingSetter) loadingSetter(mutateClientUserCreateOptions.loading);
    if (loadingSetter) loadingSetter(mutateUserOptions.loading);
  }, [loadingSetter, mutateClientUserCreateOptions, mutateUserOptions]);

  const handleUserRegistrationFormSubmit: SubmitHandler<UserDetailFormAttr> = (values) => {
    if (clientUser?.id) {
      mutateUser({
        variables: {
          clientUserId: clientUser?.id || -1,
          clientUser: {
            firstName: values.firstName,
            lastName: values.lastName,
            phone: values.phone,
            email: values.email,
            teamIds: values?.teams?.map((team) => team.value),
          },
        },
      });
    } else {
      mutateClientUserCreate({
        variables: {
          clientUser: {
            firstName: values.firstName,
            lastName: values.lastName,
            phone: values.phone,
            email: values.email,
            teamIds: values?.teams?.map((team) => team.value),
          },
        },
      });
    }
  };

  return (
    <>
      <form
        name="userRegistrationForm"
        onSubmit={handleSubmit(handleUserRegistrationFormSubmit)}
        ref={formRef}
      >
        <SimpleGrid columns={[2, 2, 2]} spacing={6} mb={7}>
          <FormControl mb={6} isRequired isInvalid={!!errors.firstName}>
            <FormLabel htmlFor="firstName">
              <Translate label={UsersLocale.FIRST_NAME} />
            </FormLabel>
            <Input
              placeholder={translate(UsersLocale.FIRST_NAME, language)}
              {...register('firstName', {
                required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
              })}
            />
            <FormErrorMessage>
              {errors.firstName && errors.firstName.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl mb={6} isRequired isInvalid={!!errors.lastName}>
            <FormLabel htmlFor="lastName">
              <Translate label={UsersLocale.LAST_NAME} />
            </FormLabel>
            <Input
              placeholder={translate(UsersLocale.LAST_NAME, language)}
              {...register('lastName', {
                required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
              })}
            />
            <FormErrorMessage>
              {errors.lastName && errors.lastName.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl mb={6} isRequired isInvalid={!!errors.phone}>
            <FormLabel htmlFor="phone">
              <Translate label={UsersLocale.PHONE} />
            </FormLabel>
            <Input
              placeholder={translate(UsersLocale.PHONE, language)}
              {...register('phone', {
                required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
              })}
            />
            <FormErrorMessage>
              {errors.phone && errors.phone.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl mb={6} isRequired isInvalid={!!errors.email}>
            <FormLabel htmlFor="email">
              <Translate label={UsersLocale.EMAIL} />
            </FormLabel>
            <Input
              placeholder={translate(UsersLocale.EMAIL, language)}
              {...register('email', {
                required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
                pattern: {
                  // eslint-disable-next-line max-len
                  value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: translate(UsersLocale.EMAIL_ERROR_INVALID, language),
                },
              })}
            />
            <FormErrorMessage>
              {errors.email && errors.email.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl mb={6} isRequired isInvalid={!!errors.teams}>
            <FormLabel htmlFor="teams">
              <Translate label={UsersLocale.TEAMS} />
            </FormLabel>
            <Controller
              name="teams"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  options={teamOptions}
                  hideSelectedOptions={false}
                  selectedOptionStyle="check"
                  isMulti
                  closeMenuOnSelect={false}
                />
              )}
              rules={{
                required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
              }}
            />
          </FormControl>
        </SimpleGrid>
      </form>
    </>
  );
};
export default UserDetailForm;
