import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  CheckHeaderNewCheck,
  H2, SectionContainer,
} from '@scaut-sro/meepo';
import {
  Box, Flex, GridItem, SimpleGrid, VStack,
} from '@chakra-ui/react';
import { useRouteMatch } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { OrderDetailTablesData } from './OrderDetail.model';
import Translate from '../../components/Translate/Translate';
import OrderDetailLocale from './OrderDetail.locale';
import OrderDetailCandidateTable from './OrderDetailCandidateTable';
import TotalPrice from '../../components/TotalPrice/TotalPrice';
import { useLazyGetPrimaryOrderDetail } from '../../build/generated-sources/service/QueryService';
import ListOfServices from '../../components/ListOfServices/ListOfServices';
import { CandidateIdentity } from '../../build/generated-sources/dto/CandidateIdentity';
import { StateBadge } from '../../components/StateBadge/StateBadge';
import CandidateScreeningsTable from '../../components/CandidateScreeningsTable/CandidateScreeningsTable';
import { CandidateScreening } from '../../build/generated-sources/dto/CandidateScreening';
import { PrimaryOrderDetail } from '../../build/generated-sources/dto/PrimaryOrderDetail';
import { OrderState } from '../../build/generated-sources/enum/OrderState';
import Confirmation from '../NewCheck/Confirmation/Confirmation';
import { ResultBadge } from '../../components/ResultBadge/ResultBadge';
import { OrderType } from '../../build/generated-sources/enum/OrderType';
import { translate } from '../../core/localization/LocaleUtils';
import NewCheckLocale from '../NewCheck/NewCheck.locale';
import { useGetUser } from '../../core/store/reducers/UserSettingsReducer';
import { UseGetPrimaryOrderDetailData } from '../../build/generated-sources/service/QueryServiceModel';
import { handleApolloErrors } from '../../core/error/ScautErrorHandler';

const OrderDetail: React.FunctionComponent = () => {
  const { params } = useRouteMatch<{ id: string }>();
  const primaryOrderId = Number.isNaN(+params.id);
  const [data, setData] = useState<PrimaryOrderDetail | undefined>();
  const { language, userProfile } = useGetUser();

  const onFetchCompleted = useCallback((response: UseGetPrimaryOrderDetailData) => {
    setData(response.primaryOrderDetail);
  }, [setData]);

  const [orderDetailHandle, { loading }] = useLazyGetPrimaryOrderDetail(
    {
      order: {
        id: true,
        name: true,
        clientOrderCustomId: true,
        type: true,
        price: true,
        team: {
          name: true,
        },
        orderState: true,
        result: true,
      },
      dynamicFields: {
        id: true,
        localizationStrings: true,

      },
      pricePreview: true,
      suborderProducts: {
        subOrder: {
          candidateScreenings: {
            id: true,
            state: true,
            result: true,
            candidateIdentity: {
              firstName: true,
              lastName: true,
              dateOfBirth: true,
              email: true,
              personalTitle: true,
              jobPosition: true,
            },
            screenings: {
              screeningDefinition: {
                id: true,
                name: { localizationStrings: true },
                description: { localizationStrings: true },
              },
            },
          },
          candidateIdentities: {
            firstName: true,
            lastName: true,
            dateOfBirth: true,
            personalTitle: true,
            jobPosition: true,
            email: true,
          },
        },
        products: {
          screeningDefinitions: {
            id: true,
            name: { localizationStrings: true },
            description: { localizationStrings: true },
            price: true,
          },
          screeningPackages: {
            id: true,
            name: { localizationStrings: true },
            description: { localizationStrings: true },
            price: true,
            screeningDefinitions: {
              id: true,
              name: { localizationStrings: true },
              description: { localizationStrings: true },
            },
          },
          country: {
            translation: { localizationStrings: true },
          },
        },
      },
    }, {
      fetchPolicy: 'no-cache',
      onCompleted: onFetchCompleted,
      onError: (error) => {
        handleApolloErrors(error, language);
      },
      variables: {
        primaryOrderId: +params.id,
      },
    },
  );
  useEffect(() => {
    if (!primaryOrderId) {
      orderDetailHandle({
        variables: {
          primaryOrderId: +params.id,
        },
      });
    }
  }, [primaryOrderId, params.id, orderDetailHandle]);

  const orderDetailTablesData: OrderDetailTablesData = useMemo(() => {
    if (data) {
      const candidateScreenings: CandidateScreening[] = [];
      const candidates: CandidateIdentity[] = [];
      data.suborderProducts?.forEach((subOrderProductsDto) => {
        subOrderProductsDto.subOrder?.candidateScreenings?.forEach((candidateScreening) => {
          candidateScreenings.push(candidateScreening);
        });
        subOrderProductsDto.subOrder?.candidateIdentities?.forEach((candidate) => {
          candidates.push({
            firstName: candidate.firstName,
            lastName: candidate.lastName,
            dateOfBirth: candidate.dateOfBirth,
            email: candidate.email,
            personalTitle: candidate.personalTitle,
            jobPosition: candidate.jobPosition,
          });
        });
      });
      return {
        candidateScreenings,
        candidates,
      };
    }
    return {};
  }, [data]);

  TagManager.dataLayer({
    dataLayer: {
      event: 'page_view',
      realm: 'client',
      page: {
        url: document.location.origin + document.location.pathname + document.location.search,
        title: 'Order',
      },
      user: {
        email: userProfile?.email,
        given_name: userProfile?.firstName,
        family_name: userProfile?.lastName,
        job_position: userProfile?.jobPosition,
        language,
      },
    },
  });

  return (
    <>
      <SectionContainer>
        { data?.order?.orderState === OrderState.DRAFT ? (
          <Confirmation
            orderData={data}
            candidates={orderDetailTablesData.candidates || []}
          />
        ) : (
          <>
            <SimpleGrid
              columns={[1, 1, 2, 2, 2, 2]}
              mb={10}
              borderBottom="2px"
              borderColor="#dedfe9"
              pl={3}
              minHeight="46px"
            >
              <GridItem>
                <H2
                  lineHeight="35px"
                  letterSpacing="-0.42px"
                  fontSize="20px"
                  color="#2c2d41"
                  fontWeight="regular"
                >
                  <Translate label={NewCheckLocale.NEW_CHECK} />
                </H2>
              </GridItem>
              <GridItem>
                <Flex alignItems="center" p={2} pl={0} justifyContent="right">
                  <Box>
                    <ResultBadge
                      result={data?.order?.result}
                      size="lg"
                    />
                  </Box>
                  <Box ml={4}>
                    <StateBadge
                      state={data?.order?.orderState}
                      size="lg"
                    />
                  </Box>
                </Flex>
              </GridItem>
            </SimpleGrid>
            <Box mb={8}>
              {data?.order?.type ? (
                <CheckHeaderNewCheck
                  controlledSubjectLabel={
                    data?.order?.type === OrderType.MULTIPLE
                      ? translate(OrderDetailLocale.MULTIPLE_CHECK, language)
                      : translate(OrderDetailLocale.CONTROLLED_PERSON, language)
                  }
                  controlledSubjectName={data?.order?.name || ''}
                  dateOfBirthLabel={translate(OrderDetailLocale.DATE_OF_BIRTH, language)}
                  emailLabel={translate(OrderDetailLocale.EMAIL, language)}
                  orderIdLabel={translate(OrderDetailLocale.ORDER_ID, language)}
                  numberOfPersonsLabel={translate(OrderDetailLocale.CANDIDATES_COUNT, language)}
                />
              ) : undefined}
              {/* <Flex alignItems="center">
                <Icon as={IoReceiptOutline} fontSize={50} />
                <Box ml={4}>
                  <Heading size="lg" color="primaryBase" mb={3}>{data?.order?.name}</Heading>
                  <TagArea>
                    { data?.order?.type ? (
                      <MeepoTag
                        leftIcon={IoShapesOutline}
                        label={
                          data?.order?.type === OrderType.MULTIPLE
                            ? translate(ChecksLocale.MULTIPLE_PEOPLE, language)
                            : translate(ChecksLocale.SINGLE_CHECK, language)
                          }
                        tooltip={translate(NewCheckLocale.TYPE, language)}
                      />
                    )
                      : undefined }
                    {data?.order?.team?.name ? (
                      <MeepoTag
                        leftIcon={AiOutlineTeam}
                        label={data?.order?.team?.name}
                        tooltip={translate(NewCheckLocale.TEAM, language)}
                      />
                    ) : undefined}
                    {data?.order?.clientOrderCustomId ? (
                      <MeepoTag
                        leftIcon={AiOutlineNumber}
                        label={data?.order?.clientOrderCustomId}
                        tooltip={translate(NewCheckLocale.ORDER_CUSTOM_ID, language)}
                      />
                    ) : undefined}
                  </TagArea>
                </Box>
              </Flex> */}
            </Box>
            <VStack
              spacing={10}
              align="stretch"
            >
              <Box>
                <H2
                  pb={6}
                  mt={8}
                  fontSize="22px"
                  color="#2c2d41"
                  fontWeight="regular"
                  borderBottom="1px solid #dedfe9"
                >
                  <Translate label={OrderDetailLocale.CANDIDATE_SCREENINGS} />
                </H2>
                <Box overflowX="auto">
                  <CandidateScreeningsTable
                    screenings={orderDetailTablesData.candidateScreenings || []}
                    isLoading={loading}
                  />
                </Box>
              </Box>
              <Box>
                <H2
                  pb={6}
                  mt={8}
                  fontSize="22px"
                  color="#2c2d41"
                  fontWeight="regular"
                  borderBottom="1px solid #dedfe9"
                >
                  <Translate label={OrderDetailLocale.SCREENINGS} />
                </H2>
                {data?.suborderProducts ? (
                  <ListOfServices
                    data={data?.suborderProducts[0].products as []}
                  />
                ) : undefined}
              </Box>
              <Box>
                <H2
                  pb={6}
                  mt={8}
                  fontSize="22px"
                  color="#2c2d41"
                  fontWeight="regular"
                  borderBottom="1px solid #dedfe9"
                >
                  <Translate label={OrderDetailLocale.CANDIDATES} />
                </H2>
                <Box overflowX="auto">
                  <OrderDetailCandidateTable
                    data={orderDetailTablesData.candidates || []}
                    isLoading={loading}
                  />
                </Box>
              </Box>
              {
                data?.order?.price ? (
                  <TotalPrice
                    price={data.order.price}
                    isMultipleCheck={data.order?.type === OrderType.MULTIPLE}
                  />
                ) : undefined
              }
            </VStack>
          </>
        )}
      </SectionContainer>
    </>
  );
};

export default OrderDetail;
