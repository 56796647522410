import { Translation } from '../../core/localization/Localization.model';

class ExpanderLocale {
  public static SHOW_MORE: Translation = {
    EN: 'Show more',
    CZ: 'Ukázat více',
  };
  public static SHOW_LESS: Translation = {
    EN: 'Show less',
    CZ: 'Ukázat méně',
  };
}

export default ExpanderLocale;
