import React, { useState } from 'react';
import { Box, chakra, SimpleGrid } from '@chakra-ui/react';
import { NotificationBox } from '@scaut-sro/meepo';
import { useGetPrimaryOrdersOverview } from '../../build/generated-sources/service/QueryService';
import NotificationBoxLocale from './NotificationBox.locale';
import Translate from '../Translate/Translate';
import { PrimaryOrderOverviewFilter } from '../../build/generated-sources/dto/PrimaryOrderOverviewFilter';
import ShowDate from '../ShowDate/ShowDate';

type BoxData = {
  num: number;
  note: any;
};

type BoxesDataType = {
  box1?: BoxData;
  box2?: BoxData;
  box3?: BoxData;
  box4?: BoxData;
};

const NotificationBoxes: React.FunctionComponent<{
  setFilter: (index: number) => void;
  // eslint-disable-next-line react/no-unused-prop-types
  filters: PrimaryOrderOverviewFilter[];
}> = ({ setFilter, filters }) => {
  const [boxesData, setBoxesData] = useState<BoxesDataType>({});
  const [activeBoxFilter, setActiveBoxFilter] = useState<number>(0);
  const { loading } = useGetPrimaryOrdersOverview({
    filterId: true,
    count: true,
    lastRecord: true,
  }, {
    fetchPolicy: 'network-only',
    variables: {
      filters,
    },
    onCompleted: (response) => {
      setBoxesData({
        box1: {
          num: response?.primaryOrdersOverview[0].count || 0,
          note: (
            <>
              <chakra.span mr={1}><Translate label={NotificationBoxLocale.LAST} /></chakra.span>
              –
              <chakra.span ml={1}><ShowDate date={response?.primaryOrdersOverview[0].lastRecord} time /></chakra.span>
            </>
          ),
        },
        box2: {
          num: response?.primaryOrdersOverview[1].count || 0,
          note: (
            <>
              <chakra.span mr={1}><Translate label={NotificationBoxLocale.LAST} /></chakra.span>
              –
              <chakra.span ml={1}><ShowDate date={response?.primaryOrdersOverview[1].lastRecord} time /></chakra.span>
            </>
          ),
        },
        box3: {
          num: response?.primaryOrdersOverview[2].count || 0,
          note: (
            <>
              <chakra.span mr={1}><Translate label={NotificationBoxLocale.LAST} /></chakra.span>
              –
              <chakra.span ml={1}><ShowDate date={response?.primaryOrdersOverview[2].lastRecord} time /></chakra.span>
            </>
          ),
        },
        box4: {
          num: response?.primaryOrdersOverview[3].count || 0,
          note: (
            <>
              <chakra.span mr={1}><Translate label={NotificationBoxLocale.LAST} /></chakra.span>
              –
              <chakra.span ml={1}><ShowDate date={response?.primaryOrdersOverview[3].lastRecord} time /></chakra.span>
            </>
          ),
        },
      });
    },
  });
  return (
    <>
      <SimpleGrid columns={[1, 1, 2, 2, 4]} spacing={6} mb={6}>
        <Box onClick={() => { setActiveBoxFilter(0); setFilter(0); }} _hover={{ cursor: 'pointer' }}>
          <NotificationBox
            key="1"
            number={boxesData.box1?.num}
            isLoading={loading}
            label={<Translate label={NotificationBoxLocale.PROCESSING} />}
            color="grey"
            isActive={activeBoxFilter === 0}
            note={boxesData.box1?.note}
          />
        </Box>
        <Box onClick={() => { setActiveBoxFilter(1); setFilter(1); }} _hover={{ cursor: 'pointer' }}>
          <NotificationBox
            key="2"
            number={boxesData.box2?.num}
            isLoading={loading}
            label={<Translate label={NotificationBoxLocale.COMPLETED} />}
            color="blue"
            isActive={activeBoxFilter === 1}
            note={boxesData.box2?.note}
          />
        </Box>
        <Box onClick={() => { setActiveBoxFilter(2); setFilter(2); }} _hover={{ cursor: 'pointer' }}>
          <NotificationBox
            key="3"
            number={boxesData.box3?.num}
            isLoading={loading}
            label={<Translate label={NotificationBoxLocale.CLEAR} />}
            color="green"
            isActive={activeBoxFilter === 2}
            note={boxesData.box3?.note}
          />
        </Box>
        <Box onClick={() => { setActiveBoxFilter(3); setFilter(3); }} _hover={{ cursor: 'pointer' }}>
          <NotificationBox
            key="4"
            number={boxesData.box4?.num}
            isLoading={loading}
            label={<Translate label={NotificationBoxLocale.CONSIDER} />}
            color="orange"
            isActive={activeBoxFilter === 3}
            note={boxesData.box4?.note}
          />
        </Box>
      </SimpleGrid>
    </>
  );
};
export default NotificationBoxes;
