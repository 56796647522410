import React, {
  useEffect, useMemo,
  useState,
} from 'react';
import {
  Box, Button, Flex, FormControl, FormLabel, GridItem, Select, Spacer, Text,
} from '@chakra-ui/react';
import {
  H2, InfoContainer, SectionContainer, Toast,
} from '@scaut-sro/meepo';
import { useGetUser } from '../../../core/store/reducers/UserSettingsReducer';
import { translate } from '../../../core/localization/LocaleUtils';
import AnonymizationLocale from './Anonymization.locale';
import { useAnonymizeAfterSet } from '../../../build/generated-sources/service/MutationService';
import Translate from '../../../components/Translate/Translate';
import DetailHeader from '../../../components/DetailHeader/DetailHeader';
import LoadingScreen from '../../LoadingScreen/LoadingScreen';
import { AnonymizeAfter } from '../../../build/generated-sources/enum/AnonymizeAfter';
import { handleApolloErrors } from '../../../core/error/ScautErrorHandler';
import { AnonymizationProps } from './Anonymization.model';

const Anonymization: React.FunctionComponent<AnonymizationProps> = (props) => {
  const { clientDetail, setClientUpdated } = props;
  const [anonymizeAfter, setAnonymizeAfter] = useState<AnonymizeAfter>(AnonymizeAfter.NINETY_DAYS);
  const { language } = useGetUser();

  useEffect(() => {
    setAnonymizeAfter(clientDetail?.client?.anonymizeAfter || AnonymizeAfter.NINETY_DAYS);
  }, [
    clientDetail,
  ]);

  const anonymizeAfterOptionsList = useMemo(() => ([
    { value: 'TEN_DAYS', label: translate(AnonymizationLocale.TEN_DAYS, language) },
    { value: 'THIRTY_DAYS', label: translate(AnonymizationLocale.THIRTY_DAYS, language) },
    { value: 'SIXTY_DAYS', label: translate(AnonymizationLocale.SIXTY_DAYS, language) },
    { value: 'NINETY_DAYS', label: translate(AnonymizationLocale.NINETY_DAYS, language) },
    { value: 'YEAR', label: translate(AnonymizationLocale.YEAR, language) },
  ]), [language]);

  const [mutateAnonymizeAfter] = useAnonymizeAfterSet(
    {
      success: true,
    },
    {
      onCompleted: () => {
        Toast({
          title: translate(AnonymizationLocale.ANONYMIZATION_CHANGE_SUCCESS, language),
          status: 'success',
        });
        setClientUpdated(true);
      },
      onError: (error) => {
        handleApolloErrors(error, language);
      },
    },
  );

  const updateAnonymizeAfter = () => {
    if (anonymizeAfter) {
      mutateAnonymizeAfter({
        variables: {
          anonymizeAfter,
        },
      });
    }
  };

  return (
    <>
      { !anonymizeAfter ? (
        <LoadingScreen />
      ) : (
        <SectionContainer>
          <Box overflowX="auto">
            <Box minW="600px">
              <DetailHeader>
                <H2><Translate label={AnonymizationLocale.ANONYMIZATION} /></H2>
              </DetailHeader>
              <Box maxWidth={800}>
                <Text><Translate label={AnonymizationLocale.INFORMATION_TEXT} /></Text>
              </Box>
              <Box overflowX="auto" mt={6}>
                <InfoContainer bgColor="#f4f5f7">
                  <Flex>
                    <GridItem colSpan={[2]}>
                      <FormControl>
                        <Flex>
                          <FormLabel whiteSpace="nowrap" mt={2}>
                            <Translate label={AnonymizationLocale.ANONYMIZATION_SELECT} />
                          </FormLabel>
                          <Select
                            maxWidth={200}
                            onChange={(event) => {
                              setAnonymizeAfter(event.target.value as AnonymizeAfter);
                            }}
                            value={anonymizeAfter}
                          >
                            { anonymizeAfterOptionsList.map((userData) => (
                              <option key={userData.value} value={userData.value}>
                                {`${userData.label}`}
                              </option>
                            )) }
                          </Select>
                          <Spacer />
                          <Text mt={2} ml={5} minWidth={200}>
                            <Translate label={AnonymizationLocale.ANONYMIZATION_SELECT_2} />
                          </Text>
                        </Flex>
                      </FormControl>
                    </GridItem>
                    <Spacer />
                    <GridItem colSpan={[1]}>
                      <Button onClick={updateAnonymizeAfter}>
                        <Translate label={AnonymizationLocale.CONFIRM_SETTINGS} />
                      </Button>
                    </GridItem>
                  </Flex>
                </InfoContainer>
              </Box>
            </Box>
          </Box>
        </SectionContainer>
      )}
    </>
  );
};
export default Anonymization;
