import React, { PropsWithChildren } from 'react';
import { Translate } from '@scaut-sro/meepo';
import { Badge } from '@chakra-ui/react';
import {
  ResultBadgeProps, ResultsLocale,
} from './ResultBadge.model';

export const ResultBadge = (
  props: PropsWithChildren<ResultBadgeProps>,
) => {
  const { result } = props;
  return (
    <>
      { result === 'CLEAR' ? (
        <Badge
          bg="#00CA72"
          variant="solid"
          fontSize="xs"
          color="white"
          p={1}
          w={24}
          textAlign="center"
          borderRadius="md"
        >
          <Translate label={ResultsLocale.CLEAR} />
        </Badge>
      ) : result === 'FOUND_RESULTS' ? (
        <Badge
          bg="#f5be60"
          variant="solid"
          fontSize="xs"
          color="white"
          p={1}
          w={24}
          textAlign="center"
          borderRadius="md"
        >
          <Translate label={ResultsLocale.FOUND_RESULTS} />
        </Badge>
      ) : (<></>)}
    </>
  );
};

export default ResultBadge;
