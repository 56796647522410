import { GraphQLScalarType, Kind } from 'graphql';
import { Language } from '../generated-sources/enum/Language';

export type Any = any;

export const Resolver = new GraphQLScalarType({
  name: 'Any',
  description: 'Any custom scalar type',
  serialize(value) {
    return value && value.toString();
  },
  parseValue(value) {
    const map = new Map<string, string>();
    for (const val in value) {
      map.set(val, value[val]);
    }
    return {};
  },
  parseLiteral(ast) {
    if (ast.kind === Kind.STRING || ast.kind === Kind.INT) {
      return {};
    }
    return null;
  },
});
