import React from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { useHistory } from 'react-router-dom';
import {
  Avatar,
  Box,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Select,
  Text,
} from '@chakra-ui/react';
import { IoLanguage } from 'react-icons/io5';
import { useGetUser, useUserSettingsReduce } from '../../core/store/reducers/UserSettingsReducer';
import Translate from '../Translate/Translate';
import UserMenuLocale from '../../core/localization/translations/UserMenu.locale';
import { useChangeLanguage } from '../../build/generated-sources/service/MutationService';
import { Language } from '../../build/generated-sources/enum/Language';
import { handleApolloErrors } from '../../core/error/ScautErrorHandler';
import FaqModal from '../../views/Faq/FaqModal';
import ScautClientRoles from '../../core/auth/ScautClientRoles';

const UserMenu: React.FunctionComponent = () => {
  const { keycloak, initialized } = useKeycloak();
  const { userProfile, language } = useGetUser();
  const history = useHistory();
  const { setLanguage } = useUserSettingsReduce();
  const [mutateLanguageChange] = useChangeLanguage({
    language: true,
  }, {
    onCompleted: (result) => {
      setLanguage(result.changeLanguage.language || Language.EN);
    },
    onError: (error) => {
      handleApolloErrors(error, language);
    },
  });

  const handleLanguageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const lang = event.target.value as Language;
    mutateLanguageChange({ variables: { language: lang } });
  };

  return (
    <Menu variant="blue">
      <MenuButton
        as={Box}
        style={{
          cursor: 'pointer',
        }}
      >
        <Avatar
          name={`${userProfile?.firstName} ${userProfile?.lastName}`}
          src={userProfile?.avatar}
        />
      </MenuButton>
      <MenuList>
        <Flex p={4} color="#2C2D41">
          <Avatar
            size="sm"
            name={`${userProfile?.firstName} ${userProfile?.lastName}`}
            src={userProfile?.avatar}
            mt={1}
          />
          <Flex flexDirection="column" ml={4}>
            <Text>{`${userProfile?.firstName} ${userProfile?.lastName}`}</Text>
            <Text fontSize="sm">{userProfile?.email}</Text>
            <Flex
              alignItems="center"
              bg="gray.200"
              borderRadius="md"
              w="max-content"
              m="6px 0 0 0"
            >
              <Icon as={IoLanguage} m="0 10px" />
              <Select
                variant="unstyled"
                size="sm"
                onChange={handleLanguageChange}
                value={language}
              >
                <option
                  value={Language.EN}
                >
                  English
                </option>
                <option
                  value={Language.CZ}
                >
                  Čeština
                </option>
              </Select>
            </Flex>
          </Flex>
        </Flex>
        <MenuDivider color="#D8D8D8" m={0} />
        <Box ml={12}>
          <MenuItem
            onClick={() => history.push('/user-settings')}
          >
            <Translate label={UserMenuLocale.USER_SETTINGS} />
          </MenuItem>
          {
            keycloak.hasResourceRole(ScautClientRoles.ADMIN, 'scaut-client-app') ? (
              <MenuItem
                onClick={() => history.push('/client-settings')}
              >
                <Translate label={UserMenuLocale.CLIENT_SETTINGS} />
              </MenuItem>
            ) : undefined
          }
          <FaqModal />
        </Box>
        <MenuDivider color="#D8D8D8" m={0} />
        <Box ml={12}>
          <MenuItem
            onClick={() => {
              if (initialized) {
                keycloak.logout({
                  redirectUri: `${window.location.protocol}//${window.location.host}`,
                });
              }
            }}
          >
            <Translate label={UserMenuLocale.LOGOUT} />
          </MenuItem>
        </Box>
      </MenuList>
    </Menu>
  );
};

export default UserMenu;
