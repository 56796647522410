import React, { useEffect, useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import {
  CheckHeader,
  FormInputLabel,
  GridParameter,
  MeepoTable, ReadOnlyInput, ScautAccordionIcon, SectionContainer, Toast,
} from '@scaut-sro/meepo';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  GridItem,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import { UnpackNestedValue } from 'react-hook-form';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { useKeycloak } from '@react-keycloak/web';
import { QueryLazyOptions } from '@apollo/client';
import {
  useLazyGetCandidateScreening,
  useLazyGetComponentNotes,
} from '../../build/generated-sources/service/QueryService';
import {
  CandidateScreeningDetail as CandidateScreeningDetailType,
} from '../../build/generated-sources/dto/CandidateScreeningDetail';
import { ScreeningsTableData } from './CandidateScreeningDetail.model';
import Translate from '../../components/Translate/Translate';
import CandidateScreeningDetailLocale from './CandidateScreeningDetail.locale';
import { ResultBadge } from '../../components/ResultBadge/ResultBadge';
import { StateBadge } from '../../components/StateBadge/StateBadge';
import { useGetUser } from '../../core/store/reducers/UserSettingsReducer';
import { translate } from '../../core/localization/LocaleUtils';
import CandidateDetailLocale from '../../components/CandidateDetail/CandidateDetail.locale';
import {
  showDate, showDateTime, showRemainingTime,
} from '../../core/date/showDate';
import MultipleCheckDetailLocale from '../MultipleCheckDetail/MultipleCheckDetail.locale';
import FormLocale from '../../core/localization/translations/Form.locale';
import TableLocale from '../../core/localization/translations/Table.locale';
import { Screening } from '../../build/generated-sources/dto/Screening';
import { Language } from '../../build/generated-sources/enum/Language';
import ScreeningTableDetail from './ScreeningTableDetail';
import {
  useFillForm,
} from '../../build/generated-sources/service/MutationService';
import { handleApolloErrors } from '../../core/error/ScautErrorHandler';
import { MAP_ScreeningId_DynamicForm } from '../../build/scalars/MAP_ScreeningId_DynamicForm';
import { DynamicFormDto } from '../../build/generated-sources/dto/DynamicFormDto';
import PowerOfAttorneySection from '../../components/PowerOfAttorneySection/PowerOfAttorneySection';
import { POAStatus } from '../../build/generated-sources/enum/POAStatus';
import LoadingScreen from '../LoadingScreen/LoadingScreen';
import { State } from '../../build/generated-sources/enum/State';
import { ResultsLocale } from '../../components/ResultBadge/ResultBadge.model';
import AuthorizationSection from '../../components/AuthorizationSection/AuthorizationSection';
import { ComponentNoteDetail } from '../../build/generated-sources/dto/ComponentNoteDetail';
import { downloadFile } from '../../core/service/queries/FileUploadService';
import { UseGetCandidateScreeningVariables } from '../../build/generated-sources/service/QueryServiceModel';

type GroupedScreeningData = {
  [key:number]: Screening[]
};

export function getScreeningsTableData(
  candidateScreeningHandle: (options?: QueryLazyOptions<UseGetCandidateScreeningVariables> | undefined) => void,
  data: Screening[],
  resultData: MAP_ScreeningId_DynamicForm,
  candidateScreeningId: number | undefined,
  language: Language,
  screeningForms?: MAP_ScreeningId_DynamicForm,
  onFormSend?: (screeningId: number, values: any) => void,
  formIsLoading?: boolean,
  notesDetails?: ComponentNoteDetail[],
): ScreeningsTableData[] {
  const groupedData: GroupedScreeningData = {};

  if (data) {
    data.forEach((screening) => {
      if (screening.screeningDefinition?.id) {
        if (!groupedData[screening.screeningDefinition.id]) {
          groupedData[screening.screeningDefinition.id] = [];
        }
        groupedData[screening.screeningDefinition.id].push(screening);
      }
    });

    const screeningTableData: ScreeningsTableData[] = [];

    Object.keys(groupedData).forEach((definitionKey) => {
      const screenings: Screening[] = groupedData[+definitionKey];

      screenings.sort((a, b) => ((a.screeningTypeIndex as number) < (b.screeningTypeIndex as number) ? -1 : 1));

      return screenings.forEach((screening) => {
        const isCountable = screenings.length > 1;

        screeningTableData.push({
          id: screening.id || '-undefined-',
          detail: (
            <ScreeningTableDetail
              candidateScreeningHandle={candidateScreeningHandle}
              screening={screening}
              screeningForms={screeningForms}
              onFormSend={onFormSend}
              formIsLoading={formIsLoading}
              displayCountable={isCountable}
              screeningResultData={resultData}
              candidateScreeningId={candidateScreeningId}
              notesDetails={notesDetails}
            />
          ),
          name: screening.screeningDefinition?.name?.localizationStrings?.[language] as string
              + (isCountable ? ` ${screening.screeningTypeIndex as number + 1}` : ''),
          country: screening.screeningDefinition?.country?.enumeration?.value,
          result: <ResultBadge result={screening.result as string} />,
          state: <StateBadge state={screening.state as string} />,
          icon: <Text fontSize="xl" m={2}><ChevronDownIcon /></Text>,
        });
      });
    });

    return screeningTableData;
  }

  return [];
}

const CandidateScreeningDetail: React.FunctionComponent = () => {
  const { params } = useRouteMatch<{ id: string }>();
  const [data, setData] = useState<CandidateScreeningDetailType | undefined>(undefined);
  const candidateId = Number.isNaN(+params.id);
  const [list, setList] = useState<ScreeningsTableData[]>([]);
  const { language } = useGetUser();
  const [globalData, setGlobalData] = useState<DynamicFormDto>();
  const [candidateScreening, setCandidateScreening] = useState<CandidateScreeningDetailType | undefined>(undefined);
  const [authorization, setAuthorization] = useState(false);

  const keycloak = useKeycloak();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const downloadReport = () => {
    if (keycloak.keycloak.token) {
      downloadFile(keycloak.keycloak.token, `/dms/downloadReport/${candidateScreening?.candidateScreening?.id}`);
    }
  };
  const [candidateScreeningHandle, { loading }] = useLazyGetCandidateScreening(
    {
      anonymizationDate: true,
      hoursToComplete: true,
      candidateScreening: {
        id: true,
        state: true,
        result: true,
        processStarted: true,
        processCompleted: true,
        powerOfAttorney: {
          poaStatus: true,
          generatedTemplates: {
            id: true,
            filename: true,
          },
        },
        screenings: {
          id: true,
          state: true,
          result: true,
          screeningTypeIndex: true,
          screeningDefinition: {
            id: true,
            name: {
              localizationStrings: true,
            },
            description: {
              localizationStrings: true,
            },
            country: {
              translation: { localizationStrings: true },
              enumeration: {
                value: true,
              },
            },
          },
          processCompleted: true,
          processStarted: true,
        },
        candidateIdentity: {
          firstName: true,
          lastName: true,
          dateOfBirth: true,
          jobPosition: true,
          personalTitle: true,
          email: true,
        },
      },
      order: {
        id: true,
        name: true,
        orderState: true,
        team: {
          name: true,
        },
        clientOrderCustomId: true,
        subOrders: {
          screeningPackages: {
            id: true,
            name: {
              localizationStrings: true,
            },
          },
          screeningDefinitions: {
            id: true,
            name: {
              localizationStrings: true,
            },
          },
        },
      },
      form: {
        globalData: {
          form: true,
          id: true,
        },
        resultData: true,
        completedData: true,
        inputData: true,
        files: true,
      },
    },
    {
      onCompleted: (response) => {
        if (response.candidateScreening === null) {
          Toast({
            title: translate(CandidateScreeningDetailLocale.ERROR_GETTING_DATA, language),
            status: 'error',
          });
        } else {
          setCandidateScreening(response.candidateScreening);
          setData(response.candidateScreening);
          // setList(onCompleteData);
          if (response.candidateScreening.form?.globalData) {
            setGlobalData(response.candidateScreening.form.globalData);
          }
        }
      },
      onError: () => {
        Toast({
          title: translate(CandidateScreeningDetailLocale.ERROR_GETTING_DATA, language),
          status: 'error',
        });
      },
      variables: {
        candidateScreeningId: +params.id,
      },
    },
  );

  const [getCandidateScreeningNotes] = useLazyGetComponentNotes(
    {
      screening: {
        id: true,
      },
      notes: {
        id: true,
        title: true,
        created: true,
        text: true,
        createdBy: true,
        updated: true,
        updatedBy: true,
      },
    },
    {
      variables: {
        candidateScreeningId: +params.id,
      },
      onCompleted: (response) => {
        if (candidateScreening) {
          const screeningsTableData = getScreeningsTableData(
            candidateScreeningHandle,
            candidateScreening.candidateScreening?.screenings || [],
            candidateScreening.form?.resultData || {},
            candidateScreening.candidateScreening?.id,
            language,
            candidateScreening.form?.inputData,
            (formId, values) => {
              // eslint-disable-next-line @typescript-eslint/no-use-before-define
              mutateFillForm({
                variables: {
                  formId,
                  formData: values,
                },
              });
            },
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            mutateFillFormOptions.loading,
            response.componentNotes,
          );
          setList(screeningsTableData);
        }
      },
      onError: (error) => {
        handleApolloErrors(error, language);
      },
    },
  );

  useEffect(() => {
    if (candidateScreening) {
      getCandidateScreeningNotes();
    }
  }, [candidateScreening, getCandidateScreeningNotes]);

  const authorizationData = useMemo(() => {
    if (globalData?.form?.authorization) {
      return globalData?.form?.authorization;
    }
    return undefined;
  },
  [globalData?.form]);

  const [mutateFillForm, mutateFillFormOptions] = useFillForm(
    {
      generalValidationMessages: true,
      invalidFields: {
        fieldId: true,
        isValid: true,
        validationMessages: true,
      },
      isValid: true,
    },
    {
      onCompleted: (response) => {
        if (!response.fillForm.isValid) {
          // eslint-disable-next-line no-console
          console.error(response.fillForm);
          setAuthorization(false);
        }
        candidateScreeningHandle({
          variables: {
            candidateScreeningId: +params.id,
          },
        });

        if (authorization) {
          Toast({
            title: translate(CandidateScreeningDetailLocale.AUTHORIZATION_SEND_SUCCESS, language),
            status: 'success',
          });
          setAuthorization(false);
        } else {
          Toast({
            title: translate(CandidateScreeningDetailLocale.SUCCESS_SEND_FORM, language),
            status: 'success',
          });
        }
      },
      onError: (error) => {
        handleApolloErrors(error, language);
      },
    },
  );

  const handleScreeningFormSubmit = (values: UnpackNestedValue<any>, formId?: number) => {
    if (formId) {
      setAuthorization(true);
      mutateFillForm({
        variables: {
          formId,
          formData: values,
        },
      });
    }
  };

  useEffect(() => {
    if (!candidateId) {
      candidateScreeningHandle({
        variables: {
          candidateScreeningId: +params.id,
        },
      });
    }
  }, [candidateScreeningHandle, params.id, candidateId, language]);

  const services: string = useMemo(() => {
    let str = '';
    data?.order?.subOrders?.[0].screeningPackages?.forEach((scrPackage) => {
      str = `${str + scrPackage.name?.localizationStrings?.[language]}, `;
    });
    data?.order?.subOrders?.[0].screeningDefinitions?.forEach((scrDefinition) => {
      str = `${str + scrDefinition.name?.localizationStrings?.[language]}, `;
    });
    str = str.substring(0, str.length - 2);
    return str;
  }, [data, language]);

  /*  const [mutateAnonymizeOrder] = useAnonymizeOrder(
    {
      success: true,
    },
    {
      onCompleted: () => {
        Toast({
          title: translate(CandidateScreeningDetailLocale.ANONYMIZATION_SUCCESS, language),
          status: 'success',
        });
        history.push('/');
      },
      onError: (error) => {
        handleApolloErrors(error, language);
      },
    },
  ); */

  /*  const anonymizeOrder = () => {
    if (data?.order?.id) {
      mutateAnonymizeOrder({
        variables: {
          primaryOrderId: data?.order?.id,
        },
      });
    }
  }; */

  const stateData: { label: React.ReactNode; colorScheme: 'orange' | 'green' | 'gray' } = useMemo(() => {
    if (data?.candidateScreening?.result === 'CLEAR') {
      return {
        label: <Translate label={ResultsLocale.CLEAR} />,
        colorScheme: 'green',
      };
    }
    if (data?.candidateScreening?.result === 'FOUND_RESULTS') {
      return {
        label: <Translate label={ResultsLocale.FOUND_RESULTS} />,
        colorScheme: 'orange',
      };
    }
    return {
      label: <Translate label={ResultsLocale.UNDEFINED} />,
      colorScheme: 'gray',
    };
  }, [data?.candidateScreening?.result]);

  return (
    <>
      { !data ? (
        <LoadingScreen />
      ) : (
        <SectionContainer>
          <Box>
            <CheckHeader
              label={<Translate label={CandidateScreeningDetailLocale.CHECKED_PERSON} />}
              caption={`${data?.candidateScreening?.candidateIdentity?.firstName} 
              ${data?.candidateScreening?.candidateIdentity?.lastName}`}
              result={stateData.label}
              colorScheme={stateData.colorScheme}
              data={[
                {
                  id: 'state',
                  label: <Translate label={CandidateScreeningDetailLocale.CHECK_TABLE_STATE} />,
                  value: (
                    <StateBadge
                      state={data?.candidateScreening?.state}
                      size="lg"
                    />
                  ),
                },
              ]}
              // downloadButtonHandler={downloadReport}
            />
          </Box>
          <Accordion
            variant="scautSectionAccordion"
            allowMultiple
            defaultIndex={[2]}
            px={[2, 8, 14]}
          >
            <AccordionItem>
              <AccordionButton>
                <Box flex="1" textAlign="left" letterSpacing="-0.2px" fontWeight="medium" color="#2c2d41">
                  <Translate label={CandidateScreeningDetailLocale.CANDIDATE_IDENTITY} />
                </Box>
                <ScautAccordionIcon />
              </AccordionButton>
              <AccordionPanel>
                <SimpleGrid columns={[1, 2, 3, 4, 5]} spacing={[6]}>
                  <GridParameter
                    label={<Translate label={CandidateDetailLocale.FULLNAME} />}
                    value={`${data?.candidateScreening?.candidateIdentity?.firstName}
                     ${data?.candidateScreening?.candidateIdentity?.lastName}`}
                  />
                  <GridParameter
                    label={<Translate label={CandidateDetailLocale.DATE_OF_BIRTH} />}
                    value={showDate(language, data?.candidateScreening?.candidateIdentity?.dateOfBirth)}
                  />
                  <GridParameter
                    label={<Translate label={CandidateDetailLocale.JOB_POSITION} />}
                    value={data?.candidateScreening?.candidateIdentity?.jobPosition}
                  />
                  <GridItem colSpan={[1, 1, 1, 1, 2]}>
                    <GridParameter
                      label={<Translate label={CandidateDetailLocale.EMAIL} />}
                      value={data?.candidateScreening?.candidateIdentity?.email}
                    />
                  </GridItem>
                </SimpleGrid>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionButton>
                <Box flex="1" textAlign="left" letterSpacing="-0.2px" fontWeight="medium" color="#2c2d41">
                  <Translate label={CandidateScreeningDetailLocale.CANDIDATE_SCREENING_INFO} />
                </Box>
                <ScautAccordionIcon />
              </AccordionButton>
              <AccordionPanel>
                <SimpleGrid columns={[1, 2, 3, 4, 5]} spacing={[6]}>
                  <GridParameter
                    label={<Translate label={MultipleCheckDetailLocale.CANDIDATE_SCREENING_START} />}
                    value={showDateTime(language, data?.candidateScreening?.processStarted)}
                  />
                  <GridParameter
                    label={<Translate label={MultipleCheckDetailLocale.CANDIDATE_SCREENING_END} />}
                    value={showDateTime(language, data?.candidateScreening?.processCompleted)}
                  />
                  <GridParameter
                    label={<Translate label={MultipleCheckDetailLocale.PLANNED_ANONYMIZATION} />}
                    value={showDate(language, data?.anonymizationDate)}
                  />
                  <GridItem colSpan={[1, 1, 1, 1, 2]}>
                    <GridParameter
                      label={<Translate label={CandidateScreeningDetailLocale.ORDERED_SERVICES} />}
                      value={services}
                    />
                  </GridItem>
                </SimpleGrid>
                {data.candidateScreening?.state === State.WAITING_FOR_CANDIDATE && data.hoursToComplete ? (
                  <SimpleGrid columns={[1]} spacing={[6]} mt={5}>
                    <ReadOnlyInput
                      label={translate(MultipleCheckDetailLocale.TIME_TO_COMPLETE, language)}
                      noValue={translate(FormLocale.NO_VALUE, language)}
                      value={`${showRemainingTime(data.hoursToComplete).days}d ${showRemainingTime(data.hoursToComplete).hours}h`}
                    />
                  </SimpleGrid>
                ) : undefined }
              </AccordionPanel>
            </AccordionItem>
            {
              (data?.candidateScreening?.powerOfAttorney
                && data?.candidateScreening.powerOfAttorney.poaStatus === POAStatus.GENERATED)
              || authorizationData ? (
                <AccordionItem>
                  <AccordionButton>
                    <Box flex="1" textAlign="left" letterSpacing="-0.2px" fontWeight="medium" color="#2c2d41">
                      <Translate label={CandidateScreeningDetailLocale.IMPORTANT_DOCUMENTS} />
                    </Box>
                    <ScautAccordionIcon />
                  </AccordionButton>
                  <AccordionPanel>
                    {
                      authorizationData ? (
                        <Box>
                          <FormInputLabel>
                            <Translate label={CandidateScreeningDetailLocale.AUTHORIZATION} />
                          </FormInputLabel>
                          <AuthorizationSection
                            dynamicForm={globalData}
                            submitHandler={handleScreeningFormSubmit}
                            isSubmitting={mutateFillFormOptions.loading}
                          />
                        </Box>
                      ) : (<></>)
                    }
                    {
                      authorizationData && data?.candidateScreening?.powerOfAttorney
                      && data?.candidateScreening.powerOfAttorney.poaStatus === POAStatus.GENERATED ? (
                        <Box mt={8} />
                        ) : (<></>)
                    }
                    {
                      data?.candidateScreening?.powerOfAttorney
                      && data?.candidateScreening.powerOfAttorney.poaStatus === POAStatus.GENERATED ? (
                        <Box>
                          <FormInputLabel>
                            <Translate label={CandidateScreeningDetailLocale.POWER_OF_ATTORNEY} />
                          </FormInputLabel>
                          <PowerOfAttorneySection powerOfAttorneyEntity={data?.candidateScreening?.powerOfAttorney} />
                        </Box>
                        ) : (<></>)
                    }
                  </AccordionPanel>
                </AccordionItem>
                ) : (<></>)
            }
          </Accordion>
          <Box px={[4, 10, 16]} mt={6}>
            <Box overflowX="auto">
              <Box minW="590px">
                <MeepoTable
                  columns={[
                    {
                      id: 'name',
                      label: translate(CandidateScreeningDetailLocale.CHECK_TABLE_NAME, language),
                      boxProps: {
                        pl: 2,
                        fontWeight: 500,
                      },
                    },
                    {
                      id: 'country',
                      boxProps: {
                        w: '15%',
                      },
                    },
                    {
                      id: 'state',
                      label: translate(CandidateScreeningDetailLocale.CHECK_TABLE_STATE, language),
                      boxProps: {
                        w: '20%',
                      },
                    },
                    {
                      id: 'result',
                      label: translate(CandidateScreeningDetailLocale.CHECK_TABLE_RESULT, language),
                      boxProps: {
                        w: '20%',
                      },
                    },
                    {
                      id: 'icon',
                      boxProps: {
                        w: '42px',
                      },
                    },
                  ]}
                  data={list}
                  isLoading={!list && loading}
                  noData={<Text><Translate label={TableLocale.NO_DATA} /></Text>}
                />
              </Box>
            </Box>
          </Box>
        </SectionContainer>
      )}
    </>
  );
};

export default CandidateScreeningDetail;
