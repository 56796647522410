import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import {GraphqlBuilder, GraphQLType} from '@scaut-sro/npm-core-generator';
import { ClientUserMapper } from '../dto/ClientUser'
import {UseUpdateUserProfileData, UseUpdateUserProfileVariables} from './MutationServiceModel'
import { UserPreferencesMapper } from '../dto/UserPreferences'
import {UseChangeLanguageData, UseChangeLanguageVariables} from './MutationServiceModel'
import {UseChangePasswordData, UseChangePasswordVariables} from './MutationServiceModel'
import { ResultResponseMapper } from '../dto/ResultResponse'
import {UseChangeRoleData, UseChangeRoleVariables} from './MutationServiceModel'
import {UseChangeActiveStatusData, UseChangeActiveStatusVariables} from './MutationServiceModel'
import { ClientUserDtoMapper } from '../dto/ClientUserDto'
import {UseClientUserData, UseClientUserVariables} from './MutationServiceModel'
import {UseClientUserCreateData, UseClientUserCreateVariables} from './MutationServiceModel'
import {UseClientUserDeleteData, UseClientUserDeleteVariables} from './MutationServiceModel'
import { ClientMapper } from '../dto/Client'
import {UseClientEditData, UseClientEditVariables} from './MutationServiceModel'
import {UseClientConflictsEditData, UseClientConflictsEditVariables} from './MutationServiceModel'
import {UseAnonymizeAfterSetData, UseAnonymizeAfterSetVariables} from './MutationServiceModel'
import {UseAddPrepaymentData, UseAddPrepaymentVariables} from './MutationServiceModel'
import {UseUpdateClientWhitelistData, UseUpdateClientWhitelistVariables} from './MutationServiceModel'
import {UseRemovePaymentProfileData, UseRemovePaymentProfileVariables} from './MutationServiceModel'
import { PrimaryOrderMapper } from '../dto/PrimaryOrder'
import {UseCreateOrderSingleData, UseCreateOrderSingleVariables} from './MutationServiceModel'
import {UseCreateOrderMultipleData, UseCreateOrderMultipleVariables} from './MutationServiceModel'
import {UsePayOrderData, UsePayOrderVariables} from './MutationServiceModel'
import {UseAnonymizeOrderData, UseAnonymizeOrderVariables} from './MutationServiceModel'
import { DynamicFormValidationResultMapper } from '../dto/DynamicFormValidationResult'
import {UseFillFormData, UseFillFormVariables} from './MutationServiceModel'
import {UseSubmitExecutionDetailsData, UseSubmitExecutionDetailsVariables} from './MutationServiceModel'
import {UseUpdateCreditCardData, UseUpdateCreditCardVariables} from './MutationServiceModel'
import { FileDeleteHandlerResponseMapper } from '../dto/FileDeleteHandlerResponse'
import {UseDeleteFileData, UseDeleteFileVariables} from './MutationServiceModel'

export function useUpdateUserProfile(
  mapper: ClientUserMapper,
  options?: MutationHookOptions<UseUpdateUserProfileData, UseUpdateUserProfileVariables>
): MutationTuple<UseUpdateUserProfileData, UseUpdateUserProfileVariables> {
  const mutationBuilder: GraphqlBuilder<ClientUserMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('updateUserProfile')
      .setReturnProperties(mapper)
      .declareVariables({
        clientUser: 'ClientUserGraphQLInput!',        
      })
      .setProperties(
        `clientUser: $clientUser`
      )
      .build(),
    options
  );
}


export function useChangeLanguage(
  mapper: UserPreferencesMapper,
  options?: MutationHookOptions<UseChangeLanguageData, UseChangeLanguageVariables>
): MutationTuple<UseChangeLanguageData, UseChangeLanguageVariables> {
  const mutationBuilder: GraphqlBuilder<UserPreferencesMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('changeLanguage')
      .setReturnProperties(mapper)
      .declareVariables({
        language: 'Language!',        
      })
      .setProperties(
        `language: $language`
      )
      .build(),
    options
  );
}


export function useChangePassword(
  mapper: ClientUserMapper,
  options?: MutationHookOptions<UseChangePasswordData, UseChangePasswordVariables>
): MutationTuple<UseChangePasswordData, UseChangePasswordVariables> {
  const mutationBuilder: GraphqlBuilder<ClientUserMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('changePassword')
      .setReturnProperties(mapper)
      .declareVariables({
        originalPassword: 'String!',
        password: 'String!',
        passwordConfirmation: 'String!',        
      })
      .setProperties(
        `originalPassword: $originalPassword,
        password: $password,
        passwordConfirmation: $passwordConfirmation`
      )
      .build(),
    options
  );
}


export function useChangeRole(
  mapper: ResultResponseMapper,
  options?: MutationHookOptions<UseChangeRoleData, UseChangeRoleVariables>
): MutationTuple<UseChangeRoleData, UseChangeRoleVariables> {
  const mutationBuilder: GraphqlBuilder<ResultResponseMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('changeRole')
      .setReturnProperties(mapper)
      .declareVariables({
        clientUserId: 'Long!',
        role: 'String!',        
      })
      .setProperties(
        `clientUserId: $clientUserId,
        role: $role`
      )
      .build(),
    options
  );
}


export function useChangeActiveStatus(
  mapper: ResultResponseMapper,
  options?: MutationHookOptions<UseChangeActiveStatusData, UseChangeActiveStatusVariables>
): MutationTuple<UseChangeActiveStatusData, UseChangeActiveStatusVariables> {
  const mutationBuilder: GraphqlBuilder<ResultResponseMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('changeActiveStatus')
      .setReturnProperties(mapper)
      .declareVariables({
        clientUserId: 'Long!',
        active: 'Boolean!',        
      })
      .setProperties(
        `clientUserId: $clientUserId,
        active: $active`
      )
      .build(),
    options
  );
}


export function useClientUser(
  mapper: ClientUserDtoMapper,
  options?: MutationHookOptions<UseClientUserData, UseClientUserVariables>
): MutationTuple<UseClientUserData, UseClientUserVariables> {
  const mutationBuilder: GraphqlBuilder<ClientUserDtoMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('clientUser')
      .setReturnProperties(mapper)
      .declareVariables({
        clientUserId: 'Long!',
        clientUser: 'ClientUserGraphQLInput',        
      })
      .setProperties(
        `clientUserId: $clientUserId,
        clientUser: $clientUser`
      )
      .build(),
    options
  );
}


export function useClientUserCreate(
  mapper: ClientUserDtoMapper,
  options?: MutationHookOptions<UseClientUserCreateData, UseClientUserCreateVariables>
): MutationTuple<UseClientUserCreateData, UseClientUserCreateVariables> {
  const mutationBuilder: GraphqlBuilder<ClientUserDtoMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('clientUserCreate')
      .setReturnProperties(mapper)
      .declareVariables({
        clientUser: 'ClientUserCreateGraphQLInput',        
      })
      .setProperties(
        `clientUser: $clientUser`
      )
      .build(),
    options
  );
}


export function useClientUserDelete(
  mapper: ResultResponseMapper,
  options?: MutationHookOptions<UseClientUserDeleteData, UseClientUserDeleteVariables>
): MutationTuple<UseClientUserDeleteData, UseClientUserDeleteVariables> {
  const mutationBuilder: GraphqlBuilder<ResultResponseMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('clientUserDelete')
      .setReturnProperties(mapper)
      .declareVariables({
        clientUserId: 'Long!',        
      })
      .setProperties(
        `clientUserId: $clientUserId`
      )
      .build(),
    options
  );
}


export function useClientEdit(
  mapper: ClientMapper,
  options?: MutationHookOptions<UseClientEditData, UseClientEditVariables>
): MutationTuple<UseClientEditData, UseClientEditVariables> {
  const mutationBuilder: GraphqlBuilder<ClientMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('clientEdit')
      .setReturnProperties(mapper)
      .declareVariables({
        client: 'ClientGraphQLInput',        
      })
      .setProperties(
        `client: $client`
      )
      .build(),
    options
  );
}


export function useClientConflictsEdit(
  mapper: ResultResponseMapper,
  options?: MutationHookOptions<UseClientConflictsEditData, UseClientConflictsEditVariables>
): MutationTuple<UseClientConflictsEditData, UseClientConflictsEditVariables> {
  const mutationBuilder: GraphqlBuilder<ResultResponseMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('clientConflictsEdit')
      .setReturnProperties(mapper)
      .declareVariables({
        conflicts: '[ClientConflictGraphQLInput]',        
      })
      .setProperties(
        `conflicts: $conflicts`
      )
      .build(),
    options
  );
}


export function useAnonymizeAfterSet(
  mapper: ResultResponseMapper,
  options?: MutationHookOptions<UseAnonymizeAfterSetData, UseAnonymizeAfterSetVariables>
): MutationTuple<UseAnonymizeAfterSetData, UseAnonymizeAfterSetVariables> {
  const mutationBuilder: GraphqlBuilder<ResultResponseMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('anonymizeAfterSet')
      .setReturnProperties(mapper)
      .declareVariables({
        anonymizeAfter: 'AnonymizeAfter!',        
      })
      .setProperties(
        `anonymizeAfter: $anonymizeAfter`
      )
      .build(),
    options
  );
}


export function useAddPrepayment(
  mapper: ResultResponseMapper,
  options?: MutationHookOptions<UseAddPrepaymentData, UseAddPrepaymentVariables>
): MutationTuple<UseAddPrepaymentData, UseAddPrepaymentVariables> {
  const mutationBuilder: GraphqlBuilder<ResultResponseMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('addPrepayment')
      .setReturnProperties(mapper)
      .declareVariables({
        balance: 'Long!',        
      })
      .setProperties(
        `balance: $balance`
      )
      .build(),
    options
  );
}


export function useUpdateClientWhitelist(
  mapper: ResultResponseMapper,
  options?: MutationHookOptions<UseUpdateClientWhitelistData, UseUpdateClientWhitelistVariables>
): MutationTuple<UseUpdateClientWhitelistData, UseUpdateClientWhitelistVariables> {
  const mutationBuilder: GraphqlBuilder<ResultResponseMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('updateClientWhitelist')
      .setReturnProperties(mapper)
      .declareVariables({
        whitelist: '[ClientWhitelistGraphQLInput]',        
      })
      .setProperties(
        `whitelist: $whitelist`
      )
      .build(),
    options
  );
}


export function useRemovePaymentProfile(
  mapper: ResultResponseMapper,
  options?: MutationHookOptions<UseRemovePaymentProfileData, UseRemovePaymentProfileVariables>
): MutationTuple<UseRemovePaymentProfileData, UseRemovePaymentProfileVariables> {
  const mutationBuilder: GraphqlBuilder<ResultResponseMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('removePaymentProfile')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}


export function useCreateOrderSingle(
  mapper: PrimaryOrderMapper,
  options?: MutationHookOptions<UseCreateOrderSingleData, UseCreateOrderSingleVariables>
): MutationTuple<UseCreateOrderSingleData, UseCreateOrderSingleVariables> {
  const mutationBuilder: GraphqlBuilder<PrimaryOrderMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('createOrderSingle')
      .setReturnProperties(mapper)
      .declareVariables({
        primaryOrderId: 'Long',
        primaryOrder: 'PrimaryOrderGraphQLInput',
        candidateIdentity: 'CandidateIdentityGraphQLInput',        
      })
      .setProperties(
        `primaryOrderId: $primaryOrderId,
        primaryOrder: $primaryOrder,
        candidateIdentity: $candidateIdentity`
      )
      .build(),
    options
  );
}


export function useCreateOrderMultiple(
  mapper: PrimaryOrderMapper,
  options?: MutationHookOptions<UseCreateOrderMultipleData, UseCreateOrderMultipleVariables>
): MutationTuple<UseCreateOrderMultipleData, UseCreateOrderMultipleVariables> {
  const mutationBuilder: GraphqlBuilder<PrimaryOrderMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('createOrderMultiple')
      .setReturnProperties(mapper)
      .declareVariables({
        primaryOrderId: 'Long',
        primaryOrder: 'PrimaryOrderGraphQLInput',
        candidateIdentities: '[CandidateIdentityGraphQLInput]',        
      })
      .setProperties(
        `primaryOrderId: $primaryOrderId,
        primaryOrder: $primaryOrder,
        candidateIdentities: $candidateIdentities`
      )
      .build(),
    options
  );
}


export function usePayOrder(
  mapper: ResultResponseMapper,
  options?: MutationHookOptions<UsePayOrderData, UsePayOrderVariables>
): MutationTuple<UsePayOrderData, UsePayOrderVariables> {
  const mutationBuilder: GraphqlBuilder<ResultResponseMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('payOrder')
      .setReturnProperties(mapper)
      .declareVariables({
        primaryOrderId: 'Long!',
        paymentMethod: 'PaymentMethod!',        
      })
      .setProperties(
        `primaryOrderId: $primaryOrderId,
        paymentMethod: $paymentMethod`
      )
      .build(),
    options
  );
}


export function useAnonymizeOrder(
  mapper: ResultResponseMapper,
  options?: MutationHookOptions<UseAnonymizeOrderData, UseAnonymizeOrderVariables>
): MutationTuple<UseAnonymizeOrderData, UseAnonymizeOrderVariables> {
  const mutationBuilder: GraphqlBuilder<ResultResponseMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('anonymizeOrder')
      .setReturnProperties(mapper)
      .declareVariables({
        primaryOrderId: 'Long!',        
      })
      .setProperties(
        `primaryOrderId: $primaryOrderId`
      )
      .build(),
    options
  );
}


export function useFillForm(
  mapper: DynamicFormValidationResultMapper,
  options?: MutationHookOptions<UseFillFormData, UseFillFormVariables>
): MutationTuple<UseFillFormData, UseFillFormVariables> {
  const mutationBuilder: GraphqlBuilder<DynamicFormValidationResultMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('fillForm')
      .setReturnProperties(mapper)
      .declareVariables({
        formId: 'Long!',
        formData: 'MAP_String_Object!',        
      })
      .setProperties(
        `formId: $formId,
        formData: $formData`
      )
      .build(),
    options
  );
}


export function useSubmitExecutionDetails(
  mapper: ResultResponseMapper,
  options?: MutationHookOptions<UseSubmitExecutionDetailsData, UseSubmitExecutionDetailsVariables>
): MutationTuple<UseSubmitExecutionDetailsData, UseSubmitExecutionDetailsVariables> {
  const mutationBuilder: GraphqlBuilder<ResultResponseMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('submitExecutionDetails')
      .setReturnProperties(mapper)
      .declareVariables({
        formId: 'Long!',
        formData: 'MAP_String_Object!',
        paymentMethod: 'PaymentMethod',        
      })
      .setProperties(
        `formId: $formId,
        formData: $formData,
        paymentMethod: $paymentMethod`
      )
      .build(),
    options
  );
}


export function useUpdateCreditCard(
  mapper: ResultResponseMapper,
  options?: MutationHookOptions<UseUpdateCreditCardData, UseUpdateCreditCardVariables>
): MutationTuple<UseUpdateCreditCardData, UseUpdateCreditCardVariables> {
  const mutationBuilder: GraphqlBuilder<ResultResponseMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('updateCreditCard')
      .setReturnProperties(mapper)
      .declareVariables({
        token: 'String',        
      })
      .setProperties(
        `token: $token`
      )
      .build(),
    options
  );
}


export function useDeleteFile(
  mapper: FileDeleteHandlerResponseMapper,
  options?: MutationHookOptions<UseDeleteFileData, UseDeleteFileVariables>
): MutationTuple<UseDeleteFileData, UseDeleteFileVariables> {
  const mutationBuilder: GraphqlBuilder<FileDeleteHandlerResponseMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('deleteFile')
      .setReturnProperties(mapper)
      .declareVariables({
        id: 'Long!',        
      })
      .setProperties(
        `id: $id`
      )
      .build(),
    options
  );
}


