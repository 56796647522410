import React from 'react';
import {
  Box, Button, Text, useDisclosure,
} from '@chakra-ui/react';
import { useKeycloak } from '@react-keycloak/web';
import { Translate } from '@scaut-sro/meepo';
import { RiDownloadFill } from 'react-icons/ri';
import { downloadFile } from '../../core/service/queries/FileUploadService';
import { POAStatus } from '../../build/generated-sources/enum/POAStatus';
import { PowerOfAttorneyProps } from './PowerOfAttorneySection.model';
import PowerOfAttorneySectionLocale from './PowerOfAttorneySection.locale';
import SupportModalDetail from '../../views/Support/SupportModalDetail';

const PowerOfAttorneySection: React.FunctionComponent<PowerOfAttorneyProps> = (props) => {
  const { powerOfAttorneyEntity } = props;

  const keycloak = useKeycloak();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const downloadPowerOfAttorney = (templateId: number) => {
    downloadFile(keycloak.keycloak.token as string, `/dms/getFile/${templateId}`);
  };

  if (!powerOfAttorneyEntity || !powerOfAttorneyEntity.generatedTemplates) {
    return (
      <>
        Power Of attorney not yet generated
      </>
    );
  }

  const { poaStatus, generatedTemplates } = powerOfAttorneyEntity;

  return (
    <>
      <Box>
        {
          poaStatus === POAStatus.GENERATED && generatedTemplates ? (
            <Box>
              <Text>
                <Translate label={PowerOfAttorneySectionLocale.INFO_TEXT1} />
                <Button variant="link" onClick={onOpen}>
                  <Translate label={PowerOfAttorneySectionLocale.INFO_TEXT_SUPPORT} />
                </Button>
                <SupportModalDetail
                  onClose={onClose}
                  isOpen={isOpen}
                />
              </Text>
              <Text mt={4}>
                <Translate label={PowerOfAttorneySectionLocale.INFO_TEXT2} />
                {' '}
                <b>Scaut s.r.o., Kaprova 15/11, 110 00, Praha 1, Česká Republika</b>
                {' '}
                <Translate label={PowerOfAttorneySectionLocale.INFO_TEXT3} />
                {' '}
                <b><Translate label={PowerOfAttorneySectionLocale.INFO_TEXT4} /></b>
                {' '}
                <b>nv3b5kd</b>
                .
              </Text>
              {
                generatedTemplates.map((template) => (
                  <Button
                    leftIcon={<RiDownloadFill />}
                    variant="outline"
                    onClick={() => downloadPowerOfAttorney(template.id as number)}
                    mt={4}
                    key={template.id}
                  >
                    <Translate label={PowerOfAttorneySectionLocale.DOWNLOAD} />
                  </Button>
                ))
              }
            </Box>
          ) : (
            <>
              Power Of attorney not yet generated
            </>
          )
        }
      </Box>
    </>
  );
};

export default PowerOfAttorneySection;
