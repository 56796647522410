import React, { useMemo } from 'react';
import { Column } from 'react-table';
import { EnhancedTable } from '@scaut-sro/meepo';
import { Box, Center } from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { useHistory } from 'react-router-dom';
import { CandidateScreening } from '../../build/generated-sources/dto/CandidateScreening';
import Translate from '../Translate/Translate';
import OrderDetailLocale from '../../views/OrderDetail/OrderDetail.locale';
import TableLocale from '../../core/localization/translations/Table.locale';
import { CandidateScreeningTableData } from './CandidateScreeningsTable.model';
import { ResultBadge } from '../ResultBadge/ResultBadge';
import ShowDate from '../ShowDate/ShowDate';

type CandidateScreeningsTableProps = {
  screenings: CandidateScreening[],
  isLoading: boolean
};

const CandidateScreeningsTable: React.FunctionComponent<CandidateScreeningsTableProps> = ({ screenings, isLoading }) => {
  const tableData: CandidateScreeningTableData[] = useMemo(() => screenings.map((candidateScreening) => ({
    id: candidateScreening.id ? candidateScreening.id : undefined,
    firstName: candidateScreening.candidateIdentity ? candidateScreening.candidateIdentity.firstName : '',
    lastName: candidateScreening.candidateIdentity?.lastName,
    dateOfBirth: candidateScreening.candidateIdentity?.dateOfBirth,
    result: candidateScreening.result,
  })), [screenings]);

  const history = useHistory();

  const columns: Column<CandidateScreeningTableData>[] = useMemo(() => [
    {
      Header: <Translate label={OrderDetailLocale.FIRST_NAME} />,
      accessor: 'firstName',
    },
    {
      Header: <Translate label={OrderDetailLocale.LAST_NAME} />,
      accessor: 'lastName',
    },
    {
      Header: <Translate label={OrderDetailLocale.DATE_OF_BIRTH} />,
      accessor: 'dateOfBirth',
      Cell: ({ row: { original } }) => (
        <ShowDate
          date={original.dateOfBirth}
        />
      ),
    },
    {
      Header: <Translate label={OrderDetailLocale.RESULT} />,
      accessor: 'result',
      Cell: ({ row: { original } }) => (
        <ResultBadge
          result={original.result}
          size="lg"
        />
      ),
    },
    {
      accessor: 'id',
      Cell: ({ row: { original } }) => (
        <ArrowForwardIcon
          boxSize={6}
          style={{ cursor: 'pointer' }}
          onClick={() => history.push(`/screening/${original.id}`)}
        />
      ),
    },
  ], [history]);

  return (
    <Box minW="650px">
      <EnhancedTable
        columns={columns}
        data={tableData}
        isLoading={isLoading}
        noData={(
          <Center p={6}>
            <Translate label={TableLocale.NO_DATA} />
          </Center>
        )}
      />
    </Box>
  );
};

export default CandidateScreeningsTable;
