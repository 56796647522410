import React, { useMemo } from 'react';
import { Column } from 'react-table';
import { Box, Center } from '@chakra-ui/react';
import { EnhancedTable } from '@scaut-sro/meepo';
import Translate from '../../components/Translate/Translate';
import TableLocale from '../../core/localization/translations/Table.locale';
import OrderDetailLocale from './OrderDetail.locale';
import { CandidateProps } from './OrderDetail.model';
import { CandidateIdentity } from '../../build/generated-sources/dto/CandidateIdentity';
import ShowDate from '../../components/ShowDate/ShowDate';

const OrderDetailCandidateTable: React.FunctionComponent<CandidateProps> = (props) => {
  const { data, isLoading } = props;

  const columns: Column<CandidateIdentity>[] = useMemo(() => [
    {
      Header: <Translate label={OrderDetailLocale.FIRST_NAME} />,
      accessor: 'firstName',
    },
    {
      Header: <Translate label={OrderDetailLocale.LAST_NAME} />,
      accessor: 'lastName',
    },
    {
      Header: <Translate label={OrderDetailLocale.DATE_OF_BIRTH} />,
      accessor: 'dateOfBirth',
      Cell: ({ row: { original } }) => (
        <ShowDate
          date={original.dateOfBirth}
        />
      ),
    },
  ], []);

  return (
    <Box minW="450px">
      <EnhancedTable
        columns={columns}
        data={data}
        isLoading={isLoading}
        noData={(
          <Center p={6}>
            <Translate label={TableLocale.NO_DATA} />
          </Center>
        )}
      />
    </Box>
  );
};

export default OrderDetailCandidateTable;
