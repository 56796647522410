import React from 'react';
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  GridItem,
  // IconButton,
  // Menu,
  // MenuButton,
  // MenuItem,
  // MenuList,
  SimpleGrid,
  Spacer,
  Text,
} from '@chakra-ui/react';
import {
  Note, GridParameter,
} from '@scaut-sro/meepo';
import { useHistory } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
// import { HiOutlineDotsVertical } from 'react-icons/hi';
import TagManager from 'react-gtm-module';
import { SingleCheckDetailProps } from './Checks.model';
import Translate from '../../components/Translate/Translate';
import ChecksLocale from './Checks.locale';
import TableLocale from '../../core/localization/translations/Table.locale';
import { showDate } from '../../core/date/showDate';
import { useGetUser } from '../../core/store/reducers/UserSettingsReducer';
import { translate } from '../../core/localization/LocaleUtils';
import { Result } from '../../build/generated-sources/enum/Result';
import { downloadFile } from '../../core/service/queries/FileUploadService';
import ScreeningBadge from '../../components/ScreeningBadge/ScreeningBadge';

const SingleCheckDetail: React.FunctionComponent<SingleCheckDetailProps> = (props) => {
  const { candidateScreening, anonymizationDate } = props;
  const { language, userProfile } = useGetUser();
  const history = useHistory();
  const keycloak = useKeycloak();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const downloadReport = () => {
    if (keycloak.keycloak.token) {
      downloadFile(keycloak.keycloak.token, `/dms/downloadReport/${candidateScreening?.id}`);
    }
  };

  TagManager.dataLayer({
    dataLayer: {
      event: 'page_view',
      realm: 'client',
      page: {
        url: document.location.origin + document.location.pathname + document.location.search,
        title: 'Checks - single',
      },
      user: {
        email: userProfile?.email,
        given_name: userProfile?.firstName,
        family_name: userProfile?.lastName,
        job_position: userProfile?.jobPosition,
        language,
      },
    },
  });

  return (
    <>
      {candidateScreening ? (
        <Box p={3} px={6}>
          <SimpleGrid pt={3} columns={[1, 1, 3, 3, 4, 4]} gap={16}>
            <GridItem colSpan={[1, 1, 2, 2, 3, 3]}>
              <Box pb={3}>
                <Text color="#002458" fontSize="lg"><Translate label={ChecksLocale.TABLE_DETAIL_SCREENINGS} /></Text>
              </Box>
              <SimpleGrid columns={[1, 1, 2, 2, 3, 3]} gap={3} mt={4}>
                {candidateScreening.screenings?.map((screening) => (
                  <ScreeningBadge
                    key={screening.id}
                    result={screening.result || Result.UNRESOLVED}
                  >
                    {screening.screeningDefinition?.name?.localizationStrings?.[language]}
                  </ScreeningBadge>
                ))}
              </SimpleGrid>
            </GridItem>
            <GridItem>
              <SimpleGrid gap={6}>
                <Text color="#002458" fontSize="lg"><Translate label={ChecksLocale.TABLE_DETAIL_INFO} /></Text>
                <GridParameter
                  label={translate(ChecksLocale.TABLE_DETAIL_CANDIDATE_SCREENING_START, language)}
                  value={showDate(language, candidateScreening.processStarted)}
                />
                <GridParameter
                  label={translate(ChecksLocale.TABLE_DETAIL_CANDIDATE_SCREENING_END, language)}
                  value={showDate(language, candidateScreening.processCompleted)}
                />
              </SimpleGrid>
            </GridItem>
          </SimpleGrid>
          <Divider mt={6} mb={3} />
          <Flex alignItems="center">
            <Note textTransform="uppercase">
              <Translate label={ChecksLocale.TABLE_DETAIL_CANDIDATE_SCREENING_ANONYMIZED} />
              {anonymizationDate ? (
                <span>{`: ${showDate(language, anonymizationDate)}`}</span>
              ) : undefined}
            </Note>
            <Spacer />
            {/*
            <Menu variant="blue" placement="top">
              <MenuButton
                as={IconButton}
                variant="ghost"
                aria-label="Context menu"
                icon={<HiOutlineDotsVertical />}
                borderRadius="full"
              />
              <MenuList>
                <MenuItem onClick={downloadReport}><Translate label={ChecksLocale.TABLE_DETAIL_DOWNLOAD_REPORT} /></MenuItem>
                {/* <MenuItem><Translate label={ChecksLocale.TABLE_DETAIL_SHARE} /></MenuItem>
              </MenuList>
            </Menu> */}
            <Button ml={3} onClick={downloadReport}>
              <Translate label={ChecksLocale.TABLE_DETAIL_DOWNLOAD_REPORT} />
            </Button>
            <Button ml={3} onClick={() => history.push(`/screening/${candidateScreening.id}`)}>
              <Translate label={ChecksLocale.TABLE_DETAIL_GO_TO_DETAIL} />
            </Button>
          </Flex>
        </Box>
      ) : (
        <Center w="100%" p={8}>
          <Text><Translate label={TableLocale.NO_DATA} /></Text>
        </Center>
      )}
    </>
  );
};

export default SingleCheckDetail;
