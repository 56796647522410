import React from 'react';
import {
  Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, SimpleGrid, Skeleton,
} from '@chakra-ui/react';
import { H4 } from '@scaut-sro/meepo';

const CatalogueLoader: React.FunctionComponent = () => (
  <Accordion defaultIndex={[0]} allowMultiple>
    <AccordionItem>
      <Skeleton>
        <AccordionButton>
          <Box flex="1" textAlign="left">
            <H4 mt="12px">This is Country label</H4>
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </Skeleton>
      <AccordionPanel p={6}>
        <Skeleton><H4 mb={6}>Packages</H4></Skeleton>
        <SimpleGrid columns={[1, 1, 2, 2, 3]} spacing={6} mb={8}>
          <Skeleton><Box h="150px" /></Skeleton>
          <Skeleton><Box h="150px" /></Skeleton>
          <Skeleton><Box h="150px" /></Skeleton>
        </SimpleGrid>
        <Skeleton><H4 mb={6}>Partial checks</H4></Skeleton>
        <SimpleGrid columns={[1, 1, 2, 2, 3]} spacing={6} mb={6}>
          <Skeleton><Box h="150px" /></Skeleton>
          <Skeleton><Box h="150px" /></Skeleton>
          <Skeleton><Box h="150px" /></Skeleton>
        </SimpleGrid>
      </AccordionPanel>
    </AccordionItem>
    <AccordionItem>
      <Skeleton>
        <AccordionButton>
          <Box flex="1" textAlign="left">
            <H4 mt="12px">This is Country label</H4>
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </Skeleton>
    </AccordionItem>
  </Accordion>
);

export default CatalogueLoader;
