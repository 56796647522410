import React, {
  useEffect, useMemo, useRef,
} from 'react';
import {
  Box, Button, Flex, FormControl, FormErrorMessage,
  FormLabel, Input, SimpleGrid, Spacer, Switch,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
  H2, SectionContainer, Toast,
} from '@scaut-sro/meepo';
import { ClientDetailProps, ClientFormAttr } from './ClientDetail.model';
import { useGetUser } from '../../../core/store/reducers/UserSettingsReducer';
import { translate } from '../../../core/localization/LocaleUtils';
import ClientDetailLocale from './ClientDetail.locale';
import { Language } from '../../../build/generated-sources/enum/Language';
import { useClientEdit } from '../../../build/generated-sources/service/MutationService';
import FormLocale from '../../../core/localization/translations/Form.locale';
import Translate from '../../../components/Translate/Translate';
import UserSettingsLocale from '../../UserSettings/UserSettings.locale';
import DetailHeader from '../../../components/DetailHeader/DetailHeader';
import LoadingScreen from '../../LoadingScreen/LoadingScreen';

const ClientDetail: React.FunctionComponent<ClientDetailProps> = (props) => {
  const { clientDetail, setClientUpdated } = props;
  const { language } = useGetUser();
  const formRef = useRef<HTMLFormElement>(null);

  const defaultValues: Partial<ClientFormAttr> = useMemo(() => ({
    clientId: clientDetail?.client?.id,
    name: clientDetail?.client?.name,
    clientEmail: clientDetail?.client?.email,
    clientPhone: clientDetail?.client?.phone,
    communicationLanguage: {
      value: clientDetail?.client?.communicationLanguage || 'EN',
      label: translate(ClientDetailLocale[clientDetail?.client?.communicationLanguage || Language.EN], language),
    },
    enabled2fa: clientDetail?.client?.enabled2fa || false,
  }), [clientDetail, language]);
  const {
    handleSubmit, register, setValue, reset, formState: { errors }, control,
  } = useForm<Partial<ClientFormAttr>>({
    defaultValues,
  });

  const languageOptions = useMemo(() => ([
    { value: 'EN', label: translate(ClientDetailLocale.ENGLISH, language) },
    { value: 'CZ', label: translate(ClientDetailLocale.CZECH, language) },
  ]), [language]);

  useEffect(() => {
    reset(defaultValues);
  }, [
    reset, setValue, defaultValues,
  ]);

  const [mutateClient] = useClientEdit({
    id: true,
  }, {
    onCompleted: () => {
      Toast({
        title: translate(FormLocale.SUCCESS, language),
        status: 'success',
      });
      setClientUpdated(true);
    },
    onError: () => {
      Toast({
        title: translate(ClientDetailLocale.ERROR_GETTING_DATA, language),
        status: 'error',
      });
    },
  });

  useEffect(() => {
    register('enabled2fa');
  }, [register]);

  const handleClientFormSubmit: SubmitHandler<ClientFormAttr> = (values) => {
    mutateClient({
      variables: {
        client: {
          name: values.name,
          ico: clientDetail?.client?.ico,
          dic: clientDetail?.client?.dic,
          clientEmail: values.clientEmail,
          clientPhone: values.clientPhone,
          street: clientDetail?.client?.street,
          zipcode: clientDetail?.client?.zipcode,
          city: clientDetail?.client?.city,
          countryId: clientDetail?.client?.country?.id,
          enabled2fa: values.enabled2fa,
          communicationLanguage: values.communicationLanguage?.value as Language,
        },
      },
    });
  };

  return (
    <>
      { !clientDetail ? (
        <LoadingScreen />
      ) : (
        <SectionContainer>
          <Box overflowX="auto">
            <Box minW="600px">
              <DetailHeader>
                <H2><Translate label={ClientDetailLocale.COMPANY_INFORMATION} /></H2>
              </DetailHeader>
              <Box overflowX="auto">
                <Box minW="600px">
                  <form
                    name="clientDetailForm"
                    ref={formRef}
                    onSubmit={handleSubmit(handleClientFormSubmit)}
                  >
                    <SimpleGrid columns={[1, 1, 1, 3, 3, 3]} spacing={6} mb={7}>
                      <FormControl isRequired isInvalid={!!errors.name}>
                        <FormLabel htmlFor="name">
                          <Translate label={ClientDetailLocale.NAME} />
                        </FormLabel>
                        <Input
                          placeholder={translate(ClientDetailLocale.NAME, language)}
                          {...register('name', {
                            required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
                          })}
                        />
                        <FormErrorMessage>
                          {errors.name && errors.name.message}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl isRequired isInvalid={!!errors.clientEmail}>
                        <FormLabel htmlFor="clientEmail">
                          <Translate label={ClientDetailLocale.CLIENT_EMAIL} />
                        </FormLabel>
                        <Input
                          type="clientEmail"
                          id="clientEmail"
                          placeholder={translate(ClientDetailLocale.EMAIL_PLACEHOLDER, language)}
                          {...register('clientEmail', {
                            required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
                            pattern: {
                              // eslint-disable-next-line max-len
                              value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                              message: translate(UserSettingsLocale.EMAIL_ERROR_INVALID, language),
                            },
                          })}
                        />
                        <FormErrorMessage>
                          {errors.clientEmail && errors.clientEmail.message}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl isRequired isInvalid={!!errors.clientPhone}>
                        <FormLabel htmlFor="clientPhone">
                          <Translate label={ClientDetailLocale.CLIENT_PHONE} />
                        </FormLabel>
                        <Input
                          type="tel"
                          id="clientPhone"
                          placeholder={translate(ClientDetailLocale.PHONE_PLACEHOLDER, language)}
                          {...register('clientPhone', {
                            required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
                            maxLength: {
                              value: 15,
                              message: translate(ClientDetailLocale.PHONE_ERROR_MAX_LENGTH, language),
                            },
                            minLength: {
                              value: 8,
                              message: translate(ClientDetailLocale.PHONE_ERROR_MIN_LENGTH, language),
                            },
                            pattern: {
                              value: /^\+?[\d\s-]*$/,
                              message: translate(FormLocale.INVALID_CHARACTERS, language),
                            },
                          })}
                        />
                        <FormErrorMessage>
                          {errors.clientPhone && errors.clientPhone.message}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl>
                        <FormLabel><Translate label={ClientDetailLocale.COMMUNICATION_LANGUAGE} /></FormLabel>
                        <Controller
                          name="communicationLanguage"
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              options={languageOptions}
                              hideSelectedOptions={false}
                              selectedOptionStyle="check"
                              closeMenuOnSelect
                            />
                          )}
                        />
                      </FormControl>
                      <FormControl
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        borderRadius="md"
                        backgroundColor="gray.100"
                        p="6px"
                        pl={3}
                      >
                        <label htmlFor="enabled2fa">{translate(ClientDetailLocale.ENABLE_2FA, language)}</label>
                        <Controller
                          control={control}
                          name="enabled2fa"
                          render={({ field }) => (
                            <Switch
                              onChange={(e) => { field.onChange(e.target.checked); }}
                              isChecked={field.value}
                              size="lg"
                              colorScheme="green"
                            />
                          )}
                        />
                      </FormControl>
                    </SimpleGrid>
                    <Flex>
                      <Spacer />
                      <Button mt={6} type="submit">
                        <Translate label={ClientDetailLocale.SAVE_CHANGES} />
                      </Button>
                    </Flex>

                  </form>
                </Box>
              </Box>
            </Box>
          </Box>
        </SectionContainer>
      )}
    </>
  );
};
export default ClientDetail;
