import { Translation } from '../../core/localization/Localization.model';

class NotesLogLocale {
  public static NO_NOTES: Translation = {
    EN: 'No history',
    CZ: 'Žádná historie',
  };
}

export default NotesLogLocale;
