import React, {
  useState,
} from 'react';
import {
  Box, Button, Flex, Spacer, Text,
} from '@chakra-ui/react';
import {
  H2, SectionContainer, Toast,
} from '@scaut-sro/meepo';
import { useGetUser } from '../../../core/store/reducers/UserSettingsReducer';
import { translate } from '../../../core/localization/LocaleUtils';
import ConflictsLocale from './Conflicts.locale';
import { useGetClientConflicts } from '../../../build/generated-sources/service/QueryService';
import { useClientConflictsEdit } from '../../../build/generated-sources/service/MutationService';
import FormLocale from '../../../core/localization/translations/Form.locale';
import Translate from '../../../components/Translate/Translate';
import DetailHeader from '../../../components/DetailHeader/DetailHeader';
import LoadingScreen from '../../LoadingScreen/LoadingScreen';
import { ClientConflict } from '../../../build/generated-sources/dto/ClientConflict';
import ConflictsEditable from './ConflictsEditable';
import { ClientConflictGraphQLInput } from '../../../build/generated-sources/dto/ClientConflictGraphQLInput';
import ConflictsFileUpload from './ConflictsFileUpload/ConflictsFileUpload';

export function getConflictsTableData(data: ClientConflict[] | undefined): ClientConflictGraphQLInput[] {
  if (data) {
    return data.map((conflict) => ({
      ico: conflict?.ico,
      name: conflict?.name,
      note: conflict?.note,
    }));
  }
  return [];
}

const Conflicts: React.FunctionComponent = () => {
  const { language } = useGetUser();
  const [fetchedData, setFetchedData] = useState<boolean>(false);
  const [conflictsList, setConflictsList] = useState<ClientConflictGraphQLInput[]>([]);

  useGetClientConflicts(
    {
      id: true,
      name: true,
      ico: true,
      note: true,
    }, {
      onError: () => {
        Toast({
          title: translate(ConflictsLocale.ERROR_GETTING_SUBJECTS, language),
          status: 'error',
        });
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: (response) => {
        const onCompleteData = getConflictsTableData(response.clientConflicts);
        setConflictsList(onCompleteData);
      },
    },
  );

  const [mutateClientConflictsEdit, { loading }] = useClientConflictsEdit({
    success: true,
  }, {
    onCompleted: () => {
      Toast({
        title: translate(FormLocale.SUCCESS, language),
        status: 'success',
      });
    },
    onError: () => {
      Toast({
        title: translate(ConflictsLocale.ERROR_GETTING_DATA, language),
        status: 'error',
      });
    },
  });

  const onFileLoaded = (clientConflicts: ClientConflictGraphQLInput[]) => {
    setConflictsList(clientConflicts);
  };

  const updateClientConflicts = () => {
    mutateClientConflictsEdit({
      variables: {
        conflicts: conflictsList,
      },
    });
  };

  const eraseClientConflicts = () => {
    setConflictsList([]);
  };

  return (
    <>
      { !conflictsList ? (
        <LoadingScreen />
      ) : (
        <SectionContainer>
          <Box overflowX="auto">
            <Box minW="600px">
              <Flex justifyContent="space-between" alignItems="center">
                <DetailHeader>
                  <H2><Translate label={ConflictsLocale.CLIENT_CONFLICTS} /></H2>
                </DetailHeader>
                <Box p="1">
                  <ConflictsFileUpload
                    onComplete={onFileLoaded}
                    newData={(value) => setFetchedData(value)}
                    setConflictsList={setConflictsList}
                  />
                </Box>
              </Flex>

              <Box overflowX="auto" mt={6}>
                <Box maxWidth={500}>
                  <Text><Translate label={ConflictsLocale.INFORMATION_TEXT} /></Text>
                </Box>
                <Box minW="600px" mt={6}>
                  <ConflictsEditable
                    conflicts={conflictsList}
                    newData={fetchedData}
                    onConflictsChange={setConflictsList}
                  />
                  <Flex>
                    <Button isLoading={loading} mt={6} onClick={updateClientConflicts}>
                      <Translate label={FormLocale.SAVE} />
                    </Button>
                    <Spacer />
                    <Button isLoading={loading} color="gray.400" variant="link" onClick={eraseClientConflicts}>
                      <Translate label={ConflictsLocale.ERASE_TABLE} />
                    </Button>
                  </Flex>
                </Box>
              </Box>
            </Box>
          </Box>
        </SectionContainer>
      )}
    </>
  );
};
export default Conflicts;
