import {
  Box, Button, Collapse, Flex,
} from '@chakra-ui/react';
import { Translate } from '@scaut-sro/meepo';
import React, {
  MutableRefObject, useEffect, useRef, useState,
} from 'react';
import { ExpanderProps } from './Expander.model';
import ExpanderLocale from './Expander.locale';

// Hook
function useOnScreen<T extends Element>(ref: MutableRefObject<T>, rootMargin: string = '0px'): boolean {
  // State and setter for storing whether element is visible
  const [isIntersecting, setIntersecting] = useState<boolean>(false);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update our state when observer callback fires
        setIntersecting(entry.isIntersecting);
      },
      {
        rootMargin,
      },
    );
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      observer.unobserve(ref.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty array ensures that effect is only run on mount and unmount
  return isIntersecting;
}

const Expander = ({ children }: ExpanderProps) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const boxRef = useRef<HTMLDivElement>(null);
  const [activateExpander, setActviateExpander] = useState<boolean>(false);

  // @ts-ignore
  const onScreen: boolean = useOnScreen<HTMLDivElement>(boxRef, '0px');

  useEffect(() => {
    if (boxRef) {
      if ((boxRef?.current?.clientHeight || 0) >= 300) {
        setActviateExpander(true);
      } else {
        setActviateExpander(false);
      }
    }
  }, [onScreen]);

  return (
    <>
      { activateExpander ? (
        <>
          <Collapse startingHeight={300} in={expanded}>
            <Box ref={boxRef}>
              {children}
            </Box>
          </Collapse>
          <Flex justifyContent="center">
            <Button size="sm" onClick={() => { setExpanded(!expanded); }} mt="1rem" variant="ghost">
              {expanded ? <Translate label={ExpanderLocale.SHOW_LESS} /> : <Translate label={ExpanderLocale.SHOW_MORE} />}
            </Button>
          </Flex>
        </>
      ) : (
        <>
          <Box ref={boxRef}>
            {children}
          </Box>
        </>
      ) }

    </>
  );
};

export default Expander;
