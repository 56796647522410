import {
  QueryHookOptions,
  useQuery,
  useLazyQuery,
  QueryResult,
  QueryTuple,
} from '@apollo/client';
import {GraphqlBuilder, GraphQLType} from '@scaut-sro/npm-core-generator';
import { EnumerationMapper } from '../dto/Enumeration'
import {UseGetEnumerationsData, UseGetEnumerationsVariables} from './QueryServiceModel'
import { ClientDetailMapper } from '../dto/ClientDetail'
import {UseGetClientDetailData, UseGetClientDetailVariables} from './QueryServiceModel'
import { ClientConflictMapper } from '../dto/ClientConflict'
import {UseGetClientConflictsData, UseGetClientConflictsVariables} from './QueryServiceModel'
import { PageableResponse__ClientChargeHistoryMapper } from '../dto/PageableResponse__ClientChargeHistory'
import {UseGetClientChargeHistoryData, UseGetClientChargeHistoryVariables} from './QueryServiceModel'
import { ClientWhitelistMapper } from '../dto/ClientWhitelist'
import {UseGetClientWhitelistData, UseGetClientWhitelistVariables} from './QueryServiceModel'
import { ClientPaymentCardInfoDtoMapper } from '../dto/ClientPaymentCardInfoDto'
import {UseGetClientPaymentCardInfoData, UseGetClientPaymentCardInfoVariables} from './QueryServiceModel'
import { ClientUserMapper } from '../dto/ClientUser'
import {UseGetUserProfileData, UseGetUserProfileVariables} from './QueryServiceModel'
import { UserPreferencesMapper } from '../dto/UserPreferences'
import {UseGetUserPreferencesData, UseGetUserPreferencesVariables} from './QueryServiceModel'
import { ClientUserDtoMapper } from '../dto/ClientUserDto'
import {UseGetClientUsersData, UseGetClientUsersVariables} from './QueryServiceModel'
import { PageableResponse__ClientUserDtoMapper } from '../dto/PageableResponse__ClientUserDto'
import {UseGetClientUsersPageData, UseGetClientUsersPageVariables} from './QueryServiceModel'
import { KeycloakRoleDtoMapper } from '../dto/KeycloakRoleDto'
import {UseGetRolesData, UseGetRolesVariables} from './QueryServiceModel'
import { TeamDtoMapper } from '../dto/TeamDto'
import {UseGetTeamsData, UseGetTeamsVariables} from './QueryServiceModel'
import { AllowedPaymentMethodMapper } from '../dto/AllowedPaymentMethod'
import {UseGetAllowedClientPaymentMethodsData, UseGetAllowedClientPaymentMethodsVariables} from './QueryServiceModel'
import { KeycloakPasswordPolicyDtoMapper } from '../dto/KeycloakPasswordPolicyDto'
import {UseGetPasswordPoliciesData, UseGetPasswordPoliciesVariables} from './QueryServiceModel'
import { PageableResponse__InvoiceMapper } from '../dto/PageableResponse__Invoice'
import {UseGetInvoicesData, UseGetInvoicesVariables} from './QueryServiceModel'
import { CountryProductsDtoMapper } from '../dto/CountryProductsDto'
import {UseGetScreeningCatalogSingleData, UseGetScreeningCatalogSingleVariables} from './QueryServiceModel'
import {UseGetScreeningCatalogMultipleData, UseGetScreeningCatalogMultipleVariables} from './QueryServiceModel'
import { ExecutionDetailPriceResponseMapper } from '../dto/ExecutionDetailPriceResponse'
import {UseGetExecutionDetailGetPriceData, UseGetExecutionDetailGetPriceVariables} from './QueryServiceModel'
import { PrimaryOrderDetailMapper } from '../dto/PrimaryOrderDetail'
import {UseGetPrimaryOrderDetailData, UseGetPrimaryOrderDetailVariables} from './QueryServiceModel'
import { PageableResponse__PrimaryOrderDtoMapper } from '../dto/PageableResponse__PrimaryOrderDto'
import {UseGetPrimaryOrdersData, UseGetPrimaryOrdersVariables} from './QueryServiceModel'
import { PrimaryOrderOverviewDtoMapper } from '../dto/PrimaryOrderOverviewDto'
import {UseGetPrimaryOrdersOverviewData, UseGetPrimaryOrdersOverviewVariables} from './QueryServiceModel'
import { ScreeningMapper } from '../dto/Screening'
import {UseGetScreeningData, UseGetScreeningVariables} from './QueryServiceModel'
import { CandidateScreeningDetailMapper } from '../dto/CandidateScreeningDetail'
import {UseGetCandidateScreeningData, UseGetCandidateScreeningVariables} from './QueryServiceModel'
import { CandidateScreeningMapper } from '../dto/CandidateScreening'
import {UseGetCandidateScreeningsData, UseGetCandidateScreeningsVariables} from './QueryServiceModel'
import { ComponentNoteDetailMapper } from '../dto/ComponentNoteDetail'
import {UseGetComponentNotesData, UseGetComponentNotesVariables} from './QueryServiceModel'
import { ResourceDatabaseItemMapper } from '../dto/ResourceDatabaseItem'
import {UseGetResourceDatabaseItemsData, UseGetResourceDatabaseItemsVariables} from './QueryServiceModel'
import {UseGetGetResourceDatabaseItemData, UseGetGetResourceDatabaseItemVariables} from './QueryServiceModel'
import { FormFieldDtoMapper } from '../dto/FormFieldDto'
import {UseGetGetFieldsData, UseGetGetFieldsVariables} from './QueryServiceModel'
import { ContactDtoMapper } from '../dto/ContactDto'
import {UseGetContactData, UseGetContactVariables} from './QueryServiceModel'
import { FAQMapper } from '../dto/FAQ'
import {UseGetFaqsData, UseGetFaqsVariables} from './QueryServiceModel'
import { FileInfoMapper } from '../dto/FileInfo'
import {UseGetGetFilesInfoData, UseGetGetFilesInfoVariables} from './QueryServiceModel'
import {UseGetGetUploadedFilesInfoData, UseGetGetUploadedFilesInfoVariables} from './QueryServiceModel'

export function useGetEnumerations(
  mapper: EnumerationMapper,
  options?: QueryHookOptions<UseGetEnumerationsData, UseGetEnumerationsVariables>
): QueryResult<UseGetEnumerationsData, UseGetEnumerationsVariables> {
  const queryBuilder: GraphqlBuilder<EnumerationMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('enumerations')
      .setReturnProperties(mapper)
      .declareVariables({
        enumerationType: 'EnumerationType!',        
      })
      .setProperties(`enumerationType: $enumerationType`)
      .build(),
    options
  );
}

export function useLazyGetEnumerations(
  mapper: EnumerationMapper,
  options?: QueryHookOptions<UseGetEnumerationsData, UseGetEnumerationsVariables>
): QueryTuple<UseGetEnumerationsData, UseGetEnumerationsVariables> {
  const queryBuilder: GraphqlBuilder<EnumerationMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('enumerations')
      .setReturnProperties(mapper)
      .declareVariables({
        enumerationType: 'EnumerationType!',        
      })
      .setProperties(`enumerationType: $enumerationType`)
      .build(),
    options
  );
}


export function useGetClientDetail(
  mapper: ClientDetailMapper,
  options?: QueryHookOptions<UseGetClientDetailData, UseGetClientDetailVariables>
): QueryResult<UseGetClientDetailData, UseGetClientDetailVariables> {
  const queryBuilder: GraphqlBuilder<ClientDetailMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('clientDetail')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}

export function useLazyGetClientDetail(
  mapper: ClientDetailMapper,
  options?: QueryHookOptions<UseGetClientDetailData, UseGetClientDetailVariables>
): QueryTuple<UseGetClientDetailData, UseGetClientDetailVariables> {
  const queryBuilder: GraphqlBuilder<ClientDetailMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('clientDetail')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}


export function useGetClientConflicts(
  mapper: ClientConflictMapper,
  options?: QueryHookOptions<UseGetClientConflictsData, UseGetClientConflictsVariables>
): QueryResult<UseGetClientConflictsData, UseGetClientConflictsVariables> {
  const queryBuilder: GraphqlBuilder<ClientConflictMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('clientConflicts')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}

export function useLazyGetClientConflicts(
  mapper: ClientConflictMapper,
  options?: QueryHookOptions<UseGetClientConflictsData, UseGetClientConflictsVariables>
): QueryTuple<UseGetClientConflictsData, UseGetClientConflictsVariables> {
  const queryBuilder: GraphqlBuilder<ClientConflictMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('clientConflicts')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}


export function useGetClientChargeHistory(
  mapper: PageableResponse__ClientChargeHistoryMapper,
  options?: QueryHookOptions<UseGetClientChargeHistoryData, UseGetClientChargeHistoryVariables>
): QueryResult<UseGetClientChargeHistoryData, UseGetClientChargeHistoryVariables> {
  const queryBuilder: GraphqlBuilder<PageableResponse__ClientChargeHistoryMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('clientChargeHistory')
      .setReturnProperties(mapper)
      .declareVariables({
        paging: 'PageableInput!',        
      })
      .setProperties(`paging: $paging`)
      .build(),
    options
  );
}

export function useLazyGetClientChargeHistory(
  mapper: PageableResponse__ClientChargeHistoryMapper,
  options?: QueryHookOptions<UseGetClientChargeHistoryData, UseGetClientChargeHistoryVariables>
): QueryTuple<UseGetClientChargeHistoryData, UseGetClientChargeHistoryVariables> {
  const queryBuilder: GraphqlBuilder<PageableResponse__ClientChargeHistoryMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('clientChargeHistory')
      .setReturnProperties(mapper)
      .declareVariables({
        paging: 'PageableInput!',        
      })
      .setProperties(`paging: $paging`)
      .build(),
    options
  );
}


export function useGetClientWhitelist(
  mapper: ClientWhitelistMapper,
  options?: QueryHookOptions<UseGetClientWhitelistData, UseGetClientWhitelistVariables>
): QueryResult<UseGetClientWhitelistData, UseGetClientWhitelistVariables> {
  const queryBuilder: GraphqlBuilder<ClientWhitelistMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('clientWhitelist')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}

export function useLazyGetClientWhitelist(
  mapper: ClientWhitelistMapper,
  options?: QueryHookOptions<UseGetClientWhitelistData, UseGetClientWhitelistVariables>
): QueryTuple<UseGetClientWhitelistData, UseGetClientWhitelistVariables> {
  const queryBuilder: GraphqlBuilder<ClientWhitelistMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('clientWhitelist')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}


export function useGetClientPaymentCardInfo(
  mapper: ClientPaymentCardInfoDtoMapper,
  options?: QueryHookOptions<UseGetClientPaymentCardInfoData, UseGetClientPaymentCardInfoVariables>
): QueryResult<UseGetClientPaymentCardInfoData, UseGetClientPaymentCardInfoVariables> {
  const queryBuilder: GraphqlBuilder<ClientPaymentCardInfoDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('clientPaymentCardInfo')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}

export function useLazyGetClientPaymentCardInfo(
  mapper: ClientPaymentCardInfoDtoMapper,
  options?: QueryHookOptions<UseGetClientPaymentCardInfoData, UseGetClientPaymentCardInfoVariables>
): QueryTuple<UseGetClientPaymentCardInfoData, UseGetClientPaymentCardInfoVariables> {
  const queryBuilder: GraphqlBuilder<ClientPaymentCardInfoDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('clientPaymentCardInfo')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}


export function useGetUserProfile(
  mapper: ClientUserMapper,
  options?: QueryHookOptions<UseGetUserProfileData, UseGetUserProfileVariables>
): QueryResult<UseGetUserProfileData, UseGetUserProfileVariables> {
  const queryBuilder: GraphqlBuilder<ClientUserMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('userProfile')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}

export function useLazyGetUserProfile(
  mapper: ClientUserMapper,
  options?: QueryHookOptions<UseGetUserProfileData, UseGetUserProfileVariables>
): QueryTuple<UseGetUserProfileData, UseGetUserProfileVariables> {
  const queryBuilder: GraphqlBuilder<ClientUserMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('userProfile')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}


export function useGetUserPreferences(
  mapper: UserPreferencesMapper,
  options?: QueryHookOptions<UseGetUserPreferencesData, UseGetUserPreferencesVariables>
): QueryResult<UseGetUserPreferencesData, UseGetUserPreferencesVariables> {
  const queryBuilder: GraphqlBuilder<UserPreferencesMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('userPreferences')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}

export function useLazyGetUserPreferences(
  mapper: UserPreferencesMapper,
  options?: QueryHookOptions<UseGetUserPreferencesData, UseGetUserPreferencesVariables>
): QueryTuple<UseGetUserPreferencesData, UseGetUserPreferencesVariables> {
  const queryBuilder: GraphqlBuilder<UserPreferencesMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('userPreferences')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}


export function useGetClientUsers(
  mapper: ClientUserDtoMapper,
  options?: QueryHookOptions<UseGetClientUsersData, UseGetClientUsersVariables>
): QueryResult<UseGetClientUsersData, UseGetClientUsersVariables> {
  const queryBuilder: GraphqlBuilder<ClientUserDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('clientUsers')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}

export function useLazyGetClientUsers(
  mapper: ClientUserDtoMapper,
  options?: QueryHookOptions<UseGetClientUsersData, UseGetClientUsersVariables>
): QueryTuple<UseGetClientUsersData, UseGetClientUsersVariables> {
  const queryBuilder: GraphqlBuilder<ClientUserDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('clientUsers')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}


export function useGetClientUsersPage(
  mapper: PageableResponse__ClientUserDtoMapper,
  options?: QueryHookOptions<UseGetClientUsersPageData, UseGetClientUsersPageVariables>
): QueryResult<UseGetClientUsersPageData, UseGetClientUsersPageVariables> {
  const queryBuilder: GraphqlBuilder<PageableResponse__ClientUserDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('clientUsersPage')
      .setReturnProperties(mapper)
      .declareVariables({
        search: 'String',
        paging: 'PageableInput!',        
      })
      .setProperties(`search: $search, paging: $paging`)
      .build(),
    options
  );
}

export function useLazyGetClientUsersPage(
  mapper: PageableResponse__ClientUserDtoMapper,
  options?: QueryHookOptions<UseGetClientUsersPageData, UseGetClientUsersPageVariables>
): QueryTuple<UseGetClientUsersPageData, UseGetClientUsersPageVariables> {
  const queryBuilder: GraphqlBuilder<PageableResponse__ClientUserDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('clientUsersPage')
      .setReturnProperties(mapper)
      .declareVariables({
        search: 'String',
        paging: 'PageableInput!',        
      })
      .setProperties(`search: $search, paging: $paging`)
      .build(),
    options
  );
}


export function useGetRoles(
  mapper: KeycloakRoleDtoMapper,
  options?: QueryHookOptions<UseGetRolesData, UseGetRolesVariables>
): QueryResult<UseGetRolesData, UseGetRolesVariables> {
  const queryBuilder: GraphqlBuilder<KeycloakRoleDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('roles')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}

export function useLazyGetRoles(
  mapper: KeycloakRoleDtoMapper,
  options?: QueryHookOptions<UseGetRolesData, UseGetRolesVariables>
): QueryTuple<UseGetRolesData, UseGetRolesVariables> {
  const queryBuilder: GraphqlBuilder<KeycloakRoleDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('roles')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}


export function useGetTeams(
  mapper: TeamDtoMapper,
  options?: QueryHookOptions<UseGetTeamsData, UseGetTeamsVariables>
): QueryResult<UseGetTeamsData, UseGetTeamsVariables> {
  const queryBuilder: GraphqlBuilder<TeamDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('teams')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}

export function useLazyGetTeams(
  mapper: TeamDtoMapper,
  options?: QueryHookOptions<UseGetTeamsData, UseGetTeamsVariables>
): QueryTuple<UseGetTeamsData, UseGetTeamsVariables> {
  const queryBuilder: GraphqlBuilder<TeamDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('teams')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}


export function useGetAllowedClientPaymentMethods(
  mapper: AllowedPaymentMethodMapper,
  options?: QueryHookOptions<UseGetAllowedClientPaymentMethodsData, UseGetAllowedClientPaymentMethodsVariables>
): QueryResult<UseGetAllowedClientPaymentMethodsData, UseGetAllowedClientPaymentMethodsVariables> {
  const queryBuilder: GraphqlBuilder<AllowedPaymentMethodMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('allowedClientPaymentMethods')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}

export function useLazyGetAllowedClientPaymentMethods(
  mapper: AllowedPaymentMethodMapper,
  options?: QueryHookOptions<UseGetAllowedClientPaymentMethodsData, UseGetAllowedClientPaymentMethodsVariables>
): QueryTuple<UseGetAllowedClientPaymentMethodsData, UseGetAllowedClientPaymentMethodsVariables> {
  const queryBuilder: GraphqlBuilder<AllowedPaymentMethodMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('allowedClientPaymentMethods')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}


export function useGetPasswordPolicies(
  mapper: KeycloakPasswordPolicyDtoMapper,
  options?: QueryHookOptions<UseGetPasswordPoliciesData, UseGetPasswordPoliciesVariables>
): QueryResult<UseGetPasswordPoliciesData, UseGetPasswordPoliciesVariables> {
  const queryBuilder: GraphqlBuilder<KeycloakPasswordPolicyDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('passwordPolicies')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}

export function useLazyGetPasswordPolicies(
  mapper: KeycloakPasswordPolicyDtoMapper,
  options?: QueryHookOptions<UseGetPasswordPoliciesData, UseGetPasswordPoliciesVariables>
): QueryTuple<UseGetPasswordPoliciesData, UseGetPasswordPoliciesVariables> {
  const queryBuilder: GraphqlBuilder<KeycloakPasswordPolicyDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('passwordPolicies')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}


export function useGetInvoices(
  mapper: PageableResponse__InvoiceMapper,
  options?: QueryHookOptions<UseGetInvoicesData, UseGetInvoicesVariables>
): QueryResult<UseGetInvoicesData, UseGetInvoicesVariables> {
  const queryBuilder: GraphqlBuilder<PageableResponse__InvoiceMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('invoices')
      .setReturnProperties(mapper)
      .declareVariables({
        paging: 'PageableInput!',        
      })
      .setProperties(`paging: $paging`)
      .build(),
    options
  );
}

export function useLazyGetInvoices(
  mapper: PageableResponse__InvoiceMapper,
  options?: QueryHookOptions<UseGetInvoicesData, UseGetInvoicesVariables>
): QueryTuple<UseGetInvoicesData, UseGetInvoicesVariables> {
  const queryBuilder: GraphqlBuilder<PageableResponse__InvoiceMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('invoices')
      .setReturnProperties(mapper)
      .declareVariables({
        paging: 'PageableInput!',        
      })
      .setProperties(`paging: $paging`)
      .build(),
    options
  );
}


export function useGetScreeningCatalogSingle(
  mapper: CountryProductsDtoMapper,
  options?: QueryHookOptions<UseGetScreeningCatalogSingleData, UseGetScreeningCatalogSingleVariables>
): QueryResult<UseGetScreeningCatalogSingleData, UseGetScreeningCatalogSingleVariables> {
  const queryBuilder: GraphqlBuilder<CountryProductsDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('screeningCatalogSingle')
      .setReturnProperties(mapper)
      .declareVariables({
        countries: '[Long]',        
      })
      .setProperties(`countries: $countries`)
      .build(),
    options
  );
}

export function useLazyGetScreeningCatalogSingle(
  mapper: CountryProductsDtoMapper,
  options?: QueryHookOptions<UseGetScreeningCatalogSingleData, UseGetScreeningCatalogSingleVariables>
): QueryTuple<UseGetScreeningCatalogSingleData, UseGetScreeningCatalogSingleVariables> {
  const queryBuilder: GraphqlBuilder<CountryProductsDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('screeningCatalogSingle')
      .setReturnProperties(mapper)
      .declareVariables({
        countries: '[Long]',        
      })
      .setProperties(`countries: $countries`)
      .build(),
    options
  );
}


export function useGetScreeningCatalogMultiple(
  mapper: CountryProductsDtoMapper,
  options?: QueryHookOptions<UseGetScreeningCatalogMultipleData, UseGetScreeningCatalogMultipleVariables>
): QueryResult<UseGetScreeningCatalogMultipleData, UseGetScreeningCatalogMultipleVariables> {
  const queryBuilder: GraphqlBuilder<CountryProductsDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('screeningCatalogMultiple')
      .setReturnProperties(mapper)
      .declareVariables({
        countries: '[Long]',        
      })
      .setProperties(`countries: $countries`)
      .build(),
    options
  );
}

export function useLazyGetScreeningCatalogMultiple(
  mapper: CountryProductsDtoMapper,
  options?: QueryHookOptions<UseGetScreeningCatalogMultipleData, UseGetScreeningCatalogMultipleVariables>
): QueryTuple<UseGetScreeningCatalogMultipleData, UseGetScreeningCatalogMultipleVariables> {
  const queryBuilder: GraphqlBuilder<CountryProductsDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('screeningCatalogMultiple')
      .setReturnProperties(mapper)
      .declareVariables({
        countries: '[Long]',        
      })
      .setProperties(`countries: $countries`)
      .build(),
    options
  );
}


export function useGetExecutionDetailGetPrice(
  mapper: ExecutionDetailPriceResponseMapper,
  options?: QueryHookOptions<UseGetExecutionDetailGetPriceData, UseGetExecutionDetailGetPriceVariables>
): QueryResult<UseGetExecutionDetailGetPriceData, UseGetExecutionDetailGetPriceVariables> {
  const queryBuilder: GraphqlBuilder<ExecutionDetailPriceResponseMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('executionDetailGetPrice')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}

export function useLazyGetExecutionDetailGetPrice(
  mapper: ExecutionDetailPriceResponseMapper,
  options?: QueryHookOptions<UseGetExecutionDetailGetPriceData, UseGetExecutionDetailGetPriceVariables>
): QueryTuple<UseGetExecutionDetailGetPriceData, UseGetExecutionDetailGetPriceVariables> {
  const queryBuilder: GraphqlBuilder<ExecutionDetailPriceResponseMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('executionDetailGetPrice')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}


export function useGetPrimaryOrderDetail(
  mapper: PrimaryOrderDetailMapper,
  options?: QueryHookOptions<UseGetPrimaryOrderDetailData, UseGetPrimaryOrderDetailVariables>
): QueryResult<UseGetPrimaryOrderDetailData, UseGetPrimaryOrderDetailVariables> {
  const queryBuilder: GraphqlBuilder<PrimaryOrderDetailMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('primaryOrderDetail')
      .setReturnProperties(mapper)
      .declareVariables({
        primaryOrderId: 'Long!',        
      })
      .setProperties(`primaryOrderId: $primaryOrderId`)
      .build(),
    options
  );
}

export function useLazyGetPrimaryOrderDetail(
  mapper: PrimaryOrderDetailMapper,
  options?: QueryHookOptions<UseGetPrimaryOrderDetailData, UseGetPrimaryOrderDetailVariables>
): QueryTuple<UseGetPrimaryOrderDetailData, UseGetPrimaryOrderDetailVariables> {
  const queryBuilder: GraphqlBuilder<PrimaryOrderDetailMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('primaryOrderDetail')
      .setReturnProperties(mapper)
      .declareVariables({
        primaryOrderId: 'Long!',        
      })
      .setProperties(`primaryOrderId: $primaryOrderId`)
      .build(),
    options
  );
}


export function useGetPrimaryOrders(
  mapper: PageableResponse__PrimaryOrderDtoMapper,
  options?: QueryHookOptions<UseGetPrimaryOrdersData, UseGetPrimaryOrdersVariables>
): QueryResult<UseGetPrimaryOrdersData, UseGetPrimaryOrdersVariables> {
  const queryBuilder: GraphqlBuilder<PageableResponse__PrimaryOrderDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('primaryOrders')
      .setReturnProperties(mapper)
      .declareVariables({
        filter: 'PrimaryOrderFilterDto',
        paging: 'PageableInput!',        
      })
      .setProperties(`filter: $filter, paging: $paging`)
      .build(),
    options
  );
}

export function useLazyGetPrimaryOrders(
  mapper: PageableResponse__PrimaryOrderDtoMapper,
  options?: QueryHookOptions<UseGetPrimaryOrdersData, UseGetPrimaryOrdersVariables>
): QueryTuple<UseGetPrimaryOrdersData, UseGetPrimaryOrdersVariables> {
  const queryBuilder: GraphqlBuilder<PageableResponse__PrimaryOrderDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('primaryOrders')
      .setReturnProperties(mapper)
      .declareVariables({
        filter: 'PrimaryOrderFilterDto',
        paging: 'PageableInput!',        
      })
      .setProperties(`filter: $filter, paging: $paging`)
      .build(),
    options
  );
}


export function useGetPrimaryOrdersOverview(
  mapper: PrimaryOrderOverviewDtoMapper,
  options?: QueryHookOptions<UseGetPrimaryOrdersOverviewData, UseGetPrimaryOrdersOverviewVariables>
): QueryResult<UseGetPrimaryOrdersOverviewData, UseGetPrimaryOrdersOverviewVariables> {
  const queryBuilder: GraphqlBuilder<PrimaryOrderOverviewDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('primaryOrdersOverview')
      .setReturnProperties(mapper)
      .declareVariables({
        filters: '[PrimaryOrderOverviewFilter]',        
      })
      .setProperties(`filters: $filters`)
      .build(),
    options
  );
}

export function useLazyGetPrimaryOrdersOverview(
  mapper: PrimaryOrderOverviewDtoMapper,
  options?: QueryHookOptions<UseGetPrimaryOrdersOverviewData, UseGetPrimaryOrdersOverviewVariables>
): QueryTuple<UseGetPrimaryOrdersOverviewData, UseGetPrimaryOrdersOverviewVariables> {
  const queryBuilder: GraphqlBuilder<PrimaryOrderOverviewDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('primaryOrdersOverview')
      .setReturnProperties(mapper)
      .declareVariables({
        filters: '[PrimaryOrderOverviewFilter]',        
      })
      .setProperties(`filters: $filters`)
      .build(),
    options
  );
}


export function useGetScreening(
  mapper: ScreeningMapper,
  options?: QueryHookOptions<UseGetScreeningData, UseGetScreeningVariables>
): QueryResult<UseGetScreeningData, UseGetScreeningVariables> {
  const queryBuilder: GraphqlBuilder<ScreeningMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('screening')
      .setReturnProperties(mapper)
      .declareVariables({
        screeningId: 'Long!',        
      })
      .setProperties(`screeningId: $screeningId`)
      .build(),
    options
  );
}

export function useLazyGetScreening(
  mapper: ScreeningMapper,
  options?: QueryHookOptions<UseGetScreeningData, UseGetScreeningVariables>
): QueryTuple<UseGetScreeningData, UseGetScreeningVariables> {
  const queryBuilder: GraphqlBuilder<ScreeningMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('screening')
      .setReturnProperties(mapper)
      .declareVariables({
        screeningId: 'Long!',        
      })
      .setProperties(`screeningId: $screeningId`)
      .build(),
    options
  );
}


export function useGetCandidateScreening(
  mapper: CandidateScreeningDetailMapper,
  options?: QueryHookOptions<UseGetCandidateScreeningData, UseGetCandidateScreeningVariables>
): QueryResult<UseGetCandidateScreeningData, UseGetCandidateScreeningVariables> {
  const queryBuilder: GraphqlBuilder<CandidateScreeningDetailMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('candidateScreening')
      .setReturnProperties(mapper)
      .declareVariables({
        candidateScreeningId: 'Long!',        
      })
      .setProperties(`candidateScreeningId: $candidateScreeningId`)
      .build(),
    options
  );
}

export function useLazyGetCandidateScreening(
  mapper: CandidateScreeningDetailMapper,
  options?: QueryHookOptions<UseGetCandidateScreeningData, UseGetCandidateScreeningVariables>
): QueryTuple<UseGetCandidateScreeningData, UseGetCandidateScreeningVariables> {
  const queryBuilder: GraphqlBuilder<CandidateScreeningDetailMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('candidateScreening')
      .setReturnProperties(mapper)
      .declareVariables({
        candidateScreeningId: 'Long!',        
      })
      .setProperties(`candidateScreeningId: $candidateScreeningId`)
      .build(),
    options
  );
}


export function useGetCandidateScreenings(
  mapper: CandidateScreeningMapper,
  options?: QueryHookOptions<UseGetCandidateScreeningsData, UseGetCandidateScreeningsVariables>
): QueryResult<UseGetCandidateScreeningsData, UseGetCandidateScreeningsVariables> {
  const queryBuilder: GraphqlBuilder<CandidateScreeningMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('candidateScreenings')
      .setReturnProperties(mapper)
      .declareVariables({
        primaryOrderId: 'Long!',        
      })
      .setProperties(`primaryOrderId: $primaryOrderId`)
      .build(),
    options
  );
}

export function useLazyGetCandidateScreenings(
  mapper: CandidateScreeningMapper,
  options?: QueryHookOptions<UseGetCandidateScreeningsData, UseGetCandidateScreeningsVariables>
): QueryTuple<UseGetCandidateScreeningsData, UseGetCandidateScreeningsVariables> {
  const queryBuilder: GraphqlBuilder<CandidateScreeningMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('candidateScreenings')
      .setReturnProperties(mapper)
      .declareVariables({
        primaryOrderId: 'Long!',        
      })
      .setProperties(`primaryOrderId: $primaryOrderId`)
      .build(),
    options
  );
}


export function useGetComponentNotes(
  mapper: ComponentNoteDetailMapper,
  options?: QueryHookOptions<UseGetComponentNotesData, UseGetComponentNotesVariables>
): QueryResult<UseGetComponentNotesData, UseGetComponentNotesVariables> {
  const queryBuilder: GraphqlBuilder<ComponentNoteDetailMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('componentNotes')
      .setReturnProperties(mapper)
      .declareVariables({
        candidateScreeningId: 'Long',        
      })
      .setProperties(`candidateScreeningId: $candidateScreeningId`)
      .build(),
    options
  );
}

export function useLazyGetComponentNotes(
  mapper: ComponentNoteDetailMapper,
  options?: QueryHookOptions<UseGetComponentNotesData, UseGetComponentNotesVariables>
): QueryTuple<UseGetComponentNotesData, UseGetComponentNotesVariables> {
  const queryBuilder: GraphqlBuilder<ComponentNoteDetailMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('componentNotes')
      .setReturnProperties(mapper)
      .declareVariables({
        candidateScreeningId: 'Long',        
      })
      .setProperties(`candidateScreeningId: $candidateScreeningId`)
      .build(),
    options
  );
}


export function useGetResourceDatabaseItems(
  mapper: ResourceDatabaseItemMapper,
  options?: QueryHookOptions<UseGetResourceDatabaseItemsData, UseGetResourceDatabaseItemsVariables>
): QueryResult<UseGetResourceDatabaseItemsData, UseGetResourceDatabaseItemsVariables> {
  const queryBuilder: GraphqlBuilder<ResourceDatabaseItemMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('resourceDatabaseItems')
      .setReturnProperties(mapper)
      .declareVariables({
        sectionName: 'String!',
        countryId: 'Long',
        search: 'String',        
      })
      .setProperties(`sectionName: $sectionName, countryId: $countryId, search: $search`)
      .build(),
    options
  );
}

export function useLazyGetResourceDatabaseItems(
  mapper: ResourceDatabaseItemMapper,
  options?: QueryHookOptions<UseGetResourceDatabaseItemsData, UseGetResourceDatabaseItemsVariables>
): QueryTuple<UseGetResourceDatabaseItemsData, UseGetResourceDatabaseItemsVariables> {
  const queryBuilder: GraphqlBuilder<ResourceDatabaseItemMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('resourceDatabaseItems')
      .setReturnProperties(mapper)
      .declareVariables({
        sectionName: 'String!',
        countryId: 'Long',
        search: 'String',        
      })
      .setProperties(`sectionName: $sectionName, countryId: $countryId, search: $search`)
      .build(),
    options
  );
}


export function useGetGetResourceDatabaseItem(
  mapper: ResourceDatabaseItemMapper,
  options?: QueryHookOptions<UseGetGetResourceDatabaseItemData, UseGetGetResourceDatabaseItemVariables>
): QueryResult<UseGetGetResourceDatabaseItemData, UseGetGetResourceDatabaseItemVariables> {
  const queryBuilder: GraphqlBuilder<ResourceDatabaseItemMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('getResourceDatabaseItem')
      .setReturnProperties(mapper)
      .declareVariables({
        id: 'Long!',        
      })
      .setProperties(`id: $id`)
      .build(),
    options
  );
}

export function useLazyGetGetResourceDatabaseItem(
  mapper: ResourceDatabaseItemMapper,
  options?: QueryHookOptions<UseGetGetResourceDatabaseItemData, UseGetGetResourceDatabaseItemVariables>
): QueryTuple<UseGetGetResourceDatabaseItemData, UseGetGetResourceDatabaseItemVariables> {
  const queryBuilder: GraphqlBuilder<ResourceDatabaseItemMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('getResourceDatabaseItem')
      .setReturnProperties(mapper)
      .declareVariables({
        id: 'Long!',        
      })
      .setProperties(`id: $id`)
      .build(),
    options
  );
}


export function useGetGetFields(
  mapper: FormFieldDtoMapper,
  options?: QueryHookOptions<UseGetGetFieldsData, UseGetGetFieldsVariables>
): QueryResult<UseGetGetFieldsData, UseGetGetFieldsVariables> {
  const queryBuilder: GraphqlBuilder<FormFieldDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('getFields')
      .setReturnProperties(mapper)
      .declareVariables({
        candidateScreeningDetailId: 'Long!',
        fieldIds: '[String]',        
      })
      .setProperties(`candidateScreeningDetailId: $candidateScreeningDetailId, fieldIds: $fieldIds`)
      .build(),
    options
  );
}

export function useLazyGetGetFields(
  mapper: FormFieldDtoMapper,
  options?: QueryHookOptions<UseGetGetFieldsData, UseGetGetFieldsVariables>
): QueryTuple<UseGetGetFieldsData, UseGetGetFieldsVariables> {
  const queryBuilder: GraphqlBuilder<FormFieldDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('getFields')
      .setReturnProperties(mapper)
      .declareVariables({
        candidateScreeningDetailId: 'Long!',
        fieldIds: '[String]',        
      })
      .setProperties(`candidateScreeningDetailId: $candidateScreeningDetailId, fieldIds: $fieldIds`)
      .build(),
    options
  );
}


export function useGetContact(
  mapper: ContactDtoMapper,
  options?: QueryHookOptions<UseGetContactData, UseGetContactVariables>
): QueryResult<UseGetContactData, UseGetContactVariables> {
  const queryBuilder: GraphqlBuilder<ContactDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('contact')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}

export function useLazyGetContact(
  mapper: ContactDtoMapper,
  options?: QueryHookOptions<UseGetContactData, UseGetContactVariables>
): QueryTuple<UseGetContactData, UseGetContactVariables> {
  const queryBuilder: GraphqlBuilder<ContactDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('contact')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}


export function useGetFaqs(
  mapper: FAQMapper,
  options?: QueryHookOptions<UseGetFaqsData, UseGetFaqsVariables>
): QueryResult<UseGetFaqsData, UseGetFaqsVariables> {
  const queryBuilder: GraphqlBuilder<FAQMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('faqs')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}

export function useLazyGetFaqs(
  mapper: FAQMapper,
  options?: QueryHookOptions<UseGetFaqsData, UseGetFaqsVariables>
): QueryTuple<UseGetFaqsData, UseGetFaqsVariables> {
  const queryBuilder: GraphqlBuilder<FAQMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('faqs')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}


export function useGetGetFilesInfo(
  mapper: FileInfoMapper,
  options?: QueryHookOptions<UseGetGetFilesInfoData, UseGetGetFilesInfoVariables>
): QueryResult<UseGetGetFilesInfoData, UseGetGetFilesInfoVariables> {
  const queryBuilder: GraphqlBuilder<FileInfoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('getFilesInfo')
      .setReturnProperties(mapper)
      .declareVariables({
        ids: '[Long]',        
      })
      .setProperties(`ids: $ids`)
      .build(),
    options
  );
}

export function useLazyGetGetFilesInfo(
  mapper: FileInfoMapper,
  options?: QueryHookOptions<UseGetGetFilesInfoData, UseGetGetFilesInfoVariables>
): QueryTuple<UseGetGetFilesInfoData, UseGetGetFilesInfoVariables> {
  const queryBuilder: GraphqlBuilder<FileInfoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('getFilesInfo')
      .setReturnProperties(mapper)
      .declareVariables({
        ids: '[Long]',        
      })
      .setProperties(`ids: $ids`)
      .build(),
    options
  );
}


export function useGetGetUploadedFilesInfo(
  mapper: FileInfoMapper,
  options?: QueryHookOptions<UseGetGetUploadedFilesInfoData, UseGetGetUploadedFilesInfoVariables>
): QueryResult<UseGetGetUploadedFilesInfoData, UseGetGetUploadedFilesInfoVariables> {
  const queryBuilder: GraphqlBuilder<FileInfoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('getUploadedFilesInfo')
      .setReturnProperties(mapper)
      .declareVariables({
        fileUploadId: 'String!',        
      })
      .setProperties(`fileUploadId: $fileUploadId`)
      .build(),
    options
  );
}

export function useLazyGetGetUploadedFilesInfo(
  mapper: FileInfoMapper,
  options?: QueryHookOptions<UseGetGetUploadedFilesInfoData, UseGetGetUploadedFilesInfoVariables>
): QueryTuple<UseGetGetUploadedFilesInfoData, UseGetGetUploadedFilesInfoVariables> {
  const queryBuilder: GraphqlBuilder<FileInfoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('getUploadedFilesInfo')
      .setReturnProperties(mapper)
      .declareVariables({
        fileUploadId: 'String!',        
      })
      .setProperties(`fileUploadId: $fileUploadId`)
      .build(),
    options
  );
}


