import React, { useEffect, useMemo, useState } from 'react';
import {
  Button, Input, InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
} from '@chakra-ui/react';
import { ProductCard, Translate } from '@scaut-sro/meepo';
import { FiSearch } from 'react-icons/fi';
import { Index } from 'flexsearch';
import { AddCountryModalProps } from './Catalogue.model';
import CatalogueLocale from './Catalogue.locale';
import { Country } from '../../build/generated-sources/dto/Country';
import { useGetUser } from '../../core/store/reducers/UserSettingsReducer';
import { translate } from '../../core/localization/LocaleUtils';

const OpenModal = ({
  onClose, isOpen, countries, allowedCountries, changeCountriesHandler,
}: AddCountryModalProps) => {
  const { language } = useGetUser();
  const [selectedCountries, setSelectedCountries] = useState<Country[]>([]);
  const [sortedAllowedCountries, setSortedAllowedCountries] = useState<Country[]>([]);
  const [filteredCountries, setFilteredCountries] = useState<Country[]>([]);

  useEffect(() => {
    const ac = [...allowedCountries];
    ac.sort((a, b) => (a.translation?.localizationStrings?.[language] || '')
      .localeCompare((b.translation?.localizationStrings?.[language] || '')));
    setSortedAllowedCountries(ac);
    setFilteredCountries(ac);
  }, [allowedCountries, setSortedAllowedCountries, setFilteredCountries, language]);

  useEffect(() => {
    setSelectedCountries(countries);
  }, [countries, setSelectedCountries]);

  const handleCountryCheck = (isChecked: boolean, id: number) => {
    const newSelectedCountries = allowedCountries.filter((ac) => {
      if (ac.id === id && isChecked) return true;
      if (ac.id === id && !isChecked) return false;
      return selectedCountries.find((sc) => sc.id === ac.id);
    });
    setSelectedCountries(newSelectedCountries);
  };

  // @ts-ignore
  const index: Index = useMemo(() => new Index({ tokenize: 'full' }), []);

  useEffect(() => {
    sortedAllowedCountries.forEach((c) => {
      index.add(c.id || -1, c.translation?.localizationStrings?.[language] || '');
    });
  }, [sortedAllowedCountries, index, language]);

  const filterCountries = (fulltext: string) => {
    if (fulltext && fulltext.length) {
      const searchResult = index.search(fulltext);
      const newFilteredCountries: Country[] = sortedAllowedCountries.filter((c) => searchResult.indexOf(c.id || -1) !== -1);
      setFilteredCountries(newFilteredCountries);
    } else {
      setFilteredCountries(sortedAllowedCountries);
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign="center"><Translate label={CatalogueLocale.CHOOSE_COUNTRY} /></ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <InputGroup
              mb={6}
              size="lg"
            >
              <InputLeftElement>
                <FiSearch />
              </InputLeftElement>
              <Input
                bg="#F4F5F7"
                variant="filled"
                w="100%"
                placeholder={translate(CatalogueLocale.SEARCH_COUNTRY, language)}
                _placeholder={{ color: '#6B6C7A' }}
                fontSize="lg"
                autoFocus
                onChange={(event) => {
                  filterCountries(event.currentTarget.value);
                }}
              />
            </InputGroup>

            <SimpleGrid spacing={1} mb={8}>
              {filteredCountries.map((country) => (
                <ProductCard
                  label={country.translation?.localizationStrings?.[language] || 'Undefined'}
                  key={country.id}
                  id={country.id || -1}
                  isChecked={!!(selectedCountries.find((sc) => country.id === sc.id))}
                  onChange={handleCountryCheck}
                />
              ))}
            </SimpleGrid>
          </ModalBody>

          <ModalFooter>
            <Button
              mr={3}
              isDisabled={!(selectedCountries.length)}
              onClick={() => {
                changeCountriesHandler(selectedCountries);
                onClose();
              }}
            >
              <Translate label={CatalogueLocale.CONFIRM_MODAL} />
            </Button>
            <Button variant="ghost" onClick={onClose}>
              <Translate label={CatalogueLocale.CANCEL_MODAL} />
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default OpenModal;
