import React from 'react';
import {
  Box, Flex, HStack, Spacer, Text,
} from '@chakra-ui/react';
import { Currency, getCurrencyLocalizationFromLanguage } from '@scaut-sro/meepo';
import { TotalPriceProps } from './TotalPrice. model';
import Translate from '../Translate/Translate';
import TotalPriceLocale from './TotalPrice.locale';
import { useGetUser } from '../../core/store/reducers/UserSettingsReducer';

// TODO: currency should be taken from redux - client setup

const TotalPrice: React.FunctionComponent<TotalPriceProps> = (props) => {
  const {
    price, isMultipleCheck, numberOfPersons,
  } = props;
  const { language } = useGetUser();

  return (
    <Flex alignItems="flex-end" flexDirection="column" color="#2c2d41">
      {isMultipleCheck && (
        <>
          <Flex w="100%" justifyContent="flex-end">
            <Box>
              <Text fontSize="lg">
                <Translate label={TotalPriceLocale.NUMBER} />
              </Text>
            </Box>
            <Flex w="244px">
              <Spacer mb={2} borderBottom="1px dashed #2c2d41" />
              <Flex>
                <Box fontSize="x-large">
                  {numberOfPersons || 0}
                </Box>
                <Box fontSize="lg" ml={2}>
                  <Translate label={TotalPriceLocale.PERSONS} />
                </Box>
              </Flex>
            </Flex>
          </Flex>
          <Flex w="100%" justifyContent="flex-end">
            <Box>
              <Text fontSize="lg">
                <Translate label={TotalPriceLocale.PRICE_PER_PERSON} />
              </Text>
            </Box>
            <Flex w="244px">
              <Spacer borderBottom="1px dashed #2c2d41" />
              <Box>
                <Currency
                  amount={price / (numberOfPersons || 1)}
                  localization={getCurrencyLocalizationFromLanguage(language)}
                  isEditableCurrency
                  currencyBoxProps={{ fontSize: '16px' }}
                  amountBoxProps={{ fontSize: '22px', lineHeight: '22px' }}
                />
              </Box>
            </Flex>
          </Flex>
        </>
      )}
      <HStack p="32px 0" width="300px" alignItems="start">
        <Box>
          <Text fontSize="lg">
            <Translate label={TotalPriceLocale.TOTAL_PRICE} />
          </Text>
        </Box>
        <Spacer />
        <Box>
          <Currency
            amount={price}
            localization={getCurrencyLocalizationFromLanguage(language)}
            isEditableCurrency
            currencyBoxProps={{ fontSize: '16px', alignItems: 'flex-start' }}
            amountBoxProps={{ fontSize: '42px', lineHeight: '42px' }}
          />
        </Box>
      </HStack>
    </Flex>
  );
};

export default TotalPrice;
