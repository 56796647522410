import React from 'react';
import { Button, Text, useDisclosure } from '@chakra-ui/react';
import { LeftMenuItem, ScautIcon } from '@scaut-sro/meepo';
import Translate from '../../components/Translate/Translate';
import MainMenuLocale from '../../core/localization/translations/MainMenu.locale';
import { MainMenuProps } from '../../components/MainMenu/MainMenu.model';
import SupportModalDetail from './SupportModalDetail';

const SupportModal: React.FunctionComponent<MainMenuProps> = (props) => {
  const {
    leftMenuWidth, headerHeight, activeItem,
  } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button variant="link" onClick={onOpen}>
        <LeftMenuItem
          activeItem={activeItem}
          itemId="support"
          headerHeight={headerHeight}
          leftMenuWidth={leftMenuWidth}
          icon={<Text stroke="#5E6C7A" fill="#5E6C7A"><ScautIcon icon="SiSupport" size="2xl" /></Text>}
        >
          <Text color="#5E6C7A" fontSize="lg" fontWeight="medium"><Translate label={MainMenuLocale.SUPPORT} /></Text>
        </LeftMenuItem>
      </Button>
      <SupportModalDetail
        onClose={onClose}
        isOpen={isOpen}
      />
    </>
  );
};

export default SupportModal;
