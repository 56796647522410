import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { NewCheckReducerState, NewCheckStepsType } from './NewCheckReducer.model';
import { OrderType } from '../../../build/generated-sources/enum/OrderType';
import { ProductFormAttr } from '../../../views/NewCheck/ProductForm/ProductForm.model';
import { CandidateFormAttr } from '../../../views/NewCheck/CandidateForm/CandidateForm.model';

const initialState: NewCheckReducerState = {
  currentStep: 1,
  definitions: [],
  packages: [],
  inviteCandidate: false,
  orderType: OrderType.SINGLE,
  candidates: [],
  assets: [],
  price: 0,
};

const newCheckSlice = createSlice({
  name: 'newCheck',
  initialState,
  reducers: {
    setFirstStep(state, action: PayloadAction<CandidateFormAttr>) {
      state.candidates = action.payload.candidates;
      state.orderName = action.payload.orderName;
      state.orderCustomId = action.payload.orderCustomId;
      state.orderType = action.payload.orderType;
      state.inviteCandidate = action.payload.inviteCandidate;
    },
    setCurrentStep(state, action: PayloadAction<NewCheckStepsType>) {
      state.currentStep = action.payload || 1;
    },
    setPrice(state, action: PayloadAction<number>) {
      state.price = action.payload || 0;
    },
    setSecondStep(state, action: PayloadAction<ProductFormAttr>) {
      state.packages = action.payload.packages;
      state.definitions = action.payload.definitions;
    },
    setDefaultValues(state) {
      state.currentStep = 1;
      state.definitions = [];
      state.packages = [];
      state.inviteCandidate = false;
      state.orderType = OrderType.SINGLE;
      state.candidates = [];
      state.assets = [];
      state.price = 0;
      state.orderName = undefined;
      state.orderCustomId = undefined;
      state.orderId = undefined;
    },
    setOrderId(state, action: PayloadAction<number | undefined>) {
      state.orderId = action.payload;
    },
  },
});

export function useGetNewCheck(): NewCheckReducerState {
  return useSelector((state: any) => state.newCheck);
}

export const useNewCheckReduce = () => {
  const dispatch = useDispatch();
  return {
    setFirstStep(candidateForm: CandidateFormAttr) {
      dispatch(newCheckSlice.actions.setFirstStep(candidateForm));
    },
    setCurrentStep(step: NewCheckStepsType) {
      dispatch(newCheckSlice.actions.setCurrentStep(step));
    },
    setPrice(price: number) {
      dispatch(newCheckSlice.actions.setPrice(price));
    },
    setSecondStep(productForm: ProductFormAttr) {
      dispatch(newCheckSlice.actions.setSecondStep(productForm));
    },
    setDefaultValues() {
      dispatch(newCheckSlice.actions.setDefaultValues());
    },
    setOrderId(id: number | undefined) {
      dispatch(newCheckSlice.actions.setOrderId(id));
    },
  };
};

export default newCheckSlice.reducer;
