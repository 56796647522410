import React from 'react';
import {
  LeftMenu, LeftMenuItem, ScautIcon,
} from '@scaut-sro/meepo';
import { Flex, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { MainMenuProps } from './MainMenu.model';
import Translate from '../Translate/Translate';
import MainMenuLocale from '../../core/localization/translations/MainMenu.locale';
import SupportModal from '../../views/Support/SupportModal';

const MainMenu: React.FunctionComponent<MainMenuProps> = (props) => {
  const {
    menuIsSmall, leftMenuWidth, headerHeight, activeItem, handleSetActiveItem,
  } = props;

  return (
    <LeftMenu
      leftMenuWidth={leftMenuWidth}
      headerHeight={headerHeight}
      isMenuSmall={menuIsSmall}
    >
      <Flex
        flexDir="column"
        justifyContent="space-between"
        h={`calc( 100vh - ${headerHeight} )`}
        pb={8}
        pt={3}
      >
        <Flex
          flexDir="column"
        >
          <div style={{ marginBottom: '12px', marginTop: '2px' }}>
            <Link to="/new-check">
              <LeftMenuItem
                activeItem={activeItem}
                handleActiveItemChange={handleSetActiveItem}
                itemId="newCheck"
                headerHeight={headerHeight}
                icon={<Text fill="#0066F8" stroke="#0066F8"><ScautIcon icon="SiPlus" size="3xl" /></Text>}
                leftMenuWidth={leftMenuWidth}
                asButton
              >
                <Text fontWeight="bold"><Translate label={MainMenuLocale.NEW_CHECK} /></Text>
              </LeftMenuItem>
            </Link>
          </div>
          <Link to="/">
            <LeftMenuItem
              activeItem={activeItem}
              handleActiveItemChange={handleSetActiveItem}
              itemId="overview"
              headerHeight={headerHeight}
              leftMenuWidth={leftMenuWidth}
              icon={<ScautIcon icon="SiHome" size="xl" />}
            >
              <Text fontWeight="medium"><Translate label={MainMenuLocale.OVERVIEW} /></Text>
            </LeftMenuItem>
          </Link>
          <Link to="/single-checks">
            <LeftMenuItem
              activeItem={activeItem}
              handleActiveItemChange={handleSetActiveItem}
              itemId="single-checks"
              headerHeight={headerHeight}
              leftMenuWidth={leftMenuWidth}
              icon={<ScautIcon icon="SiSingleCheck" size="xl" />}
            >
              <Text fontWeight="medium"><Translate label={MainMenuLocale.SINGLE_ORDERS} /></Text>
            </LeftMenuItem>
          </Link>
          <Link to="/multiple-checks">
            <LeftMenuItem
              activeItem={activeItem}
              handleActiveItemChange={handleSetActiveItem}
              itemId="multiple-checks"
              headerHeight={headerHeight}
              leftMenuWidth={leftMenuWidth}
              icon={<ScautIcon icon="SiMultipleCheck" size="xl" />}
            >
              <Text fontWeight="medium"><Translate label={MainMenuLocale.MULTIPLE_ORDERS} /></Text>
            </LeftMenuItem>
          </Link>
          {/* <Link to="/shared-with-me"> */}
          {/*  <LeftMenuItem */}
          {/*    activeItem={activeItem} */}
          {/*    handleActiveItemChange={handleSetActiveItem} */}
          {/*    itemId="shared-with-me" */}
          {/*    headerHeight={headerHeight} */}
          {/*    leftMenuWidth={leftMenuWidth} */}
          {/*    icon={<ScautIcon icon="SiSharedWithMe" />} */}
          {/*  > */}
          {/*    <Translate label={MainMenuLocale.ORDERS} /> */}
          {/*  </LeftMenuItem> */}
          {/* </Link> */}
          {/* <Link to="/shared-by-me"> */}
          {/*  <LeftMenuItem */}
          {/*    activeItem={activeItem} */}
          {/*    handleActiveItemChange={handleSetActiveItem} */}
          {/*    itemId="shared-by-me" */}
          {/*    headerHeight={headerHeight} */}
          {/*    leftMenuWidth={leftMenuWidth} */}
          {/*    icon={<ScautIcon icon="SiSharedByMe" />} */}
          {/*  > */}
          {/*    <Translate label={MainMenuLocale.ORDERS} /> */}
          {/*  </LeftMenuItem> */}
          {/* </Link> */}
        </Flex>
        <div>
          <Flex
            flexDir="column"
          >
            <SupportModal
              activeItem={activeItem}
              headerHeight={headerHeight}
              leftMenuWidth={leftMenuWidth}
            />
          </Flex>
        </div>
      </Flex>
    </LeftMenu>
  );
};

export default MainMenu;
