import { Translation } from '../../../core/localization/Localization.model';

class InvoicesLocale {
  public static INVOICE_INFORMATION: Translation = { EN: 'Invoice information', CZ: 'Fakturační údaje' };
  public static INVOICE: Translation = { EN: 'Invoice ', CZ: 'Faktura ' };
  public static SAVE_CHANGES: Translation = { EN: 'Save changes', CZ: 'Uložit změny' };
  public static NAME: Translation = { EN: 'Name', CZ: 'Název' };
  public static ID: Translation = { EN: 'ID', CZ: 'IČO' };
  public static VAT: Translation = { EN: 'VAT NR.', CZ: 'DIČ' };
  public static COUNTRY: Translation = { EN: 'Country', CZ: 'Země' };
  public static STREET: Translation = { EN: 'Street', CZ: 'Ulice' };
  public static CITY: Translation = { EN: 'City', CZ: 'Město' };
  public static ZIP: Translation = { EN: 'ZIP', CZ: 'PSČ' };
  public static INVOICE_LIST: Translation = { EN: 'Invoice overview', CZ: 'Přehled faktur' };
  public static INVOICE_NUMBER: Translation = { EN: 'Invoice number', CZ: 'Číslo faktury' };
  public static DATE: Translation = { EN: 'Date', CZ: 'Datum' };
  public static STATE: Translation = { EN: 'State', CZ: 'Stav' };
  public static AMOUNT: Translation = { EN: 'Amount', CZ: 'Částka' };
  public static DOWNLOAD_INVOICE: Translation = { EN: 'Download invoice', CZ: 'Stáhnout PDF' };
  public static ERROR_GETTING_CLIENT_DATA: Translation = {
    EN: 'There was a problem getting client data.',
    CZ: 'Při získávání klientských dat došlo k problému.',
  };
  public static ERROR_GETTING_DATA: Translation = {
    EN: 'There was a problem getting data',
    CZ: 'Při získávání dat došlo k problému',
  };
}

export default InvoicesLocale;
