import React, { useEffect, useState } from 'react';
import { MeepoTable, SectionContainer } from '@scaut-sro/meepo';
import {
  Box, Button, Flex, Text,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { PageInfo } from '@scaut-sro/meepo/lib/components/MeepoTable/MeepoTable.model';
import TagManager from 'react-gtm-module';
import {
  ChecksAllFilterType, ChecksProps, ChecksTableData, ChecksTableFilterType,
} from './Checks.model';
import ChecksTableFilter from './ChecksTableFilter';
import { useGetPrimaryOrders } from '../../build/generated-sources/service/QueryService';
import { translate } from '../../core/localization/LocaleUtils';
import ChecksLocale from './Checks.locale';
import { useGetUser } from '../../core/store/reducers/UserSettingsReducer';
import TableLocale from '../../core/localization/translations/Table.locale';
import Translate from '../../components/Translate/Translate';
import { OrderType } from '../../build/generated-sources/enum/OrderType';
import { ResultBadge } from '../../components/ResultBadge/ResultBadge';
import { StateBadge } from '../../components/StateBadge/StateBadge';
import SingleCheckDetail from './SingleCheckDetail';
import UserIcon from './UserIcon';
import ShowDate from '../../components/ShowDate/ShowDate';
import { CandidateScreening } from '../../build/generated-sources/dto/CandidateScreening';
import UnfinishedOrderCheckDetail from './UnfinishedOrderCheckDetail';
import MultipleCheckDetail from './MultipleCheckDetail';
import { Direction } from '../../build/generated-sources/enum/Direction';
import { PrimaryOrderDto } from '../../build/generated-sources/dto/PrimaryOrderDto';
import { handleApolloErrors } from '../../core/error/ScautErrorHandler';
import { OrderState } from '../../build/generated-sources/enum/OrderState';

export function getChecksTableData(
  toDetail: (id: number, route: string) => void, data?: PrimaryOrderDto[], simpleView?: boolean,
): ChecksTableData[] {
  if (data) {
    return data.map((dto) => {
      const firstCandidateScreening: CandidateScreening | undefined = dto.order?.subOrders?.[0].candidateScreenings?.[0];
      return {
        id: dto.order?.id || '-undefined-',
        detail: simpleView ? undefined : (
          firstCandidateScreening
            ? dto.order?.type === OrderType.SINGLE
              ? <SingleCheckDetail candidateScreening={firstCandidateScreening} anonymizationDate={dto.anonymizationDate} />
              : (
                <MultipleCheckDetail
                  order={dto.order}
                  candidateScreening={firstCandidateScreening}
                  anonymizationDate={dto.anonymizationDate}
                />
              )
            : <UnfinishedOrderCheckDetail order={dto.order} />
        ),
        type: <UserIcon type={dto.order?.type || OrderType.SINGLE} />,
        name: dto.order?.name,
        clientOrderCustomId: dto.order?.clientOrderCustomId,
        created: <ShowDate date={dto.order?.created} time />,
        orderedBy: `${dto.order?.clientUser?.firstName} ${dto.order?.clientUser?.lastName}`,
        result: <ResultBadge result={dto.order?.result || undefined} />,
        orderState: <StateBadge state={dto.order?.orderState || undefined} />,
        buttons: (
          <Flex justifyContent="center">
            <Button
              variant="ghost"
              size="sm"
              bg="#E5EFFE"
              onClick={() => {
                if (simpleView) {
                  toDetail(dto?.order?.id || -1, 'order');
                } else {
                  toDetail(
                    dto.order?.orderState === OrderState.DRAFT ? dto.order?.id || -1
                      : (dto.order?.type === OrderType.SINGLE ? firstCandidateScreening?.id || -1
                        : dto.order?.id || -1),
                    dto.order?.orderState === OrderState.DRAFT ? 'order'
                      : (dto.order?.type === OrderType.SINGLE ? 'screening' : 'multicheck'),
                  );
                }
              }}
            >
              Detail
            </Button>
          </Flex>
        ),
      };
    });
  }
  return [];
}

const Checks: React.FunctionComponent<ChecksProps> = (props) => {
  const [tableFilter, setTableFilter] = useState<ChecksTableFilterType>({});
  const [allFilters, setAllFilter] = useState<ChecksAllFilterType>({});
  const { filters, simpleView } = props;
  const { language, userProfile } = useGetUser();
  const [pageInfo, setPageInfo] = useState<PageInfo>({ pageNumber: 0, pageSize: 10, totalPages: 0 });
  const [sorting, setSorting] = useState<{ sortBy?: string, sortDirection?: Direction }>({});
  const [list, setList] = useState<ChecksTableData[]>([]);
  const history = useHistory();

  const routeToDetail = (id: number, route: string) => {
    history.push(`/${route}/${id}`);
  };

  const { loading } = useGetPrimaryOrders(
    {
      pageInfo: {
        pageSize: true,
        pageNumber: true,
        numberOfElements: true,
        totalElements: true,
        totalPages: true,
      },
      content: {
        anonymizationDate: true,
        order: {
          id: true,
          name: true,
          type: true,
          clientUser: {
            firstName: true,
            lastName: true,
          },
          processStarted: true,
          processCompleted: true,
          clientOrderCustomId: true,
          orderState: true,
          result: true,
          created: true,
          subOrders: {
            candidateScreenings: {
              id: true,
              processStarted: true,
              processCompleted: true,
              result: true,
              screenings: {
                id: true,
                result: true,
                screeningDefinition: {
                  id: true,
                  name: {
                    localizationStrings: true,
                  },
                },
              },
            },
          },
        },
      },
    }, {
      onError: (error) => {
        handleApolloErrors(error, language);
      },
      variables: {
        paging: {
          page: pageInfo.pageNumber,
          size: pageInfo.pageSize,
          sortBy: sorting.sortBy,
          sortDirection: sorting.sortDirection,
        },
        filter: {
          search: allFilters.fulltext && allFilters.fulltext.length ? allFilters.fulltext : undefined,
          name: allFilters.name && allFilters.name.length ? allFilters.name : undefined,
          clientOrderCustomId: allFilters.clientOrderCustomId && allFilters.clientOrderCustomId.length
            ? allFilters.clientOrderCustomId : undefined,
          createdFrom: allFilters.orderedFrom && allFilters.orderedFrom.length
            ? allFilters.orderedFrom : undefined,
          createdTo: allFilters.orderedTo && allFilters.orderedTo.length ? allFilters.orderedTo : undefined,
          results: allFilters.result && allFilters.result.length ? allFilters.result : undefined,
          states: allFilters.orderState && allFilters.orderState.length ? allFilters.orderState : undefined,
          types: allFilters.type && allFilters.type.length ? allFilters.type : undefined,
          dateOfBirth: allFilters.dateOfBirth?.length ? allFilters.dateOfBirth : undefined,
          clientUserIds: allFilters.clientUserIds && allFilters.clientUserIds?.length ? allFilters.clientUserIds : undefined,
        },
      },
      fetchPolicy: 'cache-and-network',
      pollInterval: 5000,
      onCompleted: (response) => {
        const onCompleteData = getChecksTableData(routeToDetail, response.primaryOrders.content, simpleView);
        setList(onCompleteData);
        setPageInfo({
          pageSize: response.primaryOrders.pageInfo?.pageSize,
          pageNumber: response.primaryOrders.pageInfo?.pageNumber || 0,
          totalPages: response.primaryOrders.pageInfo?.totalPages || 0,
          numberOfElements: response.primaryOrders.pageInfo?.numberOfElements,
          totalElements: response.primaryOrders.pageInfo?.totalElements,
        });
      },
    },
  );

  useEffect(() => {
    const newAllFilters: ChecksAllFilterType = {
      ...tableFilter,
      ...filters,
    };
    setAllFilter(newAllFilters);
  }, [filters, tableFilter, setAllFilter]);

  TagManager.dataLayer({
    dataLayer: {
      event: 'page_view',
      realm: 'client',
      page: {
        url: document.location.origin + document.location.pathname + document.location.search,
        title: 'Checks - list',
      },
      user: {
        email: userProfile?.email,
        given_name: userProfile?.firstName,
        family_name: userProfile?.lastName,
        job_position: userProfile?.jobPosition,
        language,
      },
    },
  });

  return (
    <SectionContainer>
      <Box mb={10}>
        <ChecksTableFilter
          filter={tableFilter}
          onFilterChange={(newTableFilter) => {
            setTableFilter(newTableFilter);
          }}
        />
      </Box>
      <Box overflowX="auto">
        <Box minW="500px">
          <MeepoTable
            columns={[
              { id: 'type' },
              { id: 'name', label: translate(ChecksLocale.LABEL, language) },
              { id: 'clientOrderCustomId', label: translate(ChecksLocale.CUSTOM_ID, language) },
              { id: 'created', label: translate(ChecksLocale.ORDER_CREATED, language) },
              {
                id: 'orderedBy',
                label: translate(ChecksLocale.ORDERED_BY, language),
                alignLabel: 'center',
                sortBy: 'clientUser.firstName',
                boxProps: {
                  w: '220px',
                },
                alignData: 'center',
              },
              {
                id: 'orderState',
                label: translate(ChecksLocale.ORDER_STATE, language),
                boxProps: {
                  w: '140px',
                },
              },
              {
                id: 'result',
                label: translate(ChecksLocale.ORDER_RESULT, language),
                boxProps: {
                  w: 24,
                },
              },
              {
                id: 'buttons',
                boxProps: {
                  w: 24,
                },
              },
            ]}
            data={list}
            isLoading={loading}
            noData={<Text><Translate label={TableLocale.NO_DATA} /></Text>}
            pageInfo={pageInfo}
            setPage={(newPageNumber, newPageSize) => {
              setPageInfo({
                ...pageInfo,
                pageNumber: newPageNumber,
                pageSize: newPageSize,
              });
            }}
            onSort={(newSorting) => {
              const direction: Direction | undefined = newSorting.type === 'asc'
                ? Direction.ASC
                : newSorting.type === 'desc'
                  ? Direction.DESC
                  : undefined;
              setSorting({
                sortBy: newSorting.column,
                sortDirection: direction,
              });
            }}
            activeSorting={{
              column: sorting.sortBy,
              type: sorting.sortDirection === Direction.ASC
                ? 'asc'
                : sorting.sortDirection === Direction.DESC
                  ? 'desc'
                  : 'none',
            }}
          />
        </Box>
      </Box>
    </SectionContainer>
  );
};

export default Checks;
