import { Translation } from '../../../core/localization/Localization.model';

class WhitelistLocale {
  public static WHITELIST: Translation = { EN: 'Access limitation', CZ: 'Omezení přístupu' };
  public static IP: Translation = { EN: 'IP', CZ: 'IP' };
  public static NOTE: Translation = { EN: 'Note', CZ: 'Poznámka' };
  public static IP_EXAMPLE: Translation = { EN: '192.168.0.1', CZ: '192.168.0.1' };
  public static ERASE_TABLE: Translation = { EN: 'Erase table', CZ: 'Smazat tabulku' };
  public static WHITELIST_INFO: Translation = {
    EN: 'You can increase the safety of the input information by defining public IP addresses that you and '
        + 'others can log in from.',
    CZ: 'Můžete zvýšit bezpečnost zadávaných informací nastavením veřejných IP adres, ze kterých se Vy '
        + 'a ostatní mohou přihlašovat.',
  };
  public static WHITELIST_EXAMPLE: Translation = {
    EN: 'You can enter specific addresses, such as 192.168.0.1',
    CZ: 'Zadávat můžete konkrétní adresy, např. 192.168.0.1',
  };
  public static RECOMMENDATION_TEXT: Translation = {
    EN: 'We recommend 200 items at maximum',
    CZ: 'Doporučujeme maximálně 200 záznamů.',
  };
  public static CHANGE_SUCCESS: Translation = {
    EN: 'Settings changed succesfully', CZ: 'Nastavení úspěšně změněno',
  };
  public static WHITELIST_CONTAINS_FORBIDDEN_CHARACTERS: Translation = {
    EN: 'Access limitation list contains forbidden characters: ;',
    CZ: 'Seznam omezení přístupu obsahuje nepovolené znaky: ;',
  };
}

export default WhitelistLocale;
