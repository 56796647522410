import React from 'react';
import { Center } from '@chakra-ui/react';
import { ScautIcon } from '@scaut-sro/meepo';
import { OrderType } from '../../build/generated-sources/enum/OrderType';

const UserIcon: React.FunctionComponent<{ type: OrderType; }> = ({ type }) => (
  <Center
    stroke="#5E6C7A"
    fill="#5E6C7A"
  >
    {
      type === OrderType.SINGLE ? (
        <ScautIcon icon="SiSingleCheck" size="xl" />
      ) : (
        <ScautIcon icon="SiMultipleCheck" size="xl" />
      )
    }
  </Center>
);

export default UserIcon;
