import React from 'react';
import { Box } from '@chakra-ui/react';
import { Result } from '../../build/generated-sources/enum/Result';

const ScreeningBadge = ({ result, children }: { result: Result; children: React.ReactNode }) => (
  <Box
    bgColor={
      result === Result.CLEAR
        ? '#40D372'
        : result === Result.FOUND_RESULTS
          ? '#F9B768'
          : '#BCBCBC'
    }
    color="white"
    textTransform="uppercase"
    textAlign="center"
    p={2}
    borderRadius="md"
    fontSize="sm"
    fontWeight={600}
  >
    { children }
  </Box>
);

export default ScreeningBadge;
