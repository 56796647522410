import React, { useRef, useState } from 'react';
import {
  Button, HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent, ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import UserDetailForm from './UserDetailForm';
import UsersLocale from '../Users.locale';
import Translate from '../../../../components/Translate/Translate';
import { UserDetailProps } from '../Users.model';
import FormLocale from '../../../../core/localization/translations/Form.locale';

const UserDetailModal: React.FunctionComponent<UserDetailProps> = (props) => {
  const { clientUser, setUsersUpdated } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState<boolean>(false);
  const formRef = useRef<HTMLFormElement>(null);

  return (
    <>
      { !clientUser.id ? (
        <Button onClick={onOpen} leftIcon={<AddIcon />}>
          <Translate label={UsersLocale.ADD_USER} />
        </Button>
      ) : (
        <Button onClick={onOpen} variant="outline" color="grey">
          <Translate label={FormLocale.EDIT} />
        </Button>
      )}

      <Modal closeOnOverlayClick={false} size="4xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text><Translate label={UsersLocale.NEW_USER} /></Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <UserDetailForm
              loadingSetter={(isLoading) => { setLoading(isLoading); }}
              formRef={formRef}
              onClose={onClose}
              clientUser={clientUser}
              setUsersUpdated={setUsersUpdated}
            />
          </ModalBody>
          <ModalFooter>
            <HStack>
              <Button
                onClick={() => {
                  if (formRef) {
                    formRef.current?.dispatchEvent(new Event('submit', {
                      cancelable: true,
                      bubbles: true,
                    }));
                  }
                }}
                isLoading={loading}
              >
                { !clientUser.id ? (
                  <Translate label={UsersLocale.ADD_USER} />
                ) : (
                  <Translate label={UsersLocale.SAVE_CHANGES} />
                )}
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default UserDetailModal;
