import { ScautTheme } from '@scaut-sro/meepo';

const ScautClientTheme = {
  ...ScautTheme,
};

export enum ScautConst {
  HEADER_HEIGHT = '64px',
  LEFT_MENU_WIDTH = '220px',
}

export default ScautClientTheme;
