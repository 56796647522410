import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import {
  Box, Button, Divider, Flex, FormControl,
  FormLabel, GridItem, Input, SimpleGrid, Spacer, Text,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
  Currency, getCurrencyLocalizationFromLanguage,
  H2, MeepoTable, SectionContainer, Toast,
} from '@scaut-sro/meepo';
import { InvoiceDetailProps, InvoiceFormAttr, InvoicesTableData } from './Invoices.model';
import { useGetUser } from '../../../core/store/reducers/UserSettingsReducer';
import { Enumeration } from '../../../build/generated-sources/dto/Enumeration';
import { translate } from '../../../core/localization/LocaleUtils';
import InvoicesLocale from './Invoices.locale';
import {
  useGetEnumerations,
  useGetInvoices,
} from '../../../build/generated-sources/service/QueryService';
import { EnumerationType } from '../../../build/generated-sources/enum/EnumerationType';
import { useClientEdit } from '../../../build/generated-sources/service/MutationService';
import FormLocale from '../../../core/localization/translations/Form.locale';
import Translate from '../../../components/Translate/Translate';
import DetailHeader from '../../../components/DetailHeader/DetailHeader';
import LoadingScreen from '../../LoadingScreen/LoadingScreen';
import { Direction } from '../../../build/generated-sources/enum/Direction';
import UsersLocale from '../ClientUsers/Users.locale';
import { Invoice } from '../../../build/generated-sources/dto/Invoice';
import TableLocale from '../../../core/localization/translations/Table.locale';
import ShowDate from '../../../components/ShowDate/ShowDate';
import DownloadInvoiceRow from './DownloadInvoiceRow';
import { InvoiceStateBadge } from '../../../components/InvoiceStateBadge/InvoiceStateBadge';
import { Language } from '../../../build/generated-sources/enum/Language';

export function getInvoicesTableData(data: Invoice[] | undefined,
  getInvoiceNumberText: (invoiceNumber?: number) => string, language: Language): InvoicesTableData[] {
  if (data) {
    return data.map((invoice) => ({
      id: invoice?.id || '-undefined-',
      invoiceNumber: getInvoiceNumberText(invoice.invoiceNumber),
      issueDate: <ShowDate date={invoice?.issueDate} />,
      price: <Currency amount={invoice.price || 0} localization={getCurrencyLocalizationFromLanguage(language)} />,
      state: <InvoiceStateBadge state={invoice?.state as string} />,
      download: <DownloadInvoiceRow fileId={invoice?.invoiceFileMetadata?.id || -1} />,
    }));
  }
  return [];
}

const Invoices: React.FunctionComponent<InvoiceDetailProps> = (props) => {
  const { clientDetail, setClientUpdated } = props;
  const { language } = useGetUser();
  const [countryEnumList, setCountryEnumList] = useState<Enumeration[]>([]);
  const formRef = useRef<HTMLFormElement>(null);
  const [invoicesList, setInvoicesList] = useState<InvoicesTableData[]>([]);
  const [page, setPage] = useState<number>(0);
  const [sorting, setSorting] = useState<{ sortBy?: string, sortDirection?: Direction }>({});
  const size = 100;

  const getInvoiceNumberText = (invoiceNumber?: number) => translate(InvoicesLocale.INVOICE, language) + invoiceNumber;

  const defaultValues: Partial<InvoiceFormAttr> = useMemo(() => ({
    clientId: clientDetail?.client?.id,
    name: clientDetail?.client?.name,
    ico: clientDetail?.client?.ico,
    dic: clientDetail?.client?.dic,
    street: clientDetail?.client?.street,
    zipcode: clientDetail?.client?.zipcode,
    city: clientDetail?.client?.city,
    countryId: clientDetail?.client?.country?.id ? {
      value: clientDetail?.client?.country?.id,
      label: clientDetail?.client?.country?.translation?.localizationStrings?.[language] || '',
    } : undefined,
  }), [clientDetail, language]);
  const {
    handleSubmit, register, setValue, reset, formState: { errors }, control,
  } = useForm<Partial<InvoiceFormAttr>>({
    defaultValues,
  });

  const { loading } = useGetInvoices({
    content: {
      id: true,
      issueDate: true,
      state: true,
      price: true,
      invoiceNumber: true,
      invoiceFileMetadata: {
        id: true,
      },
    },
  }, {
    onError: () => {
      Toast({
        title: translate(UsersLocale.ERROR_GETTING_USERS, language),
        status: 'error',
      });
    },
    variables: {
      paging: {
        page,
        size,
        sortBy: sorting.sortBy,
        sortDirection: sorting.sortDirection,
      },
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (response) => {
      const onCompleteData = getInvoicesTableData(response.invoices.content, getInvoiceNumberText, language);
      if (page === 0) {
        setInvoicesList(onCompleteData);
      } else {
        setInvoicesList((prev) => [...prev, ...onCompleteData]);
      }
    },
  });

  useGetEnumerations(
    {
      id: true,
      value: true,
      translation: {
        id: true,
        localizationStrings: true,
      },
    }, {
      variables: {
        enumerationType: EnumerationType.COUNTRY,
      },
      onError: () => {
        Toast({
          title: translate(InvoicesLocale.ERROR_GETTING_CLIENT_DATA, language),
          status: 'error',
        });
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: (response) => {
        setCountryEnumList(response.enumerations);
      },
    },
  );

  const countryEnumOptions = useMemo(() => countryEnumList.map((c) => ({
    value: c.id,
    label: c.translation?.localizationStrings?.[language],
  })), [countryEnumList, language]);

  useEffect(() => {
    reset(defaultValues);
  }, [
    reset, setValue, defaultValues,
  ]);

  const [mutateClient] = useClientEdit({
    id: true,
  }, {
    onCompleted: () => {
      Toast({
        title: translate(FormLocale.SUCCESS, language),
        status: 'success',
      });
      setClientUpdated(true);
    },
    onError: () => {
      Toast({
        title: translate(InvoicesLocale.ERROR_GETTING_DATA, language),
        status: 'error',
      });
    },
  });

  useEffect(() => {
    register('countryId');
  }, [register]);

  const handleClientFormSubmit: SubmitHandler<InvoiceFormAttr> = (values) => {
    mutateClient({
      variables: {
        client: {
          name: clientDetail?.client?.name,
          ico: values.ico,
          dic: values.dic,
          clientEmail: clientDetail?.client?.email,
          clientPhone: clientDetail?.client?.phone,
          street: values.street,
          zipcode: values.zipcode,
          city: values.city,
          countryId: values.countryId?.value,
          enabled2fa: clientDetail?.client?.enabled2fa,
          communicationLanguage: clientDetail?.client?.communicationLanguage,
        },
      },
    });
  };

  return (
    <>
      { !clientDetail ? (
        <LoadingScreen />
      ) : (
        <SectionContainer>
          <Box overflowX="auto">
            <Box minW="600px">
              <DetailHeader>
                <H2><Translate label={InvoicesLocale.INVOICE_INFORMATION} /></H2>
              </DetailHeader>
              <Box overflowX="auto">
                <Box minW="600px">
                  <form
                    name="clientDetailForm"
                    ref={formRef}
                    onSubmit={handleSubmit(handleClientFormSubmit)}
                  >
                    <SimpleGrid columns={[1, 1, 1, 3, 3, 3]} spacing={6} mb={7}>
                      <GridItem colSpan={[1]}>
                        <FormControl>
                          <FormLabel htmlFor="name">
                            <Translate label={InvoicesLocale.NAME} />
                          </FormLabel>
                          <Input
                            placeholder={translate(InvoicesLocale.NAME, language)}
                            isReadOnly
                            {...register('name')}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem colSpan={[2]} />
                      <FormControl isRequired>
                        <FormLabel htmlFor="street">{translate(InvoicesLocale.STREET, language)}</FormLabel>
                        <Input
                          id="street"
                          placeholder={translate(InvoicesLocale.STREET, language)}
                          {...register('street', {
                            required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
                          })}
                        />
                      </FormControl>
                      <FormControl isRequired>
                        <FormLabel htmlFor="city">{translate(InvoicesLocale.CITY, language)}</FormLabel>
                        <Input
                          id="city"
                          placeholder={translate(InvoicesLocale.CITY, language)}
                          {...register('city', {
                            required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
                          })}
                        />
                      </FormControl>

                      <FormControl isRequired>
                        <FormLabel htmlFor="zipcode">{translate(InvoicesLocale.ZIP, language)}</FormLabel>
                        <Input
                          id="zipcode"
                          placeholder={translate(InvoicesLocale.ZIP, language)}
                          {...register('zipcode', {
                            required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
                          })}
                        />
                      </FormControl>
                      <GridItem colSpan={[1]}>
                        <FormControl isRequired isInvalid={!!errors.countryId}>
                          <FormLabel><Translate label={InvoicesLocale.COUNTRY} /></FormLabel>
                          <Controller
                            name="countryId"
                            control={control}
                            render={({ field }) => (
                              <Select
                                {...field}
                                options={countryEnumOptions}
                                hideSelectedOptions={false}
                                selectedOptionStyle="check"
                              />
                            )}
                            rules={{
                              required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
                            }}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem colSpan={[2]} />
                      <FormControl>
                        <FormLabel htmlFor="ico">{translate(InvoicesLocale.ID, language)}</FormLabel>
                        <Input
                          id="ico"
                          placeholder={translate(InvoicesLocale.ID, language)}
                          {...register('ico')}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel htmlFor="dic">{translate(InvoicesLocale.VAT, language)}</FormLabel>
                        <Input
                          id="dic"
                          placeholder={translate(InvoicesLocale.VAT, language)}
                          {...register('dic')}
                        />
                      </FormControl>
                    </SimpleGrid>
                    <Flex>
                      <Spacer />
                      <Button mb={6} type="submit">
                        <Translate label={InvoicesLocale.SAVE_CHANGES} />
                      </Button>
                    </Flex>
                  </form>
                </Box>
              </Box>
            </Box>
            <Divider />
            <Box mt={10} overflowX="auto">
              <H2><Translate label={InvoicesLocale.INVOICE_LIST} /></H2>
              <Box mt={6} minW="600px">
                <MeepoTable
                  columns={[
                    {
                      id: 'issueDate',
                      label: translate(InvoicesLocale.DATE, language),
                    },
                    {
                      id: 'invoiceNumber',
                      label: translate(InvoicesLocale.INVOICE_NUMBER, language),
                    },
                    {
                      id: 'price',
                      label: translate(InvoicesLocale.AMOUNT, language),
                    },
                    {
                      id: 'state',
                      label: translate(InvoicesLocale.STATE, language),
                    },
                    {
                      id: 'download',
                      label: '',
                    },
                  ]}
                  data={invoicesList}
                  isLoading={loading}
                  noData={<Text><Translate label={TableLocale.NO_DATA} /></Text>}
                  setPage={() => {
                    setPage((prev) => prev + 1);
                  }}
                  onSort={(newSorting) => {
                    const direction: Direction | undefined = newSorting.type === 'asc'
                      ? Direction.ASC
                      : newSorting.type === 'desc'
                        ? Direction.DESC
                        : undefined;
                    setSorting({
                      sortBy: newSorting.column,
                      sortDirection: direction,
                    });
                  }}
                  activeSorting={{
                    column: sorting.sortBy,
                    type: sorting.sortDirection === Direction.ASC
                      ? 'asc'
                      : sorting.sortDirection === Direction.DESC
                        ? 'desc'
                        : 'none',
                  }}
                />
              </Box>
            </Box>
          </Box>
        </SectionContainer>
      )}
    </>
  );
};
export default Invoices;
