import { format } from 'date-fns';
import { Language } from '../../build/generated-sources/enum/Language';
import { useGetUser } from '../store/reducers/UserSettingsReducer';

export type BenevolentDate = string | Date | undefined;

export type RemainingTime = {
  days: number;
  hours: number;
};

export function showDate(language: Language, date?: BenevolentDate): string | undefined {
  let dateObject;
  if (date) {
    if (typeof date === 'string') {
      dateObject = new Date(date);
    } else {
      dateObject = date;
    }
    if (language === Language.CZ) {
      return format(dateObject, 'd.M.yyyy');
    }
    return format(dateObject, 'd/M/yyyy');
  }
  return undefined;
}

export function showDateTime(language: Language, date?: BenevolentDate): string | undefined {
  let dateObject;
  if (date) {
    if (typeof date === 'string') {
      dateObject = new Date(date);
    } else {
      dateObject = date;
    }
    if (language === Language.CZ) {
      return format(dateObject, 'd.M.yyyy h:mm');
    }
    return format(dateObject, 'd/M/yyyy h:mm');
  }
  return undefined;
}

export function showRemainingTime(remainingHours: number): RemainingTime {
  const days = Math.floor(remainingHours / 24);
  const Remainder = remainingHours % 24;
  const hours = Math.floor(Remainder);
  return {
    days,
    hours,
  };
}

export function useShowDate(date?: BenevolentDate, time?: boolean) {
  const { language } = useGetUser();
  return time ? showDateTime(language, date) : showDate(language, date);
}
