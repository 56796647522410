import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  GridItem,
  HStack,
  Input,
  SimpleGrid,
  Switch,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { FormTooltip, ListInfoAlert, LocalUpload } from '@scaut-sro/meepo';
import { format } from 'date-fns';
import Translate from '../../../components/Translate/Translate';
import FormLocale from '../../../core/localization/translations/Form.locale';
import { CandidateFormAttr, CandidateFormProps, ImportNewCheckData } from './CandidateForm.model';
import { translate } from '../../../core/localization/LocaleUtils';
import NewCheckLocale from '../NewCheck.locale';
import { useGetUser } from '../../../core/store/reducers/UserSettingsReducer';
import { useNewCheckReduce } from '../../../core/store/reducers/NewCheckReducer';
import CandidatesEditable from '../../../components/CandidatesEditable/CandidatesEditable';
import { CandidateIdentity } from '../../../build/generated-sources/dto/CandidateIdentity';
import ChecksLocale from '../../Checks/Checks.locale';
import { OrderType } from '../../../build/generated-sources/enum/OrderType';
import isEmailValid from '../../../core/util/Validation';
import { Language } from '../../../build/generated-sources/enum/Language';

function isCandidateMissingData(
  candidate: CandidateIdentity,
  invite: boolean,
): boolean {
  if (invite) {
    return (!candidate.firstName || candidate.firstName?.length === 0)
        || (!candidate.lastName || candidate.lastName?.length === 0)
        || !candidate.dateOfBirth
        || (!candidate.email || (candidate.email?.length === 0 || !isEmailValid(candidate.email)))
        || (!candidate.communicationLanguage || candidate.communicationLanguage?.length === 0);
  }
  return (!candidate.firstName || candidate.firstName?.length === 0)
      || (!candidate.lastName || candidate.lastName?.length === 0)
      || !candidate.dateOfBirth;
}

function isOnlyDateOfBirth(
  candidate: CandidateIdentity,
  invite: boolean,
): boolean {
  if (invite) {
    return (!candidate.firstName || candidate.firstName?.length === 0)
        && (!candidate.lastName || candidate.lastName?.length === 0)
        && !!candidate.dateOfBirth
        && (!candidate.email || candidate.email?.length === 0 || !isEmailValid(candidate.email))
        && (!candidate.communicationLanguage || candidate.communicationLanguage?.length === 0);
  }
  return (!candidate.firstName || candidate.firstName?.length === 0)
      && (!candidate.lastName || candidate.lastName?.length === 0)
      && !!candidate.dateOfBirth;
}

function isAllEmpty(
  candidate: CandidateIdentity,
  invite: boolean,
): boolean {
  if (invite) {
    return (!candidate.firstName || candidate.firstName?.length === 0)
        && (!candidate.lastName || candidate.lastName?.length === 0)
        && !candidate.dateOfBirth
        && (!candidate.email || candidate.email?.length === 0 || !isEmailValid(candidate.email))
        && (!candidate.communicationLanguage || candidate.communicationLanguage?.length === 0);
  }
  return (!candidate.firstName || candidate.firstName?.length === 0)
      && (!candidate.lastName || candidate.lastName?.length === 0)
      && !candidate.dateOfBirth;
}

const CandidateForm: React.FunctionComponent<CandidateFormProps> = (props) => {
  const {
    orderName, orderCustomId, assets,
    inviteCandidate, candidates, orderType,
  } = props;
  const {
    handleSubmit, register, setValue,
  } = useForm<CandidateFormAttr>({
    defaultValues: {
      inviteCandidate: inviteCandidate !== undefined ? inviteCandidate : true,
    },
  });
  const { language } = useGetUser();
  const [formOrderType, setFormOrderType] = useState<OrderType>(orderType);
  const { setCurrentStep, setFirstStep } = useNewCheckReduce();
  const [invite, setInvite] = useState(true);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [backToSingleCheck, setBackToSingleCheck] = useState(false);
  const [fetchedData, setFetchedData] = useState<boolean>(false);
  const [formCandidates, setFormCandidates] = useState<CandidateIdentity[]>(candidates);

  const handleCandidateFormSubmit: SubmitHandler<CandidateFormAttr> = (values) => {
    const candidateList = formCandidates.filter((candidate) => (
      !isCandidateMissingData(candidate, invite)
        && !isOnlyDateOfBirth(candidate, invite)
        && !isAllEmpty(candidate, invite)
    ));
    const form = {
      orderType: values.orderType || OrderType.SINGLE,
      inviteCandidate: invite,
      candidates: candidateList,
      orderCustomId: values.orderCustomId ? values.orderCustomId : undefined,
      orderName: values.orderName ? values.orderName : undefined,
    };
    setFirstStep(form);
    setFormCandidates(candidateList);
    setCurrentStep(2);
  };

  function checkIfButtonDisabled(newCandidates: CandidateIdentity[], isInvite: boolean, orderType1: OrderType) {
    if (newCandidates.length === 0) {
      setSubmitDisabled(true);
    } else if (orderType1 === OrderType.SINGLE) {
      setSubmitDisabled(newCandidates.length === 0
          || (isCandidateMissingData(newCandidates[0], isInvite)));
    } else {
      const isSubmitDisabled: boolean[] = [];
      newCandidates.forEach((candidate) => {
        if (!isCandidateMissingData(candidate, isInvite)
        || isOnlyDateOfBirth(candidate, isInvite)
            || isAllEmpty(candidate, isInvite)) {
          isSubmitDisabled.push(false);
        } else {
          isSubmitDisabled.push(true);
        }
      });
      setSubmitDisabled(isSubmitDisabled.includes(true));
    }
  }

  const onFileLoaded = (candidateIdentities: CandidateIdentity[]) => {
    setFetchedData(true);
    setFormCandidates(candidateIdentities);
    checkIfButtonDisabled(candidateIdentities, invite, formOrderType);
  };

  const handleChangeCandidates = (newCandidates: CandidateIdentity[]) => {
    setFormCandidates(newCandidates);
  };

  useEffect(() => {
    setValue('orderType', formOrderType);
    if (formOrderType === 'SINGLE') {
      setBackToSingleCheck(true);
    } else {
      setBackToSingleCheck(false);
    }
  }, [setValue, formOrderType]);

  useEffect(() => {
    checkIfButtonDisabled(formCandidates, invite, formOrderType);
  }, [formCandidates, formOrderType, invite]);

  useEffect(() => {
    setInvite(inviteCandidate !== undefined ? inviteCandidate : true);
    setCurrentStep(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inviteCandidate]);

  useEffect(() => {
    if (!invite) {
      const cands: CandidateIdentity[] = [];
      formCandidates.forEach((candidate) => {
        const cand = candidate;
        delete cand.email;
        delete cand.personalTitle;
        delete cand.communicationLanguage;
        cands.push(cand);
      });
      setFormCandidates(cands);
    }
  }, [invite]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box maxWidth="1017px" margin="0 auto">
      <Tabs variant="unstyled" defaultIndex={formOrderType === 'SINGLE' ? 0 : 1}>
        <TabList height="47px" borderBottom="1px solid #dedfe9">
          <Tab
            fontSize="lg"
            fontWeight="medium"
            padding="0 40px 20px 4px"
            color="#5e6c7a"
            _selected={{
              color: '#2c2d41', fontWeight: 'medium', borderBottom: '2px', borderColor: '#0066F8',
            }}
            onClick={() => {
              setFormOrderType(OrderType.SINGLE);
            }}
          >
            <Translate label={ChecksLocale.SINGLE_CHECK} />
          </Tab>
          <Tab
            fontSize="lg"
            fontWeight="medium"
            padding="0 40px 20px 4px"
            color="#5e6c7a"
            _selected={{
              color: '#2c2d41', fontWeight: 'medium', borderBottom: '2px', borderColor: '#0066F8',
            }}
            onClick={() => {
              setFormOrderType(OrderType.MULTIPLE);
            }}
          >
            <Translate label={ChecksLocale.MULTIPLE_PEOPLE} />
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel p="20px 0">
            <form name="candidateForm" onSubmit={handleSubmit(handleCandidateFormSubmit)}>
              <SimpleGrid columns={[1, 1, 2, 2, 2, 2]} spacing={6} mb={7}>
                <GridItem>
                  <FormControl>
                    <HStack alignItems="top">
                      <FormLabel htmlFor="orderName" mr={0} color="blue.700">
                        <Translate label={NewCheckLocale.ORDER_NAME} />
                      </FormLabel>
                      <FormTooltip label={translate(NewCheckLocale.ORDER_NAME_TOOLTIP, language)} />
                    </HStack>
                    <Input
                      id="orderName"
                      defaultValue={orderName}
                      placeholder={translate(NewCheckLocale.ORDER_NAME, language)}
                      size="lg"
                      {...register('orderName')}
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel htmlFor="orderCustomId" color="blue.700">
                      <Translate label={NewCheckLocale.ORDER_CUSTOM_ID} />
                    </FormLabel>
                    <Input
                      id="orderCustomId"
                      defaultValue={orderCustomId}
                      placeholder={translate(NewCheckLocale.ORDER_CUSTOM_ID, language)}
                      size="lg"
                      {...register('orderCustomId')}
                    />
                  </FormControl>
                </GridItem>
                <FormControl>
                  <HStack alignItems="center" height="100%" paddingTop="10px">
                    <Switch
                      id="inviteCandidate"
                      size="lg"
                      colorScheme="whatsapp"
                      {...register('inviteCandidate')}
                      onChange={(e) => setInvite(e.target.checked)}
                      isChecked={invite}
                    />
                    <FormLabel fontWeight="normal" fontSize="xl" color="#002458" htmlFor="inviteCandidate" mb={0}>
                      <Translate label={NewCheckLocale.INVITE_CANDIDATE} />
                    </FormLabel>
                  </HStack>
                </FormControl>
              </SimpleGrid>
              { assets && assets.length ? (
                <ListInfoAlert
                  label={(
                    <>
                      <Translate label={NewCheckLocale.ASSETS_INFO} />
                      :
                    </>
                      )}
                  list={assets}
                />
              ) : undefined}
              <CandidatesEditable
                newData={fetchedData}
                candidates={formCandidates}
                onCandidatesChange={handleChangeCandidates}
                inviteCandidate={invite}
                orderType={formOrderType}
                backToSingleCheck={backToSingleCheck}
              />

              <HStack mt={8} justifyContent="right">
                <Button
                  type="submit"
                  isDisabled={submitDisabled}
                  backgroundColor="#0066f8"
                >
                  <Translate label={FormLocale.CONTINUE} />
                </Button>
              </HStack>
            </form>
          </TabPanel>
          <TabPanel p="50px 0">
            <form name="candidateForm" onSubmit={handleSubmit(handleCandidateFormSubmit)}>
              <SimpleGrid columns={[1, 1, 1, 2, 2]} spacing={6} mb={7}>
                <GridItem colSpan={[1, 1, 1, 2, 2, 2]}>
                  <FormControl>
                    <HStack alignItems="center" height="100%">
                      <Switch
                        id="inviteCandidate"
                        size="lg"
                        colorScheme="whatsapp"
                        {...register('inviteCandidate')}
                        onChange={(e) => setInvite(e.target.checked)}
                        isChecked={invite}
                      />
                      <FormLabel fontWeight="normal" fontSize="xl" color="#002458" htmlFor="inviteCandidate" mb={0}>
                        <Translate label={NewCheckLocale.INVITE_CANDIDATES} />
                      </FormLabel>
                    </HStack>
                  </FormControl>
                </GridItem>
                <FormControl>
                  <Flex>
                    <FormLabel color="blue.700" htmlFor="orderName">
                      <Translate label={NewCheckLocale.ORDER_NAME} />
                    </FormLabel>
                  </Flex>
                  <Input
                    id="orderName"
                    defaultValue={orderName}
                    placeholder={translate(NewCheckLocale.ORDER_NAME, language)}
                    size="lg"
                    {...register('orderName')}
                  />
                </FormControl>
              </SimpleGrid>
              { assets && assets.length ? (
                <ListInfoAlert
                  label={(
                    <>
                      <Translate label={NewCheckLocale.ASSETS_INFO} />
                      :
                    </>
                      )}
                  list={assets}
                />
              ) : undefined}
              <HStack mb={10} justifyContent="right">
                <LocalUpload
                  dataCallback={(value: ImportNewCheckData[]) => {
                    const data = value.filter((row, index) => index !== 0);
                    const mappedData = data.map((row) => ({
                      firstName: row.firstName,
                      lastName: row.lastName,
                      otherName: row.otherName,
                      email: row.email,
                      jobPosition: row.jobPosition,
                      personalTitle: row.salutation,
                      communicationLanguage:
                          row.invitationLanguage === 'Čeština' || row.invitationLanguage === 'Czech' ? Language.CZ : Language.EN,
                      dateOfBirth: row.dateOfBirth ? format(row.dateOfBirth, 'yyyy-MM-dd') : undefined,
                    }));
                    if (mappedData.length) onFileLoaded(mappedData);
                  }}
                  templatePath={language === Language.CZ ? 'import-files/new_checks_cz.xlsx' : 'import-files/new_checks_en.xlsx'}
                  header={[
                    'firstName',
                    'lastName',
                    'otherName',
                    'dateOfBirth',
                    'email',
                    'personalTitle',
                    'jobPosition',
                    'invitationLanguage',
                  ]}
                />
              </HStack>
              <CandidatesEditable
                newData={fetchedData}
                candidates={formCandidates}
                onCandidatesChange={handleChangeCandidates}
                inviteCandidate={invite}
                orderType={formOrderType}
                backToSingleCheck={backToSingleCheck}
              />

              <HStack mt={8} justifyContent="right">
                <Button
                  type="submit"
                  isDisabled={submitDisabled}
                >
                  <Translate label={FormLocale.CONTINUE} />
                </Button>
              </HStack>
            </form>
          </TabPanel>
        </TabPanels>
      </Tabs>
      <br />
      <br />
    </Box>
  );
};

export default CandidateForm;
