import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel, ListItem,
  Popover, PopoverBody,
  PopoverContent,
  PopoverTrigger,
  SimpleGrid,
  UnorderedList,
  useDisclosure,
} from '@chakra-ui/react';

import { Validate } from 'react-hook-form/dist/types/validator';
import { PasswordInput, Toast } from '@scaut-sro/meepo';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FieldPath, FieldPathValue } from 'react-hook-form/dist/types/path';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import { RiQuestionLine } from 'react-icons/all';
import { format } from 'react-string-format';
import { KeycloakPasswordPolicy } from '../../build/generated-sources/enum/KeycloakPasswordPolicy';
import { getKeycloakPasswordValidations } from '../../core/util/Validation';
import { KeycloakPasswordPolicyDto } from '../../build/generated-sources/dto/KeycloakPasswordPolicyDto';
import { useGetPasswordPolicies } from '../../build/generated-sources/service/QueryService';
import { UseChangePasswordData } from '../../build/generated-sources/service/MutationServiceModel';
import { useGetUser } from '../../core/store/reducers/UserSettingsReducer';
import Translate from '../../components/Translate/Translate';
import UserSettingsLocale from './UserSettings.locale';
import { translate } from '../../core/localization/LocaleUtils';
import FormLocale from '../../core/localization/translations/Form.locale';
import { useChangePassword } from '../../build/generated-sources/service/MutationService';
import { ChangePasswordFormProps } from './UserSettings.model';
import ScautDialog from '../../components/ScautDialog/ScautDialog';
import { handleApolloErrors } from '../../core/error/ScautErrorHandler';

const ChangePasswordForm: React.FunctionComponent = () => {
  const { language, userProfile } = useGetUser();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef(null);
  const [passwordPolicies, setPasswordPolicies] = useState<KeycloakPasswordPolicyDto[]>([]);
  const [passwordPoliciesPopover, setPasswordPoliciesPopover] = useState<string[]>([]);

  const [validations, setValidations] = useState<{
    [key:string]: Validate<FieldPathValue<FieldValues, FieldPath<FieldValues>>>
  }>({});

  const {
    handleSubmit, register, formState: { errors }, reset, getValues,
  } = useForm<ChangePasswordFormProps>();

  useGetPasswordPolicies(
    {
      policy: true,
      value: true,
    }, {
      fetchPolicy: 'cache-and-network',
      onCompleted: (response) => {
        setPasswordPolicies(response.passwordPolicies);
      },
    },
  );

  const onChangePasswordComplete = (data: UseChangePasswordData) => {
    if (data.changePassword.email) {
      Toast({
        title: translate(UserSettingsLocale.PASSWORD_CHANGE_SUCCESS, language),
        status: 'success',
      });
    }
    reset();
    onClose();
  };

  const [mutateChangePassword, { loading }] = useChangePassword(
    {
      email: true,
    }, {
      onCompleted: onChangePasswordComplete,
      onError: (error) => {
        handleApolloErrors(error, language);
        reset();
        onClose();
      },
    },
  );

  const handleFormSubmit: SubmitHandler<ChangePasswordFormProps> = () => {
    onOpen();
  };

  const handleDialogConfirm = () => {
    const { originalPassword, newPassword, newPasswordConfirmation } = getValues();
    mutateChangePassword({
      variables: {
        // @ts-ignore
        originalPassword,
        password: newPassword,
        passwordConfirmation: newPasswordConfirmation,
      },
    });
  };

  useEffect(() => {
    if (passwordPolicies) {
      setValidations(getKeycloakPasswordValidations(passwordPolicies, language, userProfile?.email));
      const popoverData: string[] = [];
      passwordPolicies.forEach((policy) => {
        if (policy.policy) {
          let message = translate(UserSettingsLocale[policy.policy as KeycloakPasswordPolicy], language);
          if (policy.value) {
            message = format(message, policy.value);
          }
          popoverData.push(message);
        }
      });
      setPasswordPoliciesPopover(popoverData);
    }
  }, [passwordPolicies, language, userProfile]);

  return (
    <>
      <ScautDialog
        isOpen={isOpen}
        onConfirm={handleDialogConfirm}
        onClose={onClose}
        cancelRef={cancelRef}
        isLoading={loading}
        header={<Translate label={UserSettingsLocale.PASSWORD_CHANGE} />}
        body={<Translate label={UserSettingsLocale.CONFIRM_PASSWORD_CHANGE_DIALOG} />}
      />
      <form name="changePasswordForm" onSubmit={handleSubmit(handleFormSubmit)}>
        <SimpleGrid columns={[1, 2, 2, 2, 3]} spacing={6} mb={6}>
          <FormControl isInvalid={!!errors.originalPassword} isRequired>
            <FormLabel htmlFor="originalPassword">
              <Translate label={UserSettingsLocale.PASSWORD} />
            </FormLabel>
            <PasswordInput
              id="originalPassword"
              placeholder={translate(UserSettingsLocale.PASSWORD_PLACEHOLDER, language)}
              {...register('originalPassword', {
                required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
              })}
            />
            <FormErrorMessage>
              {errors.originalPassword && errors.originalPassword.message}
            </FormErrorMessage>
          </FormControl>
        </SimpleGrid>

        <SimpleGrid columns={[1, 2, 2, 2, 3]} spacing={6} mb={6}>
          <FormControl isInvalid={!!errors.newPassword} isRequired>
            <Flex>
              <FormLabel htmlFor="newPassword">
                <Translate label={UserSettingsLocale.NEW_PASSWORD} />
              </FormLabel>
              { passwordPoliciesPopover && passwordPoliciesPopover.length > 0 ? (
                <Popover placement="top-end">
                  <PopoverTrigger>
                    <Box mt={1}>
                      <RiQuestionLine />
                    </Box>
                  </PopoverTrigger>
                  <PopoverContent width="310px">
                    <PopoverBody>
                      <Translate label={UserSettingsLocale.PASSWORD_RULES} />
                      <UnorderedList>
                        { passwordPoliciesPopover.map((popoverInfo) => <ListItem>{popoverInfo}</ListItem>)}
                      </UnorderedList>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              ) : undefined }
            </Flex>
            <PasswordInput
              id="newPassword"
              placeholder={translate(UserSettingsLocale.NEW_PASSWORD_PLACEHOLDER, language)}
              {...register('newPassword', {
                required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
                validate: validations,
              })}
            />
            <FormErrorMessage>
              {errors.newPassword && errors.newPassword.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.newPasswordConfirmation} isRequired>
            <FormLabel htmlFor="newPasswordConfirmation">
              <Translate label={UserSettingsLocale.NEW_PASSWORD_CONFIRMATION} />
            </FormLabel>
            <PasswordInput
              id="newPasswordConfirmation"
              placeholder={
                translate(UserSettingsLocale.NEW_PASSWORD_CONFIRMATION_PLACEHOLDER, language)
              }
              {...register('newPasswordConfirmation', {
                required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
                validate: {
                  matchesPreviousPassword: (value) => {
                    const { newPassword } = getValues();
                    return newPassword === value || translate(
                      UserSettingsLocale.NEW_PASSWORD_CONFIRMATION_ERROR, language,
                    );
                  },
                },
              })}
            />
            <FormErrorMessage>
              {errors.newPasswordConfirmation && errors.newPasswordConfirmation.message}
            </FormErrorMessage>
          </FormControl>
        </SimpleGrid>

        <Button mt={4} type="submit">
          <Translate label={UserSettingsLocale.CONFIRM_PASSWORD_CHANGE} />
        </Button>
      </form>
    </>
  );
};

export default ChangePasswordForm;
