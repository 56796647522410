import React from 'react';
import {
  Center, Flex, Icon, Text,
} from '@chakra-ui/react';
import { FiUser } from 'react-icons/fi';

const ClientUserRow: React.FunctionComponent<{ name: string; }> = ({ name }) => (
  <>
    <Flex>
      <Center
        bgColor="gray.100"
        h={10}
        w={10}
        borderRadius="100%"
      >
        <Icon as={FiUser} />
      </Center>
      <Text ml={3} mt={3}>{name}</Text>
    </Flex>

  </>

);

export default ClientUserRow;
