import React from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel, Alert,
  Box,
  Flex,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { H3, Note } from '@scaut-sro/meepo';
import { MdInfoOutline } from 'react-icons/all';
import { ListOfServicesProps } from './ListOfServices.model';
import { CountryProductsDto } from '../../build/generated-sources/dto/CountryProductsDto';
import { useGetUser } from '../../core/store/reducers/UserSettingsReducer';
import ListOfServiceLoader from './ListOfServiceLoader';
import FormLocale from '../../core/localization/translations/Form.locale';
import Translate from '../Translate/Translate';
import NewCheckLocale from '../../views/NewCheck/NewCheck.locale';
import { ScreeningPackageDto } from '../../build/generated-sources/dto/ScreeningPackageDto';
import { ScreeningDefinitionDto } from '../../build/generated-sources/dto/ScreeningDefinitionDto';

const ListOfServices: React.FunctionComponent<ListOfServicesProps> = (props) => {
  const {
    data, loading, displayAlert,
  } = props;
  const { language } = useGetUser();
  const openItems: number[] | undefined = data?.map((item, index) => index);

  return (
    <>
      { loading || !data ? (
        <ListOfServiceLoader />
      ) : (
        <>
          <Accordion variant="scautSectionAccordion" defaultIndex={openItems} allowMultiple>
            {data.map((countryCatalogue: CountryProductsDto) => (
              <AccordionItem key={`catalogue_${countryCatalogue.country?.translation?.localizationStrings?.[language]}`}>
                <AccordionButton p={0}>
                  <Box flex="1" textAlign="left">
                    <H3
                      mt={6}
                      mb={3}
                      fontSize="20px"
                      color="#2c2d41"
                      fontWeight="regular"
                    >
                      {countryCatalogue.country?.translation?.localizationStrings?.[language]}
                    </H3>
                  </Box>
                </AccordionButton>
                <AccordionPanel p={8} fontSize="20px" color="#2c2d41">
                  {countryCatalogue.screeningPackages?.map((screeningPackage: ScreeningPackageDto) => (
                    <React.Fragment key={screeningPackage.id}>
                      <Box mb={8}>
                        <Flex>
                          <Text>{screeningPackage.name?.localizationStrings?.[language]}</Text>
                          <Spacer borderBottom="1px dashed #2c2d41" />
                          <Box ml={3}>
                            {`${screeningPackage.price} `}
                            <Translate label={FormLocale.CURRENCY} />
                          </Box>
                        </Flex>
                        {screeningPackage.screeningDefinitions?.map((screeningDefinition) => (
                          <Flex mb={4} mt={4} key={screeningDefinition.id}>
                            <Box>
                              <Note
                                fontSize="lg"
                                color="rgba(44,45,65,0.8)"
                              >
                                {screeningDefinition.name?.localizationStrings?.[language]}
                              </Note>
                            </Box>
                          </Flex>
                        ))}
                      </Box>
                    </React.Fragment>
                  ))}
                  {countryCatalogue.screeningDefinitions?.map((screeningDefinition: ScreeningDefinitionDto) => (
                    <React.Fragment key={screeningDefinition.id}>
                      <Flex mb={8}>
                        <Box><Text>{screeningDefinition.name?.localizationStrings?.[language]}</Text></Box>
                        <Spacer borderBottom="1px dashed #2c2d41" />
                        <Box ml={3}>
                          {`${screeningDefinition.price} `}
                          <Translate label={FormLocale.CURRENCY} />
                        </Box>
                      </Flex>
                    </React.Fragment>
                  ))}
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
          {
            displayAlert ? (
              <Alert backgroundColor="#fff4e4" display="block" mt={5} pl={8}>
                <Flex>
                  <Box pt={1}>
                    <MdInfoOutline color="#e18d18" size={18} />
                  </Box>
                  <Box ml={3}>
                    <Text color="#e18d18" fontSize="lg">
                      <Translate label={NewCheckLocale.ALERT} />
                    </Text>
                    <Text color="#e18d18" fontSize="md">
                      <Translate label={NewCheckLocale.ALERT_DETAIL} />
                    </Text>
                  </Box>
                </Flex>
              </Alert>
            ) : undefined
          }
        </>
      )}
    </>
  );
};

export default ListOfServices;
