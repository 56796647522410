import { Translation } from '../../core/localization/Localization.model';

class CandidateDetailLocale {
  public static FULLNAME: Translation = { EN: 'Full name', CZ: 'Celé jméno' };
  public static DATE_OF_BIRTH: Translation = { EN: 'Date of birth', CZ: 'Datum narození' };
  public static EMAIL: Translation = { EN: 'Email', CZ: 'Email' };
  public static PERSONAL_TITLE: Translation = { EN: 'Personal title', CZ: 'Osobní titul' };
  public static JOB_POSITION: Translation = { EN: 'Position', CZ: 'Pozice' };
}

export default CandidateDetailLocale;
