import { Translation } from '../Localization.model';

class UserMenuLocale {
  public static LOGOUT: Translation = { EN: 'Logout', CZ: 'Odhlásit' };
  public static TOGGLE_LANGUAGE: Translation = { EN: 'Toggle language', CZ: 'Přepni jazyk' };
  public static USER_SETTINGS: Translation = { EN: 'Account', CZ: 'Účet' };
  public static CLIENT_SETTINGS: Translation = { EN: 'Settings', CZ: 'Nastavení' };
  public static FAQ: Translation = { EN: 'FAQ', CZ: 'FAQ' };
}

export default UserMenuLocale;
