import * as React from 'react';
import { MouseEventHandler, useMemo, useState } from 'react';
import {
  Box, Button, Flex, FormControl, FormLabel, GridItem, Select, SimpleGrid, Spacer, Text,
} from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import {
  CheckHeaderNewCheck, H2, H3, Note, StepperTabs, Toast,
} from '@scaut-sro/meepo';
import { useHistory } from 'react-router-dom';
import { usePayOrder } from '../../../build/generated-sources/service/MutationService';
import { translate } from '../../../core/localization/LocaleUtils';
import { useGetUser } from '../../../core/store/reducers/UserSettingsReducer';
import { UsePayOrderData } from '../../../build/generated-sources/service/MutationServiceModel';
import { handleApolloErrors } from '../../../core/error/ScautErrorHandler';
import { ConfirmationProps } from './Confirmation.model';
import Translate from '../../../components/Translate/Translate';
import FormLocale from '../../../core/localization/translations/Form.locale';
import TotalPrice from '../../../components/TotalPrice/TotalPrice';
import ListOfServices from '../../../components/ListOfServices/ListOfServices';
import NewCheckLocale from '../NewCheck.locale';
import { useNewCheckReduce } from '../../../core/store/reducers/NewCheckReducer';
import { ProductFormAttr } from '../ProductForm/ProductForm.model';
import { CandidateFormAttr } from '../CandidateForm/CandidateForm.model';
import { OrderType } from '../../../build/generated-sources/enum/OrderType';
import ChecksLocale from '../../Checks/Checks.locale';
import { OrderDefinitionInputDto } from '../../../build/generated-sources/dto/OrderDefinitionInputDto';
import { useGetAllowedClientPaymentMethods } from '../../../build/generated-sources/service/QueryService';
import { PaymentMethod } from '../../../build/generated-sources/enum/PaymentMethod';
import CandidateScreeningDetailLocale from '../../CandidateScreeningDetail/CandidateScreeningDetail.locale';
import { AllowedPaymentMethod } from '../../../build/generated-sources/dto/AllowedPaymentMethod';
import { Translation } from '../../../core/localization/Localization.model';
import OrderDetailLocale from '../../OrderDetail/OrderDetail.locale';
import { CountryProductsDto } from '../../../build/generated-sources/dto/CountryProductsDto';

const Confirmation: React.FunctionComponent<ConfirmationProps> = (props) => {
  const {
    candidates, orderData,
  } = props;
  const { language } = useGetUser();
  const history = useHistory();
  const [clientAllowedPaymentMethods, setClientAllowedPaymentMethods] = useState<{ [key:string]: AllowedPaymentMethod }>({});
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethod>(PaymentMethod.CARD_PAYMENT);
  const [buttonLoading, setButtonLoading] = useState(false);

  const {
    setCurrentStep, setFirstStep, setSecondStep, setPrice, setOrderId,
  } = useNewCheckReduce();

  const screeningData = useMemo(() => {
    const packages: number[] = [];
    const definitions: OrderDefinitionInputDto[] = [];
    const price = orderData.pricePreview || 0;
    orderData.suborderProducts?.[0].products?.forEach((product: CountryProductsDto) => {
      product.screeningPackages?.forEach((pkg) => {
        packages.push(pkg.id || -1);
      });
      product.screeningDefinitions?.forEach((def) => {
        definitions.push({
          id: def.id || -1,
          amount: 1,
        });
      });
    });
    return {
      packages, definitions, price,
    };
  }, [orderData]);

  useGetAllowedClientPaymentMethods(
    {
      hasPaymentProfile: true,
      paymentMethod: true,
    }, {
      onError: () => {
        Toast({
          title: translate(ChecksLocale.ERROR_GETTING_SUBSCRIPTIONS, language),
          status: 'error',
        });
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: (response) => {
        const allowedPaymentMethods: { [key:string]: AllowedPaymentMethod } = {};

        response.allowedClientPaymentMethods.forEach((allowedPaymentMethod) => {
          allowedPaymentMethods[`${allowedPaymentMethod.paymentMethod}`] = allowedPaymentMethod;
        });

        setClientAllowedPaymentMethods(allowedPaymentMethods || {});
        if (!response.allowedClientPaymentMethods) {
          Toast({
            title: translate(CandidateScreeningDetailLocale.ERROR_GETTING_DATA, language),
            status: 'error',
          });
        } else if (response.allowedClientPaymentMethods.length > 0) {
          const allowedClientPaymentMethod = response.allowedClientPaymentMethods[0];
          setSelectedPaymentMethod(allowedClientPaymentMethod.paymentMethod as PaymentMethod);
        }
      },
    },
  );

  const clientSubscriptionOptionsList = useMemo(() => Object.keys(clientAllowedPaymentMethods).map((key) => {
    const paymentMethod = clientAllowedPaymentMethods[key].paymentMethod as PaymentMethod;
    const translation = NewCheckLocale[paymentMethod] || paymentMethod;

    return ({
      value: clientAllowedPaymentMethods[key].paymentMethod,
      label: translate(translation, language) || '',
    });
  }), [clientAllowedPaymentMethods, language]);

  const [mutatePayOrder] = usePayOrder(
    {
      success: true,
    },
    {
      onCompleted: (data: UsePayOrderData) => {
        if (data.payOrder.success) {
          Toast({
            title: translate(ChecksLocale.ORDER_PAID_SUCCESS, language),
            status: 'success',
          });
          setCurrentStep(1);
          history.push('/');
        } else {
          Toast({
            title: translate(ChecksLocale.ORDER_PAY_ERROR, language),
            status: 'error',
          });
          setButtonLoading(false);
        }
      },
      onError: (error) => {
        handleApolloErrors(error, language);
        setButtonLoading(false);
      },
    },
  );

  const handlePayOrderSubmit: MouseEventHandler = () => {
    setButtonLoading(true);
    mutatePayOrder({
      variables: {
        primaryOrderId: orderData.order?.id || -1,
        paymentMethod: selectedPaymentMethod,
      },
    });
  };

  const handleBack = () => {
    const { packages, definitions, price } = screeningData;
    const inviteCandidate: boolean = !!candidates.filter((candidate) => (candidate.email)).length;

    const firstStepData: CandidateFormAttr = {
      orderType: orderData.order?.type || OrderType.SINGLE,
      inviteCandidate,
      orderCustomId: orderData.order?.clientOrderCustomId,
      orderName: orderData.order?.name,
      candidates,
    };

    const secondStepData: ProductFormAttr = {
      packages,
      definitions,
    };
    setFirstStep(firstStepData);
    setSecondStep(secondStepData);
    setPrice(price);
    setOrderId(orderData.order?.id);
    setCurrentStep(2);
    history.goBack();
  };

  const handlePaymentSelect = (value: PaymentMethod) => {
    setSelectedPaymentMethod(value);
  };

  const errorMessage: Translation | undefined = useMemo(() => {
    const allowedMethod = clientAllowedPaymentMethods[`${selectedPaymentMethod}`];
    if (allowedMethod && selectedPaymentMethod === PaymentMethod.CARD_PAYMENT && !allowedMethod.hasPaymentProfile) {
      return NewCheckLocale.MISSING_PAYMENT_CARD;
    }

    return undefined;
  }, [selectedPaymentMethod, clientAllowedPaymentMethods]);
  return (
    <>
      <SimpleGrid
        columns={[1, 1, 2, 2, 2, 2]}
        mb={10}
        gap={10}
        borderBottom="2px"
        borderColor="#dedfe9"
        pl={3}
        minHeight="46px"
      >
        <GridItem>
          <H2
            lineHeight="35px"
            letterSpacing="-0.42px"
            fontSize="20px"
            color="#2c2d41"
            fontWeight="regular"
          >
            <Translate label={NewCheckLocale.NEW_CHECK} />
          </H2>
        </GridItem>
        <GridItem mr="0" mb="-1px">
          <StepperTabs
            steps={[
              translate(NewCheckLocale.CANDIDATES_INFORMATION, language),
              translate(NewCheckLocale.PACKAGES_AND_CHECKS, language),
              translate(NewCheckLocale.RECAPITULATION, language),
            ]}
            currentStep={2}
            colorScheme="#0066F8"
            activeStepColor="#2c2d41"
            afterActiveStepColor="#5e6c7a"
            beforeActiveStepColor="#5e6c7a"
            boxProps={{ width: '495px', height: '47px' }}
            gridItemBoxProps={{ pr: '20px' }}
            textProps={{ fontSize: 'lg' }}
          />
        </GridItem>
      </SimpleGrid>
      {/*      <Box mb={6}>
        <Flex alignItems="center">
          {orderData.order?.type === OrderType.MULTIPLE
            ? <Icon as={FiUsers} fontSize={50} />
            : <Icon as={FiUser} fontSize={50} />}
          <Box ml={4}>
            <Heading size="lg" color="primaryBase" mb={3}>{orderData.order?.name}</Heading>
            <TagArea>
              { orderData.order?.type ? (
                <MeepoTag
                  leftIcon={IoShapesOutline}
                  label={
                    orderData.order?.type === OrderType.MULTIPLE
                      ? translate(ChecksLocale.MULTIPLE_PEOPLE, language)
                      : translate(ChecksLocale.SINGLE_CHECK, language)
                  }
                  tooltip={translate(NewCheckLocale.TYPE, language)}
                />
              )
                : undefined }
              {orderData.order?.team?.name ? (
                <MeepoTag
                  leftIcon={AiOutlineTeam}
                  label={orderData.order?.team?.name}
                  tooltip={translate(NewCheckLocale.TEAM, language)}
                />
              ) : undefined}
              {orderData.order?.clientOrderCustomId ? (
                <MeepoTag
                  leftIcon={AiOutlineNumber}
                  label={orderData.order?.clientOrderCustomId}
                  tooltip={translate(NewCheckLocale.ORDER_CUSTOM_ID, language)}
                />
              ) : undefined}
            </TagArea>
          </Box>
        </Flex>
      </Box> */}
      <Box mb={8}>
        {orderData?.order?.type ? (
          <CheckHeaderNewCheck
            controlledSubjectLabel={
              orderData?.order?.type === OrderType.MULTIPLE
                ? translate(OrderDetailLocale.MULTIPLE_CHECK, language)
                : translate(OrderDetailLocale.CONTROLLED_PERSON, language)
            }
            orderIdLabel={translate(OrderDetailLocale.ORDER_ID, language)}
            orderId={orderData?.order?.clientOrderCustomId}
            controlledSubjectName={orderData?.order?.name || ''}
            dateOfBirthLabel={translate(OrderDetailLocale.DATE_OF_BIRTH, language)}
            emailLabel={translate(OrderDetailLocale.EMAIL, language)}
            numberOfPersonsLabel={translate(OrderDetailLocale.CANDIDATES_COUNT, language)}
            isMultipleCheck={orderData?.order?.type === OrderType.MULTIPLE}
            numberOfPersons={orderData?.suborderProducts?.[0]?.subOrder?.candidateIdentities?.length || 1}
            jobPosition={orderData?.suborderProducts?.[0].subOrder?.candidateIdentities?.[0].jobPosition || ''}
            dateOfBirth={orderData?.suborderProducts?.[0].subOrder?.candidateIdentities?.[0].dateOfBirth || ''}
            email={orderData?.suborderProducts?.[0].subOrder?.candidateIdentities?.[0].email || ''}
          />
        ) : undefined}
      </Box>
      {/*      <Box mb={8}>
        <CandidateDetail candidates={candidates} />
      </Box> */}
      <Box pl={8} pr={8}>
        <H2 pb={6} mt={8} fontSize="22px" color="#2c2d41" fontWeight="regular" borderBottom="1px solid #dedfe9">
          <Translate label={NewCheckLocale.SELECTED_CHECKS} />
        </H2>
        <Box mb={8}>
          <ListOfServices
            data={orderData.suborderProducts?.[0].products}
            displayAlert={orderData.dynamicFields && orderData.dynamicFields?.length > 0}
          />
        </Box>

        <Box mb={8}>
          { orderData.dynamicFields && orderData.dynamicFields?.length > 0 ? (
            <>
              <H3 mb={6} mt={8}><Translate label={NewCheckLocale.CHECKED_FIELDS} /></H3>
              <Box ml={10}>
                <SimpleGrid columns={[2]} spacing={[6]}>
                  <GridItem colSpan={[1]}>
                    <SimpleGrid columns={[4]} spacing={[3]}>
                      {orderData.dynamicFields?.map((field) => (
                        <GridItem colSpan={[1]} mt={2}>
                          <Box>
                            <Note>{field.localizationStrings?.[language]}</Note>
                          </Box>
                        </GridItem>
                      ))}
                    </SimpleGrid>
                  </GridItem>
                  <GridItem colSpan={[1]} />
                </SimpleGrid>
              </Box>
            </>
          ) : undefined }
        </Box>
        <TotalPrice
          price={screeningData.price}
          isMultipleCheck={orderData.order?.type === OrderType.MULTIPLE}
          numberOfPersons={(orderData?.suborderProducts?.[0].subOrder?.candidateIdentities?.length)}
        />
        {
        clientSubscriptionOptionsList.length > 0
          ? (
            <>
              <H2 pb={6} mt={8} fontSize="22px" color="#2c2d41" fontWeight="regular">
                <Translate label={NewCheckLocale.PAYMENT_INFORMATION} />
              </H2>
              <SimpleGrid columns={[1, 2, 2, 3, 3]} spacing={[6]}>
                <FormControl mt={3}>
                  <FormLabel><Translate label={NewCheckLocale.PAYMENT_METHOD} /></FormLabel>
                  <Select
                    onChange={(event: any) => {
                      handlePaymentSelect(event.target.value);
                    }}
                    value={selectedPaymentMethod}
                  >
                    <option value="">- Select value -</option>
                    { clientSubscriptionOptionsList.map((subscription) => (
                      <option key={subscription.value} value={subscription.value}>
                        {`${subscription.label}`}
                      </option>
                    )) }
                  </Select>
                </FormControl>
              </SimpleGrid>

            </>
          )
          : undefined
      }
        {
        errorMessage ? (
          <Text fontSize="xs" color="red"><Translate label={errorMessage} /></Text>
        ) : undefined
      }
      </Box>

      <Flex mt={8}>
        <Button variant="ghost" onClick={handleBack} leftIcon={<ArrowBackIcon />}>
          <Translate label={FormLocale.BACK} />
        </Button>
        <Spacer />
        <Button
          isLoading={buttonLoading}
          onClick={handlePayOrderSubmit}
          isDisabled={!selectedPaymentMethod || !!errorMessage}
        >
          <Translate label={FormLocale.PAY_ORDER} />
        </Button>
      </Flex>
    </>
  );
};

export default Confirmation;
