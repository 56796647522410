import React, { useEffect, useState } from 'react';
import {
  Header, Layout, MobileHeader, NotificationIndicator,
} from '@scaut-sro/meepo';
import { useHistory, useLocation, Link } from 'react-router-dom';
import {
  Box,
  Flex, Input, InputGroup, InputLeftElement, useDisclosure,
} from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { BiSearch } from 'react-icons/bi';
import UserMenu from '../UserMenu/UserMenu';
import MainMenu from '../MainMenu/MainMenu';
import { ScautConst } from '../../theme/ScautClientTheme';
import { translate } from '../../core/localization/LocaleUtils';
import CommonLocale from '../../core/localization/translations/Common.locale';
import { useGetUser } from '../../core/store/reducers/UserSettingsReducer';
import { useFilterReduce, useGetFilter } from '../../core/store/reducers/FilterReducer';
import ScautLogo from '../Logo/Logo';

const AppLayout: React.FunctionComponent = ({ children }) => {
  const location = useLocation().pathname.substring(1);
  const [menuIsSmall, setSmallMenu] = useState(false);
  const activeItem = location === '' ? 'overview' : location;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { language } = useGetUser();
  const { register, handleSubmit, setValue } = useForm<{ search: string | undefined }>();
  const { setSearch } = useFilterReduce();
  const history = useHistory();
  const { search } = useGetFilter();

  const searchSubmit: SubmitHandler<{ search: string | undefined }> = (values) => {
    setSearch(values.search);
    history.push('/checks');
  };

  const handleKeyUp = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSubmit(searchSubmit);
    }
  };

  useEffect(() => {
    setValue('search', search);
  }, [search, setValue]);

  return (
    <Layout
      headerHeight={ScautConst.HEADER_HEIGHT}
      header={(
        <Header
          headerHeight={ScautConst.HEADER_HEIGHT}
          leftMenuWidth={ScautConst.LEFT_MENU_WIDTH}
          isMenuSmall={menuIsSmall}
          toggleMenuWidth={() => setSmallMenu(!menuIsSmall)}
          // notificationCenter={(
          //   <NotificationIndicator />
          // )}
          userMenu={<UserMenu>User Menu</UserMenu>}
          logo={(
            <Link to="/">
              <Flex
                stroke="primaryBase"
                fill="primaryBase"
                h="100%"
                alignItems="center"
              >
                <Box mt={-1}><ScautLogo /></Box>
              </Flex>
            </Link>
          )}
          searchComponent={(
            <form
              style={{ width: '100%' }}
              onSubmit={handleSubmit(searchSubmit)}
            >
              <InputGroup
                color="gray.600"
                size="lg"
              >
                <InputLeftElement
                  pointerEvents="none"
                  pt={1}
                  fontSize="3xl"
                  color="81828D"
                >
                  <BiSearch />
                </InputLeftElement>
                <Input
                  bg="#F4F5F7"
                  variant="filled"
                  w="100%"
                  maxW="720px"
                  h="47px"
                  placeholder={translate(CommonLocale.HEADER_SEARCH, language)}
                  _placeholder={{ color: '#6B6C7A' }}
                  fontSize="lg"
                  onKeyUp={handleKeyUp}
                  {...register('search')}
                />
              </InputGroup>
            </form>
          )}
        />
      )}
      leftMenu={(
        <MainMenu
          leftMenuWidth={ScautConst.LEFT_MENU_WIDTH}
          headerHeight={ScautConst.HEADER_HEIGHT}
          menuIsSmall={menuIsSmall}
          activeItem={activeItem}
        />
      )}
      mobileHeader={(
        <MobileHeader
          isOpen={isOpen}
          onClose={onClose}
          onOpen={onOpen}
          headerHeight={ScautConst.HEADER_HEIGHT}
          leftMenuWidth={ScautConst.LEFT_MENU_WIDTH}
          notificationCenter={(<NotificationIndicator />)}
          userMenu={(<UserMenu>User Menu</UserMenu>)}
          leftMenu={(
            <MainMenu
              leftMenuWidth={ScautConst.LEFT_MENU_WIDTH}
              headerHeight={ScautConst.HEADER_HEIGHT}
              menuIsSmall={menuIsSmall}
              activeItem={activeItem}
              handleSetActiveItem={() => onClose()}
            />
          )}
        />
      )}
    >
      { children }
    </Layout>
  );
};

export default AppLayout;
