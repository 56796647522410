import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { Provider } from 'react-redux';
import { ReactKeycloakProvider, useKeycloak } from '@react-keycloak/web';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import {
  ApolloClient, ApolloLink, ApolloProvider, createHttpLink, InMemoryCache,
} from '@apollo/client';
import debounce from 'lodash.debounce';
import { withScalars } from 'apollo-link-scalars';
import { KeycloakInstance } from 'keycloak-js';
import TagManager from 'react-gtm-module';
import { setContext } from '@apollo/client/link/context';
// @ts-ignore
import ProductFruits from 'react-product-fruits';
import keycloakClient from './keycloak';
import ScautClientTheme from './theme/ScautClientTheme';
import AppStore from './core/store/DefaultStore';
import { useGetUser, useUserSettingsReduce } from './core/store/reducers/UserSettingsReducer';
import AppLayout from './components/AppLayout/AppLayout';
import LoadingScreen from './views/LoadingScreen/LoadingScreen';
import { schema } from './build/generated-sources/scalars/ScalarConstants';
import { useGetUserProfile } from './build/generated-sources/service/QueryService';
import Registration from './views/Registration/Registration';
import NewCheck from './views/NewCheck/NewCheck';
import Checks from './views/Checks/Checks';
import UserSettings from './views/UserSettings/UserSettings';
import Overview from './views/Overview/Overview';
import NoMatch from './views/404/404';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import ScautClientRoles from './core/auth/ScautClientRoles';
import { Language } from './build/generated-sources/enum/Language';
import OrderDetail from './views/OrderDetail/OrderDetail';
import CandidateScreeningDetail from './views/CandidateScreeningDetail/CandidateScreeningDetail';
import MultipleCheckDetail from './views/MultipleCheckDetail/MultipleCheckDetail';
import { OrderType } from './build/generated-sources/enum/OrderType';
import ClientSettings from './views/ClientSettings/ClientSettings';
import '@fontsource/metropolis/100.css';
import '@fontsource/metropolis/200.css';
import '@fontsource/metropolis/300.css';
import '@fontsource/metropolis/400.css';
import '@fontsource/metropolis/500.css';
import '@fontsource/metropolis/600.css';
import '@fontsource/metropolis/700.css';
import '@fontsource/metropolis/800.css';
import '@fontsource/metropolis/900.css';

const Root: React.FunctionComponent = ({ children }) => {
  const { setUserProfile, setLanguage } = useUserSettingsReduce();
  const { loading } = useGetUserProfile({
    firstName: true,
    lastName: true,
    email: true,
    avatar: true,
    jobPosition: true,
    phone: true,
    userPreferences: {
      language: true,
    },
  }, {
    onCompleted: (data) => {
      setUserProfile(data.userProfile);
      const lang: Language = data.userProfile.userPreferences?.language || Language.EN;
      setLanguage(lang);
    },
  });

  return (
    <>
      {loading ? <LoadingScreen /> : <AppLayout>{children}</AppLayout>}
    </>
  );
};

interface ConnectionWrapperProps {
  keycloak?: KeycloakInstance,
  initialized?: boolean
}

const ConnectionWrapper: React.FunctionComponent<ConnectionWrapperProps> = (
  {
    children,
    keycloak,
    initialized,
  },
) => {
  const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_API_URL}/graphql`,
  });

  const logoutDebounce = keycloak ? debounce(() => {
    keycloak.logout({
      redirectUri: `${window.location.protocol}//${window.location.host}`,
    });
  }, 3600000) : undefined;

  const authLink = setContext(async (_, { headers }) => {
    try {
      await keycloak?.updateToken(1);
      if (logoutDebounce) {
        logoutDebounce();
      }
      const authorizationHeader = initialized ? { authorization: `Bearer ${keycloak?.token}` } : {};
      return {
        headers: {
          ...headers,
          ...authorizationHeader,
        },
      };
    } catch (error) {
      keycloak?.logout();
      return undefined;
    }
  });

  const link = ApolloLink.from([
    withScalars({ schema }),
    authLink,
    httpLink,
  ].concat());

  const client = new ApolloClient({
    uri: `${process.env.REACT_APP_API_URL}/graphql`,
    cache: new InMemoryCache(),
    link,
  });

  return (
    <ApolloProvider client={client}>
      {children}
    </ApolloProvider>
  );
};

ConnectionWrapper.defaultProps = {
  initialized: false,
  keycloak: undefined,
};

const PublicApp: React.FunctionComponent = ({
  children,
}) => (
  <ConnectionWrapper>
    {children}
  </ConnectionWrapper>
);

const AuthorizedConnectionWrapper: React.FunctionComponent = ({ children }) => {
  const { keycloak, initialized } = useKeycloak();

  return (
    <>
      {initialized ? (
        <ConnectionWrapper initialized={initialized} keycloak={keycloak}>
          {children}
        </ConnectionWrapper>
      ) : (
        <LoadingScreen />
      )}
    </>
  );
};

const PrivateApp: React.FunctionComponent = ({ children }) => {
  const { userProfile, language } = useGetUser();

  return (
    <ReactKeycloakProvider
      authClient={keycloakClient}
      initOptions={{ onLoad: 'login-required', locale: 'en' }}
    >
      <AuthorizedConnectionWrapper>
        <Root>
          {userProfile && (
            <>
              <ProductFruits
                projectCode="KAGcuNR3yyvKUmyI"
                email={userProfile?.email}
                language={language?.toLocaleLowerCase()}
                username={userProfile?.email}
                firstname={userProfile?.firstName}
                lastname={userProfile?.lastName}
              />
            </>
          )}
          {children}
        </Root>
      </AuthorizedConnectionWrapper>
    </ReactKeycloakProvider>
  );
};

function App() {
  React.useEffect(() => {
    TagManager.initialize({ gtmId: process.env.REACT_APP_GTM_TAG || '' });
  }, []);

  return (
    <Provider store={AppStore}>
      <ChakraProvider theme={ScautClientTheme}>
        <Router>
          <Switch>
            <Route path="/registration">
              <PublicApp>
                <Registration />
              </PublicApp>
            </Route>
            <Route path="*">
              <PrivateApp>
                <Switch>
                  <PrivateRoute path="/new-check" requiredRoles={[ScautClientRoles.REGULAR]}>
                    <NewCheck />
                  </PrivateRoute>
                  <PrivateRoute path="/checks" requiredRoles={[ScautClientRoles.REGULAR]}>
                    <Checks />
                  </PrivateRoute>
                  <PrivateRoute path="/single-checks" requiredRoles={[ScautClientRoles.REGULAR]}>
                    <Checks filters={{ type: [OrderType.SINGLE] }} />
                  </PrivateRoute>
                  <PrivateRoute path="/multiple-checks" requiredRoles={[ScautClientRoles.REGULAR]}>
                    <Checks filters={{ type: [OrderType.MULTIPLE] }} />
                  </PrivateRoute>
                  <PrivateRoute exact path="/order/:id" requiredRoles={[ScautClientRoles.REGULAR]}>
                    <OrderDetail />
                  </PrivateRoute>
                  <PrivateRoute exact path="/multicheck/:id" requiredRoles={[ScautClientRoles.REGULAR]}>
                    <MultipleCheckDetail />
                  </PrivateRoute>
                  <PrivateRoute exact path="/screening/:id" requiredRoles={[ScautClientRoles.REGULAR]}>
                    <CandidateScreeningDetail />
                  </PrivateRoute>
                  <PrivateRoute path="/user-settings" requiredRoles={[ScautClientRoles.REGULAR]}>
                    <UserSettings />
                  </PrivateRoute>
                  <PrivateRoute path="/client-settings" requiredRoles={[ScautClientRoles.ADMIN]}>
                    <ClientSettings />
                  </PrivateRoute>
                  <PrivateRoute exact path="/" requiredRoles={[ScautClientRoles.REGULAR]}>
                    <Overview />
                  </PrivateRoute>
                  <PrivateRoute path="*" requiredRoles={[ScautClientRoles.REGULAR]}>
                    <NoMatch />
                  </PrivateRoute>
                </Switch>
              </PrivateApp>
            </Route>
          </Switch>
        </Router>
      </ChakraProvider>
    </Provider>
  );
}

export default App;
