import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Flex,
  FormLabel,
  GridItem,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  SimpleGrid,
  Spacer,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import {
  ConfirmationModal,
  Currency,
  getCurrencyLocalizationFromLanguage,
  H2,
  H3,
  InfoContainer,
  MeepoTable,
  SectionContainer,
  Toast,
} from '@scaut-sro/meepo';
import {
  BiWallet,
  BsCreditCard,
  FaCcDinersClub,
  FaCcDiscover,
  RiVisaFill,
  SiAmericanexpress,
  SiMastercard,
} from 'react-icons/all';
import { useApolloClient } from '@apollo/client';
import { useGetUser } from '../../../core/store/reducers/UserSettingsReducer';
import { translate } from '../../../core/localization/LocaleUtils';
import CreditsLocale from './Credits.locale';
import {
  useAddPrepayment,
  useRemovePaymentProfile,
} from '../../../build/generated-sources/service/MutationService';
import Translate from '../../../components/Translate/Translate';
import LoadingScreen from '../../LoadingScreen/LoadingScreen';
import { handleApolloErrors } from '../../../core/error/ScautErrorHandler';
import { Direction } from '../../../build/generated-sources/enum/Direction';
import {
  useLazyGetClientChargeHistory,
  useLazyGetClientPaymentCardInfo,
} from '../../../build/generated-sources/service/QueryService';
import { ChargeHistoryTableData, CreditsProps } from './Credits.model';
import { ClientChargeHistory } from '../../../build/generated-sources/dto/ClientChargeHistory';
import TableLocale from '../../../core/localization/translations/Table.locale';
import ShowDate from '../../../components/ShowDate/ShowDate';
import ClientUserRow from './ClientUserRow';
import { Language } from '../../../build/generated-sources/enum/Language';
import FormLocale from '../../../core/localization/translations/Form.locale';
import ChargifyForm from './PaymentCard/ChargifyForm';
import { ClientPaymentCardInfoDto } from '../../../build/generated-sources/dto/ClientPaymentCardInfoDto';
import { ClientChargeType } from '../../../build/generated-sources/enum/ClientChargeType';
import updateCreditCard from '../../../core/service/queries/ChargifyService';

export function getPrepaymentsTableData(data: ClientChargeHistory[] | undefined,
  language: Language): ChargeHistoryTableData[] {
  if (data) {
    return data.map((chargeHistory) => {
      let clientUserText = translate(CreditsLocale.UNKNOWN_USER, language);
      if (chargeHistory.clientUser) {
        clientUserText = `${chargeHistory?.clientUser?.firstName} ${chargeHistory?.clientUser?.lastName}`;
      }
      return ({
        id: chargeHistory?.id || '-undefined-',
        amount: (
          <Text color={chargeHistory.chargeType === ClientChargeType.PAYMENT ? 'red' : undefined}>
            <Currency
              amount={chargeHistory.amount || 0}
              localization={getCurrencyLocalizationFromLanguage(language)}
            />
          </Text>
        ),
        created: <ShowDate date={chargeHistory?.created} time />,
        clientUser: <ClientUserRow name={clientUserText} />,
      });
    });
  }
  return [];
}

const Credits: React.FunctionComponent<CreditsProps> = (props) => {
  const { clientDetail, setClientUpdated } = props;
  const client = useApolloClient();
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
  const { language } = useGetUser();
  const [prepaymentsList, setPrepaymentsList] = useState<ChargeHistoryTableData[]>([]);
  const [currentPaymentCard, setCurrentPaymentCard] = useState<ClientPaymentCardInfoDto | undefined>(undefined);
  const [amount, setAmount] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [sorting, setSorting] = useState<{ sortBy?: string, sortDirection?: Direction }>({});
  const size = 100;

  const [clientChargeHistoryHandle, { loading }] = useLazyGetClientChargeHistory({
    content: {
      id: true,
      amount: true,
      chargeType: true,
      created: true,
      clientUser: {
        firstName: true,
        lastName: true,
      },
    },
  }, {
    onError: () => {
      Toast({
        title: translate(CreditsLocale.ERROR_GETTING_PREPAYMENTS, language),
        status: 'error',
      });
    },
    variables: {
      paging: {
        page,
        size,
        sortBy: sorting.sortBy,
        sortDirection: sorting.sortDirection,
      },
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (response) => {
      const onCompleteData = getPrepaymentsTableData(response.clientChargeHistory.content, language);
      if (page === 0) {
        setPrepaymentsList(onCompleteData);
      } else {
        setPrepaymentsList((prev) => [...prev, ...onCompleteData]);
      }
    },
  });

  const [mutateAddPrepayment, mutateAddPrepaymentOptions] = useAddPrepayment(
    {
      success: true,
    },
    {
      onCompleted: () => {
        Toast({
          title: translate(CreditsLocale.CREDIT_CHARGE_SUCCESSFUL, language),
          status: 'success',
        });
        setClientUpdated(true);
        clientChargeHistoryHandle();
      },
      onError: (error) => {
        handleApolloErrors(error, language);
      },
    },
  );

  const [clientPaymentCardInfoHandle] = useLazyGetClientPaymentCardInfo({
    cardType: true,
    maskedCardNumber: true,
  }, {
    onError: () => {
      Toast({
        title: translate(CreditsLocale.ERROR_GETTING_PAYMENT_CARD_INFO, language),
        status: 'error',
      });
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (response) => {
      setCurrentPaymentCard(response.clientPaymentCardInfo);
    },
  });

  useEffect(() => {
    clientChargeHistoryHandle();
    clientPaymentCardInfoHandle();
  }, [clientDetail, clientChargeHistoryHandle, clientPaymentCardInfoHandle]);

  const addPrepayment = () => {
    if (amount > 0) {
      mutateAddPrepayment({
        variables: {
          balance: amount,
        },
      });
    }
  };

  const handleSubmit = (token: string, setButtonLoading: (value: boolean) => void) => {
    updateCreditCard(client, { success: true }, { token })
      .then(() => {
        Toast({
          title: translate(CreditsLocale.PAYMENT_CARD_CHANGE_SUCCESSFUL, language),
          status: 'success',
        });
        setClientUpdated(true);
        clientPaymentCardInfoHandle();
        setButtonLoading(false);
      }).catch((error) => {
        handleApolloErrors(error, language);
        setButtonLoading(false);
      });
  };

  const [mutateRemovePaymentProfile] = useRemovePaymentProfile({
    success: true,
  }, {
    onCompleted: () => {
      Toast({
        title: translate(CreditsLocale.PAYMENT_CARD_DELETED, language),
        status: 'success',
      });
      onDeleteClose();
      setClientUpdated(true);
      clientPaymentCardInfoHandle();
    },
    onError: () => {
      Toast({
        title: translate(CreditsLocale.ERROR_DELETING_PAYMENT_CARD, language),
        status: 'error',
      });
    },
  });

  const removePaymentCard = () => {
    mutateRemovePaymentProfile();
  };

  const getCardIcon = (): JSX.Element => {
    if (currentPaymentCard) {
      switch (currentPaymentCard.cardType) {
        case 'visa':
          return <Icon as={RiVisaFill} fontSize={50} />;
        case 'master':
          return <Icon as={SiMastercard} fontSize={50} />;
        case 'discover':
          return <Icon as={FaCcDiscover} fontSize={50} />;
        case 'american_express':
          return <Icon as={SiAmericanexpress} fontSize={50} />;
        case 'diners_club':
          return <Icon as={FaCcDinersClub} fontSize={50} />;
        default:
          return <Icon as={BsCreditCard} fontSize={50} />;
      }
    }
    return <Icon as={BsCreditCard} fontSize={50} />;
  };

  return (
    <>
      { !clientDetail ? (
        <LoadingScreen />
      ) : (
        <SectionContainer>
          <Box overflowX="auto">
            <SimpleGrid columns={[2]} spacing={[6]} mt={10}>
              <Box>
                <H2 mt={6}><Translate label={CreditsLocale.YOUR_CREDIT} /></H2>
                <Divider mt={6} />
                <SimpleGrid columns={[4]} mt={10}>
                  <GridItem mt={5} colSpan={[1]} color="gray.400">
                    <BiWallet size="35" />
                  </GridItem>
                  <GridItem colSpan={[3]}>
                    <Text fontSize="5xl" color="#40d372">
                      <Currency
                        amount={clientDetail?.prepaidBalance || 0}
                        localization={getCurrencyLocalizationFromLanguage(language)}
                      />
                    </Text>
                  </GridItem>
                </SimpleGrid>
                <SimpleGrid columns={[5]}>
                  <GridItem colSpan={[3]} color="gray.400" />
                  <GridItem colSpan={[2]}>
                    <Text color="gray.400">
                      <Translate label={CreditsLocale.LAST_CHARGE} />
                    </Text>
                    <Text color="gray.400">
                      <ShowDate date={clientDetail?.lastCharge} time />
                    </Text>
                  </GridItem>
                </SimpleGrid>
                <Divider mt={6} />
                <Box overflowX="auto" mt={6}>
                  <H3 mt={5}><Translate label={CreditsLocale.NEED_HELP} /></H3>
                  <Box minW="600px" />
                  <Text>
                    <Translate label={CreditsLocale.WRITE_TO_US} />
                    <Button
                      variant="link"
                      onClick={() => window.open('mailto:orders@scaut.com')}
                    >
                      orders@scaut.com
                    </Button>
                  </Text>
                </Box>
                <Box mt={20} overflowX="auto">
                  <H2><Translate label={CreditsLocale.CREDIT_HISTORY} /></H2>
                  <Box mt={6} minW="600px">
                    <MeepoTable
                      columns={[
                        {
                          id: 'created',
                          label: translate(CreditsLocale.DATE, language),
                          minW: '20px',
                        },
                        {
                          id: 'amount',
                          label: translate(CreditsLocale.AMOUNT, language),
                          minW: '100px',
                        },
                        {
                          id: 'clientUser',
                          label: translate(CreditsLocale.USER, language),
                          minW: '100px',
                        },
                      ]}
                      data={prepaymentsList}
                      isLoading={loading}
                      noData={<Text><Translate label={TableLocale.NO_DATA} /></Text>}
                      setPage={() => {
                        setPage((prev) => prev + 1);
                      }}
                      onSort={(newSorting) => {
                        const direction: Direction | undefined = newSorting.type === 'asc'
                          ? Direction.ASC
                          : newSorting.type === 'desc'
                            ? Direction.DESC
                            : undefined;
                        setSorting({
                          sortBy: newSorting.column,
                          sortDirection: direction,
                        });
                      }}
                      activeSorting={{
                        column: sorting.sortBy,
                        type: sorting.sortDirection === Direction.ASC
                          ? 'asc'
                          : sorting.sortDirection === Direction.DESC
                            ? 'desc'
                            : 'none',
                      }}
                    />
                  </Box>
                </Box>
              </Box>
              <Box>
                <SimpleGrid columns={[1]}>
                  <InfoContainer bgColor="#f4f5f7">
                    <H2><Translate label={CreditsLocale.ADD_CREDIT} /></H2>
                    {currentPaymentCard ? (
                      <>
                        <Text mt={5}>
                          <Translate label={CreditsLocale.ADD_CREDIT_INFO} />
                        </Text>
                        <SimpleGrid columns={[2]} mt={10}>
                          <Box mt={10}>
                            <FormLabel color="gray.400"><Translate label={CreditsLocale.AMOUNT} /></FormLabel>
                            <InputGroup size="lg">
                              <Input
                                type="number"
                                defaultValue={amount}
                                onChange={(event) => {
                                  setAmount(+event.target.value);
                                }}
                              />
                              <InputRightElement pointerEvents="none">
                                <Translate label={FormLocale.CURRENCY} />
                              </InputRightElement>
                            </InputGroup>

                          </Box>
                          <Box mt={10}>
                            <Flex mt={8}>
                              <Spacer />
                              <Button
                                ml={3}
                                size="lg"
                                bg="#40d372"
                                _hover={{ bg: '#3abe67' }}
                                _active={{
                                  bg: '#3abe67',
                                }}
                                onClick={addPrepayment}
                                isDisabled={amount <= 0}
                                isLoading={mutateAddPrepaymentOptions.loading}
                              >
                                <Translate label={CreditsLocale.ADD_CREDIT} />
                              </Button>
                            </Flex>
                          </Box>
                        </SimpleGrid>
                      </>
                    ) : (
                      <Box mt={2}>
                        <Text fontSize="xs" color="red"><Translate label={CreditsLocale.MISSING_PAYMENT_CARD} /></Text>
                      </Box>
                    )}
                  </InfoContainer>
                  <Box mt={5} />
                  <InfoContainer bgColor="#f4f5f7">
                    <H2><Translate label={CreditsLocale.PAYMENT_CARD} /></H2>
                    {currentPaymentCard ? (
                      <>
                        <Flex mt={6}>
                          { getCardIcon() }
                          <Input
                            ml={3}
                            mt={2}
                            value={currentPaymentCard.maskedCardNumber}
                            readOnly
                          />
                          <Spacer />
                          <Button mt={2} ml={5} onClick={() => onDeleteOpen()} variant="outline" colorScheme="red">
                            <Translate label={FormLocale.DELETE} />
                          </Button>
                          <ConfirmationModal
                            message={translate(CreditsLocale.PAYMENT_CARD_DELETE_CONFIRMATION, language)}
                            yes={translate(FormLocale.YES, language)}
                            no={translate(FormLocale.NO, language)}
                            name={`${currentPaymentCard.maskedCardNumber}?`}
                            modalFunction={removePaymentCard}
                            isOpen={isDeleteOpen}
                            onClose={onDeleteClose}
                            onOpen={onDeleteOpen}
                          />
                        </Flex>
                        <H3 mt={7}><Translate label={CreditsLocale.CHANGE_PAYMENT_CARD} /></H3>
                        <Text mt={5}>
                          <Translate label={CreditsLocale.CHANGE_PAYMENT_CARD_INFO} />
                        </Text>
                      </>
                    ) : (
                      <>
                        <H3 mt={7}><Translate label={CreditsLocale.ADD_PAYMENT_CARD} /></H3>
                        <Text mt={5}>
                          <Translate label={CreditsLocale.ADD_PAYMENT_CARD_INFO} />
                        </Text>
                      </>
                    ) }
                    <ChargifyForm onSubmit={handleSubmit} isUpdate={!!currentPaymentCard} />
                  </InfoContainer>
                </SimpleGrid>
              </Box>
            </SimpleGrid>
          </Box>
        </SectionContainer>
      )}
    </>
  );
};
export default Credits;
