import { ApolloClient } from '@apollo/client';
import { GraphqlBuilder, GraphQLType } from '@scaut-sro/npm-core-generator';
import { FetchResult } from '@apollo/client/link/core';
import {
  UseUpdateCreditCardData,
  UseUpdateCreditCardVariables,
} from '../../../build/generated-sources/service/MutationServiceModel';
import { ResultResponseMapper } from '../../../build/generated-sources/dto/ResultResponse';

export default function updateCreditCard(
  client: ApolloClient<any>,
  mapper: ResultResponseMapper,
  variables: UseUpdateCreditCardVariables,
): Promise<FetchResult<UseUpdateCreditCardData>> {
  const mutationBuilder: GraphqlBuilder<ResultResponseMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  const mutation = mutationBuilder
    .setName('updateCreditCard')
    .setReturnProperties(mapper)
    .declareVariables({
      token: 'String',
    })
    .setProperties(
      'token: $token',
    )
    .build();

  return client.mutate<UseUpdateCreditCardData, UseUpdateCreditCardVariables>({
    mutation,
    variables,
    fetchPolicy: 'no-cache',
  });
}
