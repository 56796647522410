import { Translation } from '../../core/localization/Localization.model';

class CandidateScreeningDetailLocale {
  public static CANDIDATE_SCREENING: Translation = { EN: 'Candidate Screening', CZ: 'Kontrola Kandidáta' };
  public static SCREENINGS: Translation = { EN: 'Screenings', CZ: 'Dílčí kontroly' };
  public static CANDIDATE_IDENTITY: Translation = { EN: 'Personal details', CZ: 'Osobní údaje' };
  public static TITLE: Translation = { EN: 'Title', CZ: 'Titul' };
  public static FIRST_NAME: Translation = { EN: 'First name', CZ: 'Jméno' };
  public static LAST_NAME: Translation = { EN: 'Last name', CZ: 'Příjmení' };
  public static DATE_OF_BIRTH: Translation = { EN: 'Date of birth', CZ: 'Datum narození' };
  public static BACK_TO_ORDER: Translation = { EN: 'Back to order', CZ: 'Zpět na objednávku' };
  public static RESULT: Translation = { EN: 'Screening result', CZ: 'Výsledek kontroly kandidáta' };
  public static CHECK_TABLE_NAME: Translation = { EN: 'Screening name', CZ: 'Název kontroly' };
  public static CHECK_TABLE_COUNTRY: Translation = { EN: 'Country', CZ: 'Země' };
  public static CHECK_TABLE_RESULT: Translation = { EN: 'Result', CZ: 'Výsledek' };
  public static CHECK_TABLE_STATE: Translation = { EN: 'State', CZ: 'Stav' };
  public static CANDIDATE_SCREENING_INFO: Translation = { EN: 'Candidate screening info', CZ: 'Informace o kontrole' };
  public static EXECUTION_DETAIL: Translation = { EN: 'Execution detail:', CZ: 'Detail exekuce:' };
  public static CARD_PAYMENT: Translation = { EN: 'Card payment', CZ: 'Platební karta' };
  public static INVOICE_AFTER_ORDER: Translation = { EN: 'Invoice after order', CZ: 'Faktura po objednávce' };
  public static INVOICE_MONTHLY: Translation = { EN: 'Invoice monthly', CZ: 'Měsíční faktura' };
  public static PREPAID: Translation = { EN: 'Prepaid', CZ: 'Předplacené' };
  public static DETAILS: Translation = { EN: 'Details', CZ: 'Detaily' };
  public static PAYMENT_INFORMATION: Translation = { EN: 'Payment information', CZ: 'Platební údaje' };
  public static PAYMENT_METHOD: Translation = { EN: 'Payment method', CZ: 'Platební metoda' };
  public static MISSING_PAYMENT_CARD: Translation = {
    EN: 'Missing payment card for the selected payment method',
    CZ: 'Pro danou platební metodu je nutné přidat platební kartu',
  };
  public static ORDERED_SERVICES: Translation = {
    EN: 'Ordered items',
    CZ: 'Objednané položky',
  };
  public static FILLED_IN_INFO: Translation = { EN: 'Filled in information', CZ: 'Doplněné informace' };
  public static POWER_OF_ATTORNEY: Translation = { EN: 'Power of Attorney', CZ: 'Plná moc' };
  public static GLOBAL_DATA: Translation = { EN: 'Global Data', CZ: 'Obecné informace' };
  public static ERROR_GETTING_DATA: Translation = {
    EN:
      'There was a problem getting data',
    CZ: 'Při získávání dat došlo k problému',
  };
  public static ANONYMIZATION_SUCCESS: Translation = {
    EN: 'Anonymization success', CZ: 'Anonymizace úspěšná',
  };
  public static ORDER_ANONYMIZATION_CONFIRMATION: Translation = {
    EN: 'Are you sure you want to anonymize order with ID',
    CZ: 'Opravdu chcete anonymizovat objednávku s ID',
  };
  public static ANONYMIZE_BUTTON: Translation = {
    EN: 'Anonymize',
    CZ: 'Anonymizovat',
  };
  public static CHECKED_PERSON: Translation = { EN: 'Subject of screening', CZ: 'Kandidát' };
  public static IMPORTANT_DOCUMENTS: Translation = { EN: 'Important documents', CZ: 'Podklady ke kontrole' };
  public static AUTHORIZATION: Translation = { EN: 'Authorization', CZ: 'Pověření' };
  public static AUTHORIZATION_SEND_SUCCESS: Translation = {
    EN: 'Authorization was sent successfully',
    CZ: 'Odeslání pověření proběhlo v pořádku',
  };
  public static AUTHORIZATION_SEND_ERROR: Translation = {
    EN: 'An error occured while sending the Authorization. Please try again later.',
    CZ: 'Při odesílání pověření došlo k chybě. Zkuste to prosím později.',
  };
  public static NOTES_LABEL: Translation = {
    EN: 'Screening detail',
    CZ: 'Detail kontroly',
  };
  public static SUCCESS_SEND_FORM: Translation = { EN: 'Success send form', CZ: 'Úspěšné odeslání formuláře' };
}

export default CandidateScreeningDetailLocale;
