import { Translation } from '../../../core/localization/Localization.model';

class ConflictsLocale {
  public static CLIENT_CONFLICTS: Translation = { EN: 'Companies - Czech Republic only!', CZ: 'Firmy - pouze ČR' };
  public static SAVE_CHANGES: Translation = { EN: 'Save changes', CZ: 'Uložit změny' };
  public static SUBJECT_NAME: Translation = { EN: 'Company name', CZ: 'Název firmy' };
  public static ID: Translation = { EN: 'Company ID*', CZ: 'IČ*' };
  public static NOTE: Translation = { EN: 'Note', CZ: 'Poznámka' };
  public static ERASE_TABLE: Translation = { EN: 'Erase table', CZ: 'Smazat tabulku' };
  public static UPLOAD_INFO: Translation = {
    EN: 'You can also easily drag and drop the file onto the button.',
    CZ: 'Soubor můžete na tlačítko také jednoduše přetáhnout myší.',
  };
  public static INFORMATION_TEXT: Translation = {
    EN: 'Check "Conflicts of interests" is based on this list. It is required to fill out the company ID.',
    CZ: 'Toto je výchozí seznam pro kontrolu "Konflikt zájmů". Zadání IČ je povinné.',
  };
  public static RECOMMENDATION_TEXT: Translation = {
    EN: 'We recommend 200 items at maximum',
    CZ: 'Doporučujeme maximálně 200 záznamů.',
  };
  public static ERROR_GETTING_SUBJECTS: Translation = {
    EN: 'There was a problem getting subjects.',
    CZ: 'Při získávání subjektů došlo k problému.',
  };
  public static ERROR_GETTING_DATA: Translation = {
    EN: 'There was a problem getting data',
    CZ: 'Při získávání dat došlo k problému',
  };
}

export default ConflictsLocale;
