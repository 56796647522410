import React, { useMemo, useState } from 'react';
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  GridItem,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SimpleGrid,
  Spacer,
  Text,
} from '@chakra-ui/react';
import {
  GridParameter,
  Note,
} from '@scaut-sro/meepo';
import { useHistory } from 'react-router-dom';
import { HiOutlineDotsVertical } from 'react-icons/hi';
import { useKeycloak } from '@react-keycloak/web';
import TagManager from 'react-gtm-module';
import { MultipleCheckDetailProps } from './Checks.model';
import Translate from '../../components/Translate/Translate';
import ChecksLocale from './Checks.locale';
import TableLocale from '../../core/localization/translations/Table.locale';
import { translate } from '../../core/localization/LocaleUtils';
import { showDate } from '../../core/date/showDate';
import { useGetUser } from '../../core/store/reducers/UserSettingsReducer';
import { Result } from '../../build/generated-sources/enum/Result';
import { ResultsLocale } from '../../components/ResultBadge/ResultBadge.model';
import { downloadFile } from '../../core/service/queries/FileUploadService';
import ScreeningBadge from '../../components/ScreeningBadge/ScreeningBadge';

const MultipleCheckDetail: React.FunctionComponent<MultipleCheckDetailProps> = (props) => {
  const { order, candidateScreening, anonymizationDate } = props;
  const history = useHistory();
  const { language, userProfile } = useGetUser();
  const [screeningsFailedCheck, setScreeningsFailedCheck] = useState<{ [key:string]: Result }>({});
  const [badResultCount, setBadResultCount] = useState<number>(0);
  const keycloak = useKeycloak();

  const downloadReport = () => {
    if (keycloak.keycloak.token) {
      downloadFile(keycloak.keycloak.token, `/dms/downloadMultipleReport/${order?.id}`);
    }
  };

  useMemo(() => {
    let count = 0;
    order?.subOrders?.[0].candidateScreenings?.forEach((screening) => {
      if (screening.result === Result.FOUND_RESULTS) count += 1;
    });
    setBadResultCount(count);

    const screenings: {
      [key:string]: Result
    } = {};
    order?.subOrders?.[0].candidateScreenings?.forEach((cs) => {
      cs.screenings?.forEach((screening) => {
        const name = screening.screeningDefinition?.name?.localizationStrings?.[language];
        if (name) {
          const result = screenings[name];
          if (!result) {
            screenings[name] = Result.CLEAR;
          }
          if (screening.result === Result.UNRESOLVED) {
            screenings[name] = Result.UNRESOLVED;
          } else if (screenings[name] !== Result.UNRESOLVED && screening.result === Result.FOUND_RESULTS) {
            screenings[name] = Result.FOUND_RESULTS;
          }
        }
      });
    });
    setScreeningsFailedCheck(screenings);
  }, [order, language]);

  TagManager.dataLayer({
    dataLayer: {
      event: 'page_view',
      realm: 'client',
      page: {
        url: document.location.origin + document.location.pathname + document.location.search,
        title: 'Checks - multiple',
      },
      user: {
        email: userProfile?.email,
        given_name: userProfile?.firstName,
        family_name: userProfile?.lastName,
        job_position: userProfile?.jobPosition,
        language,
      },
    },
  });

  return (
    <>
      {order ? (
        <Box p={3} pt={6}>
          {candidateScreening ? (
            <SimpleGrid columns={[1, 1, 3, 3, 4, 4]} gap={16}>
              <GridItem colSpan={[1, 1, 2, 2, 3, 3]}>
                <Box pb={3}>
                  <Text color="#002458" fontSize="lg"><Translate label={ChecksLocale.TABLE_DETAIL_SCREENINGS} /></Text>
                </Box>
                <SimpleGrid columns={[1, 1, 2, 2, 3, 3]} gap={3} mt={4}>
                  {Object.keys(screeningsFailedCheck)?.map((screening) => (
                    <ScreeningBadge
                      key={screening}
                      result={screeningsFailedCheck[screening] || Result.UNRESOLVED}
                    >
                      {screening}
                    </ScreeningBadge>
                  ))}
                </SimpleGrid>
              </GridItem>
              <GridItem>
                <SimpleGrid gap={6}>
                  <Text color="#002458" fontSize="lg"><Translate label={ChecksLocale.TABLE_DETAIL_INFO} /></Text>
                  <GridParameter
                    label={translate(ChecksLocale.TABLE_DETAIL_MULTIPLE_CHECK_START, language)}
                    value={showDate(language, order?.processStarted)}
                  />
                  <GridParameter
                    label={translate(ChecksLocale.TABLE_DETAIL_MULTIPLE_CHECK_END, language)}
                    value={showDate(language, order?.processCompleted)}
                  />
                  <GridParameter
                    label={translate(ChecksLocale.TABLE_DETAIL_CANDIDATES_COUNT, language)}
                    value={(
                      `${badResultCount}/${order.subOrders?.[0].candidateScreenings?.length}`
                      + ` ${translate(ResultsLocale.FOUND_RESULTS, language)}`
                    ).toLowerCase()}
                  />
                </SimpleGrid>
              </GridItem>
            </SimpleGrid>
          ) : (
            <Center w="100%" p={8}>
              <Text><Translate label={TableLocale.NO_DATA} /></Text>
            </Center>
          )}
          <Divider mt={6} mb={3} />
          <Flex alignItems="center">
            <Note textTransform="uppercase">
              <Translate label={ChecksLocale.TABLE_DETAIL_CANDIDATE_SCREENING_ANONYMIZED} />
              {anonymizationDate ? (
                <span>{`: ${showDate(language, anonymizationDate)}`}</span>
              ) : undefined}
            </Note>
            <Spacer />
            <Menu variant="blue" placement="top">
              <MenuButton
                as={IconButton}
                variant="ghost"
                aria-label="Context menu"
                icon={<HiOutlineDotsVertical />}
                borderRadius="full"
              />
              <MenuList>
                <MenuItem onClick={downloadReport}>
                  <Translate label={ChecksLocale.TABLE_DETAIL_DOWNLOAD_MULTIPLE_REPORT} />
                </MenuItem>
                {/* <MenuItem><Translate label={ChecksLocale.TABLE_DETAIL_SHARE} /></MenuItem> */}
              </MenuList>
            </Menu>
            <Button ml={3} onClick={() => history.push(`/multicheck/${order?.id}`)}>
              <Translate label={ChecksLocale.TABLE_DETAIL_GO_TO_DETAIL} />
            </Button>
          </Flex>
        </Box>
      ) : (
        <Center w="100%" p={8}>
          <Text><Translate label={TableLocale.NO_DATA} /></Text>
        </Center>
      )}
    </>
  );
};

export default MultipleCheckDetail;
