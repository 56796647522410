enum ScautError {
  CLIENT_ERROR = 'CLIENT_ERROR',
  ORDER_NOT_FOUND = 'ORDER_NOT_FOUND',
  INVALID_DYNAMIC_FORM_INPUT = 'INVALID_DYNAMIC_FORM_INPUT',
  REGISTRATION_ERROR = 'REGISTRATION_ERROR',
  ERROR_2FA_SETTINGS = 'ERROR_2FA_SETTINGS',
  PAYMENT_METHOD_NOT_ALLOWED = 'PAYMENT_METHOD_NOT_ALLOWED',
  PAYMENT_METHOD_CHANGE_ERROR = 'PAYMENT_METHOD_CHANGE_ERROR',
  PASSWORDS_NOT_EQUAL = 'PASSWORDS_NOT_EQUAL',
  PASSWORDS_WRONG_OLD_PASSWORD = 'PASSWORDS_WRONG_OLD_PASSWORD',
  MISSING_TRANSLATION = 'MISSING_TRANSLATION',
  MISSING_TYPE = 'MISSING_TYPE',
  PERMISSION_VIOLATION = 'PERMISSION_VIOLATION',
  DYNAMIC_FORM_INACTIVE = 'DYNAMIC_FORM_INACTIVE',
  MISSING_CLIENT_PAYMENT_PROFILE = 'MISSING_CLIENT_PAYMENT_PROFILE',
  WHITELIST_CONTAINS_FORBIDDEN_CHARACTERS = 'WHITELIST_CONTAINS_FORBIDDEN_CHARACTERS',
  INSUFFICIENT_CREDITS = 'INSUFFICIENT_CREDITS',
  INVALID_PASSWORD_LENGTH = 'INVALID_PASSWORD_LENGTH',
  INVALID_PASSWORD_UPPER_CASE = 'INVALID_PASSWORD_UPPER_CASE',
  INVALID_PASSWORD_SPECIAL_CHARS = 'INVALID_PASSWORD_SPECIAL_CHARS',
  INVALID_PASSWORD_DIGITS = 'INVALID_PASSWORD_DIGITS',
  INVALID_PASSWORD_MAX_LENGTH = 'INVALID_PASSWORD_MAX_LENGTH',
  INVALID_PASSWORD_HISTORY = 'INVALID_PASSWORD_HISTORY',
  INVALID_PASSWORD_LOWER_CASE = 'INVALID_PASSWORD_LOWER_CASE',
  INVALID_PASSWORD_NOT_EMAIL = 'INVALID_PASSWORD_NOT_EMAIL',
  INVALID_PASSWORD = 'INVALID_PASSWORD',
  CANNOT_DELETE_CURRENT_USER = 'CANNOT_DELETE_CURRENT_USER',
  AVATAR_VALIDATION_FAILURE = 'AVATAR_VALIDATION_FAILURE',
  AVATAR_UPLOAD_FAILURE = 'AVATAR_UPLOAD_FAILURE',
}

export default ScautError;
