import React, { useMemo } from 'react';
import { SimpleGrid } from '@chakra-ui/react';
import { getCurrencyLocalizationFromLanguage, H4, ProductCard } from '@scaut-sro/meepo';
import { ProductPickerProps } from './ProductPicker.model';
import Translate from '../Translate/Translate';
import ProductPickerLocale from './ProductPicker.locale';
import { ScreeningPackage } from '../../build/generated-sources/dto/ScreeningPackage';
import { useGetUser } from '../../core/store/reducers/UserSettingsReducer';

const ProductPicker: React.FunctionComponent<ProductPickerProps> = (props) => {
  const {
    screeningPackages, screeningDefinitions, onChange, packages, definitions, packagesLabel, screeningsLabel,
  } = props;
  const { language } = useGetUser();

  const disabledProducts: number[] = useMemo(() => {
    const newDisabledProducts: number[] = [];
    screeningPackages?.map((sPackage) => {
      if (sPackage.id && sPackage.screeningDefinitions && packages.indexOf(sPackage.id) !== -1) {
        sPackage.screeningDefinitions.map((sDefinition) => {
          if (sDefinition.id) newDisabledProducts.push(sDefinition.id);
        });
      }
    });
    return newDisabledProducts;
  }, [screeningPackages, packages]);

  const handleDefinitionAmountChange = (num: number, id: number) => {
    const newDefinitions = [...definitions];
    if (definitions.map((def) => def.id).indexOf(id) !== -1) {
      newDefinitions[definitions.map((def) => def.id).indexOf(id)].amount = num;
    } else {
      newDefinitions.push({
        id,
        amount: num,
      });
    }
    onChange(packages, newDefinitions);
  };

  const handleDefinitionChange = (isChecked: boolean, id: number) => {
    let newDefinitions = [...definitions];
    if (definitions.map((def) => def.id).indexOf(id) !== -1) {
      newDefinitions = definitions.filter((value) => value.id !== id);
    } else {
      newDefinitions.push({
        id,
        amount: 1,
      });
    }
    onChange(packages, newDefinitions);
  };

  const handlePackageChange = (isChecked: boolean, id: number) => {
    let newPackages = [...packages];
    let newDefinitions = [...definitions];
    const packageDef: ScreeningPackage | undefined = screeningPackages?.filter(
      (sPackage) => sPackage.id === id,
    )[0];
    if (packageDef) {
      const packageDefinitions: number[] = packageDef.screeningDefinitions?.filter((def) => !def.countable).map(
        (sDef) => sDef.id as number,
      ) || [];
      if (packages.indexOf(id) !== -1) {
        newPackages = packages.filter((value) => value !== id);
        newDefinitions = definitions.filter((value) => !(packageDefinitions.indexOf(value.id!) !== -1));
      } else {
        newPackages.push(id);
        newDefinitions = definitions.filter((value) => !(packageDefinitions.indexOf(value.id!) !== -1));
      }
    }
    onChange(newPackages, newDefinitions);
  };

  return (
    <>
      {
        screeningPackages && screeningPackages.length ? (
          <>
            <H4 mb={6}>{ packagesLabel || <Translate label={ProductPickerLocale.PACKAGES} />}</H4>
            <SimpleGrid columns={[1, 1, 2, 2, 3]} spacing={6} mb={8}>
              {
                screeningPackages
                  ? screeningPackages.map((productPackage) => (
                    <ProductCard
                      label={productPackage.name?.localizationStrings?.[language] || 'Undefined'}
                      key={productPackage.id}
                      id={productPackage.id || -1}
                      cost={productPackage.price}
                      description={productPackage.description?.localizationStrings?.[language]}
                      isChecked={
                        !!(productPackage.id && packages.indexOf(productPackage.id) !== -1)
                      }
                      onChange={handlePackageChange}
                      currencyLocalization={getCurrencyLocalizationFromLanguage(language)}
                    />
                  ))
                  : undefined
              }
            </SimpleGrid>
          </>
        ) : (<></>)
      }

      {
        screeningDefinitions && screeningDefinitions.length ? (
          <>
            <H4 mb={6}>{ screeningsLabel || <Translate label={ProductPickerLocale.PARTIAL_CHECKS} /> }</H4>
            <SimpleGrid columns={[1, 1, 2, 2, 3]} spacing={6} mb={6}>
              {
                screeningDefinitions
                  ? screeningDefinitions.map((productDefinition) => (
                    <ProductCard
                      label={productDefinition.name?.localizationStrings?.[language] || 'Undefined'}
                      key={productDefinition.id}
                      id={productDefinition.id || -1}
                      cost={productDefinition.price}
                      amount={definitions.find((def) => def.id === productDefinition.id)?.amount || 1}
                      amountLimit={productDefinition.countLimit}
                      multipleProduct={productDefinition.countable}
                      onAmountChange={handleDefinitionAmountChange}
                      description={productDefinition.description?.localizationStrings?.[language]}
                      onChange={handleDefinitionChange}
                      isChecked={
                        !!(productDefinition.id && definitions.map((def) => def.id).indexOf(productDefinition.id) !== -1)
                      }
                      isDisabled={
                        !!(productDefinition.id && disabledProducts.indexOf(productDefinition.id) !== -1
                          && !productDefinition.countable)
                      }
                      currencyLocalization={getCurrencyLocalizationFromLanguage(language)}
                    />
                  ))
                  : undefined
              }
            </SimpleGrid>
          </>
        ) : (<></>)
      }
    </>
  );
};

export default ProductPicker;
