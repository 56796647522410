import React, { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Box, Button, Flex, FormControl, FormErrorMessage, GridItem, SimpleGrid,
} from '@chakra-ui/react';
import { FileUpload } from '@scaut-sro/meepo';
import { useApolloClient } from '@apollo/client';
import { useKeycloak } from '@react-keycloak/web';
import { FileUploadOptions } from '@scaut-sro/meepo/lib/components/DynamicForm/DynamicForm.model';
import Translate from '../Translate/Translate';
import AuthorizationSectionLocale from './AuthorizationSection.locale';
import { AuthorizationSectionProps } from './AuthorizationSection.model';
import { getFileUploadApi } from '../../core/service/queries/FileUploadService';

const AuthorizationSection = ({ submitHandler, dynamicForm, isSubmitting }: AuthorizationSectionProps) => {
  const {
    handleSubmit, control, setValue, formState: { errors },
  } = useForm();

  const client = useApolloClient();
  const keycloak = useKeycloak();
  const fileUploadApi = getFileUploadApi(client, keycloak.keycloak.token as string);

  const fileUploadOptions: FileUploadOptions = useMemo(() => {
    if (dynamicForm?.form?.authorization?.properties?.fileUploadOptions) {
      return JSON.parse(dynamicForm?.form?.authorization?.properties?.fileUploadOptions as string);
    }
    return {};
  }, [dynamicForm?.form?.authorization?.properties?.fileUploadOptions]);

  return (
    <Box>
      <SimpleGrid columns={[1, 1, 1, 2, 3]} gridGap={6}>
        <GridItem colSpan={3}>
          <Translate label={AuthorizationSectionLocale.INFO_TEXT1} />
        </GridItem>
        <GridItem colSpan={1}>
          <form
            onSubmit={handleSubmit(
              (values) => submitHandler(values, dynamicForm?.id),
            )}
          >
            <FormControl isInvalid={errors.authorization} isRequired>
              <Controller
                control={control}
                name="authorization"
                rules={{
                  required: {
                    value: true,
                    message: 'FORM_ERROR_ATTACHMENT_MANDATORY',
                  },
                }}
                render={({ field }) => (
                  <FileUpload
                    value={field.value}
                    valueSetter={(newValue) => { setValue('authorization', newValue); }}
                    accept={fileUploadOptions?.accept}
                    isInvalid={errors.authorization}
                    onFileUpload={fileUploadApi.onFileUpload}
                    onFileDownload={fileUploadApi.onFileDownload}
                    onFileDelete={fileUploadApi.onFileDelete}
                    filesInfoGetter={fileUploadApi.filesInfoGetter}
                    uploadedFiledInfoGetter={fileUploadApi.uploadedFilesInfoGetter}
                    fileUploadId={dynamicForm?.form?.authorization?.properties?.fileUploadId || 'error-no-fileUploadId'}
                    templateFiles={fileUploadOptions?.templateFiles}
                    maxFileNumber={fileUploadOptions?.maxFileNumber}
                    maxGroupFileSize={fileUploadOptions?.maxGroupFileSize}
                    maxIndividualFileSize={fileUploadOptions?.maxIndividualFileSize}
                  />
                )}
              />
              <FormErrorMessage>
                {
                  errors
                  && errors.authorization
                  && errors.authorization.message
                  && (
                  <Translate
                    label={AuthorizationSectionLocale[errors.authorization.message as keyof AuthorizationSectionLocale]}
                  />
                  )
                }
              </FormErrorMessage>
            </FormControl>
            <Flex>
              <Button mt={4} type="submit" isLoading={isSubmitting}>
                <Translate label={AuthorizationSectionLocale.SEND} />
              </Button>
            </Flex>
          </form>
        </GridItem>
      </SimpleGrid>
    </Box>
  );
};

export default AuthorizationSection;
