import React, {
  FocusEvent, useEffect, useMemo, useState,
} from 'react';
import {
  Box, FormControl, FormLabel, GridItem, HStack,
  Input, Select, SimpleGrid, Table, Tbody, Td, Th, Thead, Tr,
} from '@chakra-ui/react';
import { CandidatesEditableProps } from './CandidatesEditable.model';
import { OrderType } from '../../build/generated-sources/enum/OrderType';
import { translate } from '../../core/localization/LocaleUtils';
import NewCheckLocale from '../../views/NewCheck/NewCheck.locale';
import { useGetUser } from '../../core/store/reducers/UserSettingsReducer';
import { CandidateIdentityGraphQLInput } from '../../build/generated-sources/dto/CandidateIdentityGraphQLInput';
import isEmailValid from '../../core/util/Validation';
import Translate from '../Translate/Translate';
import ClientDetailLocale from '../../views/ClientSettings/Client/ClientDetail.locale';
import { Language } from '../../build/generated-sources/enum/Language';

const CandidatesEditable: React.FunctionComponent<CandidatesEditableProps> = (props) => {
  const { language } = useGetUser();
  const {
    candidates, inviteCandidate, onCandidatesChange, orderType, newData, backToSingleCheck,
  } = props;

  const [rows, setRows] = useState<CandidateIdentityGraphQLInput[]>(candidates);

  useEffect(() => {
    const newRows = [...candidates] || [];
    if ((newRows.length === 0) || (
      orderType === OrderType.MULTIPLE && newRows[newRows.length - 1] && (
        !!newRows[newRows.length - 1].firstName
        && !!newRows[newRows.length - 1].lastName
        && !!newRows[newRows.length - 1].dateOfBirth
        && (!inviteCandidate
            || ((inviteCandidate && !!newRows[newRows.length - 1].email)
                && (inviteCandidate && !!newRows[newRows.length - 1].communicationLanguage)))
      )
    )) newRows.push({});
    setRows(newRows);
  }, [candidates, setRows, orderType, inviteCandidate]);

  useEffect(() => {
    if (newData === true) {
      setRows([]);
    }
  }, [newData]);

  useEffect(() => {
    if (backToSingleCheck === true) {
      setRows([{}]);
    }
  }, [backToSingleCheck]);

  const salutationOptionsList = useMemo(() => ([
    { value: 'MR', label: translate(NewCheckLocale.SALUTATION_MR, language) },
    { value: 'MRS', label: translate(NewCheckLocale.SALUTATION_MRS, language) },
  ]), [language]);

  const languageOptions = useMemo(() => ([
    { value: 'EN', label: translate(ClientDetailLocale.ENGLISH, language) },
    { value: 'CZ', label: translate(ClientDetailLocale.CZECH, language) },
  ]), [language]);

  const handleCandidatesEditable = (event: FocusEvent<HTMLInputElement>) => {
    const newCandidates = [...rows];
    const index: number = parseInt(event.target.id.split('-')[0], 10) - 1 || 0;
    const attribute: string = event.target.name;
    const { value } = event.target;

    newCandidates[index] = { ...candidates[index] };
    if (attribute === 'firstName') {
      newCandidates[index].firstName = value;
    } else if (attribute === 'lastName') {
      newCandidates[index].lastName = value;
    } else if (attribute === 'otherName') {
      newCandidates[index].otherName = value;
    } else if (attribute === 'dateOfBirth') {
      const validaceValue = value.split('-');
      if (validaceValue[0].length > 4) {
        const values = `${validaceValue[0].substring(0, 4)}-${validaceValue[1]}-${validaceValue[2]}`;
        newCandidates[index].dateOfBirth = values;
      } else {
        newCandidates[index].dateOfBirth = value;
      }
    } else if (attribute === 'email') {
      newCandidates[index].email = value;
    } else if (attribute === 'personalTitle') {
      newCandidates[index].personalTitle = value;
    } else if (attribute === 'jobPosition') {
      newCandidates[index].jobPosition = value;
    } else if (attribute === 'communicationLanguage') {
      newCandidates[index].communicationLanguage = value as Language;
    }

    onCandidatesChange(newCandidates);
  };

  const checkEmailValidity = (index: number): boolean => {
    const { email } = rows[index];
    if (!email) {
      return false;
    }
    return !isEmailValid(email);
  };

  const handleCandidatesSelectEditableChange = (id: string, value: string, name: string) => {
    const newCandidates = [...rows];
    const index: number = parseInt(id.split('-')[0], 10) - 1 || 0;
    const attribute: string = name;

    newCandidates[index] = { ...candidates[index] };
    if (attribute === 'personalTitle') {
      newCandidates[index].personalTitle = value;
    } else if (attribute === 'communicationLanguage') {
      newCandidates[index].communicationLanguage = value as Language;
    }

    onCandidatesChange(newCandidates);
  };

  const maxDate = () => {
    const nowDate:Date = new Date();
    nowDate.setFullYear(nowDate.getFullYear() - 15);
    return nowDate.toISOString().slice(0, 10);
  };

  return (
    <>
      <Box overflowX="auto">
        {orderType === 'MULTIPLE' && (
        <Table size="sm">
          <Thead>
            <Tr>
              <Th />
              <Th>{translate(NewCheckLocale.FIRST_NAME_REQUIRED, language)}</Th>
              <Th>{translate(NewCheckLocale.LAST_NAME_REQUIRED, language)}</Th>
              <Th>{translate(NewCheckLocale.DATE_OF_BIRTH_REQUIRED, language)}</Th>
              <Th>{translate(NewCheckLocale.JOB_POSITION, language)}</Th>
              {inviteCandidate ? (
                <>
                  <Th>{translate(NewCheckLocale.EMAIL_REQUIRED, language)}</Th>
                  <Th>{translate(NewCheckLocale.INVITATION_LANGUAGE_REQUIRED, language)}</Th>
                </>
              ) : undefined}
            </Tr>
          </Thead>
          <Tbody>
            {rows.map((candidate, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Tr key={index} height="51px">
                <Td>{index + 1}</Td>
                <Td p={1} pb={0}>
                  <Input
                    id={`${index + 1}-firstName`}
                    name="firstName"
                    placeholder={translate(NewCheckLocale.FIRST_NAME_PLACEHOLDER, language)}
                    defaultValue={candidate.firstName}
                    minW="120px"
                    onChange={handleCandidatesEditable}
                  />
                </Td>
                <Td p={1} pb={0}>
                  <Input
                    id={`${index + 1}-lastName`}
                    name="lastName"
                    placeholder={translate(NewCheckLocale.LAST_NAME_PLACEHOLDER, language)}
                    defaultValue={candidate.lastName}
                    minW="120px"
                    onChange={handleCandidatesEditable}
                  />
                </Td>
                <Td isNumeric p={1} pb={0}>
                  <Input
                    id={`${index + 1}-dateOfBirth`}
                    name="dateOfBirth"
                    max={maxDate()}
                    min="1900-01-01"
                    type="date"
                    placeholder={translate(NewCheckLocale.DATE_OF_BIRTH, language)}
                    defaultValue={candidates[index]?.dateOfBirth}
                    minW="120px"
                    onChange={handleCandidatesEditable}
                    value={candidates ? candidates[index]?.dateOfBirth : rows[index]?.dateOfBirth}
                  />
                </Td>
                <Td p={1} pb={0}>
                  <Input
                    id={`${index + 1}-jobPosition`}
                    name="jobPosition"
                    placeholder={translate(NewCheckLocale.JOB_POSITION, language)}
                    defaultValue={candidate.jobPosition}
                    minW="120px"
                    onChange={handleCandidatesEditable}
                  />
                </Td>
                {inviteCandidate ? (
                  <>
                    <Td p={1} pb={0}>
                      <Input
                        id={`${index + 1}-email`}
                        name="email"
                        placeholder={translate(NewCheckLocale.EMAIL_PLACEHOLDER, language)}
                        defaultValue={candidate.email}
                        minW="160px"
                        onChange={handleCandidatesEditable}
                        isInvalid={checkEmailValidity(index)}
                      />
                    </Td>
                    <Td p={1} pb={0}>
                      <Select
                        id={`${index + 1}-communicationLanguage`}
                        name="communicationLanguage"
                        minW="120px"
                        value={candidate.communicationLanguage}
                        onChange={(event) => {
                          handleCandidatesSelectEditableChange(event.target.id, event.target.value, event.target.name);
                        }}
                        borderColor="black"
                      >
                        <option> </option>
                        { languageOptions.map((communicationLanguage) => (
                          <option key={communicationLanguage.value} value={communicationLanguage.value}>
                            {`${communicationLanguage.label}`}
                          </option>
                        )) }
                      </Select>
                    </Td>
                  </>
                ) : undefined}
              </Tr>
            ))}
          </Tbody>
        </Table>
        )}
        {orderType === 'SINGLE' && (

          <SimpleGrid columns={[1, 1, 2, 2, 3, 3]} spacing={5} spacingX={10} mb={6}>
            {inviteCandidate && (
              <>
                <GridItem>
                  <HStack alignItems="top">
                    <FormLabel htmlFor="email" mr={0} color="blue.700">
                      <Translate label={NewCheckLocale.EMAIL} />
                    </FormLabel>
                    <Box color="red">*</Box>
                  </HStack>
                  <Input
                    id="email"
                    name="email"
                    size="lg"
                    onChange={handleCandidatesEditable}
                    value={candidates ? candidates[0]?.email : rows[0]?.email}
                  />
                </GridItem>
                <GridItem>
                  <HStack alignItems="top">
                    <FormLabel htmlFor="personalTitle" mr={0} color="blue.700">
                      <Translate label={NewCheckLocale.SALUTATION} />
                    </FormLabel>
                    <Box color="red">*</Box>
                  </HStack>
                  <Select
                    id="personalTitle"
                    name="personalTitle"
                    size="lg"
                    onChange={(event) => {
                      handleCandidatesSelectEditableChange(event.target.id, event.target.value, event.target.name);
                    }}
                    value={candidates ? candidates[0]?.personalTitle : rows[0]?.personalTitle}
                  >
                    <option value=""> </option>
                    { salutationOptionsList.map((salutation) => (
                      <option key={salutation.value} value={salutation.value}>
                        {`${salutation.label}`}
                      </option>
                    )) }
                  </Select>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <HStack alignItems="top">
                      <FormLabel htmlFor="communicationLanguage" mr={0} color="blue.700">
                        <Translate label={NewCheckLocale.INVITATION_LANGUAGE} />
                      </FormLabel>
                      <Box color="red">*</Box>
                    </HStack>
                    <Select
                      id="communicationLanguage"
                      name="communicationLanguage"
                      size="lg"
                      onChange={(event) => {
                        handleCandidatesSelectEditableChange(event.target.id, event.target.value, event.target.name);
                      }}
                      value={candidates ? candidates[0]?.communicationLanguage : rows[0]?.communicationLanguage}
                    >
                      <option value=""> </option>
                      { languageOptions.map((communicationLanguage) => (
                        <option key={communicationLanguage.value} value={communicationLanguage.value}>
                          {`${communicationLanguage.label}`}
                        </option>
                      )) }
                    </Select>
                  </FormControl>
                </GridItem>
              </>
            )}
            <GridItem>
              <HStack alignItems="top">
                <FormLabel htmlFor="firstName" mr={0} color="blue.700">
                  <Translate label={NewCheckLocale.FIRST_NAME} />
                </FormLabel>
                <Box color="red">*</Box>
              </HStack>
              <Input
                id="firstName"
                name="firstName"
                size="lg"
                onChange={handleCandidatesEditable}
                value={candidates ? candidates[0]?.firstName : rows[0]?.firstName}
              />
            </GridItem>
            <GridItem>
              <HStack alignItems="top">
                <FormLabel htmlFor="lastName" mr={0} color="blue.700">
                  <Translate label={NewCheckLocale.LAST_NAME} />
                </FormLabel>
                <Box color="red">*</Box>
              </HStack>
              <Input
                id="lastName"
                name="lastName"
                size="lg"
                onChange={handleCandidatesEditable}
                value={candidates ? candidates[0]?.lastName : rows[0]?.lastName}
              />
            </GridItem>
            <GridItem>
              <HStack alignItems="top">
                <FormLabel htmlFor="otherName" mr={0} color="blue.700">
                  <Translate label={NewCheckLocale.OTHER_NAME} />
                </FormLabel>
              </HStack>
              <Input
                id="otherName"
                name="otherName"
                size="lg"
                onChange={handleCandidatesEditable}
                value={candidates ? candidates[0]?.otherName : rows[0]?.otherName}
              />
            </GridItem>
            <GridItem>
              <HStack alignItems="top">
                <FormLabel htmlFor="dateOfBirth" mr={0} color="blue.700">
                  <Translate label={NewCheckLocale.DATE_OF_BIRTH} />
                </FormLabel>
                <Box color="red">*</Box>
              </HStack>
              <Input
                id="dateOfBirth"
                name="dateOfBirth"
                max={maxDate()}
                min="1900-01-01"
                type="date"
                size="lg"
                onChange={handleCandidatesEditable}
                value={candidates ? candidates[0]?.dateOfBirth : rows[0]?.dateOfBirth}
              />
            </GridItem>
            <GridItem colSpan={[1, 1, 1, 1, 2, 2]}>
              <FormLabel color="blue.700" htmlFor="jobPosition"><Translate label={NewCheckLocale.JOB_POSITION} /></FormLabel>
              <Input
                id="jobPosition"
                name="jobPosition"
                size="lg"
                onChange={handleCandidatesEditable}
                value={candidates ? candidates[0]?.jobPosition : rows[0]?.jobPosition}
              />
            </GridItem>
          </SimpleGrid>
        )}
      </Box>
    </>
  );
};

export default CandidatesEditable;
